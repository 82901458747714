import React from 'react'
import DrawerHeaderFiller from '../common/DrawerHeaderFiller'
import ClientNavigation from './ClientNavigation'
import ProfileBanner from './ProfileBanner'

export const ClientDrawerContent = () => {
  return (
    <>
      <DrawerHeaderFiller />
      <ProfileBanner />
      <ClientNavigation />
    </>
  )
}
