interface TimeEngagement {
  hours: number
  minutes: number
  seconds: number
}

// Input example: "04:05:00"
export const parseTotalEngagement = (
  totalEngagement: string | undefined,
): TimeEngagement | null => {
  try {
    const splitted = (totalEngagement || '0:0:0').split(':')
    const hours = Number(splitted[0])
    const minutes = Number(splitted[1])
    const seconds = Number(splitted[2])

    return {
      hours,
      minutes,
      seconds,
    }
  } catch (e) {
    console.error("Can't parse total engagement of user")
    console.error(e)
    return null
  }
}

export const formatTotalTime = (engagement: TimeEngagement | null): string | null => {
  if (engagement == null) {
    return null
  }

  const h = `${engagement.hours} hours`
  const m = `${engagement.minutes} minutes`
  const s = `${engagement.seconds} secs`

  if (engagement.hours && engagement.minutes) {
    return `${h} ${m}`
  }
  if (engagement.hours) {
    return h
  }
  if (engagement.minutes) {
    return m
  }
  if (engagement.seconds) {
    return s
  }
  return '0'
}

export function addTimeObjects(time1: TimeEngagement, time2: TimeEngagement) {
  let seconds = time1.seconds + time2.seconds
  let minutes = time1.minutes + time2.minutes + Math.floor(seconds / 60)
  seconds = seconds % 60
  const hours = time1.hours + time2.hours + Math.floor(minutes / 60)
  minutes = minutes % 60
  return { hours, minutes, seconds }
}
