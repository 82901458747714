import { useEffect, useState } from 'react'
import { RequestById, Response } from 'types'

interface Props<T> {
  id: string | number | undefined | null
  loadFunc: (request: RequestById) => Promise<Response<T>>
  warning: string
}

export const useEntityDataControl = <T>(props: Props<T>) => {
  const { id, loadFunc } = props

  const [data, setData] = useState<T | null>(null)
  const [loading, setLoading] = useState(true)
  const [hasError, setHasError] = useState(false)

  const reloadData = async () => {
    if (!id) {
      return
    }

    try {
      setLoading(true)
      setHasError(false)
      if (id !== undefined && id !== null) {
        const result = await loadFunc({ id: Number(id) })
        setData(result.data)
      }
    } catch (e) {
      setHasError(true)
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    if (id) {
      reloadData()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id])

  return { data, setData, loading, setLoading, reloadData, hasError, setHasError }
}
