import { AxiosResponse } from 'axios'
import {
  ApiSuccessResponse,
  BaseTableRequest,
  Course,
  CourseRequestCreate,
  CourseRequestLessonsOrder,
  RequestById,
  Response,
  TableResponse,
} from 'types'
import axiosInstance from './axios'

export const getCourses = async (request?: BaseTableRequest) => {
  return axiosInstance.post<BaseTableRequest, TableResponse<Course>>('/courses', request)
}

export const createCourse = async (request: CourseRequestCreate): Promise<Response<Course>> => {
  return axiosInstance.post('/courses/create', request)
}

export const checkCourseCanPublish = async (request: { id: number }): Promise<void> => {
  return axiosInstance.post('/courses/can-publish', request)
}

export const updateCourse = async (request: Course): Promise<Response<Course>> => {
  return axiosInstance.post('/courses/update', request)
}

export const getCourseById = async (request: RequestById): Promise<Response<Course>> => {
  return axiosInstance.post('/courses/read', request)
}

export const deleteCourse = async (request: RequestById): Promise<unknown> => {
  return axiosInstance.post('/courses/delete', request)
}

export const updateOrderOfCourseLessons = async (
  request: CourseRequestLessonsOrder,
): Promise<unknown> => {
  return axiosInstance.post('/courses/order-lessons', request)
}

export const duplicateCourse = (request: { id: number }): Promise<Response<{ id: number }>> => {
  return axiosInstance.post('/courses/duplicate', request)
}

export const checkCourseUniqueness = async (request: { name: string }): Promise<boolean> => {
  const result: AxiosResponse<ApiSuccessResponse> = await axiosInstance.post(
    '/courses/check-name',
    request,
  )

  return result.data.success
}
