import { Stack, Typography } from '@mui/material'
import SimpleSelect, { SelectValue } from 'components/select/SimpleSelect'
import WeekPicker from 'components/weekPicker/WeekPicker'
import { UIDateRange } from 'features/UI'
import { Moment } from 'moment/moment'
import React from 'react'
import { DateInterval, DateRangeType } from 'types'

const optionsRange = Object.keys(DateRangeType).map((it) => ({
  label: it,
  value: it,
}))

const optionsYear = Array.from(Array(30), (_, x) => 2023 + x).map((it) => ({
  label: String(it),
  value: String(it),
}))

const monthNames = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
]

const monthOptions = monthNames.map((it, idx) => ({
  label: it,
  value: String(idx + 1),
}))

export const DateRangeToolbar = ({
  rangeType,
  year,
  month,
  selectedDate,
  dateInterval,
  onChangeDateInterval,
  onChangeRange,
  onChangeYear,
  onChangeMonth,
  setSelectedDate,
}: {
  rangeType: string
  year: string
  month: string
  selectedDate: Moment
  dateInterval: DateInterval
  onChangeDateInterval: (value: DateInterval) => void
  onChangeRange: (name: string, value: SelectValue) => void
  onChangeYear: (name: string, value: SelectValue) => void
  onChangeMonth: (name: string, value: SelectValue) => void
  setSelectedDate: (value: Moment) => void
}) => {
  let component = null
  if (rangeType === DateRangeType.Yearly) {
    component = (
      <SimpleSelect
        options={optionsYear}
        onChange={onChangeYear}
        name="year"
        value={year}
        sx={{ minWidth: 100 }}
      />
    )
  }
  if (rangeType === DateRangeType.Monthly) {
    component = (
      <>
        <SimpleSelect
          options={optionsYear}
          onChange={onChangeYear}
          name="year"
          value={year}
          sx={{ minWidth: 100 }}
        />
        <SimpleSelect options={monthOptions} onChange={onChangeMonth} name="moth" value={month} />
      </>
    )
  }
  if (rangeType === DateRangeType.Weekly) {
    component = <WeekPicker selectedDate={selectedDate} onChangeSelectedDate={setSelectedDate} />
  }

  if (rangeType === DateRangeType.Range) {
    component = <UIDateRange value={dateInterval} onChange={onChangeDateInterval} />
  }

  return (
    <Stack direction="row" spacing={2} alignItems="center">
      <Stack direction="row" spacing={1} alignItems="center">
        <Typography>Period</Typography>
        <SimpleSelect
          options={optionsRange}
          onChange={onChangeRange}
          name="rangeType"
          value={rangeType}
          sx={{ minWidth: 110 }}
        />
      </Stack>
      <Stack direction="row" spacing={1} alignItems="center">
        {component}
      </Stack>
    </Stack>
  )
}
