import { Box, Button, Typography } from '@mui/material'
import { createMedicine, deleteMedicine, getMedicine } from 'api/medicine'
import { useGridControl } from 'hooks/useGridControl'
import { useRemoveModalControl } from 'hooks/useRemoveModalControl'
import { useShowControl } from 'hooks/useShowControl'
import React, { useCallback, useMemo } from 'react'
import { useMutation } from 'react-query'
import { getActionColumn, RowAction } from 'features/UI'
import { Medicine } from 'types'
import { useGetDataSource } from 'utils'
import AddMedicineModal from '../../../modals/AddMedicineModal'
import ConfirmRemoveModal from '../../../modals/ConfirmRemoveModal'
import NotificationSys from '../../../NotificationSystem'
import InfinityAgGrid from '../../../table/InfinityAgGrid'
import TableToolbar from '../../../table/TableToolbar'
import { medicinesColumnDefs } from '../const'

type Props = {
  carePersonId: number
  carePersonFullName: string
}

const ConcernProfileMedicinesTable = ({ carePersonId, carePersonFullName }: Props) => {
  const getMedicineDataSource = useGetDataSource(() => getMedicine(carePersonId))
  const { gridApi, onGridReady } = useGridControl(getMedicineDataSource)

  const [isOpen, handleOpen, handleClose] = useShowControl()

  const createMedicineMutation = useMutation(createMedicine, {
    onSuccess: () => {
      handleClose()
      gridApi?.purgeInfiniteCache()
      NotificationSys.showSuccess(`Medicine added successfully`)
    },
  })

  const { idToRemove, openDeleteModal, closeDeleteModal, removeLoading, handleConfirmRemove } =
    useRemoveModalControl({
      deleteFunc: deleteMedicine, // TODO
      successCallback: () => gridApi?.purgeInfiniteCache(),
      warning: "Can't remove the medicine",
    })

  const handleAddMedicineSubmit = useCallback(
    (medicine: Partial<Medicine>) => {
      createMedicineMutation.mutate({
        ...medicine,
        carePersonId: carePersonId,
      })
    },
    [createMedicineMutation, carePersonId],
  )

  const actions: RowAction[] = useMemo(() => {
    return [{ name: 'Delete', onClick: openDeleteModal }]
  }, [openDeleteModal])

  const columnDefsWithAction = [...medicinesColumnDefs, { ...getActionColumn(actions) }]

  return (
    <Box className="ag-theme-alpine">
      <Typography fontWeight={500} textAlign="center">
        Medicines for {carePersonFullName}
      </Typography>
      <Typography fontWeight={500} mb={2} textAlign="center">
        Include ALL prescription drugs over-the-counter drugs, vitamins, and herbal supplements):
      </Typography>

      <TableToolbar>
        <Button variant="outlined" sx={{ minWidth: 120 }} onClick={handleOpen}>
          Add Medicine
        </Button>
      </TableToolbar>
      <InfinityAgGrid
        columnDefs={columnDefsWithAction}
        onGridReady={onGridReady}
        domLayout="autoHeight"
      />
      <AddMedicineModal
        loading={createMedicineMutation.isLoading}
        isOpen={isOpen}
        handleClose={handleClose}
        onSubmit={handleAddMedicineSubmit}
      />
      <ConfirmRemoveModal
        entityToRemove="Medicine"
        loading={removeLoading}
        isOpen={idToRemove !== null}
        handleConfirm={handleConfirmRemove}
        handleClose={closeDeleteModal}
      />
    </Box>
  )
}

export default ConcernProfileMedicinesTable
