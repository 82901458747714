import { Button } from '@mui/material'
import ModalWithTextInput from 'components/modals/simple-modals/ModalWithTextInput'
import { useChangeEntityName } from 'hooks/useChangeEntityName'
import capitalize from 'lodash/capitalize'
import React from 'react'
import { Nullish, Response } from 'types'

export function UIControlChangeName<T extends { name: string }>({
  entityName,
  data,
  setData,
  loading,
  setLoading,
  updateRequest,
}: {
  entityName: string
  data: Nullish<T>
  loading: boolean
  setData: (newData: T) => void
  setLoading: (value: boolean) => void
  updateRequest: (request: T) => Promise<Response<T>>
}) {
  const { onChangeName, openEditName, isOpenEditName, closeEditName } = useChangeEntityName<T>({
    data,
    setLoading,
    setData,
    updateFunc: updateRequest,
    existedNameWarning: `Can't rename ${entityName} to existed name`,
    warning: `Can't rename ${entityName}`,
  })

  const name = data?.name || ''

  return (
    <>
      <Button variant="outlined" onClick={openEditName}>
        Change Name
      </Button>

      <ModalWithTextInput
        value={name}
        title={`Change ${capitalize(entityName)} name`}
        label={`${capitalize(entityName)} name`}
        errorText={`${capitalize(entityName)} name is required`}
        buttonText="Save"
        isOpen={isOpenEditName}
        loading={loading}
        handleClose={closeEditName}
        onSubmit={onChangeName}
      />
    </>
  )
}
