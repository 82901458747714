import { Checkbox } from '@mui/material'
import { ICellRendererParams } from 'ag-grid-community'

export const checkboxRenderer =
  <T,>(onChange: (data: T, checked: boolean) => void) =>
  // eslint-disable-next-line react/display-name
  (props: ICellRendererParams<T>) => {
    const checked = !!props.value
    return (
      <Checkbox
        checked={checked}
        onChange={(e) => {
          if (props.data) {
            onChange(props.data, e.target.checked)
          }
        }}
      />
    )
  }

export default checkboxRenderer
