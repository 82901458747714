import shuffle from 'lodash/shuffle'
import { Questionnaire, Quiz } from 'types'

export function reorderQuestions(data: Quiz | Questionnaire): Quiz | Questionnaire {
  const questions = shuffle(data.questions).map((question) => {
    return {
      ...question,
      answers: shuffle(question.answers),
    }
  })

  return {
    ...data,
    questions,
  }
}

export const ExamTitleMap = {
  quiz: 'Quiz',
  query: 'Query',
  questionnaire: 'Exam',
}
