import React, { ChangeEvent } from 'react'
import { FormLabel, Stack, TextField, TextFieldProps } from '@mui/material'

type Props = TextFieldProps & {
  label: string
  onValueChange: (value: string) => void
  sx?: object
}

const TextInput = (props: Props) => {
  const { label, value, onValueChange, sx = {} } = props

  const onChangeWrapper = (event: ChangeEvent<HTMLTextAreaElement>) => {
    const { value } = event.target
    onValueChange(value)
  }

  return (
    <Stack sx={sx}>
      <FormLabel required={props.required}>{label}</FormLabel>
      <TextField {...props} value={value} required onChange={onChangeWrapper} size="small" />
    </Stack>
  )
}

export default React.memo(TextInput)
