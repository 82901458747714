import { AxiosResponse } from 'axios'
import {
  ApiSuccessResponse,
  BaseTableRequest,
  CompleteLesson,
  Lesson,
  LessonRequestCreate,
  PartialNull,
  RequestById,
  Response,
  TableResponse,
} from 'types'
import { prepareTableRequestFilterByNameAndLLId } from 'utils'
import axiosInstance from './axios'

export const getLessonsByName = async (name: string) => {
  return getLessons(prepareTableRequestFilterByNameAndLLId(name))
}

export const getLessons = async (request: BaseTableRequest) => {
  return axiosInstance.post<BaseTableRequest, TableResponse<Lesson>>('/lessons', request)
}

export const createLesson = async (request: LessonRequestCreate): Promise<Response<Lesson>> => {
  return axiosInstance.post('/lessons/create', request)
}

export const checkLessonCanPublish = async (request: { id: number }): Promise<void> => {
  return axiosInstance.post('/lessons/can-publish', request)
}

export const updateLesson = async (request: PartialNull<Lesson>): Promise<Response<Lesson>> => {
  const fixedRequest = {
    ...request,
    article: request.article || {
      blocks: [],
    },
  }
  return axiosInstance.post('/lessons/update', fixedRequest)
}

export const getLessonById = async (request: RequestById): Promise<Response<Lesson>> => {
  return axiosInstance.post('/lessons/read', request)
}

export const deleteLesson = async (request: RequestById): Promise<unknown> => {
  return axiosInstance.post('/lessons/delete', request)
}

export const completeLesson = async (request: CompleteLesson): Promise<unknown> => {
  return axiosInstance.post('/lessons/complete', request)
}

export const duplicateLesson = async (request: {
  id: number
  courseId: number
}): Promise<Response<{ id: number }>> => {
  return axiosInstance.post('/lessons/duplicate', request)
}

export const moveLesson = async (request: {
  id: number
  courseId: number
}): Promise<ApiSuccessResponse> => {
  return axiosInstance.post('/lessons/move', request)
}

export const checkLessonUniqueness = async (request: { name: string }): Promise<boolean> => {
  const result: AxiosResponse<ApiSuccessResponse> = await axiosInstance.post(
    '/lessons/check-name',
    request,
  )

  return result.data.success
}
