import { Grid } from '@mui/material'
import VerticalButtonBox from 'components/buttons/VerticalButtonBox'
import AgreeTermsCheckbox from 'components/form-elements/AgreeTermsCheckbox'
import FormInput from 'components/form-elements/FormInput'
import FormSelect from 'components/form-elements/FormSelect'
import FormPasswordCheckList from 'components/FormPasswordCheckList'
import { educationOptions, preferredLanguageOptions, yearsOfExperience } from 'models/user.model'
import React, { useCallback } from 'react'
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'
import { routes } from 'routes/routes'
import { AuthFormTitle } from '../AuthFormTitle/AuthFormTitle'
import { traineeFormSettings, TraineeFormType } from './types'

interface Props {
  handleCreate: (form: TraineeFormType) => void
}

const SignUpTrainingForm = (props: Props) => {
  const { handleCreate } = props

  const methods = useForm<TraineeFormType>(traineeFormSettings)
  const { handleSubmit, watch } = methods

  const onSubmitHandler: SubmitHandler<TraineeFormType> = useCallback(
    (values: TraineeFormType) => {
      handleCreate(values)
    },
    [handleCreate],
  )

  const navigate = useNavigate()
  const onCancel = () => navigate(routes.signUp)
  const languageValue = watch('language')

  return (
    <FormProvider {...methods}>
      <AuthFormTitle>Create Account</AuthFormTitle>

      <Grid
        container
        spacing={1.5}
        component="form"
        onSubmit={handleSubmit(onSubmitHandler)}
        noValidate
        autoComplete="off"
      >
        <Grid item xs={12} sm={6}>
          <FormInput label="First Name" name="firstName" required fullWidth />
        </Grid>
        <Grid item xs={12} sm={6}>
          <FormInput label="Last Name" name="lastName" required fullWidth />
        </Grid>

        <Grid item xs={12}>
          <FormInput label="My Email" name="email" required fullWidth autoComplete="off" />
        </Grid>

        <Grid item xs={12} sm={12}>
          <FormInput
            label="My new password"
            required
            fullWidth
            name="myNewPassword"
            type="password"
            autoComplete="off"
          />
          <FormPasswordCheckList name="myNewPassword" />
        </Grid>
        <Grid item xs={12} sm={12}>
          <FormInput
            label="Same new password"
            required
            fullWidth
            name="sameNewPassword"
            type="password"
            autoComplete="off"
          />
        </Grid>

        <Grid item xs={12}>
          <FormSelect
            label="How much education did you complete?"
            name="education"
            required
            options={educationOptions}
          />
        </Grid>

        <Grid item xs={12}>
          <FormSelect
            label="Years of experience as a professional caregiver"
            name="yearsOfExperience"
            required
            options={yearsOfExperience}
          />
        </Grid>

        <Grid item sm={6} xs={12}>
          <FormSelect
            label="What is your preferred language?"
            name="language"
            required
            options={preferredLanguageOptions}
          />
        </Grid>
        {languageValue === 'other' && (
          <Grid item sm={6} xs={12}>
            <FormInput label="Other" required fullWidth name="languageOther" sx={{ mb: 1 }} />
          </Grid>
        )}

        <Grid item xs={12}>
          <AgreeTermsCheckbox name="readTermsAndConditions" />
        </Grid>

        <Grid item xs={12}>
          <VerticalButtonBox
            mt={1}
            onCancel={onCancel}
            buttonSaveType="submit"
            saveText="Create my Account"
          />
        </Grid>
      </Grid>
    </FormProvider>
  )
}

export default SignUpTrainingForm
