import { Button, Stack, Typography } from '@mui/material'
import { ImageEditorControl } from 'features/ImageEditor/components/ImageEditorControl/ImageEditorControl'
import React, { useMemo } from 'react'
import ImageUploading from 'react-images-uploading'
import { ImageListType, ImageType } from 'react-images-uploading/dist/typings'
import { base64ToFile } from 'utils'
import { v4 as uuid } from 'uuid'

const Image = ({ image }: { image: ImageType; index: number }) => {
  return (
    <Stack spacing={0.5} alignItems="flex-start">
      <Typography color="textSecondary">Icon of Topic</Typography>
      <img src={image.dataURL} alt="" style={{ maxHeight: 60, width: 'auto' }} />
    </Stack>
  )
}

const ImageUpload = ({
  imgData,
  hasRatioRestrictions = false,
  onSave,
  error,
}: {
  imgData: string | ImageType | null
  onSave: (imgData: ImageType) => void
  error: string | null
  hasRatioRestrictions?: boolean
}) => {
  const images: ImageType[] = useMemo(() => {
    if (imgData) {
      return [
        {
          dataURL: typeof imgData === 'string' ? imgData : imgData.dataURL,
        },
      ]
    }
    return []
  }, [imgData])

  const onChange = (value: ImageListType) => {
    onSave(value[0] || null)
  }

  const handleEditorSave = async (base64: string) => {
    const fileName = uuid()
    const file = await base64ToFile(base64, fileName)
    if (!file) return
    onSave({ dataURL: base64, file, fileName })
  }

  return (
    <ImageUploading value={images} onChange={onChange} acceptType={['jpg', 'jpeg', 'png']}>
      {({ imageList, onImageUpload, errors }) => {
        return (
          <Stack spacing={1} alignItems="flex-start">
            {imageList.map((image, index) => (
              <Image key={index} image={image} index={index} />
            ))}

            {hasRatioRestrictions && errors?.resolution && (
              <Typography variant="caption" color="error">
                The image resolution is incorrect. It should be 16:9.
              </Typography>
            )}
            {error && (
              <Typography variant="caption" color="error">
                {error}
              </Typography>
            )}

            <Stack direction="row" className="gap-8">
              <Button variant="outlined" onClick={onImageUpload}>
                Click to upload icon
              </Button>

              {imageList.length > 0 && imageList[0].dataURL && (
                <ImageEditorControl
                  imgData={imageList[0].dataURL}
                  hasRatioRestrictions={hasRatioRestrictions}
                  onSave={handleEditorSave}
                />
              )}
            </Stack>
          </Stack>
        )
      }}
    </ImageUploading>
  )
}

export default ImageUpload
