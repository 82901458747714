import { getTagsIsFilterable } from 'api/tags'
import { CheckboxInfo, UIFilterDropdown } from 'features/UI'
import React, { useMemo } from 'react'
import { useQuery } from 'react-query'
import { useSelector } from 'react-redux'
import { useAppDispatch } from 'store/hooks'
import { selectTagsFiltersIds, setTagFilters } from 'store/slice/tagsFilters.slice'
import { Nullish } from 'types/common.types'

export function TagsFilterDropdown() {
  const dispatch = useAppDispatch()
  const selectedTagIds: Nullish<number[]> = useSelector(selectTagsFiltersIds)

  const tagsQuery = useQuery(['getTagsIsFilterable'], () => getTagsIsFilterable(), {
    select: (response) => response.data.rows.map((tag) => ({ id: tag.id, label: tag.name })),
    cacheTime: 5 * 60 * 1000,
    staleTime: 5 * 60 * 1000,
  })

  const checkboxes = useMemo(() => {
    const result: CheckboxInfo<Nullish<number>>[] = [
      {
        id: null,
        label: 'All',
      },
    ]

    let tagCheckboxes: CheckboxInfo<number>[] = []
    if (tagsQuery.data) {
      tagCheckboxes = tagsQuery.data?.map((tag) => ({
        id: tag.id,
        label: tag.label,
      }))
    }

    return result.concat(tagCheckboxes)
  }, [tagsQuery.data])

  const handleClickCheckbox = (value: Nullish<number>[] | null) => {
    dispatch(setTagFilters(value))
  }

  return (
    <UIFilterDropdown<Nullish<number>>
      buttonName="Select"
      checkboxes={checkboxes}
      value={selectedTagIds}
      onClickCheckbox={handleClickCheckbox}
    />
  )
}
