export default function datePickerOverride(): object {
  return {
    MuiPickersPopper: {
      styleOverrides: {
        root: {
          zIndex: 1400,
          fontSize: '1rem',
        },
        paper: {
          fontSize: '1rem',
        },
      },
    },
    MuiDayPicker: {
      styleOverrides: {
        weekDayLabel: {
          fontSize: '1rem',
        },
        monthContainer: {
          fontSize: '1rem',
        },
      },
    },
    MuiPickersDay: {
      styleOverrides: {
        root: {
          fontSize: '1rem',
        },
      },
    },
  }
}
