import ImageIcon from '@mui/icons-material/Image'
import VisibilityIcon from '@mui/icons-material/Visibility'
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff'
import YouTubeIcon from '@mui/icons-material/YouTube'
import AudioIcon from '@mui/icons-material/Audiotrack'
import { Stack } from '@mui/material'
import { grey, orange, red, green } from '@mui/material/colors'
import { Box } from '@mui/system'
import React from 'react'

export function UIContentIcons({
  isPublished,
  hasVideo,
  hasImage,
  hasAudio,
}: {
  isPublished: boolean
  hasVideo: boolean
  hasImage: boolean
  hasAudio: boolean
}) {
  const iconSX = { fontSize: '1.5rem' }

  return (
    <Box display="flex" height="42px" alignItems="center">
      <Stack direction="row" spacing={1}>
        {isPublished && <VisibilityIcon sx={{ ...iconSX, color: grey[800] }} />}
        {!isPublished && <VisibilityOffIcon sx={{ ...iconSX, color: grey[600] }} />}

        {hasImage && <ImageIcon sx={{ ...iconSX, color: orange[400] }} />}

        {hasVideo && <YouTubeIcon sx={{ ...iconSX, color: red[700] }} />}

        {hasAudio && <AudioIcon sx={{ ...iconSX, color: green[700] }} />}
      </Stack>
    </Box>
  )
}
