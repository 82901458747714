import React from 'react'
import { Modal, Stack } from '@mui/material'
import { defaultModalStyle, defaultModalZIndex } from '../../../const'
import ModalFailPlaceholder from '../../placeholders/ModalFailPlaceholder'
import Loader from '../../placeholders/ModalLoadingPlaceholder'

interface Props {
  isOpen: boolean
  handleClose: () => void
  loading: boolean
  failedLoading: boolean
}

const modalSX = {
  ...defaultModalStyle,
  minHeight: 500,
}

const QuestionWizardModal = (props: React.PropsWithChildren<Props>) => {
  const { isOpen, handleClose, loading, failedLoading, children } = props

  return (
    <Modal sx={defaultModalZIndex} open={isOpen}>
      {isOpen ? (
        <Stack sx={modalSX}>
          <ModalFailPlaceholder
            show={failedLoading}
            py={7}
            error="Can't load data"
            handleClose={handleClose}
          />
          <Loader show={!failedLoading && loading} py={10} />
          {children}
        </Stack>
      ) : (
        <div />
      )}
    </Modal>
  )
}

export default QuestionWizardModal
