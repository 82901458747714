import { LoadingButton } from '@mui/lab'
import { Box, Button, Modal, Stack, Typography } from '@mui/material'
import { defaultModalStyle, defaultModalZIndex, minButtonWidth } from 'const'
import React from 'react'

export function UIConfirmDuplicateModal({
  entityName,
  entityType,
  loading,
  handleClose,
  handleConfirm,
}: {
  entityName: string
  entityType: string
  loading: boolean
  handleClose: () => void
  handleConfirm: () => void
}) {
  return (
    <Modal sx={defaultModalZIndex} open={true}>
      <Box
        sx={{
          ...defaultModalStyle,
          width: 420,
        }}
      >
        <Typography variant="h5" mb={3}>
          Duplicate {entityType}
        </Typography>
        <Typography variant="body1">
          You are trying to duplicate
          <br />
          <i>&ldquo;{entityName}&rdquo;</i>
        </Typography>
        <Typography variant="body1" mb={5}>
          Please confirm the action.
        </Typography>
        <Stack direction="row" spacing={2} justifyContent="flex-end" mt={4}>
          <Button variant="outlined" onClick={handleClose} sx={minButtonWidth}>
            Close
          </Button>
          <LoadingButton
            loading={loading}
            variant="contained"
            onClick={handleConfirm}
            sx={minButtonWidth}
          >
            Confirm
          </LoadingButton>
        </Stack>
      </Box>
    </Modal>
  )
}
