import { ColDef, RowDragEvent, SelectionChangedEvent } from 'ag-grid-community'
import InfinityAgGrid from 'components/table/InfinityAgGrid'
import { getActionColumn } from 'features/UI'
import { useCallback, useMemo } from 'react'
import { PathwayGroupedPoint } from 'types'
import { getPathwayColumns } from '../../models/PathwayColumns'

export function PathwayGrid({
  points,
  onDelete,
  onReorder,
  onSelectionChanged,
}: {
  points: PathwayGroupedPoint[]
  onDelete: (id: number) => void
  onReorder: (ids: number[]) => void
  onSelectionChanged: (point: PathwayGroupedPoint[]) => void
}) {
  const handleRowSelected = useCallback(
    (event: SelectionChangedEvent<PathwayGroupedPoint>) => {
      const nodes = event.api.getSelectedNodes().map((n) => n.data)

      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      onSelectionChanged(nodes.filter((n) => !!n).map((n) => n!))
    },
    [onSelectionChanged],
  )

  const handleRowDragEnd = useCallback(
    (e: RowDragEvent<PathwayGroupedPoint>) => {
      const orderedPointIds = e.api
        .getRenderedNodes()
        .filter((n) => !!n.data)
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        .map((n) => n.data!.id)
      onReorder(orderedPointIds)
    },
    [onReorder],
  )

  const actions = useMemo(() => {
    return [
      {
        name: 'Delete',
        onClick: onDelete,
      },
    ]
  }, [onDelete])

  const columns = getPathwayColumns()

  const columnDefs: ColDef[] = useMemo(() => {
    return [...columns, getActionColumn(actions)]
  }, [actions, columns])

  return (
    <InfinityAgGrid<PathwayGroupedPoint>
      columnDefs={columnDefs}
      rowDragManaged
      animateRows
      rowModelType="clientSide"
      rowData={points}
      rowDragMultiRow
      rowSelection="multiple"
      rowDragEntireRow
      floatingFiltersHeight={0}
      onRowDragEnd={handleRowDragEnd}
      onSelectionChanged={handleRowSelected}
    />
  )
}
