import React from 'react'
import QuestionMarkIcon from '@mui/icons-material/QuestionMark'
import { Box, IconButton, Typography } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'

interface Props {
  handleClose: () => void
  intro: string
  name: string
}

const FirstScreenQuestionnaire = ({ intro, name, handleClose }: Props) => {
  return (
    <Box textAlign="center">
      <IconButton
        size="small"
        onClick={handleClose}
        disableRipple
        sx={{ position: 'absolute', right: 24, top: 24 }}
      >
        <CloseIcon />
      </IconButton>
      <QuestionMarkIcon
        sx={{
          fontSize: '8rem',
          textAlign: 'center',
          background: '#99285F',
          color: 'white',
          borderRadius: '100%',
          padding: '12px',
          mb: 2,
        }}
      />
      <Typography variant="h5">{name}</Typography>
      <Box sx={{ mt: 2 }} textAlign="center">
        <Typography variant="body1" color="#666" fontWeight={400} component="span">
          {intro}
        </Typography>
      </Box>
    </Box>
  )
}

export default FirstScreenQuestionnaire
