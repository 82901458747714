import NotificationSys from 'components/NotificationSystem'
import React, { useCallback, useMemo, useState } from 'react'
import { useMutation } from 'react-query'
import { useNavigate } from 'react-router-dom'
import { withIdParam } from 'utils'
import { RequestById, Response } from 'types'
import { UIConfirmDuplicateModal } from '../components'

export function useDuplicateControl<T extends { id: number; name: string }>({
  entityName,
  apiMethod,
  routeSuccess,
}: {
  entityName: string
  apiMethod: (request: RequestById) => Promise<Response<{ id: number }>>
  routeSuccess: string
}) {
  const [item, setItem] = useState<T>()
  const duplicateMutation = useMutation(apiMethod)
  const navigate = useNavigate()

  const cancelDuplicate = useCallback(() => {
    setItem(undefined)
  }, [setItem])

  const confirmDuplicate = useCallback(() => {
    if (!item) {
      return
    }

    duplicateMutation.mutate(
      { id: item.id },
      {
        onSuccess: ({ data }) => {
          navigate(withIdParam(routeSuccess, data.id))

          NotificationSys.showSuccess(`${entityName} was successfully duplicated`)
        },
      },
    )
  }, [item, duplicateMutation, navigate, routeSuccess, entityName])

  const DuplicateModal = useMemo(
    () => (
      <>
        {item && (
          <UIConfirmDuplicateModal
            entityName={item.name}
            handleClose={cancelDuplicate}
            handleConfirm={confirmDuplicate}
            loading={duplicateMutation.isLoading}
            entityType={entityName}
          />
        )}
      </>
    ),
    [cancelDuplicate, confirmDuplicate, duplicateMutation.isLoading, entityName, item],
  )

  return { setItem, DuplicateModal }
}
