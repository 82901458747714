import { Stack } from '@mui/material'
import { PickersDay } from '@mui/x-date-pickers'
import { PickersDayProps } from '@mui/x-date-pickers/PickersDay/PickersDay'
import moment, { Moment } from 'moment'
import React from 'react'
import { DateInterval } from 'types'
import { UIDatePicker } from '../UIDatePicker/UIDatePicker'
import { UIDatePickerDay } from '../UIDatePickerDay/UIDatePickerDay'

export function UIDateRange({
  value,
  onChange,
}: {
  value: DateInterval
  onChange: (value: DateInterval) => void
}) {
  const handleChange = (field: 'startDate' | 'endDate') => (dateValue: Moment | null) => {
    const newValue = {
      ...value,
      [field]: dateValue,
    }
    const revertedValue = {
      startDate: moment(newValue.startDate).isSameOrBefore(newValue.endDate)
        ? newValue.startDate
        : newValue.endDate,
      endDate: moment(newValue.endDate).isAfter(newValue.startDate)
        ? newValue.endDate
        : newValue.startDate,
    }
    onChange(revertedValue)
  }

  // set background for days in interval
  const renderWrappedWeekDay = (
    date: Moment,
    selectedDates: Array<Moment | null>,
    pickersDayProps: PickersDayProps<Moment>,
  ) => {
    const dateClone = moment(date).clone()
    const selectedDateClone = moment(selectedDates[0]).clone()

    if (!selectedDateClone) {
      return <PickersDay {...pickersDayProps} />
    }

    const dayIsBetween = dateClone.isBetween(value.startDate, value.endDate, null, '[]')
    const isFirstDay = dateClone.isSame(value.startDate, 'day')
    const isLastDay = dateClone.isSame(value.endDate, 'day')

    return (
      <UIDatePickerDay
        {...pickersDayProps}
        selected={dayIsBetween}
        outsideCurrentMonth={false}
        dayIsBetween={dayIsBetween}
        isFirstDay={isFirstDay}
        isLastDay={isLastDay}
      />
    )
  }

  return (
    <Stack direction="row" alignItems="center" className="gap-8">
      <UIDatePicker
        label="Start Date"
        value={value.startDate}
        onChange={handleChange('startDate')}
        renderDay={renderWrappedWeekDay}
      />

      <div>-</div>

      <UIDatePicker
        label="End Date"
        value={value.endDate}
        onChange={handleChange('endDate')}
        renderDay={renderWrappedWeekDay}
      />
    </Stack>
  )
}
