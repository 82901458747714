import React from 'react'
import { Typography } from '@mui/material'
import HelpTooltip from '../tooltips/HelpTooltip'
import styled from '@emotion/styled'

interface Props {
  children: React.ReactNode
  color?: string
}

const TabsTitle = (props: Props) => {
  return (
    <Typography
      variant="body1"
      fontWeight={400}
      fontSize="1.1rem"
      color={props.color}
      textTransform="none"
    >
      {props.children}
    </Typography>
  )
}

const TooltipDiv = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`

export const getTitle = (text: string, color?: string, tooltip?: string) => {
  const item = <TabsTitle color={color}>{text}</TabsTitle>

  if (tooltip) {
    return (
      <HelpTooltip text={tooltip}>
        <TooltipDiv>{item}</TooltipDiv>
      </HelpTooltip>
    )
  }

  return item
}

export default React.memo(TabsTitle)
