import { getByIdQuestion, updateQuestion } from 'api/question'
import NotificationSys from 'components/NotificationSystem'
import FailPlaceholder from 'components/placeholders/FailPlaceholder'
import LoadingPlaceholder from 'components/placeholders/LoadingPlaceholder'
import React, { useMemo, useState } from 'react'
import { useMutation, useQuery } from 'react-query'
import { routes } from 'routes/routes'
import { EQuestionType, Question } from 'types'
import { getRoute, useSearchParamsObject } from 'utils'
import { QuestionEditor } from '../QuestionEditor/QuestionEditor'

export function QuestionPageEdit() {
  const params = useSearchParamsObject()
  const id = useMemo(() => {
    const value = parseInt(params.id as string)
    if (!isNaN(value)) {
      return value
    }

    return 0
  }, [params])

  const [question, setQuestion] = useState<Question>()

  const getQuestionQuery = useQuery(['getByIdQuestion', id], () => getByIdQuestion({ id }), {
    enabled: !!id,
    onSuccess: ({ data }) => {
      setQuestion(data)
    },
  })

  const saveQuestionMutation = useMutation(updateQuestion)

  const onSubmitChangeName = (newName: string) => {
    if (!!question) {
      saveQuestionMutation.mutate(
        {
          ...question,
          name: newName,
        },
        {
          onSuccess: () => {
            NotificationSys.showSuccess('The name was changed')
            setQuestion({ ...question, name: newName })
          },
        },
      )
    }
  }

  const onSaveQuestion = (entity: Question) => {
    saveQuestionMutation.mutate(entity, {
      onSuccess: () => {
        NotificationSys.showSuccess('The question was saved in the Questions library')
      },
    })
  }

  if (getQuestionQuery.isLoading) {
    return <LoadingPlaceholder />
  }

  if (getQuestionQuery.isError || !question) {
    return (
      <FailPlaceholder
        error={"Can't load data of question"}
        link={getRoute(routes.questions, { type: EQuestionType.question })}
        linkText="Back to questions table"
      />
    )
  }

  return (
    <QuestionEditor
      editorType="edit"
      data={question}
      onSubmitChangeName={onSubmitChangeName}
      onSaveQuestion={onSaveQuestion}
      saving={saveQuestionMutation.isLoading}
    />
  )
}
