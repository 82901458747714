import React from 'react'
import { Stack, Typography } from '@mui/material'
import { Link } from 'react-router-dom'

interface Props {
  error: string
  link?: string
  linkText?: string
  mb?: number
}

const FailPlaceholder = (props: Props) => {
  const { error, link, linkText, mb } = props

  return (
    <Stack
      direction="column"
      justifyContent="center"
      alignItems="center"
      flexGrow={1}
      spacing={0.25}
      mb={mb}
    >
      <Typography variant="body1" fontWeight={400} textAlign="center">
        {error}
      </Typography>
      {linkText && link && (
        <Typography>
          <Link to={link}>{linkText}</Link>
        </Typography>
      )}
    </Stack>
  )
}

export default FailPlaceholder
