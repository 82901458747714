import { Box, Button } from '@mui/material'
import { addLearnArticleEngagementMetric, addQueryEngagementMetric } from 'api/metrics'
import { getQueryById } from 'api/query'
import { useAuthContext } from 'components/context/AuthProvider'
import { minButtonWidth } from 'const'
import { noop } from 'lodash'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { LessonAnsweredQuestion, Query } from 'types'
import { IMetricScope, IMetricType } from 'types/metric.types'
import { formatMetricDate } from 'utils'
import QuestionScreen from '../../question-screen'
import QuestionnaireFooter from '../../QuestionnaireFooter'
import useLearnMore from '../../use-learn-more'
import { QueryMetric } from '../QueryMetric'
import FinalScreen from '../summary'

interface Props {
  id: number
  handleClose: () => void
  onLoadingStart: () => void
  onLoadingFail: () => void
  onLoadingFinish: () => void
  retakeQuiz: () => void
  answers: LessonAnsweredQuestion[]
}

const FIRST_SCREEN = -1

const ModalContent = (props: Props) => {
  const { handleClose, id } = props
  const { user } = useAuthContext()

  const [stage, setStage] = useState(FIRST_SCREEN)
  const [query, setQuery] = useState<Query>()
  const answers = useMemo(() => {
    return props.answers.reduce((acc, item) => {
      acc[item.questionId] = item
      return acc
    }, {} as Record<string, LessonAnsweredQuestion>)
  }, [props.answers])

  const [openLearnMoreArticle] = useLearnMore(query?.learnArticleId ?? 0)

  useEffect(() => {
    props.onLoadingStart()

    getQueryById({ id }).then(
      (r) => {
        setQuery(r.data)
        props.onLoadingFinish()
      },
      () => props.onLoadingFail(),
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const learnMoreClicked = useCallback(async () => {
    await openLearnMoreArticle()
    handleClose()
  }, [openLearnMoreArticle, handleClose])

  const question = useMemo(() => {
    const index = Math.floor(stage)
    return query?.questions[index]
  }, [query, stage])

  const answer = useMemo(() => {
    if (question) {
      return answers[question?.id]
    }
  }, [answers, question])

  const stageType = useMemo(() => {
    if (stage == FIRST_SCREEN) {
      return 'summary'
    }

    return 'question'
  }, [stage])

  const onClickNext = () => {
    setStage(stage + 1)
  }

  const onClickPrevious = () => {
    setStage(stage - 1)
  }

  const isPreviousVisible = stage !== FIRST_SCREEN
  const isNextVisible = stage < (query?.questions.length ?? 0) - 1

  const handleSaveAudioMetric = async (value: number, uuid: string, scope: IMetricScope) => {
    if (!user || !query || !query.learnArticleId) {
      return
    }

    addLearnArticleEngagementMetric({
      userId: user.id,
      learnArticleId: query.learnArticleId,
      learnTopicId: query.learnArticle.topicId,
      scope,
      engagedFrom: formatMetricDate(value),
      engagedTo: formatMetricDate(),
    })

    addQueryEngagementMetric({
      userId: user.id,
      queryId: query.id,
      learnArticleId: query.learnArticleId,
      scope: IMetricScope.audio,
      engagedFrom: formatMetricDate(value),
      engagedTo: formatMetricDate(),
    })
  }

  if (!query) {
    return null
  }

  const renderStage = () => {
    if (stageType == 'summary')
      return (
        <FinalScreen
          retakeQuiz={props.retakeQuiz}
          handleClose={handleClose}
          name={query.name}
          learnMoreAvailable={!!query.learnArticleId}
          onLearnMoreClicked={learnMoreClicked}
        />
      )

    if (!question) return null

    return (
      <QuestionScreen
        answer={answer?.answer}
        onChange={noop}
        metricType={IMetricType.learnArticle}
        metricEntityId={query.id}
        onSaveAudioMetric={handleSaveAudioMetric}
        handleClose={handleClose}
        question={question}
        disabled={true}
      />
    )
  }

  return (
    <>
      <Box sx={{ mb: 4, flex: 1 }}>{renderStage()}</Box>
      <QueryMetric query={query} />

      <Box>
        <QuestionnaireFooter
          leftButton={
            <Button variant="outlined" onClick={onClickPrevious} sx={minButtonWidth}>
              {'< Previous'}
            </Button>
          }
          rightButton={
            <Button variant="outlined" onClick={onClickNext} sx={minButtonWidth}>
              {'Next >'}
            </Button>
          }
          isPreviousVisible={isPreviousVisible}
          isNextVisible={isNextVisible}
        />
      </Box>
    </>
  )
}

export default ModalContent
