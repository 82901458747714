import { zodResolver } from '@hookform/resolvers/zod'
import { Box, Typography } from '@mui/material'
import { forgotPassword } from 'api/auth'
import VerticalButtonBox from 'components/buttons/VerticalButtonBox'
import FormInput from 'components/form-elements/FormInput'
import NotificationSys from 'components/NotificationSystem'
import { EMAIL_IS_INVALID } from 'const'
import React from 'react'
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form'
import { useMutation } from 'react-query'
import { useNavigate } from 'react-router-dom'
import { object, string, TypeOf } from 'zod'
import { AuthFormTitle } from '../AuthFormTitle/AuthFormTitle'
import { AuthLogo } from '../AuthLogo/AuthLogo'

const formSchema = object({
  email: string().min(1, 'Email is required').email(EMAIL_IS_INVALID),
})

type FormType = TypeOf<typeof formSchema>

export const AuthPageForgotPassword = () => {
  const navigate = useNavigate()

  const methods = useForm<FormType>({
    mode: 'onChange',
    resolver: zodResolver(formSchema),
    defaultValues: {
      email: '',
    },
  })
  const { handleSubmit } = methods

  const forgotMutation = useMutation(forgotPassword, {
    onSuccess: () => {
      NotificationSys.showSuccess(`Email sent successfully`)
    },
  })

  const onClose = () => {
    navigate(-1)
  }

  const onSubmitHandler: SubmitHandler<FormType> = (values: FormType) => {
    forgotMutation.mutate({
      email: values.email,
    })
  }

  return (
    <FormProvider {...methods}>
      <Box component="form" onSubmit={handleSubmit(onSubmitHandler)} width="100%" noValidate>
        <AuthLogo mb={2} />
        <AuthFormTitle mb={0.5}>Forgot Password</AuthFormTitle>

        <Typography textAlign="center" sx={{ mb: 0.5 }} color="grey.700">
          No worries. Send us your email
          <br />
          and we will help you reset it.
        </Typography>
        <FormInput label="Email Address" name="email" required fullWidth />

        {forgotMutation.isSuccess && (
          <Typography textAlign="center" color="green" sx={{ mt: 4 }}>
            Please check your email. Instructions have been sent to your inbox.
          </Typography>
        )}

        <VerticalButtonBox
          buttonSaveType="submit"
          saveText="Send"
          disabledSave={forgotMutation.isSuccess}
          loading={forgotMutation.isLoading}
          onCancel={onClose}
          onSave={handleSubmit(onSubmitHandler)}
          mt={2}
        />
      </Box>
    </FormProvider>
  )
}
