import { addQuestion } from 'api/question'
import NotificationSys from 'components/NotificationSystem'
import { defaultQueryInstruction, defaultQuestionInstruction } from 'const'
import React, { useCallback, useState } from 'react'
import { useMutation } from 'react-query'
import { useNavigate } from 'react-router-dom'
import { routes } from 'routes/routes'
import { EQuestionType, Question } from 'types'
import { useSearchParamsObject, withIdParam } from 'utils'
import { QuestionEditor } from '../QuestionEditor/QuestionEditor'

export function QuestionPageAdd() {
  const queryParams = useSearchParamsObject()
  const type = queryParams.type as EQuestionType
  const name = queryParams.name as string

  const navigate = useNavigate()
  const [question, setQuestion] = useState<Question>({
    name: name || '',
    type: type || EQuestionType.question,
    answers: type == EQuestionType.question ? [] : [{ text: 'Yes' }, { text: 'No' }],
    correctAnswer: type == EQuestionType.question ? '' : 'Yes',
    explanation: '',
    id: 0,
    instructions: type === 'query' ? defaultQuestionInstruction : defaultQueryInstruction,
    question: '',
    stem: '',
  })

  const addQuestionMutation = useMutation(addQuestion, {
    onSuccess: ({ data }) => {
      NotificationSys.showSuccess('The question was saved in the Questions library')
      if (data !== undefined) {
        navigate(withIdParam(routes.questionEdit, data.id))
      }
    },
  })

  const handleSaveQuestion = useCallback(
    (question: Question) => {
      addQuestionMutation.mutate(question)
    },
    [addQuestionMutation],
  )

  const handleChangeName = useCallback(
    (name: string) => {
      setQuestion({ ...question, name })
    },
    [question],
  )

  return (
    <QuestionEditor
      editorType="add"
      data={question}
      onSubmitChangeName={handleChangeName}
      onSaveQuestion={handleSaveQuestion}
      saving={addQuestionMutation.isLoading}
    />
  )
}
