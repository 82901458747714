import { getCareArticleById, updateCareArticle } from 'api/careArticles'
import { getLibraryArticleById, updateLibraryArticle } from 'api/learnArticles'
import { getLessonById, updateLesson } from 'api/lessons'
import { routes } from 'routes/routes'
import { RequestById, Response } from 'types'

export interface IArticleOptionsByType {
  [key: string]: {
    parentHref: string
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    loadFunc: (request: RequestById) => Promise<Response<any>>
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    updateFunc: any
  }
}

export const ArticleOptionsByType: IArticleOptionsByType = {
  care: {
    parentHref: routes.timelinePostEdit,
    loadFunc: getCareArticleById,
    updateFunc: updateCareArticle,
  },
  learn: {
    parentHref: routes.learnArticleEdit,
    loadFunc: getLibraryArticleById,
    updateFunc: updateLibraryArticle,
  },
  lesson: {
    parentHref: routes.lessonEdit,
    loadFunc: getLessonById,
    updateFunc: updateLesson,
  },
}

export type ContentType = 'learn' | 'care' | 'lesson'

export enum AudioLanguages {
  EN = 'en',
  ES = 'es',
}

export const AudioLanguagesOptions = {
  [AudioLanguages.EN]: {
    value: 'en',
    label: 'English',
  },
  [AudioLanguages.ES]: {
    value: 'es',
    label: 'Spanish',
  },
}

export interface AudioFile {
  file: File
  src: string
}

export type AudioFiles = Record<AudioLanguages, AudioFile | null>
