import React from 'react'
import { IconButton, Stack, Typography } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'

interface Props {
  children: React.ReactNode
  handleClose: () => void
}

const ModalHeader = (props: Props) => {
  const { children, handleClose } = props

  return (
    <Stack direction="row" justifyContent="space-between" spacing={2} alignItems="center">
      {children}
      <IconButton size="small" onClick={handleClose} disableRipple sx={{ alignSelf: 'flex-start' }}>
        <CloseIcon />
      </IconButton>
    </Stack>
  )
}

export default React.memo(ModalHeader)
