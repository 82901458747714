import { LabelValue } from 'features/Metadata'
import { useMemo } from 'react'
import { Course } from 'types'

export function useCourseMetadata(course: Course | null): LabelValue[] {
  return useMemo(() => {
    if (!course) {
      return []
    }

    return [
      {
        label: 'Lessons Total',
        value: course.countOfLessons,
      },
      {
        label: 'Lessons Published',
        value: course.countOfPublishedLessons,
      },
    ]
  }, [course])
}
