import { EAccessCodeType } from 'types'

export const organizationSingleNumbers = [
  {
    value: 'ALF',
    label: 'A',
  },
  {
    value: 'PCA',
    label: 'P',
  },
]

export const typeOptions = [
  {
    label: 'Promo Code',
    value: EAccessCodeType.promoCode,
  },
  {
    label: 'Institutional access code',
    value: EAccessCodeType.institutionAccessCode,
  },
]

export const organizationTypes = [
  {
    value: 'ALF',
    label: 'ALF',
  },
  {
    value: 'PCA',
    label: 'PCA',
  },
]
