export enum IMetricScope {
  lesson = 'lesson',
  quiz = 'quiz',
  query = 'query',
  audio = 'audio',
  video = 'video',
  learnArticle = 'learnArticle',
  careArticle = 'careArticle',
  questionnaire = 'questionnaire',
}

export enum IMetricType {
  lesson = 'lesson',
  learnArticle = 'learnArticle',
  careArticle = 'careArticle',
  questionnaire = 'questionnaire',
  query = 'query',
}

export interface MetricRequestLessonViewCount {
  lessonId: number
  courseId: number
  userId: number
  viewedAt: string
}

export interface MetricRequestLessonEngagement {
  lessonUuid: string
  lessonId: number
  courseId: number
  userId: number
  engagedFrom: string
  engagedTo: string
  scope: IMetricScope
}

export interface MetricRequestTotalEngagement {
  userId: number
  engagedFrom: string
  engagedTo: string
}

export interface MetricRequestLearnArticleEngagement {
  userId: number
  learnArticleId: number
  learnTopicId: number
  scope: IMetricScope
  engagedFrom: string
  engagedTo: string
}

export interface MetricRequestQueryEngagement {
  userId: number
  queryId: number
  learnArticleId: number
  scope: IMetricScope
  engagedFrom: string
  engagedTo: string
}

export interface MetricRequestCareArticleEngagement {
  userId: number
  careArticleId: number
  scope: IMetricScope
  engagedFrom: string
  engagedTo: string
}

export interface MetricRequestQuestionnaireEngagement {
  userId: number
  questionnaireId: number
  scope: IMetricScope
  engagedFrom: string
  engagedTo: string
}
