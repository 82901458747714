import { styled } from '@mui/material/styles'

const DrawerHeaderFiller = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'end',
  padding: theme.spacing(0, 1),
  ...theme.mixins.toolbar,
  justifyContent: 'flex-end',
}))

export default DrawerHeaderFiller
