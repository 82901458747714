import { Stack, Typography } from '@mui/material'
import { useDrawersContext } from 'components/context/ClientsDrawersProvider'
import { UITag } from 'features/UI'
import React, { useCallback, useMemo } from 'react'
import { useNavigate } from 'react-router-dom'
import { routes } from 'routes/routes'
import { useActions } from 'store/hooks'
import { ProgressExam } from 'types'
import { ProgressItemViewButton } from '../ProgressItemViewButton/ProgressItemViewButton'

export function ProgressExamItem({ exam }: { exam: ProgressExam }) {
  const navigate = useNavigate()
  const { handleOpenQuestioning } = useDrawersContext()

  const { getTimelineById } = useActions()

  const buttonTitle = useMemo(() => {
    if (exam.questionnaireStatus === 'new') {
      return 'Take'
    }

    if (exam.questionnaireStatus === 'retake') {
      return 'Retake'
    }

    return 'Take'
  }, [exam])

  const handleView = useCallback(() => {
    if (!exam || exam.questionnaireId === null) {
      return
    }

    handleOpenQuestioning({
      mode: 'take',
      id: exam.questionnaireId,
      type: 'questionnaire',
      onQuestioningTaken: () => {
        getTimelineById({})
      },
    })

    navigate(routes.journey)
  }, [getTimelineById, handleOpenQuestioning, exam, navigate])

  return (
    <Stack
      direction="row"
      alignItems="center"
      sx={{
        background: 'white',
        padding: 1.5,
        borderRadius: 2,
        width: '100%',
        cursor: 'pointer',
        gap: '8px',
      }}
      onClick={
        exam.isQuestionnaireInTimeline && exam.questionnaireStatus !== 'done'
          ? handleView
          : undefined
      }
    >
      <Stack direction="row" alignItems="center" justifyContent="space-between" flexGrow={1}>
        <Typography>
          <b>Exam:</b>
          {` ${exam.questionnaireName} `}
        </Typography>

        {exam.questionnaireStatus !== 'done' && (
          <ProgressItemViewButton
            hasTooltip={!exam.isQuestionnaireInTimeline}
            disabled={!exam.isQuestionnaireInTimeline}
            onClick={handleView}
            title={buttonTitle}
          />
        )}

        {exam.questionnaireStatus === 'done' && <UITag background="#8094A1">Done</UITag>}
      </Stack>
    </Stack>
  )
}
