import { Stack } from '@mui/material'
import { getLessonById, updateLesson } from 'api/lessons'
import { createQuiz } from 'api/quiz'
import Breadcrumbs from 'components/Breadcumbs'
import ConfirmRemoveModal from 'components/modals/ConfirmRemoveModal'
import LinkToLearnLibraryModal from 'components/modals/lms/LinkToLearnLibraryModal'
import NotificationSys from 'components/NotificationSystem'
import PageTitle from 'components/page/PageTitle'
import FailPlaceholder from 'components/placeholders/FailPlaceholder'
import LoadingPlaceholder from 'components/placeholders/LoadingPlaceholder'
import { ExamPreviewPanel, ExamQuizControlAdd } from 'features/Exam'
import { LinkArticleWithTags } from 'features/Tags'
import {
  UIControlBanner,
  UIControlChangeName,
  UIEntityItemArticle,
  UIEntityItemConnection,
} from 'features/UI'
import { useChangeEntityProperty } from 'hooks/useChangeEntityName'
import { useEntityDataControl } from 'hooks/useEntityDataControl'
import { useRemoveModalControl } from 'hooks/useRemoveModalControl'
import React, { useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { routes } from 'routes/routes'
import { Lesson } from 'types'
import { useSearchParamsObject } from 'utils'

const getPath = (data: Lesson) => {
  const lessonName = data.name
  const courseName = data.courseName
  const courseId = data.courseId

  return [
    {
      href: routes.courses,
      text: 'Courses',
    },
    {
      id: courseId,
      href: routes.courseEdit,
      text: courseName,
    },
    {
      text: lessonName,
    },
  ]
}

export const LessonEditPage = () => {
  const { id } = useSearchParamsObject()
  const { data, setData, loading, setLoading, reloadData } = useEntityDataControl({
    id,
    loadFunc: getLessonById,
    warning: "Can't load lesson",
  })

  const { state } = useLocation()
  const navigate = useNavigate()

  useEffect(() => {
    if (state?.notification) {
      NotificationSys.showSuccess(state.notification)
    }
  }, [state])

  const [onChangeLearnArticleLink, openLinkEdit, isOpenLinkEdit, closeLinkEdit] =
    useChangeEntityProperty({
      data,
      setLoading,
      setData,
      updateFunc: updateLesson,
      warning: "Can't update Learn Article Link",
      field: 'learnArticleId',
    })

  const linkRemoveModal = useRemoveModalControl({
    deleteFunc: async () => {
      const response = await getLessonById({ id: Number(id) })
      await updateLesson({
        ...response.data,
        learnArticleId: null,
      })
    },
    successCallback: reloadData,
    warning: "Can't remove link from lesson",
  })

  const onEditMedia = () => {
    const id = data?.id || ''
    navigate(`${routes.lessonContentEdit}?id=${id}&type=lesson`)
  }

  if (loading) {
    return <LoadingPlaceholder />
  }

  if (!data) {
    return (
      <FailPlaceholder
        error="Can't load data of lesson"
        link={routes.courses}
        linkText="Back to courses table"
      />
    )
  }

  const path = getPath(data)

  return (
    <>
      <Stack spacing={2}>
        <PageTitle>Lesson View</PageTitle>

        <Stack direction="row" spacing={1} justifyContent="space-between" alignItems="center">
          <Stack direction="row" spacing={2} alignItems="center">
            <Breadcrumbs path={path} />

            <UIControlChangeName<Lesson>
              entityName="lesson"
              data={data}
              setData={setData}
              loading={loading}
              setLoading={setLoading}
              updateRequest={updateLesson}
            />
          </Stack>
        </Stack>

        <Stack spacing={3}>
          <UIControlBanner<Lesson>
            entityName="Lesson"
            data={data}
            setData={setData}
            setLoading={setLoading}
            updateRequest={updateLesson}
          />

          <UIEntityItemArticle
            onEdit={onEditMedia}
            text="Lesson Article"
            article={data.article}
            hasAudio={data.hasAudio}
          />

          <ExamPreviewPanel
            type="quiz"
            id={data.quizId}
            name={data.quiz?.name}
            onReload={reloadData}
            addControl={
              <ExamQuizControlAdd
                entityType="quiz"
                entityName="Quiz"
                routeEdit={routes.quizEdit}
                parentId={Number(data?.id)}
                parentIdField="lessonId"
                createRequest={createQuiz}
              />
            }
          />

          <UIEntityItemConnection
            linkId={data.learnArticleId}
            text="Link to Learn Library Article"
            articleName={data.learnArticleName}
            onEdit={openLinkEdit}
            onDelete={() => linkRemoveModal.openDeleteModal(0)}
          />

          <LinkArticleWithTags<Lesson>
            data={data}
            setLoading={setLoading}
            reloadData={reloadData}
            updateQuery={updateLesson}
          />
        </Stack>
      </Stack>

      <LinkToLearnLibraryModal
        isOpen={isOpenLinkEdit}
        handleClose={closeLinkEdit}
        value={data.learnArticleId}
        onSubmit={onChangeLearnArticleLink}
      />

      <ConfirmRemoveModal
        entityToRemove="Link to Learn Library"
        loading={linkRemoveModal.removeLoading}
        isOpen={linkRemoveModal.idToRemove !== null}
        handleConfirm={linkRemoveModal.handleConfirmRemove}
        handleClose={linkRemoveModal.closeDeleteModal}
      />
    </>
  )
}
