import CloseIcon from '@mui/icons-material/Close'
import { Box, Button, Stack, Typography } from '@mui/material'
import { withdrawInvitation } from 'api/careTeams'
import ConfirmModal from 'components/modals/ConfirmModal'
import NotificationSys from 'components/NotificationSystem'
import HelpTooltip from 'components/tooltips/HelpTooltip'
import { UIUserIcon } from 'features/UI'
import React, { useCallback, useState } from 'react'
import { useMutation } from 'react-query'
import * as Styled from './TimelineJourneyMemberItem.styled'

export const TimelineJourneyMemberItem = ({
  id,
  pending,
  firstName,
  lastName,
  email,
  isSelf,
  isEdit,
  onClick,
  onRemove,
}: {
  firstName: string
  lastName: string
  email: string
  isSelf?: boolean
  isEdit?: boolean
  onClick: () => void
  pending: boolean
  onRemove: () => void
  id: number
}) => {
  const [showWithdrawalConfirmation, setShowWithdrawalConfirmation] = useState(false)
  const handleWithdrawInvitationClick = useCallback(() => {
    setShowWithdrawalConfirmation(true)
  }, [])

  const handleWithdrawalConfirmationClose = useCallback(() => {
    setShowWithdrawalConfirmation(false)
  }, [])

  const withdrawConfirmationMutation = useMutation(withdrawInvitation, {
    onSuccess: () => {
      onRemove()
      NotificationSys.showSuccess('The invitation has been successfully withdrawn')
    },
  })

  const hadleConfirm = useCallback(() => {
    withdrawConfirmationMutation.mutate({ id })
  }, [withdrawConfirmationMutation, id])

  return (
    <>
      {showWithdrawalConfirmation && (
        <ConfirmModal
          title="Confirmation withdrawal"
          body="Are you sure you want to withdraw the invitation?"
          handleClose={handleWithdrawalConfirmationClose}
          handleConfirm={hadleConfirm}
          loading={withdrawConfirmationMutation.isLoading}
        />
      )}
      <Styled.Container pending={pending}>
        {pending && (
          <HelpTooltip text="Withdraw invitation">
            <Styled.IconWrapper onClick={handleWithdrawInvitationClick}>
              <CloseIcon />
            </Styled.IconWrapper>
          </HelpTooltip>
        )}
        <Stack direction="row" spacing={2} paddingBottom={1} alignItems="center">
          <Box display="flex" flexShrink={0}>
            <UIUserIcon firstName={firstName} lastName={lastName} />
          </Box>
          <Typography overflow="hidden" textOverflow="ellipsis">
            {firstName} {lastName} {isSelf ? ' - Self' : ` (${email})`}
          </Typography>
        </Stack>

        <Stack direction="row" spacing={1} justifyContent="flex-end">
          {!pending && (
            <Button size="small" variant="contained" onClick={onClick}>
              {isEdit ? 'Edit' : 'View'}
            </Button>
          )}
        </Stack>
      </Styled.Container>
    </>
  )
}
