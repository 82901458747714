import { zodResolver } from '@hookform/resolvers/zod/dist/zod'
import { validatePassword } from 'components/FormPasswordCheckList'
import { EMAIL_IS_INVALID, FIELD_IS_REQUIRED, PASSWORD_REQUIREMENTS } from 'const'
import { boolean, object, string, TypeOf } from 'zod'

export interface TraineeFormValues {
  firstName: string
  lastName: string
  email: string
  myNewPassword: string
  sameNewPassword: string
  education: number | null
  yearsOfExperience: number | null
  isEnglishPreferredLanguage: boolean | null
  readTermsAndConditions: boolean
}

const formSchema = object({
  firstName: string().min(1, 'First name is required'),
  lastName: string().min(1, 'Last name is required'),

  email: string().min(1, 'Email is required').email(EMAIL_IS_INVALID),

  myNewPassword: string().refine(
    (password: string) => validatePassword(password).allCheck,
    PASSWORD_REQUIREMENTS,
  ),
  sameNewPassword: string().min(1, 'Password is required'),

  education: string().min(1, 'Education is required'),
  yearsOfExperience: string().min(1, FIELD_IS_REQUIRED),

  language: string().min(1, FIELD_IS_REQUIRED),
  languageOther: string(),

  readTermsAndConditions: boolean().refine(
    (val) => val,
    'Please confirm of reading terms and conditions.',
  ),
})
  .refine((data) => data.myNewPassword === data.sameNewPassword, {
    path: ['sameNewPassword'],
    message: 'Passwords do not match',
  })
  .refine((data) => !(data.language === 'other' && data.languageOther.trim() === ''), {
    path: ['languageOther'],
    message: FIELD_IS_REQUIRED,
  })

export type TraineeFormType = TypeOf<typeof formSchema>

const defaultValues: TraineeFormType = {
  firstName: '',
  lastName: '',
  email: '',
  myNewPassword: '',
  sameNewPassword: '',
  education: '',
  yearsOfExperience: '',
  language: '',
  languageOther: '',
  readTermsAndConditions: false,
}

export const traineeInitialState = {
  traineeForm: defaultValues,
  accessCode: undefined as string | undefined,
}

export const traineeFormSettings = {
  mode: 'onChange' as const,
  resolver: zodResolver(formSchema),
  defaultValues,
}

export enum TraineeSignupStage {
  TraineeForm,
  PromocodeForm,
  CardForm,
}

export interface SignUpTraineeFormValues {
  traineeForm: TraineeFormValues
  promocode?: string
  cardNumber?: string
}
