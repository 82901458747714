import { Response } from 'types'
import { useShowControl } from './useShowControl'

interface ChangeProps<T, P> {
  setLoading: (value: boolean) => void
  onSuccess: (entity: P) => void
  requestFunc: (value: T) => Promise<Response<P>>
  existedNameWarning?: string
  warning: string
}

type OutProps<T> = [(newValue: T) => Promise<void>, () => void, boolean, () => void]

export const useChangeEntity = <T, P>(props: ChangeProps<T, P>): OutProps<T> => {
  const { setLoading, onSuccess, requestFunc, warning, existedNameWarning = warning } = props

  const [isOpenChangeEntity, openEditField, closeEditEntity] = useShowControl()

  const onSubmit = async (newValue: T) => {
    try {
      setLoading(true)
      const response = await requestFunc(newValue)
      onSuccess(response.data)
      closeEditEntity()
    } finally {
      setLoading(false)
    }
  }

  return [onSubmit, openEditField, isOpenChangeEntity, closeEditEntity]
}
