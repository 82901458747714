import React from 'react'
import { CssBaseline, StyledEngineProvider } from '@mui/material'
import { createTheme, responsiveFontSizes, ThemeProvider } from '@mui/material/styles'
import { componentsOverrides } from './overrides/componentsOverrides'
import type {} from '@mui/x-date-pickers/themeAugmentation'
import { paletteOverride } from './overrides/paletteOverride'

interface Props {
  children: React.ReactNode
}

export default function ThemeCustomization(props: Props) {
  const { children } = props

  let themes = createTheme()
  themes = responsiveFontSizes(themes)
  themes.components = componentsOverrides(themes)
  themes.palette = paletteOverride(themes)

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={themes}>
        <CssBaseline />
        {children}
      </ThemeProvider>
    </StyledEngineProvider>
  )
}
