import { Box, Button, Chip } from '@mui/material'
import { getTags } from 'api/tags'
import { Option } from 'components/form-elements/FormSelect'
import ModalWithMultipleSelect from 'components/modals/simple-modals/ModalWithMultipleSelect'
import { useChangeEntityProperty } from 'hooks/useChangeEntityName'
import React, { useMemo } from 'react'
import { useQuery } from 'react-query'
import { Response, Tag } from 'types'
import { noop, selectOptionsFromTableResponse } from 'utils'
import * as Styled from './LinkArticleWithTags.styled'

interface Entity {
  name: string
  tags: Tag[]
}

export function LinkArticleWithTags<T extends Entity>({
  data,
  fieldForUpdate = 'tags',
  setLoading,
  reloadData,
  updateQuery,
}: {
  data: T
  fieldForUpdate?: string
  setLoading: (loading: boolean) => void
  reloadData: () => void
  updateQuery: (value: T) => Promise<Response<T>>
}) {
  const tagsQuery = useQuery(['getTags'], () => getTags(), {
    select: selectOptionsFromTableResponse,
  })

  const [onChangeTags, openTagsEdit, isOpenTagsEdit, closeTagsEdit] = useChangeEntityProperty({
    data,
    setLoading,
    setData: noop,
    updateFunc: updateQuery,
    onSuccess: () => {
      reloadData()
    },
    warning: "Can't update tags",
    field: fieldForUpdate,
  })

  const tagsValue = useMemo(() => {
    return data.tags.map((tag) => ({ value: tag.id, label: tag.name }))
  }, [data])

  const handleChangeTags = (tags: Option[]) => {
    const newTags = tags.map((tag) => ({ id: tag.value, name: tag.label }))
    onChangeTags(newTags)
  }

  const handleDeleteTag = (tagItem: Option) => () => {
    handleChangeTags(tagsValue.filter((tag) => tag.value !== tagItem.value))
  }

  return (
    <Box>
      <Styled.TagsList>
        {tagsValue.map((tagItem) => {
          return (
            <Chip key={tagItem.value} label={tagItem.label} onDelete={handleDeleteTag(tagItem)} />
          )
        })}
      </Styled.TagsList>

      <Button variant="contained" onClick={openTagsEdit}>
        {tagsValue.length > 0 ? 'Edit Tags to the Article' : 'Add Tags to the Article'}
      </Button>

      <ModalWithMultipleSelect
        value={tagsValue}
        options={tagsQuery.data ?? []}
        loading={tagsQuery.isLoading}
        loadingOptions={tagsQuery.isLoading}
        title="Add tags to the Article"
        label="Available tags, choose one or more"
        buttonText="Save"
        isOpen={isOpenTagsEdit}
        handleClose={closeTagsEdit}
        onSubmit={handleChangeTags}
      />
    </Box>
  )
}
