import { Button } from '@mui/material'
import ModalWithTextAndIcon from 'components/modals/simple-modals/ModalWithTextAndIcon'
import { useChangeEntity } from 'hooks/useChangeEntity'
import React from 'react'
import { Nullish, Response } from 'types'

export function UIControlChangeNameAndIcon<T extends { name: string; bannerUrl: string | null }>({
  entityName,
  data,
  setData,
  loading,
  setLoading,
  updateRequest,
}: {
  entityName: string
  data: Nullish<T>
  loading: boolean
  setData: (newData: T) => void
  setLoading: (value: boolean) => void
  updateRequest: (request: T) => Promise<Response<T>>
}) {
  const [onSubmit, onOpen, isOpen, onClose] = useChangeEntity({
    setLoading,
    onSuccess: setData,
    requestFunc: updateRequest,
    existedNameWarning: `Can't rename ${entityName} to existed name`,
    warning: `Can't rename ${entityName}`,
  })

  return (
    <>
      <Button variant="outlined" onClick={onOpen}>
        Change Name and Icon
      </Button>

      <ModalWithTextAndIcon
        title={`Change ${entityName} Name and Icon`}
        label={`${entityName} name`}
        buttonText="Save"
        isOpen={isOpen}
        handleClose={onClose}
        loading={loading}
        setLoading={setLoading}
        onSubmit={(values) => {
          if (!data) {
            return
          }
          onSubmit({
            ...data,
            name: values.name,
            bannerMediaFileId: values.icon,
          })
        }}
        value={{
          name: data?.name || '',
          icon: data?.bannerUrl || null,
        }}
      />
    </>
  )
}
