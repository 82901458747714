export async function base64ToFile(base64: string, filename: string): Promise<File | null> {
  try {
    const response = await fetch(base64)
    const blob = await response.blob()
    const mime = blob.type
    return new File([blob], filename, { type: mime })
  } catch (error) {
    console.error('Error converting base64 to file:', error)
    return null
  }
}

export function isAspectRatio16by9(base64: string): Promise<boolean> {
  return new Promise((resolve, reject) => {
    const img = new Image()
    img.onload = () => {
      const aspectRatio = img.width / img.height
      resolve(Math.abs(aspectRatio - 16 / 9) < 0.01)
    }
    img.onerror = reject
    img.src = base64
  })
}

export const fileToBase64 = function (file: File) {
  return new Promise((resolve) => {
    const reader = new FileReader()
    reader.readAsDataURL(file)

    reader.onload = (e) => {
      resolve(e.target?.result)
    }
  })
}
