import { Link, Stack, Typography } from '@mui/material'
import React from 'react'
import { MAIN_SITE, PRIVACY_POLICY, TERMS_OF_USE } from 'routes/routes'

export function AuthCopyright() {
  return (
    <Stack spacing={0.5} alignItems="center" sx={{ mt: 3, pb: 1 }}>
      <Typography variant="body2" color="text.secondary" align="center">
        By using Memory Care Partner you acknowledge that you have read and agree to our{' '}
        <Link color="inherit" href={TERMS_OF_USE} target="_blank">
          Terms of Use
        </Link>
        {' and '}
        <Link color="inherit" href={PRIVACY_POLICY} target="_blank">
          Privacy Policy
        </Link>
        .
      </Typography>

      <Typography variant="body2" color="text.secondary" align="center">
        {'© '}
        {new Date().getFullYear()}{' '}
        <Link color="inherit" href={MAIN_SITE} target="_blank">
          ProActive Memory Service, Inc.
        </Link>{' '}
        {'All right reserved.'}
      </Typography>
    </Stack>
  )
}
