import { Alert, AlertTitle, Button, Typography } from '@mui/material'
import { confirmUserPathwayChanges } from 'api/users'
import React, { useCallback } from 'react'
import { useMutation } from 'react-query'
import { useAppDispatch, useAppSelector } from 'store/hooks'
import { updateProfileConfirmPathwayChanges } from 'store/slice/user.slice'
import { User } from 'types'

export function UserPathwayAlert() {
  const dispatch = useAppDispatch()
  const user = useAppSelector((state) => state.currentUser) as User
  const saveUserMutation = useMutation(confirmUserPathwayChanges)

  const handleConfirmPathwayChanges = useCallback(async () => {
    await saveUserMutation.mutateAsync({ id: user.id, isTrainingPathwayTypeChanged: false })
    dispatch(updateProfileConfirmPathwayChanges())
  }, [dispatch, saveUserMutation, user.id])

  return (
    <Alert severity="info">
      <AlertTitle>Info</AlertTitle>
      <Typography mb={2}>
        Your training pathway type has been changed to {user.trainingPathwayTypeName}
      </Typography>
      <Button variant="contained" color="primary" onClick={handleConfirmPathwayChanges}>
        Confirm
      </Button>
    </Alert>
  )
}
