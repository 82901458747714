import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from 'store/store'
import { Option } from 'types'

interface TagsState {
  tags: Option[]
}

const initialState: TagsState = {
  tags: [],
}

export const tagsSlice = createSlice({
  name: 'adminTags',
  initialState,
  reducers: {
    setTags: (state, action: PayloadAction<{ tags: Option[] }>) => {
      state.tags = action.payload.tags
    },
    removeTag: (state, action: PayloadAction<{ tagId: number }>) => {
      state.tags = state.tags.filter((tag) => {
        return tag.value !== action.payload.tagId
      })
    },
  },
})

export const { setTags, removeTag } = tagsSlice.actions

export const selectTagsSlice = (state: RootState): TagsState => state.adminTags

export const selectTags = createSelector(selectTagsSlice, (slice) => slice.tags)
