import { UIFilterDropdown, UITaskPostArticleImg } from 'features/UI'
import IconLesson from 'images/icons/1_man_with_board.png'
import IconTimelinePost from 'images/icons/icon_article.png'
import IconNotes from 'images/icons/icon_journal.png'
import IconQuestion from 'images/icons/icon_sm_question.png'
import { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { useAppDispatch } from 'store/hooks'
import { selectTimelineItems } from 'store/slice/timeline.slice'
import {
  selectTimelineFiltersTypes,
  setTimelineFiltersTypes,
} from 'store/slice/timelineFilters.slice'
import { ETimelinePostType, Nullish } from 'types'
import { useTimelineList } from '../../hooks/useTimelineList'

interface IFilterItem {
  id:
    | ETimelinePostType.course
    | ETimelinePostType.lessons
    | ETimelinePostType.careArticle
    | ETimelinePostType.taskArticle
    | ETimelinePostType.queries
    | ETimelinePostType.note
    | null
  label: string
  icon?: string
  svgIcon?: JSX.Element
}

const filterItems: IFilterItem[] = [
  {
    id: null,
    label: 'All',
  },
  {
    id: ETimelinePostType.course,
    label: 'Courses',
    icon: IconLesson,
  },
  {
    id: ETimelinePostType.lessons,
    label: 'Lessons',
    icon: IconLesson,
  },
  {
    id: ETimelinePostType.careArticle,
    label: 'Guide Posts',
    icon: IconTimelinePost,
  },
  {
    id: ETimelinePostType.taskArticle,
    label: 'Action Posts',
    svgIcon: <UITaskPostArticleImg isOnTimeline={false} size={20} svgSize={16} />,
  },
  {
    id: ETimelinePostType.queries,
    label: 'Queries',
    icon: IconQuestion,
  },
  {
    id: ETimelinePostType.note,
    label: 'Notes',
    icon: IconNotes,
  },
]

export function TimelineFilterByType() {
  const dispatch = useAppDispatch()
  const timelineTypes = useSelector(selectTimelineFiltersTypes)
  const timeline = useSelector(selectTimelineItems)

  const { amountsByType } = useTimelineList(timeline)

  const checkboxes = useMemo(() => {
    return filterItems.map((filterItem) => {
      const amount = filterItem.id ? amountsByType[filterItem.id] : 0
      return {
        ...filterItem,
        label: amount > 0 ? `${filterItem.label} (${amount})` : filterItem.label,
      }
    })
  }, [amountsByType])

  const handleClickCheckbox = (value: Nullish<ETimelinePostType>[] | null) => {
    dispatch(setTimelineFiltersTypes(value))
  }

  return (
    <UIFilterDropdown<Nullish<ETimelinePostType>>
      buttonName="Type"
      checkboxes={checkboxes}
      value={timelineTypes}
      onClickCheckbox={handleClickCheckbox}
    />
  )
}
