// @ts-ignore
import { stringExcel } from '@json2csv/formatters'
// @ts-ignore
import { Parser } from '@json2csv/plainjs'
import FileSaver from 'file-saver'
import { useCallback } from 'react'
import { CSVField } from './useExportCSV'

const delimiter = ','

export const useExportArrayCSV = (rows: object[], fields?: CSVField[], filename?: string) => {
  const onExportCSV = useCallback(async () => {
    try {
      const opts = {
        delimiter,
        fields,
        formatters: {
          string: stringExcel,
        },
      }
      const parser = new Parser(opts)
      const csv = parser.parse(rows)
      const blob = new Blob([csv], { type: 'text/plain;charset=utf-8' })
      FileSaver.saveAs(blob, filename || 'report.csv')
    } catch (e: unknown) {
      console.error(e)
    }
  }, [rows, fields, filename])

  return { onExportCSV }
}
