import { LoadingButton } from '@mui/lab'
import {
  Box,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Stack,
  Typography,
} from '@mui/material'
import LinearProgressWithLabel from 'components/LinearProgressWithLabel'
import { minButtonWidth } from 'const'
import { UIModal } from 'features/UI'
import React, { BaseSyntheticEvent, ChangeEvent, useMemo, useRef, useState } from 'react'
import { AudioFiles, AudioLanguages, AudioLanguagesOptions } from '../../models/Article.model'
import { ArticleAudioUploadItem } from '../ArticleAudioUploadItem/ArticleAudioUploadItem'

interface Props {
  isOpen: boolean
  handleClose: () => void
  title: string
  buttonText?: string
  enMediaUrl: string | null
  enMediaMimeType?: string
  esMediaUrl: string | null
  esMediaMimeType?: string
  onSubmit: (files: AudioFiles, filesToDelete: Record<AudioLanguages, boolean>) => void
  loading?: boolean
  progressBarValue: Record<AudioLanguages, number>
  onAbortUpload: () => void
}

function ModalContent({
  handleClose,
  enMediaUrl,
  enMediaMimeType,
  esMediaUrl,
  esMediaMimeType,
  onSubmit,
  loading,
  progressBarValue,
  onAbortUpload,
}: Props) {
  const [error, setError] = useState<string | null>(null)
  const [language, setLanguage] = useState('')

  const handleChangeLanguage = (event: SelectChangeEvent) => {
    setLanguage(event.target.value)
  }

  const [files, setFiles] = useState<AudioFiles>({
    [AudioLanguages.EN]: null,
    [AudioLanguages.ES]: null,
  })

  const [oldFilesUrls, setOldFilesUrls] = useState<Record<AudioLanguages, string | null>>({
    [AudioLanguages.EN]: enMediaUrl,
    [AudioLanguages.ES]: esMediaUrl,
  })

  const [filesToDelete, setFilesToDelete] = useState<Record<AudioLanguages, boolean>>({
    [AudioLanguages.EN]: false,
    [AudioLanguages.ES]: false,
  })

  const isNotChanged = useMemo(() => {
    return (
      !files[AudioLanguages.EN] &&
      !files[AudioLanguages.ES] &&
      !filesToDelete[AudioLanguages.EN] &&
      !filesToDelete[AudioLanguages.ES]
    )
  }, [files, filesToDelete])

  const onChangeFile = (event: ChangeEvent<HTMLInputElement>) => {
    const file = (event as unknown as BaseSyntheticEvent<File>).target.files[0]
    event.target.value = ''
    setFiles((prev) => ({
      ...prev,
      [language]: {
        file,
        src: URL.createObjectURL(file),
      },
    }))
    setError(null)
  }

  const onSubmitWrapper = () => {
    onSubmit(files, filesToDelete)
  }

  const handleRemove = (language: AudioLanguages) => {
    setFiles((prev) => ({
      ...prev,
      [language]: null,
    }))
    setOldFilesUrls((prev) => ({
      ...prev,
      [language]: null,
    }))
    setFilesToDelete((prev) => ({
      ...prev,
      [language]: true,
    }))
  }

  const inputRef = useRef<HTMLInputElement | null>(null)

  const onClickUpload = () => {
    inputRef.current?.click()
  }

  return (
    <Box width="100%">
      <Stack minHeight="72px" spacing={2}>
        <FormControl sx={{ width: '50%' }} size="small">
          <InputLabel>Language</InputLabel>
          <Select value={language} label="Language" onChange={handleChangeLanguage}>
            <MenuItem value="">Select a language</MenuItem>
            {Object.keys(AudioLanguagesOptions).map((lang) => {
              const langOptions = AudioLanguagesOptions[lang as AudioLanguages]
              return (
                <MenuItem key={lang} value={lang}>
                  {langOptions.label}
                </MenuItem>
              )
            })}
          </Select>
        </FormControl>

        <input
          ref={inputRef}
          onChange={onChangeFile}
          id="audio-file"
          type="file"
          accept="audio/wav, audio/mpeg, audio/mp4, audio/ogg, audio/aacp, audio/flac"
          style={{ display: 'none' }}
        />

        <Button
          variant="contained"
          disabled={
            !language ||
            !!files[language as AudioLanguages] ||
            !!oldFilesUrls[language as AudioLanguages]
          }
          onClick={onClickUpload}
        >
          Upload file
        </Button>

        {Object.keys(AudioLanguagesOptions).map((lang) => {
          const file = files[lang as AudioLanguages]
          const oldFilesUrl = oldFilesUrls[lang as AudioLanguages]
          const src = file?.src || oldFilesUrl
          const oldMimeType = lang === AudioLanguages.EN ? enMediaMimeType : esMediaMimeType
          const mimeType = file?.file?.type || oldMimeType

          if (!src) {
            return null
          }

          return (
            <ArticleAudioUploadItem
              key={`player-${lang}`}
              src={src}
              mimeType={mimeType}
              language={lang as AudioLanguages}
              onRemove={handleRemove}
            />
          )
        })}

        {error && (
          <Typography color="error" variant="subtitle2" fontWeight={400} mt={1}>
            {error}
          </Typography>
        )}

        {loading && (
          <Box>
            <LinearProgressWithLabel
              value={
                (progressBarValue[AudioLanguages.EN] + progressBarValue[AudioLanguages.ES]) / 2 || 0
              }
            />
          </Box>
        )}
      </Stack>

      <Stack direction="row" spacing={2} justifyContent="flex-end" sx={{ mt: 5 }}>
        {loading ? (
          <Button variant="outlined" onClick={onAbortUpload} sx={minButtonWidth}>
            Stop
          </Button>
        ) : (
          <Button variant="outlined" onClick={handleClose} sx={minButtonWidth}>
            Cancel
          </Button>
        )}

        <LoadingButton
          disabled={isNotChanged}
          loading={loading}
          variant="contained"
          sx={minButtonWidth}
          onClick={onSubmitWrapper}
        >
          Save
        </LoadingButton>
      </Stack>
    </Box>
  )
}

export const ArticleAudioUploadModal = (props: Props) => {
  const onClose = () => {
    props.onAbortUpload()
    props.handleClose()
  }

  return (
    <UIModal title={props.title} isOpen={props.isOpen} width={500} onClose={onClose}>
      <ModalContent {...props} />
    </UIModal>
  )
}
