import { Stack, Typography } from '@mui/material'
import { useDrawersContext } from 'components/context/ClientsDrawersProvider'
import { UITag } from 'features/UI'
import React, { useCallback, useMemo } from 'react'
import { useNavigate } from 'react-router-dom'
import { routes } from 'routes/routes'
import { useActions } from 'store/hooks'
import { ProgressLesson } from 'types'
import { ProgressItemViewButton } from '../ProgressItemViewButton/ProgressItemViewButton'

export function ProgressLessonItem({ lesson }: { lesson: ProgressLesson }) {
  const navigate = useNavigate()
  const { handleOpenQuestioning } = useDrawersContext()

  const { getTimelineById } = useActions()

  const isCompleted = useMemo(
    () => Number(lesson.quiz.quizPercentOfCorrectAnswers) === 100,
    [lesson],
  )

  const buttonTitle = useMemo(() => {
    if (
      lesson.quiz.quizStatus === 'new' ||
      lesson.quiz.quizStatus === null ||
      !lesson.isLessonInTimeline
    ) {
      return 'Take'
    }

    if (!isCompleted) {
      return 'Retake'
    }

    return 'Take'
  }, [isCompleted, lesson])

  const handleView = useCallback(() => {
    if (!lesson || lesson.quiz.quizId === null) {
      return
    }

    handleOpenQuestioning({
      mode: 'take',
      id: lesson.quiz.quizId,
      type: 'quiz',
      onQuestioningTaken: () => {
        getTimelineById({})
      },
    })

    navigate(routes.journey)
  }, [getTimelineById, handleOpenQuestioning, lesson, navigate])

  return (
    <Stack
      direction="row"
      alignItems="center"
      sx={{
        background: 'white',
        padding: 1.5,
        borderRadius: 2,
        width: '100%',
        cursor: 'pointer',
        gap: '8px',
      }}
      onClick={!isCompleted && lesson.isLessonInTimeline ? handleView : undefined}
    >
      <Stack direction="row" alignItems="center" justifyContent="space-between" flexGrow={1}>
        <Typography>
          <b>Quiz:</b>
          {` ${lesson.quiz.quizName}`}
        </Typography>

        {!isCompleted && (
          <ProgressItemViewButton
            hasTooltip={!lesson.isLessonInTimeline}
            disabled={!lesson.isLessonInTimeline}
            onClick={handleView}
            title={buttonTitle}
          />
        )}

        {isCompleted && <UITag background="#8094A1">Done</UITag>}
      </Stack>
    </Stack>
  )
}
