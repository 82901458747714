import { ColDef } from 'ag-grid-community'

export const medicinesColumnDefs: ColDef[] = [
  { headerName: 'Id', field: 'id', width: 50, minWidth: 50 },
  { headerName: 'Medicine', field: 'medicine' },
  { headerName: 'Form', field: 'form' },
  { headerName: 'Dose', field: 'dose' },
  { headerName: 'When Taken', field: 'whenTaken' },
  { headerName: 'Dates', field: 'dates' },
  { headerName: 'Directions', field: 'directions' },
  { headerName: 'Reason', field: 'reason' },
  { headerName: 'Notes', field: 'notes' },
]

export const contactsColumnDefs: ColDef[] = [
  { headerName: 'Name', field: 'name' },
  { headerName: 'Role in Care', field: 'roleInCare' },
  { headerName: 'Street Number', field: 'streetNumber' },
  { headerName: 'Street Name', field: 'streetName' },
  { headerName: 'City', field: 'city' },
  { headerName: 'State', field: 'state' },
  { headerName: 'Country', field: 'country' },
  { headerName: 'Zip Code', field: 'zipcode' },
  { headerName: 'Phone Number', field: 'phoneNumber' },
  { headerName: 'Email', field: 'email' },
]
