import { Button, Stack } from '@mui/material'
import { heightOfToolbar } from 'const'
import React, { useCallback, useEffect, useMemo } from 'react'
import { useActions, useAppSelector } from 'store/hooks'
import { CareTeam, UserType } from 'types'
import { useAuthContext } from 'components/context/AuthProvider'
import { useDrawersContext } from 'components/context/ClientsDrawersProvider'
import ScrollContainer from 'components/page/ScrollContainer'
import FailPlaceholder from 'components/placeholders/FailPlaceholder'
import LoadingPlaceholder from 'components/placeholders/LoadingPlaceholder'
import { TimelineJourneyMemberItem } from 'features/Timeline/components/TimelineJourneyMemberItem/TimelineJourneyMemberItem'

const useMemberTabData = () => {
  const { getCareTeamById } = useActions()

  const refetch = useCallback(() => {
    getCareTeamById({})
  }, [getCareTeamById])

  const { isLoading, careTeam } = useAppSelector((state) => state.careTeam)

  useEffect(() => {
    refetch()
  }, [refetch])

  return { isLoading, careTeam, refetch }
}

export function TimelineJourneyMembersTab() {
  const { isTrainee, user } = useAuthContext()
  const { isLoading, careTeam, refetch } = useMemberTabData()
  const {
    handleOpenInviteMember,
    handleOpenAddMember,
    handleOpenProfile,
    handleOpenTeamMemberDrawer,
  } = useDrawersContext()

  const careTeams = useMemo(() => {
    // current user always first in list
    return [...(careTeam || [])]?.sort((a) => (a.invitedUserId == user?.id ? -1 : 1))
  }, [careTeam, user])

  const handleClickMember = isTrainee ? () => handleOpenAddMember() : handleOpenInviteMember

  const handleClick = (member: CareTeam) => {
    if (member.invitedUserType && !member.invitedUserType.includes(UserType.CARETEAM)) {
      handleOpenProfile(member.invitedUserId)
    } else {
      handleOpenTeamMemberDrawer(member.id, member.invitedUserId === user?.id)
    }
  }

  if (isLoading) {
    return <LoadingPlaceholder />
  }

  if (!careTeam) {
    return <FailPlaceholder error="Can't load data of care teams" />
  }

  return (
    <Stack paddingLeft={{ xs: 2 }} paddingRight={1} spacing={1}>
      <Stack direction="row" justifyContent="flex-end" spacing={2} pt={1.5} pb={0.5} pr={1}>
        <Button variant="outlined" onClick={refetch}>
          Refresh
        </Button>
        <Button variant="contained" onClick={handleClickMember}>
          Add Member
        </Button>
      </Stack>

      <ScrollContainer deltaSpace={heightOfToolbar}>
        <Stack spacing={2}>
          {careTeams.map((member) => (
            <TimelineJourneyMemberItem
              isEdit={member.invitedUserId == user?.id}
              isSelf={member.invitedUserId == user?.id}
              key={member.id}
              firstName={member.firstName}
              lastName={member.lastName}
              email={member.email}
              onClick={() => handleClick(member)}
              pending={member.status === 'invited'}
              id={member.id}
              onRemove={refetch}
            />
          ))}
        </Stack>
      </ScrollContainer>
    </Stack>
  )
}
