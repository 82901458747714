import { zodResolver } from '@hookform/resolvers/zod/dist/zod'
import { LoadingButton } from '@mui/lab'
import { Box, FormLabel, Grid, Stack, TextField, Typography } from '@mui/material'
import { checkUpdateUser, updateUser } from 'api/users'
import FormInput from 'components/form-elements/FormInput'
import FormSelect from 'components/form-elements/FormSelect'
import ConfirmModal from 'components/modals/ConfirmModal'
import NotificationSys from 'components/NotificationSystem'
import { EMAIL_IS_INVALID, FIELD_IS_REQUIRED } from 'const'
import { CohortsMultiSelect } from 'features/Cohorts'
import {
  educationOptions,
  highestDegreeOptions,
  preferredLanguageOptions,
  racialDesignationOptions,
  yearsOfExperience,
} from 'models/user.model'
import React, { useCallback, useMemo, useState } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { useMutation } from 'react-query'
import {
  EUserEducation,
  EUserExperience,
  EUserHighestDegree,
  EUserLanguage,
  EUserRacialDesignation,
  Pathway,
  TableResponse,
  UserModel,
  UserUpdateNoticeData,
  UserUpdateRequest,
} from 'types'
import { number, object, string, TypeOf, ZodIssueCode } from 'zod'
import { UserDetailsBreadcrumbs } from '../UserDetailsBreadcrumbs/UserDetailsBreadcrumbs'

const formSchema = object({
  firstname: string().min(1, FIELD_IS_REQUIRED),
  lastname: string().min(1, FIELD_IS_REQUIRED),
  email: string().email(EMAIL_IS_INVALID),
  cohorts: object({
    label: string(),
    value: number(),
  }).array(),
  education: string().min(1, 'Education is required'),
  experience: string().min(1, FIELD_IS_REQUIRED),
  language: string().min(1, FIELD_IS_REQUIRED),
  highestDegree: string(),
  otherHighestDegree: string(),
  racialDesignation: string(),
  otherRacialDesignation: string(),
  jobTitle: string().max(50, 'Maximum length is 50 characters'),
}).superRefine((data, refinementContext) => {
  if (data.highestDegree === EUserHighestDegree.other && data.otherHighestDegree === '') {
    refinementContext.addIssue({
      code: ZodIssueCode.custom,
      message: FIELD_IS_REQUIRED,
      path: ['otherHighestDegree'],
    })
  }

  if (
    data.racialDesignation === EUserRacialDesignation.other &&
    data.otherRacialDesignation === ''
  ) {
    refinementContext.addIssue({
      code: ZodIssueCode.custom,
      message: FIELD_IS_REQUIRED,
      path: ['otherRacialDesignation'],
    })
  }
})

type FormType = TypeOf<typeof formSchema>

export function UserDetailsForm({
  user,
  pathways,
  onAfterUpdate,
}: {
  user: UserModel
  pathways: TableResponse<Pathway>
  onAfterUpdate: () => void
}) {
  const [isOpenConfirm, setIsOpenConfirm] = useState(false)
  const [checkResult, setCheckResult] = useState<UserUpdateNoticeData[]>([])

  const handleCloseConfirm = useCallback(() => setIsOpenConfirm(false), [])

  const confirmMessage = useMemo(() => {
    if (checkResult.length === 0) {
      return ''
    }

    const oldName = checkResult[0].data.oldTrainingPathwayType.name
    const newName = checkResult[0].data.newTrainingPathwayType.name

    return `After saving these changes the trainee's pathway type will be changed from ${oldName} to ${newName}. Please confirm the action.`
  }, [checkResult])

  const defaultValues = {
    firstname: user.firstname,
    lastname: user.lastname,
    email: user.email,
    cohorts: user.cohorts.map((cohort) => ({ label: cohort.name, value: cohort.id })),
    education: user.education,
    experience: user.experience,
    language: user.language,
    highestDegree: user.highestDegree || '',
    otherHighestDegree: user.otherHighestDegree || '',
    racialDesignation: user.racialDesignation || '',
    otherRacialDesignation: user.otherRacialDesignation || '',
    jobTitle: user.jobTitle || '',
  }

  const methods = useForm<FormType>({
    mode: 'all',
    resolver: zodResolver(formSchema),
    defaultValues,
  })

  const highestDegreeValue = methods.watch('highestDegree')
  const racialDesignationValue = methods.watch('racialDesignation')

  const checkUserMutation = useMutation(checkUpdateUser)
  const saveUserMutation = useMutation(updateUser)

  const getRequestData = useCallback(() => {
    const values = methods.getValues()
    const data: UserUpdateRequest = {
      id: user.id,
      firstname: values.firstname,
      lastname: values.lastname,
      email: values.email,
      cohortIds: values.cohorts.map((item) => item.value),
      education: values.education as EUserEducation,
      experience: values.experience as EUserExperience,
      language: values.language as EUserLanguage,
      highestDegree: values.highestDegree as EUserHighestDegree,
      racialDesignation: values.racialDesignation as EUserRacialDesignation,
      jobTitle: values.jobTitle,
    }

    if (values.highestDegree === EUserHighestDegree.other) {
      data.otherHighestDegree = values.otherHighestDegree
    }

    if (values.racialDesignation === EUserRacialDesignation.other) {
      data.otherRacialDesignation = values.otherRacialDesignation
    }

    return data
  }, [methods, user.id])

  const handleUpdate = useCallback(async () => {
    const request = getRequestData()
    await saveUserMutation.mutateAsync(request)
    handleCloseConfirm()
    NotificationSys.showSuccess('User updated')
    onAfterUpdate()
  }, [getRequestData, handleCloseConfirm, onAfterUpdate, saveUserMutation])

  const handleCheckUpdate = useCallback(async () => {
    const request = getRequestData()
    const checkResult = await checkUserMutation.mutateAsync(request)
    if (checkResult.data.length === 0) {
      await handleUpdate()
    } else {
      setCheckResult(checkResult.data)
      setIsOpenConfirm(true)
    }
  }, [checkUserMutation, getRequestData, handleUpdate])

  const pathwayName = useMemo(() => {
    const pathway = pathways.data.rows.find(
      (item) => item.trainingPathwayTypeId === user.trainingPathwayTypeId,
    )
    return pathway ? pathway.trainingPathwayTypeName : ''
  }, [pathways, user.trainingPathwayTypeId])

  return (
    <>
      <UserDetailsBreadcrumbs user={user} />

      <FormProvider {...methods}>
        <Box
          component="form"
          onSubmit={methods.handleSubmit(handleCheckUpdate)}
          noValidate
          width="100%"
          mt={4}
        >
          <Grid container spacing={4}>
            <Grid item sm={12} md={12} lg={6} xl={6}>
              <Stack spacing={2}>
                <FormInput label="First Name" name="firstname" required fullWidth />
                <FormInput label="Last Name" name="lastname" required fullWidth />
                <FormInput label="Email" name="email" required fullWidth autoComplete="off" />

                <CohortsMultiSelect defaultValue={defaultValues.cohorts} />
              </Stack>

              <Stack mt={4}>
                <Typography fontWeight={600}>Pathway changes</Typography>
                <Typography fontSize={14} sx={{ opacity: '0.6' }}>
                  Please note that modifying the fields below may potentially alter the pathway for
                  trainees
                </Typography>
              </Stack>

              <Stack spacing={2} mt={2}>
                <FormSelect
                  label="How much education did you complete?"
                  name="education"
                  required
                  options={educationOptions}
                />

                <FormSelect
                  label="Years of experience as a professional caregiver"
                  name="experience"
                  required
                  options={yearsOfExperience}
                />

                <FormSelect
                  label="What is your preferred language?"
                  name="language"
                  required
                  options={preferredLanguageOptions}
                />

                {pathwayName && (
                  <Stack spacing={0.5}>
                    <FormLabel>Pathway Type</FormLabel>
                    <TextField size="small" value={pathwayName} disabled />
                  </Stack>
                )}
              </Stack>
            </Grid>

            <Grid item sm={12} md={12} lg={6} xl={6}>
              <Stack spacing={2}>
                <FormSelect
                  label="Survey Monkey - Highest degree or level of education"
                  name="highestDegree"
                  options={highestDegreeOptions}
                />

                {highestDegreeValue === EUserHighestDegree.other && (
                  <FormInput
                    label=""
                    name="otherHighestDegree"
                    required
                    fullWidth
                    checkTouchField={false}
                  />
                )}

                <FormSelect
                  label="Survey Monkey - Racial designations"
                  name="racialDesignation"
                  options={racialDesignationOptions}
                />

                {racialDesignationValue === EUserRacialDesignation.other && (
                  <FormInput
                    label=""
                    name="otherRacialDesignation"
                    required
                    fullWidth
                    checkTouchField={false}
                  />
                )}

                <FormInput label="Job title" name="jobTitle" fullWidth />
              </Stack>
            </Grid>

            <Grid item sm={12} md={12} lg={12} xl={12}>
              <LoadingButton
                disabled={saveUserMutation.isLoading || checkUserMutation.isLoading}
                loading={saveUserMutation.isLoading || checkUserMutation.isLoading}
                variant="contained"
                type="submit"
                sx={{ width: '120px' }}
              >
                Save
              </LoadingButton>
            </Grid>
          </Grid>
        </Box>

        {isOpenConfirm && (
          <ConfirmModal
            title="Confirm Pathway Changes"
            body={confirmMessage}
            handleClose={handleCloseConfirm}
            handleConfirm={handleUpdate}
            loading={saveUserMutation.isLoading}
          />
        )}
      </FormProvider>
    </>
  )
}
