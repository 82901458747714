import { CircularProgress, FormLabel, Stack } from '@mui/material'
import Autocomplete from '@mui/material/Autocomplete'
import TextField from '@mui/material/TextField'
import React from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import { Option } from './FormSelect'

type Props = {
  name: string
  label: string
  options: Option[]
  defaultValue: Option[]
  isLoading: boolean
}

const isOptionEqualToValue = (option: Option, value: Option) => option.value === value.value
const getOptionLabel = (option: Option) => option.label

const FormMultipleSelect = (props: Props) => {
  const { options, isLoading, label, name, defaultValue } = props

  const {
    control,
    formState: { errors },
  } = useFormContext()

  return (
    <Controller
      control={control}
      name={name}
      render={({ field }) => {
        return (
          <Stack spacing={0.5}>
            <FormLabel>{label}</FormLabel>
            <Autocomplete
              multiple
              isOptionEqualToValue={isOptionEqualToValue}
              getOptionLabel={(a) => getOptionLabel(a).toString()}
              options={options}
              loading={isLoading}
              value={field.value}
              defaultValue={defaultValue}
              onChange={(e, value) => field.onChange(value)}
              openOnFocus={true}
              renderInput={(params) => (
                <TextField
                  {...params}
                  size="small"
                  name={name}
                  error={!!errors[name]}
                  helperText={(errors[name] ? errors[name]?.message : '') as string}
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: (
                      <React.Fragment>
                        {isLoading ? <CircularProgress color="inherit" size={20} /> : null}
                        {params.InputProps.endAdornment}
                      </React.Fragment>
                    ),
                  }}
                />
              )}
            />
          </Stack>
        )
      }}
    />
  )
}

export default React.memo(FormMultipleSelect)
