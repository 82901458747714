import { zodResolver } from '@hookform/resolvers/zod/dist/zod'
import { Box, Button, Stack } from '@mui/material'
import { FIELD_IS_REQUIRED } from 'const'
import React from 'react'
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form'
import { Note } from 'types'
import { object, string, TypeOf } from 'zod'
import FormInput from '../../form-elements/FormInput'

const formSchema = object({
  subject: string().min(1, FIELD_IS_REQUIRED),
  body: string().min(1, FIELD_IS_REQUIRED).max(50, 'Maximum length is 50 characters'),
  link: string().refine((value) => {
    if (value === '') {
      return true
    }
    return /[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/.test(
      value,
    )
  }, 'Link has incorrect format'),
})

type FormType = TypeOf<typeof formSchema>

interface Props {
  values?: Note
  onSubmitHandler: SubmitHandler<FormType>
  removeDisabled: boolean
  onRemove: () => void
}

const NoteEditForm = (props: Props) => {
  const { values, onSubmitHandler, removeDisabled, onRemove } = props

  const formSettings = {
    mode: 'onChange' as const,
    resolver: zodResolver(formSchema),
    defaultValues: {
      subject: values?.subject || '',
      body: values?.body || '',
      link: values?.link || '',
    },
  }
  const methods = useForm<FormType>(formSettings)

  const { handleSubmit } = methods

  return (
    <FormProvider {...methods}>
      <Box component="form" onSubmit={handleSubmit(onSubmitHandler)} width="100%" noValidate>
        <Stack spacing={2}>
          <FormInput label="Subject (max 50 characters)" required fullWidth name="subject" />

          <FormInput
            label="Note"
            required
            fullWidth
            name="body"
            multiline
            minRows={5}
            maxRows={12}
          />

          <FormInput label="Link to image or website" fullWidth name="link" />
        </Stack>

        <Stack direction="row" spacing={2} mt={4}>
          <Button variant="contained" type="submit" sx={{ minWidth: 120 }}>
            Post
          </Button>
          <Button
            variant="outlined"
            color="error"
            sx={{ minWidth: 120 }}
            disabled={removeDisabled}
            onClick={onRemove}
          >
            Delete
          </Button>
        </Stack>
      </Box>
    </FormProvider>
  )
}

export default React.memo(NoteEditForm)
