import React from 'react'
import { CircularProgress, Stack } from '@mui/material'

const LoadingPlaceholder = () => {
  return (
    <Stack direction="row" justifyContent="center" alignItems="center" flexGrow={1}>
      <CircularProgress sx={{ width: 64, height: 64 }} />
    </Stack>
  )
}

export default LoadingPlaceholder
