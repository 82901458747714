import styled from '@emotion/styled'
import { zodResolver } from '@hookform/resolvers/zod/dist/zod'
import LockIcon from '@mui/icons-material/Lock'
import { LoadingButton } from '@mui/lab'
import { Box, FormLabel, Modal, Stack, Typography } from '@mui/material'
import {
  defaultModalStyle,
  defaultModalZIndex,
  EMAIL_IS_INVALID,
  FIELD_IS_REQUIRED,
  minButtonWidth,
} from 'const'
import React, { useCallback, useRef } from 'react'
import ContentEditable from 'react-contenteditable'
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form'
import { useMutation } from 'react-query'
import { User } from 'types'
import { object, string, TypeOf } from 'zod'
import { inviteTeamMember } from '../../api/careTeams'
import { useAuthContext } from '../context/AuthProvider'
import FormInput from '../form-elements/FormInput'
import NotificationSys from '../NotificationSystem'
import HelpTooltip from '../tooltips/HelpTooltip'

interface Props {
  isOpen: boolean
  handleClose: () => void
}

const formSchema = object({
  email: string().min(1, FIELD_IS_REQUIRED).email(EMAIL_IS_INVALID),
})

const formSettings = {
  mode: 'onChange' as const,
  resolver: zodResolver(formSchema),
  defaultValues: {
    email: '',
  },
}

type FormType = TypeOf<typeof formSchema>

const generateBody = (user: User | undefined, uri: string) => {
  if (!user) {
    return ''
  }

  const person_of_concern = () => {
    if (!user.personOfConcernFirstName && !user.personOfConcernLastName) {
      return `<i>Person of concern</i>`
    }

    if (!user.personOfConcernFirstName) {
      return user.personOfConcernLastName
    }

    if (!user.personOfConcernLastName) {
      return user.personOfConcernFirstName
    }

    return `${user.personOfConcernFirstName} ${user.personOfConcernLastName}`
  }

  return `<span>Dear friend,</span>

<p>You have been invited by ${user.firstname} ${
    user.lastname
  } to be part of the team supporting the care of ${person_of_concern()} through the <a href='${uri}'>Memory Care Partner</a> App by PAMS Inc.</p>
`
}

const BodyWrapper = styled.div`
  &:hover {
    border-color: #000000de;
  }
  &:focus-within {
    border-color: #1976d2;
    outline: none;
  }
  border: 1px solid #0000003b;
  border-radius: 4px;
  height: 350px;
  overflow: auto;
`

const InviteLink = styled.div`
  padding: 4px 4px;
  margin: 0 10px;
  box-sizing: content-box;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 1px solid #fff;

  &:hover {
    border: 1px solid #ccc;
    background-color: #eee;
    .lock-icon {
      display: block;
    }
  }
`

const ContentEditableInput = styled.div``

const ModalContent = ({ handleClose }: { handleClose: () => void }) => {
  const methods = useForm<FormType>(formSettings)
  const { handleSubmit } = methods

  const { user } = useAuthContext()
  const body = useRef(generateBody(user, document.location.origin))

  const inviteMutation = useMutation(inviteTeamMember, {
    onSuccess: () => {
      handleClose()
      NotificationSys.showSuccess(`Care Team Member successfully invited`)
    },
  })

  const onSubmitHandler: SubmitHandler<FormType> = (values: FormType) => {
    inviteMutation.mutate({ email: values.email, text: body.current })
  }

  const handleChange = useCallback(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (evt: any) => {
      body.current = evt.target.value
    },
    [body],
  )

  return (
    <Box sx={defaultModalStyle}>
      <Typography variant="h5" textAlign="center" mb={3}>
        Invite Care Member
      </Typography>

      <FormProvider {...methods}>
        <Box component="form" onSubmit={handleSubmit(onSubmitHandler)} noValidate>
          <Stack spacing={2}>
            <FormInput label="Email" name="email" required />
            <ContentEditableInput>
              <FormLabel>Text</FormLabel>
              <BodyWrapper>
                <ContentEditable
                  html={body.current}
                  onChange={handleChange}
                  style={{
                    padding: '8.5px 14px 0 14px',
                    outline: 'none',
                  }}
                />

                <InviteLink>
                  <span>
                    <a href="#">Click here</a> to register as team member
                  </span>{' '}
                  <HelpTooltip
                    text="This text cannot be changed and will be added to the bottom of your email"
                    placement="right"
                  >
                    <LockIcon
                      className="lock-icon"
                      sx={{ color: '#858585', cursor: 'pointer', display: 'none' }}
                    />
                  </HelpTooltip>
                </InviteLink>
              </BodyWrapper>
            </ContentEditableInput>
          </Stack>

          <Stack direction="row" spacing={2} justifyContent="flex-end" sx={{ mt: 4 }}>
            <LoadingButton
              loading={inviteMutation.isLoading}
              variant="outlined"
              onClick={handleClose}
              sx={minButtonWidth}
            >
              Close
            </LoadingButton>
            <LoadingButton
              loading={inviteMutation.isLoading}
              variant="contained"
              type="submit"
              sx={minButtonWidth}
            >
              Send Invite
            </LoadingButton>
          </Stack>
        </Box>
      </FormProvider>
    </Box>
  )
}

const InviteModal = (props: Props) => {
  const { isOpen, handleClose } = props

  return (
    <Modal sx={defaultModalZIndex} open={isOpen}>
      {isOpen ? <ModalContent handleClose={handleClose} /> : <Box />}
    </Modal>
  )
}

export default InviteModal
