import Box from '@mui/material/Box'
import Toolbar from '@mui/material/Toolbar'
import { ADMIN_NAV_DRAWER_WIDTH, HEADER_ZINDEX } from 'const'
import { useCheckAuthorizationAndRedirect } from 'hooks/useCheckAuthorizationAndRedirect'
import React from 'react'
import { Outlet } from 'react-router-dom'
import { UserType } from 'types'
import AdminDrawerContent from '../../drawer/AdminDrawerContent'
import DrawerHeaderFiller from '../../drawer/common/DrawerHeaderFiller'
import { NavigationDrawer } from '../../drawer/navigation/NavigationDrawer'
import { AdminHeaderContent } from '../components/AdminHeaderContent'
import AppBar from '../components/AppBar'
import Main from '../components/Main'

const mainSX = {
  display: 'flex',
  flexDirection: 'column',
  height: '100vh',
}

const AdminPageLayout = () => {
  const redirect = useCheckAuthorizationAndRedirect([UserType.ADMIN])
  if (redirect) {
    return <>{redirect}</>
  }

  return (
    <Box display="flex">
      <AppBar position="fixed" elevation={0} sx={{ zIndex: HEADER_ZINDEX }}>
        <Toolbar>
          <AdminHeaderContent />
        </Toolbar>
      </AppBar>

      <NavigationDrawer width={ADMIN_NAV_DRAWER_WIDTH}>
        <AdminDrawerContent />
      </NavigationDrawer>
      <Main sx={mainSX}>
        <DrawerHeaderFiller />
        <Box
          paddingX={2}
          paddingTop={2}
          flexGrow={1}
          className="ag-theme-alpine"
          display="flex"
          flexDirection="column"
          style={{ fontSize: '1rem' }}
        >
          <Outlet />
        </Box>
      </Main>
    </Box>
  )
}

export default AdminPageLayout
