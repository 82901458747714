import { ColDef, ICellRendererParams } from 'ag-grid-community'
import { PLACEHOLDER } from 'components/column-renderers'
import { CSVField } from 'hooks/useExportCSV'
import { CourseCompleted, LessonCompletedInReport } from 'types'
import { formatDate, isDefined } from 'utils'

export const createColumnPassedExam = (field: string): ColDef => {
  return {
    headerName: 'Passed on',
    sortable: false,
    minWidth: 200,
    cellRenderer: (
      props: ICellRendererParams<{
        [field: string]: (CourseCompleted | LessonCompletedInReport)[]
      }>,
    ) => {
      const completedItems = props.data?.[field]
      if (isDefined(completedItems)) {
        const lastPassedItem = completedItems.reverse().find((item) => item.status === 'passed')

        if (!lastPassedItem) return PLACEHOLDER

        return formatDate(lastPassedItem.completedAt, 'YYYY-MM-DD HH:mm:ss')
      }
      return PLACEHOLDER
    },
  }
}

export const createCsvColumnPassedExam = (field: string): CSVField => {
  return {
    label: 'Passed on',
    value: (data) => {
      const completedItems = data?.[field] as (CourseCompleted | LessonCompletedInReport)[]
      if (isDefined(completedItems)) {
        const lastPassedItem = completedItems.reverse().find((item) => item.status === 'passed')

        if (!lastPassedItem) return PLACEHOLDER

        return formatDate(lastPassedItem.completedAt, 'YYYY-MM-DD HH:mm:ss')
      }
      return PLACEHOLDER
    },
  }
}
