import { useMemo } from 'react'
import { CSVField } from 'hooks/useExportCSV'
import { csvColumnCreatedBy, csvColumnUpdatedBy } from 'features/UI'
import { formatDate, isDefined } from 'utils'
import { PLACEHOLDER } from 'components/column-renderers'
import { columnContentCSV } from 'features/UI/helpers/columnContentCSV'
import { createTagsColumnCSV } from 'features/Tags/models/TagsColumn'

export function useTopicCSVField() {
  return useMemo<CSVField[]>(
    () => [
      {
        label: 'Learn Library Article Name',
        value: 'name',
      },
      {
        label: 'Link to Lesson Article',
        value: ({ lesson }) => (isDefined(lesson) ? lesson.name : PLACEHOLDER),
      },
      {
        label: 'Link to Timeline Post Article',
        value: ({ careArticle }) => (isDefined(careArticle) ? careArticle.name : PLACEHOLDER),
      },
      columnContentCSV(),
      {
        label: 'Query',
        value: ({ queryId }) => (isDefined(queryId) ? 'Yes' : PLACEHOLDER),
      },
      {
        label: 'Published',
        value: ({ isPublished }) => (isPublished ? 'Published' : 'Unpublished'),
      },
      createTagsColumnCSV('tags'),
      {
        label: 'Creation Date',
        value: ({ createdAt }) => formatDate(createdAt),
      },
      csvColumnCreatedBy(),
      {
        label: 'Last Edited',
        value: ({ updatedAt }) => formatDate(updatedAt),
      },
      csvColumnUpdatedBy(),
    ],
    [],
  )
}
