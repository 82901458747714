import { createSelector, createSlice } from '@reduxjs/toolkit'
import { ETimelinePostStatus, Nullish } from 'types'
import { ETimelinePostType } from 'types/timeline.types'
import { RootState } from '../store'

interface InitialState {
  timelineTypes: Nullish<ETimelinePostType[]>
  timelineFilter: Nullish<ETimelinePostStatus[]>
}

const initialState: InitialState = {
  timelineTypes: null,
  timelineFilter: null,
}

export const timelineFiltersSlice = createSlice({
  name: 'timelineFilters',
  initialState,
  reducers: {
    setTimelineFiltersTypes: (state, { payload }) => {
      state.timelineTypes = payload
    },
    setTimelineFilters: (state, { payload }) => {
      state.timelineFilter = payload
    },
  },
})

export const { setTimelineFiltersTypes, setTimelineFilters } = timelineFiltersSlice.actions

export const selectTimelineFiltersSlice = (state: RootState) => state.timelineFilters

export const selectTimelineFiltersTypes = createSelector(
  selectTimelineFiltersSlice,
  (slice) => slice.timelineTypes,
)

export const selectTimelineFilter = createSelector(
  selectTimelineFiltersSlice,
  (slice) => slice.timelineFilter,
)
