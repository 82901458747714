import { LoadingButton } from '@mui/lab'
import { Button, Grid } from '@mui/material'
import React from 'react'

interface Props {
  onCancel: () => void
  onSave?: () => void
  saveText?: string
  mt?: number
  buttonSaveType?: 'submit'
  loading?: boolean
  disabledSave?: boolean
}

const VerticalButtonBox = (props: Props) => {
  const { onCancel, onSave, saveText, mt, buttonSaveType, loading, disabledSave = false } = props

  return (
    <Grid container spacing={2} direction="column" alignItems="center" mt={mt}>
      <Grid item>
        <LoadingButton
          onClick={onSave}
          variant="contained"
          color="secondary"
          sx={{ minWidth: 200 }}
          type={buttonSaveType}
          loading={loading}
          disabled={disabledSave}
        >
          {saveText}
        </LoadingButton>
      </Grid>
      <Grid item>
        <Button onClick={onCancel} variant="outlined" color="info" sx={{ minWidth: 200 }}>
          Cancel
        </Button>
      </Grid>
    </Grid>
  )
}

export default VerticalButtonBox
