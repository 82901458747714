const ITEM_HEIGHT = 54
const ITEM_PADDING_TOP = 8
export const selectMenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4 + ITEM_PADDING_TOP * 2,
      scrollbarWidth: 'thin',
    },
  },
}
