import { Button, Stack, Typography } from '@mui/material'
import { UIStatusBadge } from 'features/UI'
import { ETimelinePostStatus } from 'types'

export function TimelineCourseChildItem({
  name,
  bannerUrl,
  status,
  buttonTitle,
  onClick,
}: {
  name: string
  bannerUrl: string | null
  status: ETimelinePostStatus
  buttonTitle: string
  onClick: () => void
}) {
  return (
    <Stack
      direction="row"
      alignItems="center"
      sx={{
        background: 'white',
        padding: 1.5,
        borderRadius: 2,
        width: '100%',
        cursor: 'pointer',
        gap: '8px',
      }}
      onClick={onClick}
    >
      {bannerUrl && (
        <img
          style={{
            verticalAlign: 'middle',
            maxWidth: 60,
            maxHeight: 60,
          }}
          src={bannerUrl}
          alt=""
        />
      )}

      <Stack flexGrow={1}>
        <Typography>{name}</Typography>

        <Stack direction="row" alignItems="center" justifyContent="space-between" flexGrow={1}>
          <UIStatusBadge status={status} />

          <Button size="small" variant="contained" onClick={onClick}>
            {buttonTitle}
          </Button>
        </Stack>
      </Stack>
    </Stack>
  )
}
