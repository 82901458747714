import { useMediaQuery } from '@mui/material'
import Box from '@mui/material/Box'
import { useTheme } from '@mui/material/styles'
import Toolbar from '@mui/material/Toolbar'
import { HEADER_ZINDEX } from 'const'
import { LayoutClientPageTitle } from 'features/Layout'
import { useCheckAuthorizationAndRedirect } from 'hooks/useCheckAuthorizationAndRedirect'
import { useShowControl } from 'hooks/useShowControl'
import React from 'react'
import { Outlet } from 'react-router-dom'
import { UserType } from 'types'
import ClientsDrawersProvider from '../../context/ClientsDrawersProvider'
import DrawerHeaderFiller from '../../drawer/common/DrawerHeaderFiller'
import { ClientDrawerContent } from '../../drawer/navigation/ClientDrawerContent'
import { NavigationDrawer } from '../../drawer/navigation/NavigationDrawer'
import QuickTipModal from '../../modals/QuickTipModal'
import AppBar from '../components/AppBar'
import { ClientsHeaderContent } from '../components/ClientsHeaderContent'
import Main from '../components/Main'
import FooterContent from '../FooterContent'

const ClientsPageLayout = () => {
  const theme = useTheme()
  const [isTipOpen, , setTipClose] = useShowControl(false) // TODO enable by default
  const matchesXs = useMediaQuery(theme.breakpoints.down('sm'))

  const redirect = useCheckAuthorizationAndRedirect([
    UserType.TRAINEE,
    UserType.FAMILY,
    UserType.CARETEAM,
    UserType.CAREGIVER,
    UserType.ORGANIZATION,
  ])

  if (redirect) {
    return <>{redirect}</>
  }

  return (
    <ClientsDrawersProvider>
      <Box display="flex">
        <AppBar position="fixed" elevation={0} sx={{ zIndex: HEADER_ZINDEX }}>
          <Toolbar>
            <ClientsHeaderContent />
          </Toolbar>
        </AppBar>

        <AppBar
          position="fixed"
          elevation={0}
          sx={{ zIndex: (theme) => theme.zIndex.drawer + 1, top: 'auto', bottom: 0 }}
        >
          <Toolbar>
            <FooterContent />
          </Toolbar>
        </AppBar>

        <NavigationDrawer>
          <ClientDrawerContent />
        </NavigationDrawer>

        <Main
          sx={{
            minHeight: '100vh',
            display: 'flex',
            flexDirection: 'column',
            marginBottom: matchesXs ? '72px' : 0,
          }}
        >
          <DrawerHeaderFiller />
          {matchesXs && <LayoutClientPageTitle />}

          <Outlet />
        </Main>
        <QuickTipModal isOpen={isTipOpen} handleClose={setTipClose} />
      </Box>
    </ClientsDrawersProvider>
  )
}

export default ClientsPageLayout
