import axiosInstance from 'api/axios'
import {
  BaseRegisterRequest,
  ECareLevel,
  EUserEducation,
  EUserExperience,
  PaymentOutResponse,
  RegisterRequest,
  UserType,
} from 'types'
import { FamilyFormType } from '../components/AuthSignupFamily/types'
import { TraineeFormType } from '../components/AuthSignupTrainee/types'

export const createPaymentIntent = (
  email: string,
  payment_method: string,
  subscriptionType: 'trainee' | 'family',
  formData: FamilyFormType | TraineeFormType,
): Promise<PaymentOutResponse> => {
  let registerRequest: RegisterRequest | null
  const base: BaseRegisterRequest = {
    accessCode: null,
    firstname: formData.firstName,
    lastname: formData.lastName,
    email: formData.email,
    type: subscriptionType === 'trainee' ? UserType.TRAINEE : UserType.CAREGIVER,
    password: formData.myNewPassword,
  }

  if ('education' in formData) {
    registerRequest = {
      ...base,
      education: formData.education as EUserEducation,
      experience: formData.yearsOfExperience as EUserExperience,
      language: formData.language === 'other' ? formData.languageOther : formData.language,
    }
  } else {
    registerRequest = {
      ...base,
      personOfConcernFirstName: formData.personOfConcernFirstName,
      personOfConcernLastName: formData.personOfConcernLastNameOrInitial,
      careLevel: formData.levelOfCareNeeded as ECareLevel,
    }
  }

  return axiosInstance.post('/auth/subscribe', {
    email,
    paymentMethod: payment_method,
    subscriptionType,
    userData: registerRequest,
  })
}
