import { useAuthContext } from 'components/context/AuthProvider'
import { UIUserIcon } from 'features/UI'

export const UserCurrentIcon = ({
  width = 40,
  height = 40,
}: {
  width?: number
  height?: number
}) => {
  const { user } = useAuthContext()

  return (
    <UIUserIcon
      width={width}
      height={height}
      firstName={user?.firstname}
      lastName={user?.lastname}
    />
  )
}
