import { Box, Tab, Tabs } from '@mui/material'
import { grey } from '@mui/material/colors'
import Drawer from '@mui/material/Drawer'
import { getCarePerson } from 'api/carePersons'
import { defaultDrawerSX } from 'const'
import { useTabsControl } from 'hooks/useTabsControl'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useQuery } from 'react-query'
import { useActions } from 'store/hooks'
import { CarePerson } from 'types'
import { getTitle } from '../../tabs/TabsTitle'
import ProfileBanner from '../article/ProfileBanner'
import DrawerContainer from '../common/DrawerContainer'
import DrawerHeader from '../common/DrawerHeader'
import ConcernProfileBasicInfoForm from './forms/ConcernProfileBasicInfoForm'
import ConcernProfileContactsTab from './forms/ConcernProfileContactsTab'
import ConcernProfileMedicinesTable from './forms/ConcernProfileMedicinesTable'
import ConcernProfileReportsForm from './forms/ConcernProfileReportsForm'

interface Props {
  isOpen: boolean
  handleClose: () => void
  carePersonId: number
}

const PersonOfConcernProfileDrawer = (props: Props) => {
  const { carePersonId, isOpen, handleClose } = props
  const [person, setPerson] = useState<CarePerson>()

  const getPeson = useQuery(
    ['personOfConcern', props.carePersonId],
    () => getCarePerson({ id: props.carePersonId }),
    {
      enabled: !!carePersonId,
      onSuccess: (person) => {
        setPerson(person)
      },
    },
  )

  const carePersonFullName = useMemo(() => {
    if (!person) {
      return 'N/A'
    }

    if (!person.firstname && !person.lastname) {
      return 'N/A'
    }

    if (!person.lastname) {
      return person.firstname
    }

    if (!person.firstname) {
      return person.lastname
    }

    return `${person.firstname} ${person.lastname}`
  }, [person])

  const { selectedTab, handleChangeSelectedTab, setSelectedTab } = useTabsControl('1')

  const { updateCarePerson } = useActions()

  const handlePersonUpdated = useCallback(
    (newPerson: CarePerson) => {
      setPerson(newPerson)
      updateCarePerson({ firstName: newPerson.firstname, lastName: newPerson.lastname })
    },
    [setPerson, updateCarePerson],
  )

  const renderedTab = useMemo(() => {
    if (!person) {
      return null
    }

    switch (selectedTab) {
      case '1':
        return (
          <ConcernProfileBasicInfoForm carePerson={person} onPersonUpdated={handlePersonUpdated} />
        )
      case '2':
        return <ConcernProfileContactsTab carePersonId={props.carePersonId} />
      case '3':
        return (
          <ConcernProfileMedicinesTable
            carePersonId={props.carePersonId}
            carePersonFullName={carePersonFullName}
          />
        )
      case '4':
        return (
          <ConcernProfileReportsForm
            carePersonId={props.carePersonId}
            carePersonFullName={carePersonFullName}
          />
        )
    }
    return null
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedTab, person])

  useEffect(() => {
    if (!isOpen) {
      setSelectedTab('1')
    }
  }, [isOpen, setSelectedTab])

  return (
    <Drawer anchor="right" open={isOpen} onClose={handleClose} sx={defaultDrawerSX}>
      {isOpen ? (
        <DrawerContainer
          isOpen={isOpen}
          hasData
          loading={getPeson.isLoading}
          handleClose={handleClose}
        >
          <DrawerHeader handleClose={handleClose}>Person of Concern Profile</DrawerHeader>

          <ProfileBanner profileName={carePersonFullName} typeOfProfile="personOfConcern" />

          <Tabs
            value={selectedTab}
            onChange={handleChangeSelectedTab}
            textColor="secondary"
            indicatorColor="secondary"
            variant="fullWidth"
            sx={{ background: grey[100] }}
          >
            <Tab value="1" label={getTitle('Basic Info')} />
            <Tab value="2" label={getTitle('Contacts')} />
            <Tab value="3" label={getTitle('Medicines')} />
            <Tab value="4" label={getTitle('Reports')} />
          </Tabs>
          <Box mt={2}>{renderedTab}</Box>
        </DrawerContainer>
      ) : null}
    </Drawer>
  )
}

export default PersonOfConcernProfileDrawer
