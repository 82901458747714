import { Box } from '@mui/material'
import { ColDef } from 'ag-grid-community'
import { getQuizzesReport } from 'api/reports'
import InfinityAgGrid from 'components/table/InfinityAgGrid'
import TableToolbar from 'components/table/TableToolbar'
import {
  createCohortsNamesColumn,
  createCohortsNamesCsvColumn,
  useCohortsFilter,
} from 'features/Cohorts'
import { useExportCSV } from 'hooks/useExportCSV'
import { useGridControl } from 'hooks/useGridControl'
import React, { useCallback } from 'react'
import { BaseTableRequest, FilterModel } from 'types'
import { useGetDataSource } from 'utils'

const columnDefs: ColDef[] = [
  { headerName: 'Quiz Name', field: 'quizName', filter: 'agTextColumnFilter' },
  createCohortsNamesColumn('cohortNames'),
  { headerName: 'Number of Completed Quizzes', field: 'completedCount', minWidth: 300 },
  { headerName: 'Passed Quizzes', field: 'passedCount' },
  { headerName: 'Failed Quizzes', field: 'failedCount' },
  { headerName: '% of Passed Quizzes', field: 'passedPercent', minWidth: 240, filter: 'number' },
  { headerName: '% of Failed Quizzes', field: 'failedPercent', minWidth: 240, filter: 'number' },
]

const csvFields = [
  {
    label: 'Quiz Name',
    value: 'quizName',
  },
  createCohortsNamesCsvColumn('cohortNames'),
  {
    label: 'Number of Completed Quizzes',
    value: 'completedCount',
  },
  {
    label: 'Passed Quizzes',
    value: 'passedCount',
  },
  {
    label: 'Failed Quizzes',
    value: 'failedCount',
  },
  {
    label: '% of Passed Quizzes',
    value: 'passedPercent',
  },
  {
    label: '% of Failed Quizzes',
    value: 'failedPercent',
  },
]

export const ReportsQuizzes = () => {
  const { cohortsFilterValue, CohortsFilter } = useCohortsFilter()

  const getRequest = useCallback(
    (req: BaseTableRequest) => {
      const filter: FilterModel = {
        ...(req.filter || {}),
      }

      if (cohortsFilterValue) {
        filter.cohortId = cohortsFilterValue
      }

      const request = { ...req, filter }

      return getQuizzesReport(request)
    },
    [cohortsFilterValue],
  )

  const getDataSource = useGetDataSource(getRequest)
  const { onGridReady, gridApi, columnApi } = useGridControl(getDataSource)

  const { csvLoading, onExportCSV } = useExportCSV(
    getRequest,
    gridApi,
    columnApi,
    csvFields,
    'Quizzes Report.csv',
  )

  return (
    <>
      <TableToolbar
        left={<Box minWidth="200px">{CohortsFilter}</Box>}
        exportCSVEnable
        csvLoading={csvLoading}
        onExportCSV={onExportCSV}
      />
      <InfinityAgGrid pagination columnDefs={columnDefs} onGridReady={onGridReady} />
    </>
  )
}
