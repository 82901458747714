import { ColDef } from 'ag-grid-community'
import { CSVField } from 'hooks/useExportCSV'
import { createEngagementRenderer } from './createEngagementRenderer'
import { formatEngagement } from './formatEngagement'

export const createEngagementColumn = (
  title: string,
  field: string,
  options?: Partial<ColDef>,
): ColDef => {
  return {
    headerName: title,
    field: field,
    minWidth: 250,
    cellRenderer: createEngagementRenderer(field),
    ...options,
  }
}

export const createEngagementCsvColumn = (title: string, field: string): CSVField => {
  return {
    label: title,
    value: (data) => formatEngagement(data[field], '-'),
  }
}
