import React from 'react'
import NotificationSystem from 'react-notification-system'
import styled from '@emotion/styled'
import { Alert, AlertTitle } from '@mui/material'
import { AlertWithTimer } from './alertWithTimer/AlertWithTimer'

const NotificationWrapper = styled('div')`
  .notification {
    border-width: 8px !important;
    padding: 0 !important;
  }

  .notification-dismiss {
    top: 10px !important;
    right: 8px !important;
    width: 24px !important;
    height: 24px !important;
    font-size: 20px !important;
    line-height: 24px !important;
  }

  .notification-title {
    font-size: 16px !important;
  }
`

class NotificationSys extends React.Component {
  static notificationSystem = React.createRef<NotificationSystem>()

  static addNotification = (notification: NotificationSystem.Notification) => {
    const sys = this.notificationSystem.current
    sys?.addNotification(notification)
  }

  static showWarning = (title: React.ReactNode, autoDismiss = 7) => {
    NotificationSys.addNotification({
      level: 'warning',
      title: (
        <Alert severity="warning">
          <AlertTitle>Warning</AlertTitle>
          {title}
        </Alert>
      ),
      autoDismiss,
    })
  }

  static showSuccess = (title: React.ReactNode, autoDismiss = 7) => {
    NotificationSys.addNotification({
      level: 'success',
      title: (
        <Alert severity="success">
          <AlertTitle>Success</AlertTitle>
          {title}
        </Alert>
      ),
      autoDismiss,
    })
  }

  static showWarningWithTimer = (
    title: string,
    buttonTitle: string,
    timerMin: number = 1,
  ): void => {
    NotificationSys.addNotification({
      level: 'warning',
      title: <AlertWithTimer title={title} buttonTitle={buttonTitle} intervalMin={timerMin} />,
      autoDismiss: timerMin * 60,
    })
  }

  render() {
    return (
      <NotificationWrapper>
        <NotificationSystem ref={NotificationSys.notificationSystem} />
      </NotificationWrapper>
    )
  }
}

export default NotificationSys
