import { Box, Stack } from '@mui/material'
import ScrollContainer from 'components/page/ScrollContainer'
import FailPlaceholder from 'components/placeholders/FailPlaceholder'
import LoadingPlaceholder from 'components/placeholders/LoadingPlaceholder'
import { LearnLibraryStatusDropdown } from 'features/LearnLibrary/components/LearnLibraryStatusDropdown/LearnLibraryStatusDropdown'
import { heightOfToolbar } from 'const'
import { TagsFilterDropdown } from 'features/Tags'
import { useUnmount } from 'hooks/useUnmount'
import sortBy from 'lodash/sortBy'
import React, { useEffect, useMemo } from 'react'
import { useSelector } from 'react-redux'
import { useActions, useAppDispatch, useAppSelector } from 'store/hooks'
import {
  resetLearnLibraryFilter,
  selectLearnLibraryStatusFilter,
} from 'store/slice/learnLibraryFilters.slice'
import { resetTagFilters, selectTagsFiltersIds } from 'store/slice/tagsFilters.slice'
import { allFiltersStatuses, ArticleCategory, ETimelinePostStatus } from 'types'
import { CoursesList } from './CoursesList'

const LearnLibraryTab = () => {
  const dispatch = useAppDispatch()
  const { isLoading, loadingFailed, learnLibraries } = useAppSelector((s) => s.learnLibrary)
  const { getTopics } = useActions()
  const statusFilter = useSelector(selectLearnLibraryStatusFilter)
  const selectedTagIdsFilter = useSelector(selectTagsFiltersIds)

  const categories = useMemo(() => {
    const map = learnLibraries
      .filter((learnLibrary) => learnLibrary.type === 'learnTopics')
      .reduce((acc, item) => {
        if (!item.learnArticle || !item.learnTopic) {
          return acc
        }

        let topic = acc[item.learnTopic.id]
        if (!topic) {
          topic = acc[item.learnTopic.id] = {
            id: item.learnTopic.id,
            name: item.learnTopic.name,
            articles: [],
            icon: item.learnTopic.bannerUrl,
            order: item.learnTopic.order,
          }
        }

        topic.articles.push({
          id: item.learnArticle.id,
          name: item.learnArticle.name,
          status: item.status,
          post: item.learnArticle.post,
          order: item.learnArticle.order,
          icon: item.learnArticle.bannerUrl,
        })

        return acc
      }, {} as Record<number, ArticleCategory>)

    // sort articles by order, articles with status done should be at the end
    const sortedCategories = sortBy(Object.values(map), 'order')
    const result = sortedCategories.map((category) => {
      const doneArticles = category.articles.filter(
        (article) => article.status === ETimelinePostStatus.done,
      )
      const otherArticles = category.articles.filter(
        (article) => article.status !== ETimelinePostStatus.done,
      )

      return {
        ...category,
        articles: sortBy(otherArticles, 'order').concat(sortBy(doneArticles, 'order')),
      }
    })

    return result
  }, [learnLibraries])

  useEffect(() => {
    getTopics({
      status: statusFilter !== null ? statusFilter : allFiltersStatuses,
      selectedTagIds: selectedTagIdsFilter,
    })
  }, [getTopics, selectedTagIdsFilter, statusFilter])

  useUnmount(() => {
    dispatch(resetLearnLibraryFilter())
    dispatch(resetTagFilters())
  })

  if (loadingFailed) {
    return <FailPlaceholder error="Can't load data of topics" />
  }

  return (
    <Stack paddingLeft={{ xs: 2, sm: 2 }} paddingRight={{ xs: 1, sm: 1, lg: 0 }} spacing={2}>
      <Stack direction="row" flexWrap="wrap" className="gap-8" pt={2}>
        <LearnLibraryStatusDropdown />
        <TagsFilterDropdown />
      </Stack>

      {isLoading && (
        <Box sx={{ paddingY: 3 }}>
          <LoadingPlaceholder />
        </Box>
      )}
      {!isLoading && (
        <Stack>
          <ScrollContainer deltaSpace={heightOfToolbar}>
            <CoursesList categoriesOfArticles={categories} />
          </ScrollContainer>
        </Stack>
      )}
    </Stack>
  )
}

export default LearnLibraryTab
