import { ProgressCourse, ProgressResult, Response, User } from 'types'
import { getFromLocalStorage, USER_DATA } from 'utils'
import axiosInstance from './axios'

const getUserIdRequest = () => {
  const user = getFromLocalStorage(USER_DATA) as User | null
  const userId = user?.id
  return { userId }
}

export const getProgressResults = async (): Promise<Response<ProgressResult[]>> => {
  return axiosInstance.post('/progress/results', getUserIdRequest())
}

export const getProgressAssessments = async (): Promise<Response<ProgressCourse[]>> => {
  return axiosInstance.post('/progress/assessments', getUserIdRequest())
}
