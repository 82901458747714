import { zodResolver } from '@hookform/resolvers/zod/dist/zod'
import { LoadingButton } from '@mui/lab'
import { Box, Button, Grid, Modal, Stack, Typography } from '@mui/material'
import {
  defaultModalStyle,
  defaultModalZIndex,
  EMAIL_IS_INVALID,
  FIELD_IS_REQUIRED,
  minButtonWidth,
} from 'const'
import React from 'react'
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form'
import { Contact } from 'types'
import { object, string, TypeOf } from 'zod'
import FormInput from '../form-elements/FormInput'

interface Props {
  isOpen: boolean
  loading: boolean
  handleClose: () => void
  onSubmit: (value: Partial<Contact>) => void
}

const formSchema = object({
  name: string().min(1, FIELD_IS_REQUIRED),
  roleInCare: string().min(1, FIELD_IS_REQUIRED),
  streetNumber: string().min(1, FIELD_IS_REQUIRED),
  streetName: string().min(1, FIELD_IS_REQUIRED),
  city: string().min(1, FIELD_IS_REQUIRED),
  state: string().min(1, FIELD_IS_REQUIRED),
  country: string().min(1, FIELD_IS_REQUIRED),
  zipCode: string().min(1, FIELD_IS_REQUIRED),
  phoneNumber: string().min(1, FIELD_IS_REQUIRED),
  email: string().min(1, FIELD_IS_REQUIRED).email(EMAIL_IS_INVALID),
})

type FormType = TypeOf<typeof formSchema>

const defaultValues = {
  name: '',
  roleInCare: '',
  streetNumber: '',
  streetName: '',
  state: '',
  country: '',
  zipcode: '',
  phoneNumber: '',
  email: '',
}

const FormContent = (props: Props) => {
  const { handleClose, loading, onSubmit } = props

  const formSettings = {
    mode: 'onChange' as const,
    resolver: zodResolver(formSchema),
    defaultValues,
  }

  const methods = useForm<FormType>(formSettings)
  const { handleSubmit } = methods

  const onSubmitHandler: SubmitHandler<FormType> = (values: FormType) => {
    onSubmit(values)
  }

  return (
    <Box sx={defaultModalStyle}>
      <Typography variant="h5" mb={2}>
        Add Contact
      </Typography>
      <FormProvider {...methods}>
        <Box component="form" onSubmit={handleSubmit(onSubmitHandler)} width="100%" noValidate>
          <Grid container rowSpacing={1} columnSpacing={2} sx={{ mb: 1 }}>
            <Grid item sm={12} xs={12}>
              <FormInput label="Name" required fullWidth name="name" />
            </Grid>

            <Grid item xs={12}>
              <FormInput label="Role in Care" required fullWidth name="roleInCare" />
            </Grid>

            <Grid item sm={6} xs={12}>
              <FormInput label="Street Number" required fullWidth name="streetNumber" />
            </Grid>
            <Grid item sm={6} xs={12}>
              <FormInput label="Street Name" required fullWidth name="streetName" />
            </Grid>
            <Grid item sm={6} xs={12}>
              <FormInput label="City" required fullWidth name="city" />
            </Grid>
            <Grid item sm={6} xs={12}>
              <FormInput label="State" required fullWidth name="state" />
            </Grid>
            <Grid item sm={6} xs={12}>
              <FormInput label="Country" required fullWidth name="country" />
            </Grid>

            <Grid item sm={6} xs={12}>
              <FormInput label="Zip Code" required fullWidth name="zipCode" />
            </Grid>
          </Grid>

          <Grid container spacing={2}>
            <Grid item sm={6} xs={12}>
              <FormInput label="Phone Number" required fullWidth name="phoneNumber" />
            </Grid>
            <Grid item sm={6} xs={12}>
              <FormInput label="Email" required fullWidth name="email" />
            </Grid>
          </Grid>

          <Stack direction="row" spacing={2} justifyContent="flex-end" sx={{ mt: 4 }}>
            <Button disabled={loading} variant="outlined" onClick={handleClose} sx={minButtonWidth}>
              Close
            </Button>
            <LoadingButton loading={loading} variant="contained" type="submit" sx={minButtonWidth}>
              Create
            </LoadingButton>
          </Stack>
        </Box>
      </FormProvider>
    </Box>
  )
}

const AddContactModal = (props: Props) => {
  const { isOpen } = props

  return (
    <Modal sx={defaultModalZIndex} open={isOpen}>
      {isOpen ? <FormContent {...props} /> : <Box />}
    </Modal>
  )
}

export default AddContactModal
