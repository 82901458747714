import { ChangeEvent, useCallback, useState } from 'react'
import FormInput from 'components/form-elements/FormInput'
import { useCharactersLeft } from 'hooks/useCharactersLeft'

export const QuestionEditorFieldExplanation = ({
  value,
  maxLength,
}: {
  value: string
  maxLength: number
}) => {
  const [explanation, setExplanation] = useState(value)

  const handleChange = useCallback(
    (e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
      setExplanation(e.target.value)
    },
    [setExplanation],
  )

  const charactersLeft = useCharactersLeft(maxLength, explanation.length)
  return (
    <FormInput
      name="explanation"
      label="Explanation"
      placeholder="Start typing"
      fullWidth
      minRows={3}
      maxRows={6}
      multiline
      onChange={handleChange}
      helperText={charactersLeft}
    />
  )
}
