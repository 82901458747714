import { Button } from '@mui/material'
import { createQuestionnaire } from 'api/questionaries'
import ModalWithTextAndIcon, {
  NameAndIconRequest,
} from 'components/modals/simple-modals/ModalWithTextAndIcon'
import { useShowControl } from 'hooks/useShowControl'
import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { routes } from 'routes/routes'

export function ExamControlAdd({ parentId }: { parentId: number }) {
  const navigate = useNavigate()
  const [isOpen, handleOpen, handleClose] = useShowControl()
  const [isLoading, setIsLoading] = useState(false)

  const handleSubmit = async (values: NameAndIconRequest) => {
    try {
      if (!parentId) return

      if (!values.icon) return

      setIsLoading(true)
      const response = await createQuestionnaire({
        courseId: parentId,
        name: values.name,
        bannerMediaFileId: values.icon,
      })

      const id = response.data.id || ''
      navigate(`${routes.questionnaireEdit}?id=${id}&type=questionnaire`)
      handleClose()
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <>
      <Button variant="contained" onClick={handleOpen}>
        Add Exam
      </Button>

      <ModalWithTextAndIcon
        value={{ name: '', icon: null }}
        title="Create Exam"
        label="Exam name"
        errorText="Exam name is required"
        buttonText="Create"
        isOpen={isOpen}
        handleClose={handleClose}
        onSubmit={handleSubmit}
        loading={isLoading}
        setLoading={setIsLoading}
      />
    </>
  )
}
