import { OutputData } from '@editorjs/editorjs/types/data-formats/output-data'
import { Button } from '@mui/material'
import { checkLessonUniqueness, createLesson } from 'api/lessons'
import ModalWithTextInput from 'components/modals/simple-modals/ModalWithTextInput'
import NotificationSys from 'components/NotificationSystem'
import { useShowControl } from 'hooks/useShowControl'
import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { routes } from 'routes/routes'

const article: OutputData = {
  blocks: [
    {
      type: 'paragraph',
      data: {
        text: 'First block of article',
      },
    },
  ],
}

export function LessonControlAdd({ courseId }: { courseId: number }) {
  const navigate = useNavigate()
  const [isOpenAddLesson, openAddLesson, closeAddLesson] = useShowControl()

  const [addLoading, setAddLoading] = useState(false)

  const onSaveLesson = async (lessonName: string) => {
    try {
      if (courseId) {
        setAddLoading(true)
        const success = await checkLessonUniqueness({ name: lessonName })
        if (!success) {
          NotificationSys.showWarning('Lesson with such name already exists')
          setAddLoading(false)
          return
        }

        const response = await createLesson({
          courseId,
          name: lessonName,
          article,
        })
        const lessonId = response.data.id || ''
        navigate(`${routes.lessonEdit}?id=${lessonId}&courseId=${courseId}`)
        closeAddLesson()
      }
    } finally {
      setAddLoading(false)
    }
  }

  return (
    <>
      <Button variant="contained" onClick={openAddLesson}>
        Add Lesson
      </Button>

      <ModalWithTextInput
        value=""
        title="Create Lesson"
        label="Lesson name"
        errorText="Lesson name is required"
        buttonText="Create"
        isOpen={isOpenAddLesson}
        handleClose={closeAddLesson}
        onSubmit={onSaveLesson}
        loading={addLoading}
      />
    </>
  )
}
