import { AxiosResponse } from 'axios'
import { BaseTableRequest, Medicine, RequestById, TableResponse } from 'types'
import axiosInstance from './axios'

export const getMedicine = (id: number) => {
  return axiosInstance.post<void, TableResponse<Medicine>>('/medicine', {
    filter: {
      carePersonId: {
        type: 'number',
        op: 'equals',
        value: id,
      },
    },
  } satisfies BaseTableRequest)
}

export const createMedicine = (contact: Partial<Medicine>) => {
  return axiosInstance.post<void, AxiosResponse<void>>('/medicine/create', contact)
}

export const deleteMedicine = (request: RequestById) => {
  return axiosInstance.post<void, AxiosResponse<void>>('/medicine/delete', request)
}
