import { ICellRendererParams } from 'ag-grid-community'
import { PLACEHOLDER } from 'components/column-renderers'
import { EAccessCodeType } from 'types'

export const AccessManagementColumnCodeType = (
  props: ICellRendererParams<{ codeType: EAccessCodeType }>,
) => {
  const codeType = props?.data?.codeType
  if (!codeType) {
    return PLACEHOLDER
  }
  if (codeType === EAccessCodeType.institutionAccessCode) {
    return 'Institutional Access Code'
  }

  if (codeType === EAccessCodeType.promoCode) {
    return 'Promo Code'
  }
  return codeType
}
