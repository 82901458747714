import React from 'react'
import { CircularProgress, FormLabel, Stack } from '@mui/material'
import Autocomplete from '@mui/material/Autocomplete'
import TextField from '@mui/material/TextField'
import { Option } from './FormSelect'
import { useShowControl } from '../../hooks/useShowControl'
import { TextFieldProps } from '@mui/material/TextField/TextField'
import { Controller, useFormContext } from 'react-hook-form'

type Props = {
  name: string
  label: string
  options: Option[]
  isLoading: boolean
  onChangeInput?: ((value: string) => void) | undefined
} & TextFieldProps

const isOptionEqualToValue = (option: Option, value: Option) => option.value === value.value
const getOptionLabel = (option: Option) => option.label

const FormAutocomplete = (props: Props) => {
  const { onChangeInput, options, isLoading, label, name, defaultValue } = props

  const [open, onOpen, onClose] = useShowControl(false)

  const {
    control,
    formState: { errors },
  } = useFormContext()

  return (
    <Controller
      control={control}
      name={name}
      defaultValue={defaultValue}
      render={({ field }) => {
        const onInputChange = (e: unknown, newInputValue: string) => {
          if (!onChangeInput) {
            return
          }
          if (!options.some((it) => it.label === newInputValue)) {
            onChangeInput(newInputValue)
          } else {
            onChangeInput('')
          }
        }

        const onOpenWrapper = () => {
          onOpen()
          onInputChange(null, '')
        }
        return (
          <Stack spacing={0.5}>
            <FormLabel>{label}</FormLabel>
            <Autocomplete
              value={options.find((it) => it.value === field.value) || null}
              open={open}
              onOpen={onOpenWrapper}
              onClose={onClose}
              isOptionEqualToValue={isOptionEqualToValue}
              getOptionLabel={(a) => getOptionLabel(a).toString()}
              options={options}
              loading={isLoading}
              onInputChange={onInputChange}
              onChange={(e, value) => field.onChange(value && value.value)}
              openOnFocus={true}
              renderInput={(params) => (
                <TextField
                  {...params}
                  {...field}
                  size="small"
                  name={name}
                  error={!!errors[name]}
                  helperText={(errors[name] ? errors[name]?.message : '') as string}
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: (
                      <React.Fragment>
                        {isLoading ? <CircularProgress color="inherit" size={20} /> : null}
                        {params.InputProps.endAdornment}
                      </React.Fragment>
                    ),
                  }}
                />
              )}
            />
          </Stack>
        )
      }}
    />
  )
}

export default React.memo(FormAutocomplete)
