import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { getProgressResults } from 'api/progress'
import { ProgressResult } from 'types'

export const getProgressResultsById = createAsyncThunk(
  'progress-results/by-user-id',
  async (_, thunkAPI) => {
    try {
      const response = await getProgressResults()
      return response.data
    } catch (e) {
      thunkAPI.rejectWithValue({})
    }
  },
)

export const progressResultsSlice = createSlice({
  name: 'progress-results',
  initialState: {
    progressResults: null as ProgressResult[] | null,
    isLoading: false,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getProgressResultsById.pending, (state) => {
        state.isLoading = true
      })
      .addCase(getProgressResultsById.fulfilled, (state, action) => {
        state.isLoading = false
        state.progressResults = action.payload ?? null
      })
      .addCase(getProgressResultsById.rejected, (state) => {
        state.isLoading = false
        state.progressResults = null
      })
  },
})
