import React from 'react'
import Drawer from '@mui/material/Drawer'
import { defaultDrawerSX } from '../../../const'
import { Link, Stack, Typography } from '@mui/material'
import DrawerContainer from '../common/DrawerContainer'
import DrawerHeader from '../common/DrawerHeader'
import logo_full from '../../../images/logo_full.png'
import { LEARN_MORE_ABOUT_US, MAIL_CONTACT } from '../../../routes/routes'

interface Props {
  isOpen: boolean
  handleClose: () => void
}

const AboutUsDrawer = (props: Props) => {
  const { isOpen, handleClose } = props

  const variant = 'subtitle1'
  const sx = { fontSize: '1.25rem', color: '#333', lineHeight: '2rem' }

  return (
    <Drawer anchor="right" open={isOpen} onClose={handleClose} sx={defaultDrawerSX}>
      <DrawerContainer isOpen={isOpen} hasData loading={false} handleClose={handleClose}>
        <DrawerHeader handleClose={handleClose}>{null}</DrawerHeader>
        <img
          src={logo_full}
          alt=""
          style={{
            display: 'block',
            width: '100%',
            maxWidth: '360px',
            height: 'auto',
            margin: '-52px auto 0',
          }}
        />
        <Stack mt={4} spacing={3} textAlign="center">
          <Typography fontWeight={400} variant={variant} sx={sx}>
            An innovative approach to memory loss and aging from
            <br /> ProActive Memory Service, Inc.
          </Typography>
          <Typography fontWeight={400} variant={variant} sx={sx}>
            We welcome your feedback!
            <br />
            Join our caregiver community and help us improve Memory Care Partner by sending your
            questions and comments to:{' '}
            <Link href={MAIL_CONTACT}>Contact@proactivememoryservices.com</Link>
          </Typography>
          <Typography fontWeight={400} variant={variant} sx={sx}>
            Learn more about us and our innovative approach to caregiving at:{' '}
            <Link target="__blank" href={LEARN_MORE_ABOUT_US}>
              http://www.proactivememoryservices.com/
            </Link>
          </Typography>
          <Typography fontWeight={400} variant={variant} sx={sx}>
            We would love to hear from you!
            <br />
            Click here to answer a short survey about your experience
            <br />
            using Memory Care Partner.
          </Typography>
        </Stack>
      </DrawerContainer>
    </Drawer>
  )
}

export default AboutUsDrawer
