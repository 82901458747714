import { UIFilterDropdown } from 'features/UI'
import { useSelector } from 'react-redux'
import { useAppDispatch } from 'store/hooks'
import { selectTimelineFilter, setTimelineFilters } from 'store/slice/timelineFilters.slice'
import { ETimelinePostStatus, Nullish } from 'types'

const filterItems = [
  {
    id: null,
    label: 'All',
  },
  {
    id: ETimelinePostStatus.new,
    label: 'New',
  },
  {
    id: ETimelinePostStatus.review,
    label: 'Continue',
  },
  {
    id: ETimelinePostStatus.done,
    label: 'Done',
  },
]

export function TimelineFilterByStatus() {
  const dispatch = useAppDispatch()
  const timelineFilter = useSelector(selectTimelineFilter)

  const handleClickCheckbox = (value: Nullish<ETimelinePostStatus>[] | null) => {
    dispatch(setTimelineFilters(value))
  }

  return (
    <UIFilterDropdown<Nullish<ETimelinePostStatus>>
      buttonName="Read Status"
      checkboxes={filterItems}
      value={timelineFilter}
      onClickCheckbox={handleClickCheckbox}
    />
  )
}
