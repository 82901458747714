import { useEffectOnce } from 'hooks/useEffectOnce'
import { useUnmount } from 'hooks/useUnmount'
import { useCallback, useEffect, useRef } from 'react'
import { useSelector } from 'react-redux'
import { IMetricScope, IMetricType } from 'types/metric.types'
import { useAppDispatch } from '../../store/hooks'
import { metricSetUuid, selectMetricIds } from '../../store/slice/metric.slice'
import { MetricTimer, MetricTimerStatus } from './MetricTimer'

export function useMetric({
  entityId,
  type,
  scope,
  onSave,
  isImmediateStart = true,
  hasIdleTimer = false,
  idleLimitMin = 2,
}: {
  scope?: IMetricScope
  entityId?: number
  type?: IMetricType
  onSave: (value: number, uuid: string, scope: IMetricScope, isDestroyed: boolean) => void
  isImmediateStart?: boolean
  hasIdleTimer?: boolean
  idleLimitMin?: number
}) {
  const dispatch = useAppDispatch()

  const savedMetricIds = useSelector(selectMetricIds(type))
  const metricTimer = useRef<MetricTimer>()

  const handleTimerStart = useCallback(() => {
    metricTimer.current?.start()
  }, [])

  const handleSave = useCallback(
    (isDestroyed: boolean | undefined = false) => {
      if (!metricTimer.current || !savedMetricIds?.uuid || !scope) {
        return
      }

      onSave(metricTimer.current.dateFrom, savedMetricIds.uuid, scope, !!isDestroyed)
    },
    [onSave, savedMetricIds, scope],
  )

  const handleSaveAndDestroy = useCallback(() => {
    if (!metricTimer.current) {
      return
    }

    if (metricTimer.current.status !== MetricTimerStatus.Created) {
      handleSave(true)
    }
    metricTimer.current.destroy()
  }, [handleSave])

  useEffectOnce(() => {
    if (entityId === undefined || type === undefined) {
      return
    }

    metricTimer.current = new MetricTimer({
      hasIdleTimer,
      idleLimitMin,
      onReady: handleSave,
    })

    if (isImmediateStart) {
      metricTimer.current?.start()
    }
  })

  useEffect(() => {
    if (entityId === undefined || type === undefined) {
      return
    }

    if (savedMetricIds?.id !== entityId) {
      dispatch(metricSetUuid({ type, id: entityId }))
    }
  }, [dispatch, entityId, savedMetricIds, type])

  // save on destroy
  useUnmount(handleSaveAndDestroy)

  // add unload handlers
  useEffect(() => {
    window.addEventListener('unload', handleSaveAndDestroy)

    return () => {
      window.removeEventListener('unload', handleSaveAndDestroy)
    }
  }, [handleSaveAndDestroy])

  return {
    onMetricTimerStart: handleTimerStart,
    onMetricSave: handleSave,
  }
}
