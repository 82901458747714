import isEqual from 'lodash/isEqual'

export function isSimpleArrayEquals<T>(a: T[], b: T[]): boolean {
  if (a.length !== b.length) {
    return false
  }

  return isEqual(a.slice().sort(), b.slice().sort())
}

export function reorder<T>(list: T[], startIndex: number, endIndex: number) {
  const result = Array.from(list)
  const [removed] = result.splice(startIndex, 1)
  result.splice(endIndex, 0, removed)
  return result
}
