import {
  BaseTableRequest,
  Cohort,
  CohortRequestCreate,
  CohortRequestUpdate,
  RequestById,
  Response,
  TableResponse,
} from 'types'
import axiosInstance from './axios'

export const getCohorts = async (
  request: BaseTableRequest = { filter: {}, sort: null },
): Promise<TableResponse<Cohort>> => {
  return axiosInstance.post('/cohorts', request)
}

export const createCohort = async (request: CohortRequestCreate): Promise<Response<Cohort>> => {
  return axiosInstance.post('/cohorts/create', request)
}

export const updateCohort = async (request: CohortRequestUpdate): Promise<Response<Cohort>> => {
  return axiosInstance.post('/cohorts/update', request)
}

export const deleteCohort = async (request: RequestById): Promise<unknown> => {
  return axiosInstance.post('/cohorts/delete', request)
}
