import styled from '@emotion/styled'
import MenuIcon from '@mui/icons-material/Menu'
import { Button, Stack, Typography, useMediaQuery } from '@mui/material'
import { useTheme } from '@mui/material/styles'
import headerLogo from 'images/header_short.png'
import profileLogo from 'images/profile_logo.png'
import React from 'react'
import { useSelector } from 'react-redux'
import { useAppDispatch } from 'store/hooks'
import { selectSidebarIsOpen, toggleSidebar } from 'store/slice/navigation.slice'
import { useDrawersContext } from '../../context/ClientsDrawersProvider'
import ProfileInfo from '../client-profile/ProfileInfo'

const iconBackColor = 'grey.100'
const iconBackColorOpen = 'grey.300'

export const StyledProfileLogo = styled('img')`
  height: 28px;
  width: auto;
`

export const ClientsHeaderContent = () => {
  const dispatch = useAppDispatch()
  const isShowDrawer = useSelector(selectSidebarIsOpen)

  const theme = useTheme()
  const matchDownLG = useMediaQuery(theme.breakpoints.down('lg'))

  const { handleOpenAboutUsDrawer } = useDrawersContext()

  const handleDrawerToggle = () => {
    dispatch(toggleSidebar())
  }

  return (
    <>
      {matchDownLG && (
        <Stack direction="row" alignItems="center" spacing={1}>
          <StyledProfileLogo src={profileLogo} alt="Logo" onClick={handleOpenAboutUsDrawer} />

          <Button
            aria-label="open drawer"
            onClick={handleDrawerToggle}
            color="secondary"
            disableRipple
            sx={{
              minWidth: 0,
              color: 'text.primary',
              bgcolor: isShowDrawer ? iconBackColorOpen : iconBackColor,
            }}
          >
            <MenuIcon />
            <Typography ml={0.5}>Menu</Typography>
          </Button>
        </Stack>
      )}

      {!matchDownLG && <img src={headerLogo} alt="Logo" onClick={handleOpenAboutUsDrawer} />}

      <ProfileInfo />
    </>
  )
}
