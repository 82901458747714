import {
  BaseTableRequest,
  QueryRequestComplete,
  QueryRequestCreate,
  Query,
  QueryResult,
  QueryRequestUpdate,
  RequestById,
  TableResponse,
  Response,
} from 'types'
import axiosInstance from './axios'

export const getQuery = async (request: BaseTableRequest): Promise<TableResponse<Query>> => {
  return axiosInstance.post('/queries', request)
}

export const createQuery = async (request: QueryRequestCreate): Promise<Response<Query>> => {
  return axiosInstance.post('/queries/create', request)
}

export const getQueryById = async (request: RequestById): Promise<Response<Query>> => {
  return axiosInstance.post('/queries/read', request)
}

export const updateQuery = async (request: QueryRequestUpdate): Promise<Response<Query>> => {
  return axiosInstance.post('/queries/update', request)
}

export const deleteQuery = async (request: RequestById): Promise<unknown> => {
  return axiosInstance.post('/queries/delete', request)
}

export const getQueryResults = (id: number): Promise<Response<QueryResult[]>> => {
  return axiosInstance.post('/queries/results', { id })
}

export const completeQuery = (request: QueryRequestComplete): Promise<void> => {
  return axiosInstance.post('/queries/complete', request)
}
