import { Box, Button, Stack } from '@mui/material'
import { ColDef, ICellRendererParams } from 'ag-grid-community'
import {
  checkCourseCanPublish,
  deleteCourse,
  duplicateCourse,
  getCourseById,
  getCourses,
  updateCourse,
} from 'api/courses'
import columnRenderers, { PLACEHOLDER } from 'components/column-renderers'
import ConfirmPublishModal from 'components/modals/ConfirmPublishModal'
import ConfirmRemoveModal from 'components/modals/ConfirmRemoveModal'
import AddCourseModal from 'components/modals/lms/AddCourseModal'
import NotificationSys from 'components/NotificationSystem'
import InfinityAgGrid from 'components/table/InfinityAgGrid'
import TableToolbar from 'components/table/TableToolbar'
import { useCoursesMetadata } from 'features/Course/hooks/useCoursesMetadata'
import { useCoursesCSVField } from 'features/Course/hooks/useCoursesCSVField'
import { Metadata } from 'features/Metadata'
import { createSummaryTagsColumn, useTagsFilter } from 'features/Tags'
import { columnCreatedBy, columnUpdatedBy, getActionColumn, useDuplicateControl } from 'features/UI'
import { useExportCSV } from 'hooks/useExportCSV'
import { useGridControl } from 'hooks/useGridControl'
import { PublishAction, usePublishModalControl } from 'hooks/usePublishModalControl'
import { useRemoveModalControl } from 'hooks/useRemoveModalControl'
import { useShowControl } from 'hooks/useShowControl'
import moment from 'moment'
import React, { useCallback, useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { routes } from 'routes/routes'
import { BaseTableRequest, Course, FilterModel, TableResponse } from 'types'
import { isDefined, useGetDataSource, withIdParam } from 'utils'

export const CoursesTablePage = () => {
  const { TagFilter, tagsFilterValue } = useTagsFilter()
  const [courses, setCourses] = useState<TableResponse<Course>>()

  const getRequest = useCallback(
    (req: BaseTableRequest) => {
      const filter: FilterModel = {
        ...(req.filter || {}),
      }

      if (tagsFilterValue) {
        filter.tagId = tagsFilterValue
      }

      const request = { ...req, filter }

      return getCourses(request).then((res) => {
        setCourses(res)
        return res
      })
    },
    [tagsFilterValue],
  )

  const getDataSource = useGetDataSource(getRequest)
  const { onGridReady, gridApi, columnApi } = useGridControl(getDataSource)
  const navigate = useNavigate()

  const [isOpenAddCourseModal, openAddCourseModal, closeAddCourseModal] = useShowControl()

  const { DuplicateModal, setItem: setCourseToDuplicate } = useDuplicateControl<Course>({
    entityName: 'Course',
    apiMethod: duplicateCourse,
    routeSuccess: routes.courseEdit,
  })

  const { idToRemove, openDeleteModal, closeDeleteModal, removeLoading, handleConfirmRemove } =
    useRemoveModalControl({
      deleteFunc: deleteCourse,
      successCallback: () => gridApi?.purgeInfiniteCache(),
      warning: "Can't remove course",
    })

  const onClickEdit = useCallback(
    (id: number) => {
      navigate(withIdParam(routes.courseEdit, id))
    },
    [navigate],
  )

  const publishActionRender = useCallback(
    (props: ICellRendererParams<{ isPublished: boolean }>) => {
      const isPublished = props?.data?.isPublished
      return isPublished ? 'Unpublish' : 'Publish'
    },
    [],
  )

  const {
    action,
    idToPublish,
    openPublishModal,
    closePublishModal,
    publishLoading,
    handleConfirmPublish,
  } = usePublishModalControl({
    successCallback: (id: number, action: PublishAction) => {
      const isPublished = action === PublishAction.Publish
      if (isPublished) {
        NotificationSys.showSuccess(`Course is published`)
      } else {
        NotificationSys.showSuccess(`Course is unpublished`)
      }
      gridApi?.purgeInfiniteCache()
    },
    loadFunc: getCourseById,
    updateFunc: updateCourse,
  })

  const onPublish = useCallback(
    async (id: number, props: ICellRendererParams<{ isPublished: boolean }>) => {
      try {
        const isPublished = props?.data?.isPublished
        const action = isPublished ? PublishAction.Unpublish : PublishAction.Publish
        if (!isPublished) {
          await checkCourseCanPublish({ id })
        }
        openPublishModal(id, action)
      } catch (e) {
        console.error(e)
      }
    },
    [openPublishModal],
  )

  const handleDuplicateClicked = useCallback(
    (id: number, props: ICellRendererParams<Course>) => {
      setCourseToDuplicate(props.data)
    },
    [setCourseToDuplicate],
  )

  const actions = useMemo(() => {
    return [
      {
        name: 'Edit',
        onClick: onClickEdit,
      },
      { name: 'Delete', onClick: openDeleteModal },
      { renderName: publishActionRender, onClick: onPublish },
      { name: 'Duplicate', onClick: handleDuplicateClicked },
    ]
  }, [handleDuplicateClicked, onClickEdit, onPublish, openDeleteModal, publishActionRender])

  const columnDefs: ColDef[] = useMemo(() => {
    return [
      {
        headerName: 'Course Name',
        cellRenderer: columnRenderers.createLink('name', routes.courseEdit),
        filter: 'agTextColumnFilter',
        colId: 'name',
      },
      { headerName: 'Number of Lessons', field: 'numberOfLessons', minWidth: 200 },
      {
        headerName: 'Exam',
        cellRenderer: (props: ICellRendererParams<{ [questionnaireId: string]: string }>) => {
          return isDefined(props.data?.['questionnaireId']) ? 'created' : PLACEHOLDER
        },
        minWidth: 200,
        colId: 'questionnaireId',
      },
      {
        headerName: 'Published',
        cellRenderer: columnRenderers.published,
        minWidth: 160,
        colId: 'isPublished',
      },
      createSummaryTagsColumn('lessons'),
      {
        headerName: 'Creation Date',
        cellRenderer: columnRenderers.createdAt,
        colId: 'createdAt',
      },
      columnCreatedBy(),
      {
        headerName: 'Last Edited',
        cellRenderer: columnRenderers.updatedAt,
        colId: 'updatedAt',
        sort: 'desc',
      },
      columnUpdatedBy(),
      { ...getActionColumn(actions) },
    ]
  }, [actions])

  const metadata = useCoursesMetadata(courses)

  const csvFields = useCoursesCSVField()

  const { csvLoading, onExportCSV } = useExportCSV(
    getRequest,
    gridApi,
    columnApi,
    csvFields,
    `Course_Overview_${moment().format('YYYY-MM-DD')}.csv`,
  )

  return (
    <>
      <TableToolbar
        title="Course Overview"
        exportCSVEnable
        csvLoading={csvLoading}
        onExportCSV={onExportCSV}
      >
        <Button variant="outlined" sx={{ minWidth: 120 }} onClick={openAddCourseModal}>
          Add Course
        </Button>
      </TableToolbar>

      <Metadata data={metadata} />

      <Stack flexDirection="row" mt={1} mb={2} className="gap-16">
        <Box minWidth="200px">{TagFilter}</Box>
      </Stack>

      <InfinityAgGrid pagination columnDefs={columnDefs} onGridReady={onGridReady} />

      <AddCourseModal isOpen={isOpenAddCourseModal} handleClose={closeAddCourseModal} />

      <ConfirmRemoveModal
        entityToRemove="Course"
        loading={removeLoading}
        isOpen={idToRemove !== null}
        handleConfirm={handleConfirmRemove}
        handleClose={closeDeleteModal}
      />

      <ConfirmPublishModal
        loading={publishLoading}
        handleClose={closePublishModal}
        isOpen={idToPublish !== null}
        handleConfirm={handleConfirmPublish}
        action={action}
        entity="Course"
      />

      {DuplicateModal}
    </>
  )
}
