import { Box, InputLabel, Stack, TextField } from '@mui/material'
import HorizontalButtonBox from 'components/buttons/HorizontalButtonBox'
import { useAuthContext } from 'components/context/AuthProvider'
import React, { ChangeEvent, useState } from 'react'
import { AuthFormTitle } from '../AuthFormTitle/AuthFormTitle'

export enum PromocodeFormType {
  FamilyCaregiver,
  Trainee,
}

interface Props {
  type: PromocodeFormType
  handleUsePromocode: (code: string) => void
  handleUseCard: () => void
}

const getTitle = (type: PromocodeFormType) => {
  if (type === PromocodeFormType.FamilyCaregiver) {
    return 'Enter a Promo Code for access or purchase with a credit card'
  }
  return 'Enter a institutional access code for access or purchase with a credit card'
}

const getLabel = (type: PromocodeFormType) => {
  if (type === PromocodeFormType.FamilyCaregiver) {
    return 'Promo Code'
  }
  return 'Institutional access code'
}

const getButtonTitle = (type: PromocodeFormType) => {
  if (type === PromocodeFormType.FamilyCaregiver) {
    return 'Use Promo Code'
  }
  return 'Use institutional access code'
}

export const AuthPromoCodeForm = (props: Props) => {
  const { handleUsePromocode, handleUseCard, type } = props

  const { regLoading } = useAuthContext()

  const [promoCode, setPromoCode] = useState('')

  const onChangePromoCode = (event: ChangeEvent<HTMLInputElement>) => {
    setPromoCode(event.target.value)
  }

  const onClickUsePromocode = () => {
    handleUsePromocode(promoCode)
  }

  const isCorrectCode = promoCode.length !== 0 && !/\s/.test(promoCode)

  return (
    <Stack direction="column" justifyContent="space-between" spacing={2}>
      <Box>
        <AuthFormTitle textColor="secondary.main" mb={2}>
          {getTitle(type)}
        </AuthFormTitle>

        <Stack spacing={1}>
          <InputLabel>{getLabel(type)}</InputLabel>
          <TextField
            margin="normal"
            required
            fullWidth
            name="accessCode"
            onChange={onChangePromoCode}
            autoFocus
            size="small"
            error={!isCorrectCode}
          />
        </Stack>
      </Box>

      <HorizontalButtonBox
        firstBtnText={getButtonTitle(type)}
        firstButtonLoading={regLoading}
        disableFirstButton={!isCorrectCode}
        onClickFirstButton={onClickUsePromocode}
        secondBtnText="No Code? Pay with Card"
        onClickSecondButton={handleUseCard}
      />
    </Stack>
  )
}
