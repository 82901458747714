import { CourseCollapseItem } from 'app/imports/App.components'
import { NumberBadge } from 'components/number-badge/NumberBadge'
import { useCollapseContext } from 'features/UI'
import { useEffectOnce } from 'hooks/useEffectOnce'
import React, { useMemo } from 'react'
import { useParams } from 'react-router-dom'
import { ArticleType, ETimelinePostStatus } from 'types'
import { LibraryArticleItem } from './LibraryArticleItem'

export function LibraryCategoriesItem({
  id,
  title,
  icon,
  articles,
}: {
  id: number
  title: string
  icon: string | null
  articles: {
    id: number
    name: string
    post: string
    status: ETimelinePostStatus
    icon: string | null
  }[]
}) {
  const { setActiveCollapse } = useCollapseContext()
  const { learnLibraryItemType, learnLibraryItemId } = useParams()

  const reviewItemsCount = useMemo(() => {
    const reviewArticles = articles.filter(
      (article) => article.status === ETimelinePostStatus.review,
    )
    return reviewArticles.length
  }, [articles])

  useEffectOnce(() => {
    const isTypeCorrect = learnLibraryItemType === ArticleType.LearnLibrary
    const childIds = articles.map((article) => article.id)
    const hasChild = childIds.includes(Number(learnLibraryItemId))
    if (isTypeCorrect && hasChild) {
      setActiveCollapse(id)
    }
  })

  return (
    <CourseCollapseItem
      id={id}
      name={
        <>
          {title}
          {reviewItemsCount > 0 && <NumberBadge value={reviewItemsCount} />}
        </>
      }
      bannerUrl={icon}
      isForceExpanded={false}
    >
      {articles.map((article) => {
        return (
          <LibraryArticleItem
            key={article.id}
            id={article.id}
            title={article.post || article.name}
            status={article.status}
            icon={article.icon}
          />
        )
      })}
    </CourseCollapseItem>
  )
}
