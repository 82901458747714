import debounce from 'lodash/debounce'
import React, { useCallback, useEffect, useMemo } from 'react'
import { Box, Button, Modal, Stack, Typography } from '@mui/material'
import {
  defaultModalStyle,
  defaultModalZIndex,
  FIELD_IS_REQUIRED,
  minButtonWidth,
} from '../../../const'
import { any, object, TypeOf } from 'zod'
import { zodResolver } from '@hookform/resolvers/zod/dist/zod'
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form'
import { Option } from '../../form-elements/FormSelect'
import { LoadingButton } from '@mui/lab'
import FormAutocomplete from '../../form-elements/FormAutocomplete'

interface Props {
  title: string
  label: string
  description?: string
  errorText?: string
  buttonText: string
  loadingOptions: boolean
  loading: boolean

  value?: number | null
  options: Option[]
  isOpen: boolean
  handleClose: () => void
  onSubmit: (newValue: number) => void
  onChangeInput?: (input: string) => void
  controls?: React.ReactNode
}

const modalStyle = {
  ...defaultModalStyle,
  width: 420,
}

const ModalContent = (props: Props) => {
  const {
    value,
    handleClose,
    title,
    label,
    description,
    errorText = FIELD_IS_REQUIRED,
    buttonText,
    onSubmit,
    options,
    loadingOptions,
    loading,
    onChangeInput,
    controls,
  } = props
  const formSchema = useMemo(() => {
    return object({
      value: any().refine((val) => val ?? false, errorText),
    })
  }, [errorText])

  const formSettings = useMemo(() => {
    return {
      mode: 'onChange' as const,
      resolver: zodResolver(formSchema),
      defaultValues: {
        value: value,
      },
    }
  }, [formSchema, value])

  type FormType = TypeOf<typeof formSchema>

  const methods = useForm<FormType>(formSettings)
  const { handleSubmit, setValue, resetField } = methods

  useEffect(() => {
    resetField('value')
    setValue('value', value)
  }, [resetField, setValue, value])

  const onSubmitHandler: SubmitHandler<FormType> = useCallback(
    (values: FormType) => {
      onSubmit(values.value)
    },
    [onSubmit],
  )

  const handleChangeInput = useCallback(
    (value: string) => {
      onChangeInput?.(value)
    },
    [onChangeInput],
  )

  const debouncedHandleChangeInput = debounce(handleChangeInput, 500)

  return (
    <Box sx={modalStyle}>
      <Typography variant="h5" mb={3}>
        {title}
      </Typography>

      {description && <Typography mb={3}>{description}</Typography>}

      {controls}

      <FormProvider {...methods}>
        <Box
          component="form"
          onSubmit={(e: React.FormEvent<HTMLFormElement>) => {
            e.preventDefault()
            e.stopPropagation()
            handleSubmit(onSubmitHandler)(e)
          }}
          width="100%"
          noValidate
        >
          <FormAutocomplete
            name="value"
            options={loadingOptions ? [] : options}
            isLoading={loadingOptions}
            label={label}
            onChangeInput={debouncedHandleChangeInput}
          />

          <Stack direction="row" spacing={2} justifyContent="flex-end" sx={{ mt: 5 }}>
            <Button variant="outlined" onClick={handleClose} sx={minButtonWidth}>
              Close
            </Button>
            <LoadingButton loading={loading} variant="contained" type="submit" sx={minButtonWidth}>
              {buttonText}
            </LoadingButton>
          </Stack>
        </Box>
      </FormProvider>
    </Box>
  )
}

const ModalWithSelect = (props: Props) => {
  const { isOpen } = props

  return (
    <Modal sx={defaultModalZIndex} open={isOpen}>
      <div>{isOpen ? <ModalContent {...props} /> : <Box />}</div>
    </Modal>
  )
}

export default ModalWithSelect
