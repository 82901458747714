import { Avatar, Stack, Typography } from '@mui/material'
import purple from '@mui/material/colors/purple'
import blue from '@mui/material/colors/blue'
import React from 'react'

const caregiverProfileBG = 'linear-gradient(180deg, #F96DAA 0%, #964599 100%)'
const personOfConcernProfileBG = 'linear-gradient(180deg, #71CACB 0%, #005B8C 100%)'

interface Props {
  profileName: string
  typeOfProfile: 'caregiver' | 'personOfConcern'
}

const splitName = (name: string): string => {
  const split = name.split(' ')
  return split.map((it) => it[0]).join('')
}

const ProfileBanner = (props: Props) => {
  const { profileName, typeOfProfile } = props

  const isCaregiver = typeOfProfile === 'caregiver'
  const bannerBG = isCaregiver ? caregiverProfileBG : personOfConcernProfileBG
  const avatarBG = isCaregiver ? purple[600] : blue[300]

  return (
    <Stack direction="row" spacing={2} p={2} sx={{ background: bannerBG }}>
      <Avatar sx={{ bgcolor: avatarBG, height: 64, width: 64, fontSize: '1.5rem' }}>
        {splitName(profileName)}
      </Avatar>
      <Stack>
        <Typography variant="h6" color="white">
          {profileName}
        </Typography>
        <Typography variant="body1" color="white">
          {isCaregiver ? 'leader' : 'Person of Concern'}
        </Typography>
      </Stack>
    </Stack>
  )
}

export default React.memo(ProfileBanner)
