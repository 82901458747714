import { Button } from '@mui/material'
import ModalWithTextInput from 'components/modals/simple-modals/ModalWithTextInput'
import { useShowControl } from 'hooks/useShowControl'
import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { QuestioningType, Response } from 'types'

export function ExamQuizControlAdd<T extends { id: number }>({
  entityType,
  entityName,
  parentId,
  parentIdField,
  routeEdit,
  createRequest,
}: {
  entityType: QuestioningType
  entityName: string
  parentId: number
  parentIdField: string
  routeEdit: string
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  createRequest: (request: any) => Promise<Response<T>>
}) {
  const navigate = useNavigate()
  const [isOpen, handleOpen, handleClose] = useShowControl()
  const [isLoading, setIsLoading] = useState(false)

  const handleSubmit = async (name: string) => {
    try {
      if (!parentId) return

      setIsLoading(true)
      const response = await createRequest({
        [parentIdField]: parentId,
        name,
      })

      const id = response.data.id || ''
      navigate(`${routeEdit}?id=${id}&type=${entityType}`)
      handleClose()
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <>
      <Button variant="contained" onClick={handleOpen}>
        Add {entityName}
      </Button>

      <ModalWithTextInput
        value=""
        title={`Create ${entityName}`}
        label={`${entityName} name`}
        errorText={`${entityName} name is required`}
        buttonText="Create"
        isOpen={isOpen}
        handleClose={handleClose}
        onSubmit={handleSubmit}
        loading={isLoading}
      />
    </>
  )
}
