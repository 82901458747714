import { useCallback, useEffect, useRef, useState } from 'react'

export const useTimer = () => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const timer = useRef<any>()
  const [state, setState] = useState(0)
  const [isActive, setIsActive] = useState(false)

  const startTimer = useCallback(() => {
    setIsActive(true)
    timer.current = setInterval(() => {
      setState((prev) => prev + 1)
    }, 1000)
  }, [])

  const stopTimer = useCallback(() => {
    setIsActive(false)
    clearInterval(timer.current)
  }, [])

  const resetTimer = useCallback(() => {
    setState(0)
  }, [])

  useEffect(() => {
    // clear on destroy
    return () => {
      stopTimer()
    }
  }, [stopTimer])

  return {
    state,
    startTimer,
    stopTimer,
    resetTimer,
    isActive,
  }
}
