import { css, styled } from '@mui/material'

export const Content = styled('div')(() => {
  return css`
    display: flex;
    flex-direction: column;
    gap: 12px;
  `
})

export const CloseIconContainer = styled('div')(() => {
  return css`
    position: absolute;
    right: 24px;
    top: 24px;
  `
})
