import { Divider, Stack } from '@mui/material'
import { Nullish } from 'types'
import { MetricTextItem } from '../MetricTextItem/MetricTextItem'

export function MetricText({
  wordCount,
  talkTime,
  silentReadingTime,
  fleschReadingEase,
  fryReadabilityGradeLevel,
  inArticle = true,
}: {
  wordCount: Nullish<number>
  talkTime: Nullish<number>
  silentReadingTime: Nullish<number>
  fleschReadingEase: Nullish<number>
  fryReadabilityGradeLevel: Nullish<number>
  inArticle?: boolean
}) {
  return (
    <Stack
      direction="row"
      flexWrap="wrap"
      className="gap-8"
      sx={{ width: '100%', maxWidth: inArticle ? 800 : 'auto' }}
    >
      {wordCount !== null && (
        <>
          <MetricTextItem label="Word Count" value={wordCount} precision={0} />
          <Divider orientation="vertical" flexItem />
        </>
      )}

      {talkTime !== null && (
        <>
          <MetricTextItem label="Talk Time" value={talkTime} isMinutes />
          <Divider orientation="vertical" flexItem />
        </>
      )}

      {silentReadingTime !== null && (
        <>
          <MetricTextItem label="Silent Reading Time" value={silentReadingTime} isMinutes />
          <Divider orientation="vertical" flexItem />
        </>
      )}

      {fleschReadingEase !== null && (
        <>
          <MetricTextItem label="Flesсh Reading Ease" value={fleschReadingEase} />
          <Divider orientation="vertical" flexItem />
        </>
      )}

      {fryReadabilityGradeLevel !== null && (
        <MetricTextItem label="Fry Readability Grade Level" value={fryReadabilityGradeLevel} />
      )}
    </Stack>
  )
}
