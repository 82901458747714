import FormInput from 'components/form-elements/FormInput'
import { useCharactersLeft } from 'hooks/useCharactersLeft'
import { ChangeEvent, useCallback, useState } from 'react'

export function QuestionEditorFieldQuestion({
  value,
  maxLength,
}: {
  value: string
  maxLength: number
}) {
  const [question, setQuestion] = useState(value)

  const handleChange = useCallback(
    (e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
      setQuestion(e.target.value)
    },
    [setQuestion],
  )

  const charactersLeft = useCharactersLeft(maxLength, question.length)

  return (
    <FormInput
      name="question"
      fullWidth
      minRows={3}
      maxRows={6}
      multiline
      placeholder="Start typing"
      onChange={handleChange}
      helperText={charactersLeft}
    />
  )
}
