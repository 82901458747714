import { zodResolver } from '@hookform/resolvers/zod/dist/zod'
import { LoadingButton } from '@mui/lab'
import { Box, Grid, Stack } from '@mui/material'
import { updateCaregiverUser } from 'api/users'
import FormInput from 'components/form-elements/FormInput'
import FormSelect from 'components/form-elements/FormSelect'
import NotificationSys from 'components/NotificationSystem'
import { EMAIL_IS_INVALID, FIELD_IS_REQUIRED } from 'const'
import { CohortsMultiSelect } from 'features/Cohorts'
import { careLevels } from 'models/user.model'
import React, { useCallback } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { useMutation } from 'react-query'
import { ECareLevel, UserModel } from 'types'
import { number, object, string, TypeOf } from 'zod'
import { UserDetailsBreadcrumbs } from '../UserDetailsBreadcrumbs/UserDetailsBreadcrumbs'

const formSchema = object({
  firstname: string().min(1, FIELD_IS_REQUIRED),
  lastname: string().min(1, FIELD_IS_REQUIRED),
  email: string().email(EMAIL_IS_INVALID),
  cohorts: object({
    label: string(),
    value: number(),
  }).array(),
  careLevel: string().min(1, FIELD_IS_REQUIRED),
})

type FormType = TypeOf<typeof formSchema>

export function UserDetailsCaregiverForm({
  user,
  onAfterUpdate,
}: {
  user: UserModel
  onAfterUpdate: () => void
}) {
  const defaultValues = {
    firstname: user.firstname,
    lastname: user.lastname,
    email: user.email,
    cohorts: user.cohorts.map((cohort) => ({ label: cohort.name, value: cohort.id })),
    careLevel: user.careLevel || '',
  }

  const methods = useForm<FormType>({
    mode: 'onChange',
    resolver: zodResolver(formSchema),
    defaultValues: defaultValues,
  })

  const saveUserMutation = useMutation(updateCaregiverUser)

  const handleUpdate = useCallback(
    async (values: FormType) => {
      const request = {
        id: user.id,
        firstname: values.firstname,
        lastname: values.lastname,
        email: values.email,
        cohortIds: values.cohorts.map((item) => item.value),
        careLevel: values.careLevel as ECareLevel,
      }
      await saveUserMutation.mutateAsync(request)
      NotificationSys.showSuccess('User updated')
      onAfterUpdate()
    },
    [onAfterUpdate, saveUserMutation, user.id],
  )

  return (
    <>
      <UserDetailsBreadcrumbs user={user} />

      <Grid container>
        <Grid item sm={12} md={12} lg={8} xl={6} mt={4}>
          <FormProvider {...methods}>
            <Box component="form" onSubmit={methods.handleSubmit(handleUpdate)} noValidate>
              <Stack spacing={2}>
                <FormInput label="First Name" name="firstname" required fullWidth />
                <FormInput label="Last Name" name="lastname" required fullWidth />
                <FormInput label="Email" name="email" required fullWidth autoComplete="off" />

                <CohortsMultiSelect defaultValue={defaultValues.cohorts} />

                <FormSelect
                  label="What level of care does your person of concern need?"
                  name="careLevel"
                  required
                  options={careLevels}
                />
              </Stack>

              <Box mt={4}>
                <LoadingButton
                  disabled={saveUserMutation.isLoading}
                  loading={saveUserMutation.isLoading}
                  variant="contained"
                  type="submit"
                  sx={{ width: '120px' }}
                >
                  Save
                </LoadingButton>
              </Box>
            </Box>
          </FormProvider>
        </Grid>
      </Grid>
    </>
  )
}
