import React from 'react'
import { Stack } from '@mui/material'
import LogoutIcon from '@mui/icons-material/Logout'
import PageTitle from '../page/PageTitle'
import { SelectValue } from '../select/SimpleSelect'
import { LoadingButton } from '@mui/lab'

interface Props {
  left?: React.ReactNode
  children?: React.ReactNode
  title?: string
  exportCSVEnable?: boolean
  pageSize?: number
  handleChangePagination?: (name: string, value: SelectValue) => void
  csvLoading?: boolean
  onExportCSV?: () => void
}

const minButtonWidth = { minWidth: 120, flexShrink: 0 }

const TableToolbar = (props: Props) => {
  const { left, title, exportCSVEnable, children, csvLoading, onExportCSV } = props

  return (
    <>
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="flex-end"
        spacing={2}
        sx={{ mb: 2 }}
      >
        {left || <div />}
        {title && <PageTitle>{title}</PageTitle>}
        <Stack direction="row" spacing={2}>
          {children}
          {exportCSVEnable && (
            <LoadingButton
              variant="outlined"
              loading={csvLoading}
              onClick={onExportCSV}
              sx={minButtonWidth}
            >
              <Stack direction="row" spacing={1} alignItems="center">
                <LogoutIcon sx={{ transform: 'rotate(-90deg)' }} />
                <span>Export CSV</span>
              </Stack>
            </LoadingButton>
          )}
        </Stack>
      </Stack>
    </>
  )
}

export default React.memo(TableToolbar)
