import React from 'react'
import DrawerHeaderFiller from './DrawerHeaderFiller'
import { Box, Stack } from '@mui/material'
import DrawerLoadingPlaceholder from '../DrawerLoadingPlaceholder'
import CantLoadDataDrawerPlaceholder from '../CantLoadDataDrawerPlaceholder'

interface Props {
  children: React.ReactNode
  isOpen: boolean
  loading: boolean
  hasData: boolean
  handleClose: () => void
}

const DrawerContainer = (props: Props) => {
  const { children, isOpen, loading, handleClose, hasData } = props

  if (!isOpen) {
    return null
  }

  return (
    <Stack direction="column" sx={{ minHeight: '100%' }}>
      <DrawerHeaderFiller />
      <Box display="flex" flexDirection="column" px={{ sm: 4, xs: 2 }} py={2} flexGrow={1}>
        {loading && <DrawerLoadingPlaceholder handleClose={handleClose} />}
        {!loading && hasData && children}
        {!loading && !hasData && <CantLoadDataDrawerPlaceholder handleClose={handleClose} />}
      </Box>
    </Stack>
  )
}

export default DrawerContainer
