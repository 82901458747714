import { useAuthContext } from 'components/context/AuthProvider'
import React, { useState } from 'react'
import { EUserEducation, EUserExperience, RegisterRequest, UserType } from 'types'
import { AuthLogo } from '../AuthLogo/AuthLogo'
import { AuthPromoCodeForm, PromocodeFormType } from '../AuthPromoCodeForm/AuthPromoCodeForm'
import SignUpTrainingForm from './SignUpTrainingForm'
import TraineeCardForm from './TraineeCardForm'
import { TraineeFormType, traineeInitialState, TraineeSignupStage } from './types'

export const AuthPageSignupTrainee = () => {
  const { register } = useAuthContext()

  const [state, setState] = useState(traineeInitialState)
  const [stage, setStage] = useState<TraineeSignupStage>(TraineeSignupStage.TraineeForm)

  const handleCreate = (form: TraineeFormType) => {
    setState({
      ...state,
      traineeForm: form,
    })
    setStage(TraineeSignupStage.PromocodeForm)
  }

  const handleUsePromocode = (accessCode: string) => {
    const newState = {
      ...state,
      accessCode,
    }
    setState(newState)

    const request: RegisterRequest = {
      accessCode,
      firstname: newState.traineeForm.firstName,
      lastname: newState.traineeForm.lastName,
      email: newState.traineeForm.email,
      type: UserType.TRAINEE,
      password: newState.traineeForm.myNewPassword,
      education: newState.traineeForm.education as EUserEducation,
      experience: newState.traineeForm.yearsOfExperience as EUserExperience,
      language:
        newState.traineeForm.language !== 'other'
          ? newState.traineeForm.language
          : newState.traineeForm.languageOther,
    }
    register(request)
  }

  const handleUseCard = () => {
    setState({
      ...state,
      accessCode: undefined,
    })
    setStage(TraineeSignupStage.CardForm)
  }

  return (
    <>
      <AuthLogo />
      {stage === TraineeSignupStage.TraineeForm && (
        <SignUpTrainingForm handleCreate={handleCreate} />
      )}
      {stage === TraineeSignupStage.PromocodeForm && (
        <AuthPromoCodeForm
          type={PromocodeFormType.Trainee}
          handleUsePromocode={handleUsePromocode}
          handleUseCard={handleUseCard}
        />
      )}
      {stage === TraineeSignupStage.CardForm && <TraineeCardForm formData={state.traineeForm} />}
    </>
  )
}
