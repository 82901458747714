export const columnContentCSV = (label = 'Content') => ({
  label,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  value: ({ hasImage, hasVideo, hasAudio }: any) => {
    const result = []
    if (hasImage) {
      result.push('image')
    }
    if (hasVideo) {
      result.push('video')
    }
    if (hasAudio) {
      result.push('audio')
    }
    return result.join(', ')
  },
})
