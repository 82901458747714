import { styled } from '@mui/material/styles'
import { PickersDay } from '@mui/x-date-pickers'

export const UIDatePickerDay = styled(PickersDay, {
  shouldForwardProp: (prop) =>
    prop !== 'dayIsBetween' && prop !== 'isFirstDay' && prop !== 'isLastDay',
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
})<any>(({ theme, dayIsBetween, isFirstDay, isLastDay }) => ({
  ...((dayIsBetween || isFirstDay) && {
    borderRadius: 0,
    margin: 0,
    width: '40px',
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
    '&:hover, &:focus': {
      backgroundColor: theme.palette.primary.dark,
    },
  }),
  '&:selected': {
    backgroundColor: theme.palette.primary.main,
  },
  ...(isFirstDay && {
    borderTopLeftRadius: '50%',
    borderBottomLeftRadius: '50%',
  }),
  ...(isLastDay && {
    borderTopRightRadius: '50%',
    borderBottomRightRadius: '50%',
  }),
}))
