import NotificationSys from 'components/NotificationSystem'
import { useCallback, useState } from 'react'
import { Course, LearnTopic, RequestById, Response } from 'types'
import { noop } from 'utils'

export enum PublishAction {
  Publish,
  Unpublish,
}

interface Props<T> {
  loadFunc: (request: RequestById) => Promise<Response<T>>
  updateFunc: (request: T) => Promise<unknown>
  successCallback?: (idToPublish: number, action: PublishAction) => void
}

export const publishSuccessCallbackCourse = (
  id: number,
  action: PublishAction,
  data: Course | null,
  setData: (value: Course) => void,
) => {
  if (!data) {
    return
  }
  const isPublished = action === PublishAction.Publish

  if (isPublished) {
    NotificationSys.showSuccess(`Lesson is published`)
  } else {
    NotificationSys.showSuccess(`Lesson is unpublished`)
  }

  const newLessons = data.lessons.map((it) => {
    if (it.id === id) {
      return {
        ...it,
        isPublished,
      }
    }
    return it
  })
  setData({
    ...data,
    lessons: newLessons,
  })
}

export const publishSuccessCallbackTopic = (
  id: number,
  action: PublishAction,
  data: LearnTopic | null,
  setData: (value: LearnTopic) => void,
) => {
  if (!data) {
    return
  }
  const isPublished = action === PublishAction.Publish

  if (isPublished) {
    NotificationSys.showSuccess(`Article is published`)
  } else {
    NotificationSys.showSuccess(`Article is unpublished`)
  }
  const newArticles = data.articles.map((it) => {
    if (it.id === id) {
      return {
        ...it,
        isPublished,
      }
    }
    return it
  })
  setData({
    ...data,
    articles: newArticles,
  })
}

export const usePublishModalControl = <T>(props: Props<T>) => {
  const { loadFunc, updateFunc, successCallback = noop } = props

  const [action, setAction] = useState<PublishAction>(PublishAction.Unpublish)
  const [idToPublish, setId] = useState<number | null>(null)
  const [publishLoading, setPublishLoading] = useState(false)

  const openPublishModal = useCallback((id: number, action: PublishAction) => {
    setId(id)
    setAction(action)
  }, [])

  const closePublishModal = useCallback(() => {
    setId(null)
  }, [])

  const handleConfirmPublish = async () => {
    try {
      if (idToPublish === null) {
        return
      }
      setPublishLoading(true)
      const response = await loadFunc({ id: idToPublish })
      const data = {
        ...response.data,
        isPublished: action === PublishAction.Publish,
      }
      await updateFunc(data)
      closePublishModal()
      successCallback(idToPublish, action)
    } finally {
      setPublishLoading(false)
    }
  }

  return {
    action,
    idToPublish,
    openPublishModal,
    closePublishModal,
    publishLoading,
    handleConfirmPublish,
  }
}
