import React from 'react'
import LoadingPlaceholder from './LoadingPlaceholder'
import { Box } from '@mui/material'

interface Props {
  show: boolean
  py?: number
}

const Loader = (props: Props) => {
  if (!props.show) {
    return null
  }
  return (
    <Box
      py={props.py ?? 0}
      display="flex"
      justifyContent="center"
      alignItems="center"
      minHeight={450}
    >
      <LoadingPlaceholder />
    </Box>
  )
}

export default Loader
