import { useDrawersContext } from 'components/context/ClientsDrawersProvider'
import React, { useCallback, useMemo } from 'react'
import { useActions } from 'store/hooks'
import { ETimelinePostStatus, QuestionnaireTimelineItem } from 'types'
import { TimelineCourseChildItem } from '../TimelineCourseChildItem/TimelineCourseChildItem'

const buttonTitleByStatus = {
  [ETimelinePostStatus.new]: 'Take',
  [ETimelinePostStatus.review]: 'Retake',
  [ETimelinePostStatus.retake]: 'Retake',
  [ETimelinePostStatus.done]: 'View',
}

export function TimelineExamItem({ item }: { item: QuestionnaireTimelineItem }) {
  const { handleOpenQuestioning } = useDrawersContext()
  const { getTimelineById } = useActions()

  const onQuestioningTaken = useCallback(() => {
    getTimelineById({})
  }, [getTimelineById])

  const onClickQuestioning = () => {
    handleOpenQuestioning({
      mode: 'take',
      id: item.questionnaireId,
      type: 'questionnaire',
      onQuestioningTaken: onQuestioningTaken,
    })
  }

  const buttonTitle = useMemo(() => buttonTitleByStatus[item.status], [item])

  return (
    <TimelineCourseChildItem
      name={item.questionnaire.name}
      bannerUrl={item.questionnaire.bannerUrl}
      status={item.status}
      buttonTitle={buttonTitle}
      onClick={onClickQuestioning}
    />
  )
}
