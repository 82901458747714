import { Box, Button } from '@mui/material'
import { useShowControl } from 'hooks/useShowControl'
import { ImageEditorModal } from '../ImageEditorModal/ImageEditorModal'

export function ImageEditorControl({
  imgData,
  hasRatioRestrictions,
  onSave,
}: {
  imgData: string
  hasRatioRestrictions: boolean
  onSave: (base64: string) => void
}) {
  const [isOpen, onOpen, onClose] = useShowControl()

  return (
    <Box>
      <Button variant="outlined" onClick={onOpen}>
        Edit Image
      </Button>

      <ImageEditorModal
        imgData={imgData}
        isOpen={isOpen}
        hasRatioRestrictions={hasRatioRestrictions}
        onClose={onClose}
        onSave={onSave}
      />
    </Box>
  )
}
