export const careTimelineTooltip =
  'Care Timeline provides a chronological list of post to guide you along the caregiver journey. ' +
  'As you proceed, new posts appear at the top.'

export const trainingTimelineTooltip =
  'Training Timeline provides a chronological list of posts to guide you along the caregiver journey. ' +
  'As you proceed, new posts appear at the top.'

export const traineeCareTeamTooltip =
  'The Care Team is a community of people who care for and support you and those you care for. ' +
  'Complete profiles to contact them easily.'

export const careTeamTooltip =
  'The Care Team is a community of people who care for and support your Person of Concern. ' +
  'Send an email through the app to form the Care Team. ' +
  'Add additional people by clicking on the "Invite" button in the Care Team tab.'
