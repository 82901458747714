import { Typography } from '@mui/material'
import { useAuthContext } from 'components/context/AuthProvider'
import React, { useMemo } from 'react'
import { useLocation } from 'react-router-dom'
import { routes } from 'routes/routes'
import { UserType } from 'types'
import * as Styled from './styled'

export function LayoutClientPageTitle() {
  const { pathname } = useLocation()

  const { user } = useAuthContext()

  const pageTitle = useMemo(() => {
    if (!user) {
      return ''
    }

    if (pathname.includes(routes.journey)) {
      if (user.currentType === UserType.TRAINEE) {
        return 'Training Journey'
      }

      if (user.currentType === UserType.CAREGIVER || user.currentType === UserType.CARETEAM) {
        return 'Care Journey'
      }
    }

    if (pathname.includes(routes.learnLibrary)) {
      return 'Learn Library'
    }

    if (pathname.includes(routes.progress)) {
      return 'Progress'
    }

    return ''
  }, [pathname, user])

  if (!pageTitle) {
    return null
  }

  return (
    <Styled.Container>
      <Typography variant="h6">{pageTitle}</Typography>
    </Styled.Container>
  )
}
