import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { User } from 'types'
import { getFromLocalStorage, saveToLocalStorage, TOKEN, USER_DATA } from 'utils'

const emptyUser = {
  createdAt: undefined,
  email: undefined,
  firstname: undefined,
  id: undefined,
  lastname: undefined,
  password: undefined,
  personOfConcernFirstName: undefined,
  personOfConcernLastName: undefined,
  totalEngagement: undefined,
  type: [],
  accessCodeExpiryDate: null,
  trainingPathwayTypeName: undefined,
  currentType: undefined,
}

export const currentUserSlice = createSlice({
  initialState: (getFromLocalStorage(USER_DATA) as Partial<User>) ?? emptyUser,
  name: 'currentUser',
  reducers: {
    signIn: (state, action: PayloadAction<{ user: User; token: string }>) => {
      action.payload.user.currentType = action.payload.user.type[0]

      saveToLocalStorage(USER_DATA, action.payload.user)
      saveToLocalStorage(TOKEN, action.payload.token)

      return action.payload.user
    },
    updateProfile: (state, action: PayloadAction<{ firstName: string; lastName: string }>) => {
      state.firstname = action.payload.firstName
      state.lastname = action.payload.lastName

      saveToLocalStorage(USER_DATA, state)
    },
    updateProfileConfirmPathwayChanges: (state) => {
      state.isTrainingPathwayTypeChanged = false

      saveToLocalStorage(USER_DATA, state)
    },
    updateCarePerson: (state, action: PayloadAction<{ firstName: string; lastName: string }>) => {
      state.personOfConcernFirstName = action.payload.firstName
      state.personOfConcernLastName = action.payload.lastName

      saveToLocalStorage(USER_DATA, state)
    },
    logout: () => {
      return emptyUser
    },
  },
})

export const {
  signIn,
  updateProfile,
  updateCarePerson,
  updateProfileConfirmPathwayChanges,
  logout,
} = currentUserSlice.actions
