import { zodResolver } from '@hookform/resolvers/zod/dist/zod'
import { LoadingButton } from '@mui/lab'
import { Box, Button, Stack } from '@mui/material'
import FormInput from 'components/form-elements/FormInput'
import FormSelect from 'components/form-elements/FormSelect'
import { FIELD_IS_REQUIRED, minButtonWidth } from 'const'
import { UIModal } from 'features/UI'
import React, { useMemo } from 'react'
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form'
import { CareArticleType } from 'types'
import { object, string, TypeOf } from 'zod'

const options = [
  { label: 'Guide Post', value: CareArticleType.Care },
  { label: 'Action Post', value: CareArticleType.Task },
]

const ModalContent = ({
  onClose,
  onSubmit,
  loading,
}: {
  onClose: () => void
  onSubmit: (name: string, type: string) => void
  loading: boolean
}) => {
  const formSchema = useMemo(() => {
    return object({
      name: string().min(1, FIELD_IS_REQUIRED),
      type: string().min(1, FIELD_IS_REQUIRED),
    })
  }, [])

  const formSettings = useMemo(() => {
    return {
      mode: 'onChange' as const,
      resolver: zodResolver(formSchema),
      defaultValues: {
        name: '',
        type: '',
      },
    }
  }, [formSchema])

  type FormType = TypeOf<typeof formSchema>

  const methods = useForm<FormType>(formSettings)
  const { handleSubmit } = methods

  const onSubmitHandler: SubmitHandler<FormType> = async (values: FormType) => {
    onSubmit(values.name, values.type)
  }

  return (
    <FormProvider {...methods}>
      <Box component="form" onSubmit={handleSubmit(onSubmitHandler)} width="100%" noValidate>
        <Stack spacing={1}>
          <FormSelect label="Article Type" name="type" fullWidth options={options} />
          <FormInput label="Article Name" name="name" fullWidth />
        </Stack>
        <Stack direction="row" spacing={2} justifyContent="flex-end" sx={{ mt: 5 }}>
          <Button variant="outlined" onClick={onClose} sx={minButtonWidth}>
            Close
          </Button>
          <LoadingButton loading={loading} variant="contained" type="submit" sx={minButtonWidth}>
            Save
          </LoadingButton>
        </Stack>
      </Box>
    </FormProvider>
  )
}

export function CareArticleModalCreate({
  isOpen,
  isLoading,
  onClose,
  onSubmit,
}: {
  isOpen: boolean
  isLoading: boolean
  onClose: () => void
  onSubmit: (name: string, type: string) => void
}) {
  return (
    <UIModal isOpen={isOpen} title="Add Article" onClose={onClose}>
      <ModalContent onClose={onClose} onSubmit={onSubmit} loading={isLoading} />
    </UIModal>
  )
}
