import { CircularProgress, Typography } from '@mui/material'
import Autocomplete from '@mui/material/Autocomplete'
import TextField from '@mui/material/TextField'
import React, { SyntheticEvent, useCallback, useMemo } from 'react'
import { useQuery } from 'react-query'
import { NameAndIdEntity, Option, TableResponse } from 'types'
import { backendDataMapper } from 'utils'
import * as Styled from './UIFilterAsyncAutocomplete.styled'

export function UIFilterAsyncAutocomplete<T extends NameAndIdEntity>({
  label,
  value,
  onChange,
  queryId,
  apiRequest,
}: {
  label: string
  value: Option[]
  onChange: (value: Option[]) => void
  queryId: string
  apiRequest: () => Promise<TableResponse<T>>
}) {
  const query = useQuery([queryId], () => apiRequest())

  const handleChange = useCallback(
    (_: SyntheticEvent, newValue: Option[]) => {
      onChange(newValue)
    },
    [onChange],
  )

  const options = useMemo(() => {
    if (!query.data) {
      return []
    }

    return query.data.data.rows.map(backendDataMapper)
  }, [query.data])

  return (
    <Styled.Container>
      <Typography>{label}</Typography>

      <Autocomplete
        multiple
        options={options}
        loading={query.isLoading}
        value={value}
        onChange={handleChange}
        openOnFocus
        fullWidth
        renderInput={(params) => (
          <TextField
            {...params}
            size="small"
            name={queryId}
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <React.Fragment>
                  {query.isLoading ? <CircularProgress color="inherit" size={20} /> : null}
                  {params.InputProps.endAdornment}
                </React.Fragment>
              ),
            }}
          />
        )}
      />
    </Styled.Container>
  )
}
