import { zodResolver } from '@hookform/resolvers/zod/dist/zod'
import { LoadingButton } from '@mui/lab'
import { Button, Grid } from '@mui/material'
import Drawer from '@mui/material/Drawer'
import { changePassword } from 'api/auth'
import { defaultDrawerSX, PASSWORD_REQUIREMENTS } from 'const'
import React from 'react'
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form'
import { useMutation } from 'react-query'
import { useAppSelector } from 'store/hooks'
import { object, string, TypeOf } from 'zod'
import FormInput from '../../form-elements/FormInput'
import FormPasswordCheckList, { validatePassword } from '../../FormPasswordCheckList'
import NotificationSys from '../../NotificationSystem'
import DrawerContainer from '../common/DrawerContainer'
import DrawerHeader from '../common/DrawerHeader'

const formSchema = object({
  password: string().min(1, 'Password is required'),
  newPassword: string().refine(
    (password: string) => validatePassword(password).allCheck,
    PASSWORD_REQUIREMENTS,
  ),
  newPasswordRepeat: string().min(1, 'Password is required'),
}).refine((data) => data.newPassword === data.newPasswordRepeat, {
  path: ['newPasswordRepeat'],
  message: 'Passwords do not match',
})

type FormType = TypeOf<typeof formSchema>

export function ChangePasswordDrawer({
  isOpen,
  handleClose,
}: {
  isOpen: boolean
  handleClose: () => void
}) {
  const email = useAppSelector((state) => state.currentUser.email)

  const methods = useForm<FormType>({
    mode: 'onChange',
    resolver: zodResolver(formSchema),
    defaultValues: {
      password: '',
      newPassword: '',
      newPasswordRepeat: '',
    },
  })
  const { handleSubmit } = methods

  const submitMutation = useMutation(changePassword, {
    onSuccess: () => {
      NotificationSys.showSuccess(`Your password was changed successfully.`)
      handleClose()
    },
  })

  const onSubmitHandler: SubmitHandler<FormType> = (values: FormType) => {
    if (!email) {
      console.error("User don't have an email")
      return
    }

    submitMutation.mutate({
      email,
      oldPassword: values.password,
      newPassword: values.newPassword,
    })
  }

  return (
    <Drawer anchor="right" open={isOpen} onClose={handleClose} sx={defaultDrawerSX}>
      <DrawerContainer isOpen={isOpen} hasData loading={false} handleClose={handleClose}>
        <DrawerHeader handleClose={handleClose}>Change Password</DrawerHeader>
        <FormProvider {...methods}>
          <Grid
            container
            rowSpacing={1.5}
            component="form"
            onSubmit={handleSubmit(onSubmitHandler)}
            width="100%"
            noValidate
          >
            <Grid item xs={12} sm={12}>
              <FormInput
                label="Old password"
                required
                fullWidth
                name="password"
                type="password"
                autoComplete="off"
              />
            </Grid>
            <Grid item xs={12} sm={12}>
              <FormInput
                label="New password"
                required
                fullWidth
                name="newPassword"
                type="password"
                autoComplete="off"
              />
              <FormPasswordCheckList name="newPassword" />
            </Grid>
            <Grid item xs={12} sm={12}>
              <FormInput
                label="Same new password"
                required
                fullWidth
                name="newPasswordRepeat"
                type="password"
                autoComplete="off"
              />
            </Grid>

            <Grid item xs={12} sm={12} md={6}>
              <Grid
                container
                spacing={2}
                mt={2}
                direction="row"
                alignItems="center"
                justifyContent="flex-start"
              >
                <Grid item xs={6}>
                  <LoadingButton
                    variant="contained"
                    color="secondary"
                    type="submit"
                    loading={submitMutation.isLoading}
                    fullWidth
                  >
                    Change password
                  </LoadingButton>
                </Grid>
                <Grid item xs={6}>
                  <Button onClick={handleClose} variant="outlined" color="info" fullWidth>
                    Cancel
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </FormProvider>
      </DrawerContainer>
    </Drawer>
  )
}
