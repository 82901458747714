import React, { useMemo } from 'react'
import { Box, Button, Modal, Stack, Typography } from '@mui/material'
import {
  defaultModalStyle,
  defaultModalZIndex,
  FIELD_IS_REQUIRED,
  minButtonWidth,
} from '../../../const'
import FormInput from '../../form-elements/FormInput'
import { object, string, TypeOf } from 'zod'
import { zodResolver } from '@hookform/resolvers/zod/dist/zod'
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form'
import { LoadingButton } from '@mui/lab'

interface Props {
  title: string
  label: string
  errorText?: string
  buttonText: string

  value?: string
  isOpen: boolean
  handleClose: () => void
  onSubmit: (newValue: string) => void
  loading?: boolean
}

const modalStyle = {
  ...defaultModalStyle,
  width: 420,
}

const ModalContent = (props: Props) => {
  const {
    value,
    handleClose,
    title,
    label,
    errorText = FIELD_IS_REQUIRED,
    buttonText,
    onSubmit,
    loading,
  } = props

  const formSchema = useMemo(() => {
    return object({
      name: string().min(1, errorText),
    })
  }, [errorText])

  const formSettings = useMemo(() => {
    return {
      mode: 'onChange' as const,
      resolver: zodResolver(formSchema),
      defaultValues: {
        name: value || '',
      },
    }
  }, [formSchema, value])

  type FormType = TypeOf<typeof formSchema>

  const methods = useForm<FormType>(formSettings)
  const { handleSubmit } = methods

  const onSubmitHandler: SubmitHandler<FormType> = (values: FormType) => {
    onSubmit(values.name)
  }

  return (
    <Box sx={modalStyle}>
      <Typography variant="h5" mb={3}>
        {title}
      </Typography>

      <FormProvider {...methods}>
        <Box component="form" onSubmit={handleSubmit(onSubmitHandler)} width="100%" noValidate>
          <FormInput label={label} name="name" fullWidth={true} />
          <Stack direction="row" spacing={2} justifyContent="flex-end" sx={{ mt: 5 }}>
            <Button variant="outlined" onClick={handleClose} sx={minButtonWidth}>
              Close
            </Button>
            <LoadingButton loading={loading} variant="contained" type="submit" sx={minButtonWidth}>
              {buttonText}
            </LoadingButton>
          </Stack>
        </Box>
      </FormProvider>
    </Box>
  )
}

const ModalWithTextInput = (props: Props) => {
  const { isOpen } = props

  return (
    <Modal sx={defaultModalZIndex} open={isOpen}>
      <div>{isOpen ? <ModalContent {...props} /> : <Box />}</div>
    </Modal>
  )
}

export default ModalWithTextInput
