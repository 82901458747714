import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { getCareTeams } from 'api/careTeams'
import { BaseTableRequest, CareTeam } from 'types'

export const getCareTeamById = createAsyncThunk(
  'care-team/by-user-id',
  async (request: BaseTableRequest, thunkAPI) => {
    try {
      const response = await getCareTeams(request)
      return response.data.rows
    } catch (e) {
      thunkAPI.rejectWithValue({})
    }
  },
)

export const careTeamSlice = createSlice({
  name: 'care-team',
  initialState: {
    careTeam: null as CareTeam[] | null,
    isLoading: false,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getCareTeamById.pending, (state) => {
        state.isLoading = true
      })
      .addCase(getCareTeamById.fulfilled, (state, action) => {
        state.isLoading = false
        state.careTeam = action.payload ? action.payload || [] : null
      })
      .addCase(getCareTeamById.rejected, (state) => {
        state.isLoading = false
        state.careTeam = null
      })
  },
})
