import { Stack } from '@mui/material'
import FailPlaceholder from 'components/placeholders/FailPlaceholder'
import LoadingPlaceholder from 'components/placeholders/LoadingPlaceholder'
import { TagsFilterDropdown } from 'features/Tags'
import { useEffect } from 'react'
import { useActions, useAppSelector } from 'store/hooks'
import { TimelineFilterByStatus } from '../TimelineFilterByStatus/TimelineFilterByStatus'
import { TimelineFilterByType } from '../TimelineFilterByType/TimelineFilterByType'
import { TimelineList } from '../TimelineList/TimelineList'

export function TimelineTab() {
  const { isLoading, timeline } = useAppSelector((state) => state.timeline)
  const { getTimelineById } = useActions()

  useEffect(() => {
    getTimelineById({})
  }, [getTimelineById])

  if (isLoading) {
    return <LoadingPlaceholder />
  }

  if (!timeline) {
    return <FailPlaceholder error="Can't load data of timeline" />
  }

  return (
    <Stack paddingLeft={{ xs: 2, sm: 2 }} paddingRight={{ xs: 2, md: 0 }} spacing={2}>
      <Stack direction="row" pt={2} flexWrap="wrap" className="gap-8">
        <TimelineFilterByType />
        <TimelineFilterByStatus />

        <TagsFilterDropdown />
      </Stack>

      <TimelineList timeline={timeline} />
    </Stack>
  )
}
