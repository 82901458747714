import Drawer from '@mui/material/Drawer'
import { deleteNote, getNoteById } from 'api/notes'
import { setStatus } from 'api/timelines'
import { defaultDrawerSX } from 'const'
import { UIPencilImg } from 'features/UI'
import React from 'react'
import { useMutation, useQuery } from 'react-query'
import { useActions } from 'store/hooks'
import { ETimelinePostStatus, ETimelinePostType, Note } from 'types'
import { useAuthContext } from '../../context/AuthProvider'
import NotificationSys from '../../NotificationSystem'
import DrawerContainer from '../common/DrawerContainer'
import DrawerHeader from '../common/DrawerHeader'
import DrawerNoteItem from './DrawerNoteItem'

interface Props {
  id: number | null
  timelineId: number | null
  handleClose: () => void
}

interface ContentProps {
  data: Note
  showActions: boolean
  onDelete: (id: number) => void
  onUpdateStatus: (status: ETimelinePostStatus) => void
  handleClose: () => void
}

const Content = (props: ContentProps) => {
  const { data, showActions, onDelete, handleClose, onUpdateStatus } = props
  const title = data.subject
  const author = data.authorName || 'Unknown Author'

  const date = data.createdAt
  const text = data.body

  const onDeleteWrapper = () => {
    onDelete(data.id)
  }
  const onReviewLaterWrapper = () => {
    onUpdateStatus(ETimelinePostStatus.review)
  }
  const onDoneWrapper = () => {
    onUpdateStatus(ETimelinePostStatus.done)
  }

  return (
    <>
      <DrawerHeader handleClose={handleClose} borderEnable={true}>
        <UIPencilImg />
        <span>Note</span>
      </DrawerHeader>

      <DrawerNoteItem
        title={title}
        author={author}
        date={date}
        text={text}
        showActions={showActions}
        onDelete={onDeleteWrapper}
        onDone={onDoneWrapper}
        onReviewLater={onReviewLaterWrapper}
      />
    </>
  )
}

const ViewNoteDrawer = (props: Props) => {
  const { id, handleClose } = props

  const isOpen = id !== null

  const { user } = useAuthContext()
  const { getTimelineById } = useActions()
  const userId = user?.id ?? -1

  const query = useQuery(['getNoteById', { id }], () => getNoteById(id ?? -1), {
    enabled: isOpen,
    select: (req) => req.data,
    cacheTime: 0,
    staleTime: 0,
  })

  const removeMutation = useMutation(deleteNote, {
    onSuccess: () => {
      handleClose()
      getTimelineById({})
      NotificationSys.showSuccess(`Note successfully removed`)
    },
  })

  const setStatusMutation = useMutation(setStatus, {
    onSuccess: () => {
      handleClose()
      getTimelineById({})
      NotificationSys.showSuccess(`Note successfully updated`)
    },
  })

  const onUpdateStatus = (status: ETimelinePostStatus) => {
    setStatusMutation.mutate({
      id: id ?? -1,
      status,
      type: ETimelinePostType.note,
    })
  }

  const { data, isLoading } = query

  return (
    <Drawer anchor="right" open={isOpen} onClose={handleClose} sx={defaultDrawerSX}>
      <DrawerContainer
        isOpen={isOpen}
        hasData={!!data}
        loading={isLoading || removeMutation.isLoading}
        handleClose={handleClose}
      >
        {data && (
          <Content
            data={data}
            showActions={userId === data.authorId}
            onDelete={removeMutation.mutate}
            handleClose={handleClose}
            onUpdateStatus={onUpdateStatus}
          />
        )}
      </DrawerContainer>
    </Drawer>
  )
}

export default ViewNoteDrawer
