import { addQuestionnaireEngagementMetric } from 'api/metrics'
import { useAuthContext } from 'components/context/AuthProvider'
import { useCallback } from 'react'
import { useMetric } from 'services/Metric'
import { IMetricScope, IMetricType, Questionnaire } from 'types'
import { formatMetricDate } from 'utils'

export function QuestionnaireMetric({ questionnaire }: { questionnaire: Questionnaire }) {
  const { user } = useAuthContext()

  const handleSaveMetric = useCallback(
    async (value: number, uuid: string, scope: IMetricScope) => {
      if (!user) {
        return
      }

      await addQuestionnaireEngagementMetric({
        userId: user.id,
        questionnaireId: questionnaire.id,
        scope,
        engagedFrom: formatMetricDate(value),
        engagedTo: formatMetricDate(),
      })
    },
    [questionnaire, user],
  )

  useMetric({
    type: IMetricType.questionnaire,
    entityId: questionnaire.id,
    isImmediateStart: true,
    hasIdleTimer: true,
    idleLimitMin: 5,
    scope: IMetricScope.questionnaire,
    onSave: handleSaveMetric,
  })

  return null
}
