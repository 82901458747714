import { getTags } from 'api/tags'
import { UIFilterAsyncAutocomplete } from 'features/UI'
import get from 'lodash/get'
import React, { useCallback, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Option, Tag } from 'types'
import { selectTags, setTags } from '../state/Tags.slice'

export function useTagsFilterForDraggableTable<T>(
  data: T[],
  path: string,
  customFilterCallback?: (tagsValue: Option[]) => T[],
) {
  const dispatch = useDispatch()
  const tags = useSelector(selectTags)

  const isDraggable = useMemo(() => tags.length === 0, [tags])

  const handleChangeTagFilter = useCallback(
    (newValue: Option[]) => {
      dispatch(setTags({ tags: newValue }))
    },
    [dispatch],
  )

  const rowData = useMemo(() => {
    if (!tags.length) {
      return data
    }

    if (customFilterCallback) {
      return customFilterCallback(tags)
    }

    return data.filter((dataItem) => {
      return get(dataItem, path).some((tag: Tag) => {
        return tags.some((selectedTag) => selectedTag.value === tag.id)
      })
    })
  }, [customFilterCallback, data, path, tags])

  const TagFilter = useMemo(() => {
    return (
      <UIFilterAsyncAutocomplete
        label="Tags"
        value={tags}
        onChange={handleChangeTagFilter}
        queryId="tags"
        apiRequest={getTags}
      />
    )
  }, [handleChangeTagFilter, tags])

  return { TagFilter, rowData, isDraggable }
}
