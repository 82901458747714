import { zodResolver } from '@hookform/resolvers/zod/dist/zod'
import { LoadingButton } from '@mui/lab'
import { Box, Stack } from '@mui/material'
import { EMAIL_IS_INVALID, FIELD_IS_REQUIRED, minButtonWidth } from 'const'
import React, { useMemo } from 'react'
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form'
import { useMutation } from 'react-query'
import { object, string, TypeOf } from 'zod'
import { useAuthContext } from '../context/AuthProvider'
import FormInput from '../form-elements/FormInput'
import NotificationSys from '../NotificationSystem'
import { contactUs } from 'api/users'
import { UIModal } from 'features/UI'

interface Props {
  isOpen: boolean
  handleClose: () => void
}

const formSchema = object({
  subject: string().min(1, FIELD_IS_REQUIRED),
  email: string().min(1, FIELD_IS_REQUIRED).email(EMAIL_IS_INVALID),
  text: string().optional(),
})

type FormType = TypeOf<typeof formSchema>

const ModalContent = ({ handleClose }: { handleClose: () => void }) => {
  const { user } = useAuthContext()
  const formSettings = useMemo(
    () => ({
      mode: 'onChange' as const,
      resolver: zodResolver(formSchema),
      defaultValues: {
        email: user?.email,
      },
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  )

  const formProps = useForm<FormType>(formSettings)
  const { handleSubmit, formState } = formProps

  const contactUsMutation = useMutation(contactUs, {
    onSuccess: () => {
      handleClose()
      NotificationSys.showSuccess(
        'Thank you for your feedback! We will get back to you within 2 business days.',
      )
    },
  })

  const onSubmitHandler: SubmitHandler<FormType> = ({ subject, email, text }: FormType) => {
    contactUsMutation.mutate({ subject, email, text })
  }

  return (
    <FormProvider {...formProps}>
      <Box component="form" onSubmit={handleSubmit(onSubmitHandler)} noValidate>
        <Stack spacing={2}>
          <FormInput label="Subject" name="subject" required />
          <FormInput label="Email" name="email" required />
          <FormInput label="Message" name="text" minRows={3} multiline />
        </Stack>

        <Stack direction="row" spacing={2} justifyContent="flex-end" sx={{ mt: 4 }}>
          <LoadingButton variant="outlined" onClick={handleClose} sx={minButtonWidth}>
            Close
          </LoadingButton>
          <LoadingButton
            loading={contactUsMutation.isLoading}
            disabled={!formState.isValid}
            variant="contained"
            type="submit"
            sx={minButtonWidth}
          >
            Send
          </LoadingButton>
        </Stack>
      </Box>
    </FormProvider>
  )
}

const ContactUsModal = ({ isOpen, handleClose }: Props) => (
  <UIModal title="Contact Us" isOpen={isOpen} onClose={handleClose}>
    <ModalContent handleClose={handleClose} />
  </UIModal>
)

export default ContactUsModal
