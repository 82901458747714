import { Stack } from '@mui/material'
import { getLibraryArticleById, updateLibraryArticle } from 'api/learnArticles'
import { createQuery } from 'api/query'
import Breadcrumbs from 'components/Breadcumbs'
import ConfirmRemoveModal from 'components/modals/ConfirmRemoveModal'
import LinkToLessonOrCareArticleModal from 'components/modals/lms/LinkToLessonOrCareArticleModal'
import PageTitle from 'components/page/PageTitle'
import FailPlaceholder from 'components/placeholders/FailPlaceholder'
import LoadingPlaceholder from 'components/placeholders/LoadingPlaceholder'
import { ExamPreviewPanel, ExamQuizControlAdd } from 'features/Exam'
import { LinkArticleWithTags } from 'features/Tags'
import {
  UIControlBanner,
  UIControlChangeName,
  UIEntityItemArticle,
  UIEntityItemConnection,
} from 'features/UI'
import { useChangeEntityProperty } from 'hooks/useChangeEntityName'
import { useEntityDataControl } from 'hooks/useEntityDataControl'
import { useRemoveModalControl } from 'hooks/useRemoveModalControl'
import React from 'react'
import { useNavigate } from 'react-router-dom'
import { routes } from 'routes/routes'
import { LearnArticle } from 'types'
import { noop, useSearchParamsObject } from 'utils'

const getPath = (data: LearnArticle) => {
  const lessonName = data.name

  return [
    {
      id: data.topicId,
      href: routes.topicEdit,
      text: 'Topic',
    },
    {
      text: lessonName,
    },
  ]
}

export const LearnLibraryArticlePage = () => {
  const { id } = useSearchParamsObject()
  const navigate = useNavigate()

  const { data, setData, loading, setLoading, reloadData } = useEntityDataControl({
    id,
    loadFunc: getLibraryArticleById,
    warning: "Can't load article",
  })

  const onEditMedia = () => {
    const id = data?.id || ''
    navigate(`${routes.lessonContentEdit}?id=${id}&type=learn`)
  }

  const linkRemoveModal = useRemoveModalControl({
    deleteFunc: async () => {
      const response = await getLibraryArticleById({ id: Number(id) })
      await updateLibraryArticle({
        ...response.data,
        careArticleId: null,
        lessonId: null,
      })
    },
    successCallback: reloadData,
    warning: "Can't remove link from the learn library article",
  })

  const [onChangeCareArticleLink, openLinkEdit, isOpenLinkEdit, closeLinkEdit] =
    useChangeEntityProperty({
      data,
      setLoading,
      setData: noop,
      updateFunc: async (request) => {
        return await updateLibraryArticle({
          ...request,
          lessonId: null,
        })
      },
      onSuccess: () => {
        reloadData()
      },
      warning: "Can't update timeline post Link",
      field: 'careArticleId',
    })

  const [onChangeLessonLink] = useChangeEntityProperty({
    data,
    setLoading,
    setData: noop,
    updateFunc: async (request) => {
      return await updateLibraryArticle({
        ...request,
        careArticleId: null,
      })
    },
    onSuccess: () => {
      closeLinkEdit()
      reloadData()
    },
    warning: "Can't update lesson link",
    field: 'lessonId',
  })

  if (loading) {
    return <LoadingPlaceholder />
  }

  if (!data) {
    return (
      <FailPlaceholder
        error="Can't load data of article"
        link={routes.manageLearnLibrary}
        linkText="Back to topics table"
      />
    )
  }

  return (
    <>
      <Stack spacing={2}>
        <PageTitle>Learn Library Article View</PageTitle>

        <Stack direction="row" spacing={1} justifyContent="space-between" alignItems="center">
          <Stack direction="row" spacing={2} alignItems="center">
            <Breadcrumbs path={getPath(data)} />

            <UIControlChangeName<LearnArticle>
              entityName="article"
              data={data}
              setData={setData}
              loading={loading}
              setLoading={setLoading}
              updateRequest={updateLibraryArticle}
            />
          </Stack>
        </Stack>

        <Stack spacing={3}>
          <UIControlBanner<LearnArticle>
            entityName="Learn Library"
            data={data}
            setData={setData}
            setLoading={setLoading}
            updateRequest={updateLibraryArticle}
          />

          <UIEntityItemArticle
            onEdit={onEditMedia}
            text="Learn Library Article"
            article={data.article}
            hasAudio={data.hasAudio}
          />

          <ExamPreviewPanel
            type="query"
            id={data.queryId}
            name={data.query?.name}
            onReload={reloadData}
            addControl={
              <ExamQuizControlAdd
                entityType="query"
                entityName="Query"
                routeEdit={routes.questionnaireEdit}
                parentId={Number(data?.id)}
                parentIdField="learnArticleId"
                createRequest={createQuery}
              />
            }
          />

          <UIEntityItemConnection
            linkId={data.careArticleId || data.lessonId}
            text="Link to Lesson or Timeline Post"
            onEdit={openLinkEdit}
            onDelete={() => linkRemoveModal.openDeleteModal(0)}
            articleName={data.careArticleName || data.lessonName}
          />

          <LinkArticleWithTags<LearnArticle>
            data={data}
            setLoading={setLoading}
            reloadData={reloadData}
            updateQuery={updateLibraryArticle}
          />
        </Stack>
      </Stack>

      <LinkToLessonOrCareArticleModal
        isOpen={isOpenLinkEdit}
        handleClose={closeLinkEdit}
        careArticleId={data.careArticleId}
        lessonId={data.lessonId}
        onSubmitCare={onChangeCareArticleLink}
        onSubmitLesson={onChangeLessonLink}
      />

      <ConfirmRemoveModal
        entityToRemove="Link to Lesson or Timeline Post"
        loading={linkRemoveModal.removeLoading}
        isOpen={linkRemoveModal.idToRemove !== null}
        handleConfirm={linkRemoveModal.handleConfirmRemove}
        handleClose={linkRemoveModal.closeDeleteModal}
      />
    </>
  )
}
