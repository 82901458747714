import IdLink from 'components/IdLink'
import { PLACEHOLDER } from './common'
import { ICellRendererParams } from 'ag-grid-community'

const createLinkRenderer =
  (
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    config: string | { nameSelector: (data: any) => string; idSelector: (data: any) => number },
    baseRoute: string,
  ) =>
  // eslint-disable-next-line react/display-name
  (props: ICellRendererParams<{ [propName: string]: string }>) => {
    const name =
      typeof config === 'object' ? config.nameSelector(props.data) : props?.data?.[config]

    const itemId = typeof config === 'object' ? config.idSelector(props.data) : props?.data?.id

    if (!itemId) {
      return name ?? PLACEHOLDER
    }

    return (
      <IdLink href={baseRoute} id={itemId}>
        {name || PLACEHOLDER}
      </IdLink>
    )
  }

export default createLinkRenderer
