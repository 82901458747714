import { Stack, Typography } from '@mui/material'
import { UICarePostArticleImg, UILearnArticleImg } from 'features/UI'
import moment from 'moment/moment'
import React, { useMemo } from 'react'
import { ArticleType, CareArticleType, Nullish } from 'types'
import DrawerHeader from './common/DrawerHeader'

const articleAuthor = 'Memory Care Partner'

const formatDateForArticle = (date: string | Date) => {
  return moment(date).format('h:mm A dddd Do of MMMM, YYYY')
}

export function ArticleHeader({
  articleTitle,
  handleClose,
  type,
  careArticleType,
  date,
}: {
  articleTitle: string
  date: string
  handleClose: () => void
  type: ArticleType
  careArticleType: Nullish<CareArticleType>
}) {
  const formattedDate = formatDateForArticle(date)

  const title = useMemo(() => {
    if (type === ArticleType.Lesson) {
      return 'Lesson Article'
    }

    if (type === ArticleType.LearnLibrary) {
      return 'Learn Library Article'
    }

    if (type === ArticleType.CareArticle) {
      if (careArticleType === CareArticleType.Care) {
        return 'Guide Post'
      }

      return 'Action Post'
    }

    return 'Memory Care Article'
  }, [careArticleType, type])

  return (
    <>
      <DrawerHeader handleClose={handleClose} borderEnable={true}>
        {type === ArticleType.Lesson ? <UILearnArticleImg /> : <UICarePostArticleImg />}
        <span>{title}</span>
      </DrawerHeader>

      <Typography variant="h4" mt={-1} mb={0.5}>
        {articleTitle}
      </Typography>
      <Stack direction="row" sx={{ gap: '8px' }}>
        <Typography color="textSecondary" variant="body1">
          by {articleAuthor} · {formattedDate}
        </Typography>
      </Stack>
    </>
  )
}
