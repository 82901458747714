import { LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'
import moment from 'moment'
import React from 'react'
import { QueryClient, QueryClientProvider } from 'react-query'
import { Provider } from 'react-redux'
import { RouterProvider } from 'react-router-dom'
import { PersistGate } from 'redux-persist/integration/react'
import { persistor, store } from 'store/store'
import AuthProvider from './components/context/AuthProvider'
import NotificationSys from './components/NotificationSystem'
import { router } from './routes/router'
import { SwipeProvider } from './providers/SwipeProvider'
import ThemeCustomization from './themes/ThemeCustomization'

/* Start week - Monday */
moment.updateLocale('en', {
  week: {
    dow: 1,
  },
})

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      cacheTime: 0,
      staleTime: 0,
      retry: false,
    },
  },
})

function App() {
  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <QueryClientProvider client={queryClient}>
          <SwipeProvider>
            <AuthProvider>
              <LocalizationProvider dateAdapter={AdapterMoment}>
                <ThemeCustomization>
                  <RouterProvider router={router} />
                </ThemeCustomization>
              </LocalizationProvider>
              <NotificationSys />
            </AuthProvider>
          </SwipeProvider>
        </QueryClientProvider>
      </PersistGate>
    </Provider>
  )
}

export default App
