/* eslint-disable @typescript-eslint/no-explicit-any */

import { allFiltersStatuses } from 'types'

export const migrations = {
  0: (state: any) => {
    return {
      ...state,
      learnLibraryFilters: {
        ...state.learnLibraryFilters,
        selectedTagIds: null,
      },
    }
  },
  1: (state: any) => {
    return {
      ...state,
      learnLibraryFilters: {
        ...state.learnLibraryFilters,
        status: [...allFiltersStatuses],
        selectedTagIds: null,
      },
    }
  },
  2: (state: any) => {
    return {
      ...state,
      learnLibraryFilters: {
        status: [...allFiltersStatuses],
      },
    }
  },
  3: (state: any) => {
    return {
      ...state,
      timelineFilters: {
        timelineTypes: null,
        timelineFilter: null,
      },
    }
  },
}
