import { InfoOutlined } from '@mui/icons-material'
import { Button, IconButton, Stack, Typography } from '@mui/material'
import Tooltip from '@mui/material/Tooltip'
import { ColDef, ICellRendererParams } from 'ag-grid-community'
import { createCohort, deleteCohort, getCohorts, updateCohort } from 'api/cohorts'
import columnRenderers from 'components/column-renderers'
import ConfirmRemoveModal from 'components/modals/ConfirmRemoveModal'
import InfinityAgGrid from 'components/table/InfinityAgGrid'
import TableToolbar from 'components/table/TableToolbar'
import { getActionColumn } from 'features/UI'
import { useChangeEntity } from 'hooks/useChangeEntity'
import { useGridControl } from 'hooks/useGridControl'
import { useRemoveModalControl } from 'hooks/useRemoveModalControl'
import React, { useCallback, useMemo, useState } from 'react'
import { Cohort, CohortRequestCreate, CohortRequestUpdate, Nullish } from 'types'
import { useGetDataSource } from 'utils'
import { CohortsEditModal } from '../CohortsEditModal/CohortsEditModal'
import { useExportCSV } from 'hooks/useExportCSV'
import moment from 'moment'
import { useCohortCSVField } from 'features/Cohorts/hooks/useCohortCSVField'

export function CohortsPage() {
  const [editedItem, setEditedItem] = useState<Nullish<Cohort>>(null)
  const getDataSource = useGetDataSource(getCohorts)
  const { onGridReady, gridApi, columnApi } = useGridControl(getDataSource)

  const [loading, setLoading] = useState(false)

  const [onSubmit, openAddModal, isOpenAddModal, closeAddModal] = useChangeEntity({
    setLoading,
    onSuccess: () => {
      gridApi?.purgeInfiniteCache()
    },
    requestFunc: createCohort,
    existedNameWarning: "Name must be unique. Can't create the cohort with existing name",
    warning: "Can't create the cohort",
  })

  const [onUpdate, openEditModal, isOpenEditModal, closeEditModal] = useChangeEntity({
    setLoading,
    onSuccess: () => {
      gridApi?.purgeInfiniteCache()
    },
    requestFunc: updateCohort,
    existedNameWarning: "Can't rename cohort to existed name",
    warning: "Can't update cohort",
  })

  const { idToRemove, openDeleteModal, closeDeleteModal, removeLoading, handleConfirmRemove } =
    useRemoveModalControl({
      deleteFunc: deleteCohort,
      warning: "Can't delete cohort",
      successCallback: () => {
        gridApi?.purgeInfiniteCache()
      },
    })

  const handleDelete = useCallback(
    (id: number) => {
      openDeleteModal(id)
    },
    [openDeleteModal],
  )

  const handleCreate = useCallback(
    (values: CohortRequestCreate) => {
      onSubmit(values)
    },
    [onSubmit],
  )

  const handleEdit = useCallback(
    async (cohort: CohortRequestUpdate) => {
      await onUpdate(cohort)
    },
    [onUpdate],
  )

  const handleEditFromModal = useCallback(
    async (values: CohortRequestCreate) => {
      if (!editedItem) {
        return
      }

      await handleEdit({
        id: editedItem.id,
        name: values.name,
        comment: values.comment,
      })

      setEditedItem(null)
      closeEditModal()
    },
    [closeEditModal, editedItem, handleEdit],
  )

  const actions = useMemo(() => {
    return [
      {
        name: 'Edit',
        onClick: (_: number, props: ICellRendererParams<Cohort>) => {
          if (props.data) {
            setEditedItem(props.data)
          }
          openEditModal()
        },
      },
      {
        name: 'Delete',
        onClick: handleDelete,
      },
    ]
  }, [handleDelete, openEditModal])

  const columnDefs: ColDef[] = useMemo(() => {
    return [
      {
        headerName: 'Cohort Name',
        field: 'name',
        flex: 1,
        filter: 'agTextColumnFilter',
        cellRenderer: (cell: ICellRendererParams<Cohort>) => {
          if (!cell.data) {
            return null
          }

          return (
            <Stack alignItems="center" direction="row" height="100%">
              <Typography>{cell.data.name}</Typography>
              {cell.data.comment && (
                <Tooltip title={cell.data.comment} placement="right" arrow>
                  <IconButton size="small">
                    <InfoOutlined />
                  </IconButton>
                </Tooltip>
              )}
            </Stack>
          )
        },
      },
      {
        headerName: 'Creation Date',
        cellRenderer: columnRenderers.createdAt,
        colId: 'createdAt',
      },
      {
        headerName: 'Last Edited',
        cellRenderer: columnRenderers.updatedAt,
        minWidth: 220,
        colId: 'updatedAt',
        sort: 'desc',
      },
      { ...getActionColumn(actions) },
    ]
  }, [actions])

  const csvFields = useCohortCSVField()

  const { csvLoading, onExportCSV } = useExportCSV(
    getCohorts,
    gridApi,
    columnApi,
    csvFields,
    `Cohorts Overview_${moment().format('YYYY-MM-DD')}.csv`,
  )

  return (
    <>
      <TableToolbar
        title="Cohorts Overview"
        exportCSVEnable
        csvLoading={csvLoading}
        onExportCSV={onExportCSV}
      >
        <Button variant="outlined" sx={{ minWidth: 120 }} onClick={openAddModal}>
          Create Cohort
        </Button>
      </TableToolbar>
      <InfinityAgGrid pagination columnDefs={columnDefs} onGridReady={onGridReady} />

      <CohortsEditModal
        defaultValues={null}
        isOpen={isOpenAddModal}
        onClose={closeAddModal}
        loading={loading}
        onSubmit={handleCreate}
      />

      <CohortsEditModal
        defaultValues={editedItem}
        isOpen={isOpenEditModal}
        onClose={closeEditModal}
        loading={loading}
        onSubmit={handleEditFromModal}
      />

      <ConfirmRemoveModal
        entityToRemove="Cohort"
        loading={removeLoading}
        isOpen={idToRemove !== null}
        handleConfirm={handleConfirmRemove}
        handleClose={closeDeleteModal}
      />
    </>
  )
}
