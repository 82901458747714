import { Grid, Stack, Tab, Tabs, useMediaQuery } from '@mui/material'
import { useTheme } from '@mui/material/styles'
import { useAuthContext } from 'components/context/AuthProvider'
import PageTab from 'components/tabs/PageTab'
import { getTitle } from 'components/tabs/TabsTitle'
import { useTabsControl } from 'hooks/useTabsControl'
import React from 'react'
import { TimelineJourneyMembersTab } from '../TimelineJourneyMembersTab/TimelineJourneyMembersTab'
import {
  careTeamTooltip,
  careTimelineTooltip,
  traineeCareTeamTooltip,
  trainingTimelineTooltip,
} from '../../models/Timeline.model'
import { TimelineTab } from '../TimelineTab/TimelineTab'

export function TimelineJourneyPage() {
  const theme = useTheme()
  const matchesSm = useMediaQuery(theme.breakpoints.down('md'))
  const { selectedTab, handleChangeSelectedTab } = useTabsControl<string>('first')
  const { isTrainee } = useAuthContext()

  const timelineText = isTrainee ? 'Training Timeline' : 'Care Timeline'
  const teamText = 'Care Team'

  const firstTab = <TimelineTab />
  const secondTab = <TimelineJourneyMembersTab />

  const journeyTooltip = isTrainee ? trainingTimelineTooltip : careTimelineTooltip
  const teamTooltip = isTrainee ? traineeCareTeamTooltip : careTeamTooltip

  if (matchesSm) {
    return (
      <>
        <Tabs
          value={selectedTab}
          onChange={handleChangeSelectedTab}
          color="secondary"
          indicatorColor="secondary"
          textColor="secondary"
          variant="fullWidth"
          sx={{ background: 'white' }}
        >
          <Tab value="first" label={getTitle(timelineText, undefined, journeyTooltip)} />

          <Tab value="second" label={getTitle(teamText, undefined, teamTooltip)} />
        </Tabs>
        <>
          {selectedTab === 'first' && firstTab}
          {selectedTab === 'second' && secondTab}
        </>
      </>
    )
  }

  return (
    <Grid container spacing={1} flexGrow={1}>
      <Grid item sm={12} md={6} lg={8} flexGrow={1}>
        <Stack height="100%">
          <PageTab title={timelineText} tooltip={journeyTooltip} />
          {firstTab}
        </Stack>
      </Grid>

      <Grid item sm={12} md={6} lg={4} flexGrow={1}>
        <Stack height="100%">
          <PageTab title={teamText} tooltip={teamTooltip} />
          {secondTab}
        </Stack>
      </Grid>
    </Grid>
  )
}
