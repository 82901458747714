const getFirstLetter = (value?: string) => {
  if (value) {
    return value[0].toUpperCase()
  }

  return ''
}

export const UIUserIcon = ({
  firstName,
  lastName,
  width = 40,
  height = 40,
}: {
  width?: number
  height?: number
  firstName?: string
  lastName?: string
}) => {
  return (
    <svg width={width} height={height}>
      <linearGradient x1="0%" y1="0%" x2="0%" y2="100%" id="purple_gradient">
        <stop offset="0%" style={{ stopColor: 'rgb(142, 74, 147)' }}></stop>
        <stop offset="100%" style={{ stopColor: 'rgb(52, 27, 54)' }}></stop>
      </linearGradient>
      <circle fill="url(#purple_gradient)" cx="20" cy="20" r="20"></circle>
      <text textAnchor="middle" x="50%" y="50%" dy="0.35em" fill="white" fontSize="16">
        {getFirstLetter(firstName)}
        {getFirstLetter(lastName)}
      </text>
    </svg>
  )
}
