import { Box, Button, Typography } from '@mui/material'
import { ExamTitleMap } from 'models/exam.model'
import React from 'react'
import { DraggableProvidedDragHandleProps } from 'react-beautiful-dnd'
import { QuestioningType } from 'types'
import { isDefined } from 'utils'
import { UIEntityItemBox } from '../UIEntityItemBox/UIEntityItemBox'

export function UIEntityItemExam({
  name,
  type,
  addQuiz,
  onEdit,
  onDelete,
  dragHandleProps,
  quizId,
}: {
  name?: string
  type: QuestioningType
  quizId: number | null | undefined
  onEdit: () => void
  addQuiz?: () => void
  onDelete?: () => void
  dragHandleProps?: DraggableProvidedDragHandleProps | null | undefined
}) {
  const title = ExamTitleMap[type] || 'Not supported'

  if (!isDefined(quizId) && addQuiz) {
    return (
      <Box>
        <Button onClick={addQuiz} variant="contained">
          Add {title}
        </Button>
      </Box>
    )
  }

  return (
    <UIEntityItemBox onEdit={onEdit} dragHandleProps={dragHandleProps} onDelete={onDelete}>
      <Typography variant="body1" fontSize="1.125rem">
        {title} - {name}
      </Typography>
    </UIEntityItemBox>
  )
}
