import { zodResolver } from '@hookform/resolvers/zod/dist/zod'
import { LoadingButton } from '@mui/lab'
import { Box, Button, Stack } from '@mui/material'
import { checkQuestionUniqueness } from 'api/question'
import FormInput from 'components/form-elements/FormInput'
import FormSelect from 'components/form-elements/FormSelect'
import NotificationSys from 'components/NotificationSystem'
import { FIELD_IS_REQUIRED, minButtonWidth } from 'const'
import { UIModal } from 'features/UI'
import React, { useMemo, useState } from 'react'
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'
import { routes } from 'routes/routes'
import { EQuestionType } from 'types'
import { withParams } from 'utils'
import { object, string, TypeOf } from 'zod'

const questionTypes = [
  { label: 'Quiz/Exam', value: EQuestionType.question },
  { label: 'Query', value: EQuestionType.query },
]

const ModalContent = ({
  onClose,
  onSubmit,
  loading,
}: {
  onClose: () => void
  onSubmit: (value: { name: string; type: string }) => void
  loading: boolean
}) => {
  const formSchema = useMemo(() => {
    return object({
      name: string().min(1, FIELD_IS_REQUIRED),
      type: string().min(1, FIELD_IS_REQUIRED),
    })
  }, [])

  const formSettings = useMemo(() => {
    return {
      mode: 'onChange' as const,
      resolver: zodResolver(formSchema),
      defaultValues: {
        name: '',
        type: '',
      },
    }
  }, [formSchema])

  type FormType = TypeOf<typeof formSchema>

  const methods = useForm<FormType>(formSettings)
  const { handleSubmit } = methods

  const onSubmitHandler: SubmitHandler<FormType> = async (values: FormType) => {
    const success = await checkQuestionUniqueness({ name: values.name })
    if (!success) {
      NotificationSys.showWarning('Question with such name already exists')
      return
    }

    onSubmit(values)
  }

  return (
    <FormProvider {...methods}>
      <Box component="form" onSubmit={handleSubmit(onSubmitHandler)} width="100%" noValidate>
        <Stack spacing={1}>
          <FormSelect label="Question type" name="type" fullWidth options={questionTypes} />
          <FormInput label="Question name" name="name" fullWidth />
        </Stack>
        <Stack direction="row" spacing={2} justifyContent="flex-end" sx={{ mt: 5 }}>
          <Button variant="outlined" onClick={onClose} sx={minButtonWidth}>
            Close
          </Button>
          <LoadingButton loading={loading} variant="contained" type="submit" sx={minButtonWidth}>
            Save
          </LoadingButton>
        </Stack>
      </Box>
    </FormProvider>
  )
}

export function QuestionModalCreate({ isOpen, onClose }: { isOpen: boolean; onClose: () => void }) {
  const navigate = useNavigate()

  const [loading, setLoading] = useState(false)

  const onSubmit = async (value: { name: string; type: string }) => {
    try {
      setLoading(true)
      const { name, type } = value
      navigate(withParams(routes.questionAdd, { name, type }))
    } finally {
      setLoading(false)
    }
  }

  return (
    <UIModal isOpen={isOpen} title="Add Question" onClose={onClose}>
      <ModalContent onClose={onClose} onSubmit={onSubmit} loading={loading} />
    </UIModal>
  )
}
