import { useMemo } from 'react'
import { CSVField } from 'hooks/useExportCSV'
import { csvColumnCreatedBy, csvColumnUpdatedBy } from 'features/UI'
import { formatDate } from 'utils'
import { createTagsColumnCSV } from 'features/Tags/models/TagsColumn'

export function useArticleCSVField() {
  return useMemo<CSVField[]>(
    () => [
      {
        label: 'Timeline Post Name',
        value: 'name',
      },
      {
        label: 'Linked Learn Library Article',
        value: 'learnArticleName',
      },
      {
        label: 'Published',
        value: ({ isPublished }) => (isPublished ? 'Published' : 'Unpublished'),
      },
      createTagsColumnCSV('articleTags'),
      {
        label: 'Creation Date',
        value: ({ createdAt }) => formatDate(createdAt),
      },
      csvColumnCreatedBy(),
      {
        label: 'Last Edited',
        value: ({ updatedAt }) => formatDate(updatedAt),
      },
      csvColumnUpdatedBy(),
    ],
    [],
  )
}
