import { UIModal } from 'features/UI'
import React, { useMemo } from 'react'
import { ImageEditor } from '../ImageEditor/ImageEditor'

export function ImageEditorModal({
  imgData,
  isOpen,
  hasRatioRestrictions,
  onClose,
  onSave,
}: {
  imgData: string
  isOpen: boolean
  hasRatioRestrictions: boolean
  onClose: () => void
  onSave: (base64: string) => void
}) {
  const width = useMemo(() => {
    return Math.round((window.innerWidth * 90) / 100)
  }, [])

  const maxWidth = useMemo(() => {
    const fullWidth = (window.innerHeight * 16) / 9
    return (fullWidth * 80) / 100
  }, [])

  const handleSave = (base64: string) => {
    onSave(base64)
    onClose()
  }

  return (
    <UIModal
      title="Image Editor"
      isOpen={isOpen}
      width={width}
      maxWidth={maxWidth}
      onClose={onClose}
    >
      <ImageEditor
        imgData={imgData}
        hasRatioRestrictions={hasRatioRestrictions}
        onSave={handleSave}
      />
    </UIModal>
  )
}
