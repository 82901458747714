import { Checkbox, MenuItem, Typography } from '@mui/material'
import React, { ReactNode, useCallback } from 'react'
import * as Styled from './UICheckboxItem.styled'

export function UICheckboxItem<T>({
  id,
  label,
  icon,
  svgIcon,
  checked,
  onClick,
}: {
  id: T
  label: string
  icon?: string
  svgIcon?: ReactNode
  checked: boolean
  onClick: (id: T) => void
}) {
  const handleClick = useCallback(() => {
    onClick(id)
  }, [id, onClick])

  return (
    <MenuItem sx={{ height: 40 }} onClick={handleClick}>
      <Checkbox checked={checked} sx={{ ml: -1.5 }} />
      {icon && <Styled.Image src={icon} />}
      {svgIcon ? svgIcon : null}
      <Typography mr={1}>{label}</Typography>
    </MenuItem>
  )
}
