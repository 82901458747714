import IdLink from './IdLink'
import { Typography } from '@mui/material'
import React from 'react'
import { reactDomLinkStyles } from '../const'
import { Link } from 'react-router-dom'

interface IdLinkPath {
  id: string
  href: string
  text: string
}

interface LinkPath {
  href: string
  text: string
}

interface TextPath {
  text: string
}

export type Path = IdLinkPath | LinkPath | TextPath

interface Props {
  path: Path[]
}

const Breadcrumbs = (props: Props) => {
  const { path } = props

  const elements = path.map((it, idx) => {
    if ('id' in it) {
      return (
        <IdLink key={String(idx * 2)} id={it.id} href={it.href}>
          {it.text}
        </IdLink>
      )
    }
    if ('href' in it) {
      return (
        <Link key={String(idx * 2)} to={it.href} style={reactDomLinkStyles}>
          {it.text}
        </Link>
      )
    }
    return it.text
  })

  const elementsWithSeparator: React.ReactNode[] = []
  for (let i = 0; i < elements.length; ++i) {
    elementsWithSeparator.push(elements[i])
    if (i !== elements.length - 1) {
      elementsWithSeparator.push(' / ')
    }
  }

  return (
    <Typography variant="h6" fontWeight={400}>
      {elementsWithSeparator}
    </Typography>
  )
}

export default React.memo(Breadcrumbs)
