import { FormControl, FormControlLabel, FormLabel, Radio, RadioGroup } from '@mui/material'
import { getCareArticleById, getCareArticlesByName } from 'api/careArticles'
import { getLessonById, getLessonsByName } from 'api/lessons'
import { pathwayCacheTime } from 'models/pathways.model'
import React, { useMemo, useState } from 'react'
import { useQuery } from 'react-query'
import { CareArticle, Lesson } from 'types'
import { isDefined, selectOptionsFromTableResponse } from 'utils'
import { Option } from '../../form-elements/FormSelect'
import ModalWithSelect from '../simple-modals/ModalWithSelect'

interface Props {
  isOpen: boolean
  handleClose: () => void
  careArticleId?: number
  lessonId?: number
  onSubmitCare: (id: number) => void
  onSubmitLesson: (id: number) => void
}

type LinkType = 'care-article' | 'lesson'

const LinkToLessonOrCareArticleModal = (props: Props) => {
  const { isOpen, handleClose, careArticleId, lessonId, onSubmitCare, onSubmitLesson } = props

  const [search, setSearch] = useState('')
  const initialType = isDefined(careArticleId) ? 'care-article' : 'lesson'
  const [type, setType] = useState<LinkType>(initialType)

  const selectedElementLesson = useQuery(
    ['selectedElementLesson', { lessonId }],
    () => getLessonById({ id: lessonId || 0 }),
    {
      enabled: isOpen && lessonId !== null,
      cacheTime: pathwayCacheTime,
    },
  )

  const selectedElementCare = useQuery(
    ['selectedElementCare', { careArticleId }],
    () => getCareArticleById({ id: careArticleId || 0 }),
    {
      enabled: isOpen && careArticleId !== null,
      cacheTime: pathwayCacheTime,
    },
  )

  const learnLibraryQuery = useQuery(
    ['learnLibraryQuery', { search }],
    () => getLessonsByName(search),
    {
      enabled: isOpen && type === 'lesson' && !selectedElementLesson.isLoading,
      select: selectOptionsFromTableResponse,
      cacheTime: pathwayCacheTime,
    },
  )

  const careArticlesQuery = useQuery(
    ['careArticlesQuery', { search }],
    () => getCareArticlesByName(search),
    {
      enabled: isOpen && type === 'care-article' && !selectedElementCare.isLoading,
      select: selectOptionsFromTableResponse,
      cacheTime: pathwayCacheTime,
    },
  )

  const loadingOptions =
    selectedElementCare.isLoading ||
    selectedElementLesson.isLoading ||
    learnLibraryQuery.isLoading ||
    careArticlesQuery.isLoading

  const options = useMemo(() => {
    const base = (type === 'lesson' ? learnLibraryQuery.data : careArticlesQuery.data) ?? []
    let selected: undefined | Lesson | CareArticle
    if (type === 'lesson') {
      selected = selectedElementLesson.data?.data
    } else {
      selected = selectedElementCare.data?.data
    }

    if (selected) {
      const el: Option = {
        value: selected.id,
        label: selected.name,
      }
      if (base.some((it) => it.value === el.value)) {
        return base
      }
      return base.concat(el)
    }
    return base
  }, [
    careArticlesQuery.data,
    learnLibraryQuery.data,
    selectedElementCare.data?.data,
    selectedElementLesson.data?.data,
    type,
  ])

  const onSubmit = (id: number) => {
    if (type === 'lesson') {
      onSubmitLesson(id)
    } else {
      onSubmitCare(id)
    }
  }

  const controls = (
    <FormControl>
      <FormLabel>Type of Article</FormLabel>
      <RadioGroup
        row
        aria-labelledby="demo-controlled-radio-buttons-group"
        name="controlled-radio-buttons-group"
        value={type}
        onChange={(_, value) => {
          setSearch('')
          setType(value as LinkType)
        }}
      >
        <FormControlLabel value="lesson" control={<Radio />} label="Lesson" />
        <FormControlLabel value="care-article" control={<Radio />} label="Timeline Post" />
      </RadioGroup>
    </FormControl>
  )

  return (
    <ModalWithSelect
      value={type === 'lesson' ? lessonId : careArticleId}
      title="Change Link to Learn Library"
      label={type === 'lesson' ? 'Link to Lesson' : 'Link to Timeline Post'}
      buttonText="Save"
      isOpen={isOpen}
      handleClose={handleClose}
      onSubmit={onSubmit}
      options={options}
      loading={false}
      loadingOptions={loadingOptions}
      onChangeInput={setSearch}
      controls={controls}
    />
  )
}

export default React.memo(LinkToLessonOrCareArticleModal)
