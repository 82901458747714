import { useParams } from 'react-router-dom'
import { UserDetails } from '../UserDetails/UserDetails'

export function UserDetailsPage() {
  const { userId } = useParams()

  if (!userId) {
    return null
  }

  return <UserDetails userId={Number(userId)} />
}
