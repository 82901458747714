export function formatDurationFromMinutes(minutesInput: number): string {
  const hours = Math.floor(minutesInput / 60)
  const minutes = Math.floor(minutesInput % 60)
  const seconds = Math.round((minutesInput - Math.floor(minutesInput)) * 60)
  let result = ''

  if (hours > 0) {
    result += `${hours}h `
  }
  if (minutes > 0) {
    result += `${minutes}m `
  }
  if (seconds > 0) {
    result += `${seconds}s`
  }
  return result.trim()
}
