import { zodResolver } from '@hookform/resolvers/zod'
import { LoadingButton } from '@mui/lab'
import { Button, Stack } from '@mui/material'
import FormInput from 'components/form-elements/FormInput'
import { FIELD_IS_REQUIRED, minButtonWidth } from 'const'
import { UIModal } from 'features/UI'
import React, { useCallback, useMemo } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { Cohort, CohortRequestCreate, Nullish } from 'types'
import { object, string, TypeOf } from 'zod'

interface Props {
  defaultValues: Nullish<Cohort>
  isOpen: boolean
  loading: boolean
  onClose: () => void
  onSubmit: (values: CohortRequestCreate) => void
}

const formSchema = object({
  name: string().min(1, FIELD_IS_REQUIRED),
  comment: string(),
})

type FormType = TypeOf<typeof formSchema>

function CohortsEditModalContent({ defaultValues, loading, onClose, onSubmit }: Props) {
  const methods = useForm<FormType>({
    mode: 'onChange',
    resolver: zodResolver(formSchema),
    defaultValues: {
      name: defaultValues?.name || '',
      comment: defaultValues?.comment || '',
    },
  })

  const handleSubmit = useCallback(
    (values: CohortRequestCreate) => {
      onSubmit(values)
    },
    [onSubmit],
  )

  return (
    <FormProvider {...methods}>
      <Stack component="form" onSubmit={methods.handleSubmit(handleSubmit)} noValidate spacing={3}>
        <FormInput label="Cohort Name" name="name" fullWidth />
        <FormInput label="Comment" name="comment" fullWidth multiline rows={4} />

        <Stack direction="row" spacing={2} justifyContent="flex-end" mt={3}>
          <Button variant="outlined" onClick={onClose} sx={minButtonWidth}>
            Close
          </Button>
          <LoadingButton loading={loading} variant="contained" type="submit" sx={minButtonWidth}>
            Save
          </LoadingButton>
        </Stack>
      </Stack>
    </FormProvider>
  )
}

export function CohortsEditModal(props: Props) {
  const { defaultValues, isOpen, onClose } = props
  const isCreateMode = useMemo(() => !defaultValues, [defaultValues])
  const title = useMemo(() => (isCreateMode ? 'Create Cohort' : 'Update Cohort'), [isCreateMode])

  return (
    <UIModal isOpen={isOpen} title={title} onClose={onClose}>
      <CohortsEditModalContent {...props} />
    </UIModal>
  )
}
