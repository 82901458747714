import { getCohorts } from 'api/cohorts'
import FormMultipleSelect from 'components/form-elements/FormMultipleSelect'
import React from 'react'
import { useQuery } from 'react-query'
import { backendDataMapper } from 'utils'

export function CohortsMultiSelect({
  defaultValue,
}: {
  defaultValue: { label: string; value: number }[]
}) {
  const cohortsQuery = useQuery(['cohorts'], () => getCohorts())

  return (
    <FormMultipleSelect
      label="Cohort"
      name="cohorts"
      options={cohortsQuery.data ? cohortsQuery.data.data.rows.map(backendDataMapper) : []}
      isLoading={cohortsQuery.isLoading}
      defaultValue={defaultValue}
    />
  )
}
