import { Stack } from '@mui/material'
import { LabelValue } from '../models/LabelValue'
import { MetadataItem } from './MetadataItem'
import { memo } from 'react'

function MetadataComponent({ data }: { data: LabelValue[] }) {
  return (
    <Stack direction="row" flexWrap="wrap" className="gap-8">
      {data.map(({ label, value }, index) => (
        <MetadataItem key={label} label={label} value={value} isLast={index === data.length - 1} />
      ))}
    </Stack>
  )
}

export const Metadata = memo(MetadataComponent)
