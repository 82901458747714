import styled from '@emotion/styled'
import { Button, Stack, Typography } from '@mui/material'
import React from 'react'
import { useNavigate } from 'react-router-dom'
import { routes } from 'routes/routes'
import { ArticleType } from 'types'
import { getRoute } from 'utils'
import ModalHeader from '../ModalHeader'

const Paragraph = styled.p`
  margin-top: 0;
  margin-bottom: 10px;
`

const Footer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 40px !important;
`

const FinalScreen = ({
  handleClose,
  percentCorrect,
  retakeQuiz,
  lessonId,
}: {
  handleClose: () => void
  retakeQuiz: () => void
  percentCorrect: number
  lessonId: number
}) => {
  const navigate = useNavigate()
  const isPassed = percentCorrect === 100

  const handleOpenLesson = () => {
    const route = getRoute(routes.journeyItem, {
      journeyItemType: ArticleType.Lesson,
      journeyItemId: lessonId,
    })
    navigate(route)
    handleClose()
  }

  return (
    <Stack spacing={2}>
      <ModalHeader handleClose={handleClose}>
        <Typography fontWeight={400} variant="h5">
          Results panel
        </Typography>
      </ModalHeader>

      <div>
        <Paragraph>
          You have correctly answered {percentCorrect}% of questions. This is{' '}
          {percentCorrect < 75 ? 'not' : ''} a passing grade.
        </Paragraph>
        <Paragraph>
          Your score for this lesson is recorded in your Results list in Progress.
        </Paragraph>
        {!isPassed && (
          <Paragraph>
            You can learn more about this topic and then retake this quiz to improve your score, or
            retake quiz now, if you wish.
          </Paragraph>
        )}
      </div>
      <Footer>
        {isPassed && (
          <Button
            variant="contained"
            color="primary"
            onClick={handleOpenLesson}
            sx={{ marginLeft: '16px' }}
          >
            Done
          </Button>
        )}

        {!isPassed && (
          <>
            <Button
              variant="contained"
              color="primary"
              onClick={handleOpenLesson}
              sx={{ marginLeft: '16px' }}
            >
              Return to Lesson
            </Button>

            <Button
              variant="contained"
              color="secondary"
              onClick={retakeQuiz}
              sx={{ marginLeft: '16px' }}
            >
              Retake Quiz
            </Button>
          </>
        )}
      </Footer>
    </Stack>
  )
}

export default FinalScreen
