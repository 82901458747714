import { PLACEHOLDER } from 'components/column-renderers'
import { Engagement } from 'types'

export const formatEngagement = (engagement: Engagement, placeholder: string = PLACEHOLDER) => {
  const { days, hours = 0, minutes = 0, seconds = 0 } = engagement

  if (!hours && !days && !minutes && !seconds) {
    return placeholder
  }

  const time = [
    String(hours).padStart(2, '0'),
    String(minutes).padStart(2, '0'),
    String(seconds).padStart(2, '0'),
  ].join(':')

  if (days) {
    return `${days}d ${time}`
  }

  return `${time}`
}
