import { Chip, Stack } from '@mui/material'
import React from 'react'
import { CohortShort } from 'types'

export function CohortsCellRenderer({ cohorts }: { cohorts: CohortShort[] }) {
  return (
    <Stack direction="row" alignItems="center" spacing={1} height="100%">
      {cohorts.map((cohort) => (
        <Chip key={cohort.id} label={cohort.name} />
      ))}
    </Stack>
  )
}
