import { TextField } from '@mui/material'
import { DatePicker } from '@mui/x-date-pickers'
import { PickersDayProps } from '@mui/x-date-pickers/PickersDay/PickersDay'
import { Moment } from 'moment'
import { useState } from 'react'

export function UIDatePicker({
  label,
  value,
  onChange,
  renderDay,
}: {
  label: string
  value: Moment
  onChange: (newValue: Moment | null) => void
  renderDay?: (
    day: Moment,
    selectedDays: Moment[],
    pickersDayProps: PickersDayProps<Moment>,
  ) => JSX.Element
}) {
  const [isOpen, setIsOpen] = useState(false)

  return (
    <DatePicker
      label={label}
      value={value}
      onChange={onChange}
      open={isOpen}
      onClose={() => {
        setIsOpen(false)
      }}
      renderInput={(params) => (
        <TextField
          size="small"
          onClick={() => {
            setIsOpen(!isOpen)
          }}
          sx={{ width: 200 }}
          {...params}
        />
      )}
      renderDay={renderDay}
    />
  )
}
