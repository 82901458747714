import React from 'react'
import {
  Box,
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  Radio,
  RadioGroup,
  Stack,
} from '@mui/material'
import { Controller, useFormContext } from 'react-hook-form'
import { RadioGroupProps } from '@mui/material/RadioGroup/RadioGroup'

export type Value = number | string | boolean | null

interface Option {
  label: string
  value: Value
}

type IFormInputProps = {
  name: string
  label: string
  required: boolean
  options: Option[]
  direction?: 'row' | 'column'
  disabled?: boolean
} & RadioGroupProps

const FormRadioGroup = (props: IFormInputProps) => {
  const {
    label,
    sx = {},
    name,
    defaultValue,
    required,
    options,
    direction = 'column',
    disabled = false,
    ...otherProps
  } = props

  const {
    control,
    formState: { errors },
  } = useFormContext()

  return (
    <Controller
      control={control}
      name={name}
      defaultValue={defaultValue}
      render={({ field }) => (
        <FormControl
          error={!!errors[name]}
          disabled={disabled}
          // helperText={(errors[name] ? errors[name]?.message : '') as string}
          fullWidth={false}
          sx={sx}
        >
          <FormLabel required={required}>{label} </FormLabel>
          <RadioGroup {...field} {...otherProps}>
            <Stack direction={direction}>
              {options.map((it) => (
                <FormControlLabel
                  key={String(it.value)}
                  value={it.value}
                  control={<Radio />}
                  label={it.label}
                />
              ))}
            </Stack>
          </RadioGroup>
          <FormHelperText>
            {!!errors[name] && <span>{(errors[name] ? errors[name]?.message : '') as string}</span>}
          </FormHelperText>
        </FormControl>
      )}
    />
  )
}

export default React.memo(FormRadioGroup)
