import KeyboardArrowDown from '@mui/icons-material/KeyboardArrowDown'
import { ListItem, ListItemButton } from '@mui/material'
import List from '@mui/material/List'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'
import Typography from '@mui/material/Typography'
import React, { ReactNode } from 'react'

export function DrawerCollapseHeader({
  title,
  icon,
  isOpen,
  onToggle,
}: {
  title: string
  icon: ReactNode
  isOpen: boolean
  onToggle: () => void
}) {
  return (
    <List disablePadding>
      <ListItem disablePadding sx={{ borderBottom: '1px solid #ccc' }}>
        <ListItemButton
          sx={{ paddingX: 2, paddingY: 1, display: 'flex', justifyContent: 'space-between' }}
          onClick={onToggle}
        >
          <ListItemIcon sx={{ minWidth: 40 }}>{icon}</ListItemIcon>
          <ListItemText
            primary={
              <Typography variant="body1" color="text.secondary">
                {title}
              </Typography>
            }
          />
          <KeyboardArrowDown
            sx={{
              transform: isOpen ? 'rotate(-180deg)' : 'rotate(0)',
              transition: '0.2s',
            }}
          />
        </ListItemButton>
      </ListItem>
    </List>
  )
}
