import { Box, Stack, Typography } from '@mui/material'
import { UIItemContentContainer, UIStatusBadge } from 'features/UI'
import { ReactNode } from 'react'
import { ETimelinePostStatus } from 'types'

export function TimelineItemCard({
  title,
  preview,
  status,
  controls,
  onClick,
}: {
  preview: ReactNode | null
  controls: ReactNode | null
  title: string
  status: ETimelinePostStatus
  onClick: () => void
}) {
  return (
    <UIItemContentContainer onClick={onClick}>
      <Stack direction={{ xs: 'column', sm: 'row' }} spacing={{ xs: 1.5, sm: 2 }}>
        {preview}
        <Stack flexGrow={1} justifyContent="space-between" className="gap-8">
          <Stack direction="row" spacing={2} alignItems="center">
            <Typography variant="h5" fontWeight={400}>
              {title}
            </Typography>
          </Stack>
          <Stack direction="row" alignItems="center" spacing={1} justifyContent="space-between">
            <Box>
              <UIStatusBadge status={status} />
            </Box>
            <Box minWidth="64px">{controls}</Box>
          </Stack>
        </Stack>
      </Stack>
    </UIItemContentContainer>
  )
}
