import {
  BaseTableRequest,
  TagRequestCreate,
  Tag,
  TagRequestUpdate,
  RequestById,
  TableResponse,
  Response,
} from 'types'
import axiosInstance from './axios'

export const getTags = async (
  request: BaseTableRequest = { filter: {}, sort: null },
): Promise<TableResponse<Tag>> => {
  return axiosInstance.post('/tags', request)
}

export const getTagsIsFilterable = async (
  request: BaseTableRequest = { filter: {}, sort: null },
): Promise<TableResponse<Tag>> => {
  return getTags({
    ...request,
    filter: {
      isFilterable: {
        type: 'boolean',
        op: 'equals',
        value: true,
      },
    },
    pagination: {
      page: 1,
      pageSize: 1000, // TODO add search
    },
    sort: null,
  })
}

export const createTag = async (request: TagRequestCreate): Promise<Response<Tag>> => {
  return axiosInstance.post('/tags/create', request)
}

export const updateTag = async (request: TagRequestUpdate): Promise<Response<Tag>> => {
  return axiosInstance.post('/tags/update', request)
}

export const deleteTag = async (request: RequestById): Promise<unknown> => {
  return axiosInstance.post('/tags/delete', request)
}
