import { zodResolver } from '@hookform/resolvers/zod/dist/zod'
import { LoadingButton } from '@mui/lab'
import { Box, Button, Stack } from '@mui/material'
import Drawer from '@mui/material/Drawer'
import React from 'react'
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form'
import { useMutation, useQuery } from 'react-query'
import { CareTeam } from 'types'
import { object, string, TypeOf } from 'zod'
import { getCareTeamById, updateCareTeam } from '../../api/careTeams'
import { defaultDrawerSX, EMAIL_IS_INVALID, FIELD_IS_REQUIRED, minButtonWidth } from '../../const'
import { useRefetchCareTeam } from '../../features/Timeline/hooks/useRefetchCareTeam'
import FormInput from '../form-elements/FormInput'
import NotificationSys from '../NotificationSystem'
import DrawerContainer from './common/DrawerContainer'
import DrawerHeader from './common/DrawerHeader'

interface ContentProps {
  id: number
  data: CareTeam
  handleClose: () => void
  isCanEdit: boolean
}

const formSchema = object({
  firstName: string().min(1, FIELD_IS_REQUIRED),
  lastName: string().min(1, FIELD_IS_REQUIRED),
  email: string().min(1, FIELD_IS_REQUIRED).email(EMAIL_IS_INVALID),
})

type FormType = TypeOf<typeof formSchema>

const defaultValues = {
  firstName: '',
  lastName: '',
  email: '',
}

const Content = (props: ContentProps) => {
  const { id, data, handleClose, isCanEdit } = props

  const refetch = useRefetchCareTeam()

  const formSettings = {
    mode: 'onChange' as const,
    resolver: zodResolver(formSchema),
    defaultValues: data ? data : defaultValues,
  }

  const methods = useForm<FormType>(formSettings)
  const { handleSubmit } = methods

  const updateMutation = useMutation(updateCareTeam, {
    onSuccess: () => {
      handleClose()
      refetch()
      NotificationSys.showSuccess(`Care Team Member successfully updated`)
    },
  })

  const onSubmitHandler: SubmitHandler<FormType> = (values: FormType) => {
    if (!isCanEdit) {
      return
    }
    const baseRequest = {
      ...data,
      firstName: values.firstName,
      lastName: values.lastName,
      email: values.email,
    }

    updateMutation.mutate({
      ...baseRequest,
      id,
    })
  }

  return (
    <FormProvider {...methods}>
      <Box component="form" onSubmit={handleSubmit(onSubmitHandler)} width="100%" noValidate>
        <Stack spacing={1.5}>
          <FormInput label="First Name" name="firstName" fullWidth disabled={!isCanEdit} />
          <FormInput label="Last Name" name="lastName" fullWidth disabled={!isCanEdit} />
          <FormInput label="Email" name="email" fullWidth disabled={!isCanEdit} />
        </Stack>

        {isCanEdit && (
          <Stack direction="row" spacing={2} justifyContent="flex-end" sx={{ mt: 4 }}>
            <Button
              disabled={updateMutation.isLoading}
              variant="outlined"
              onClick={handleClose}
              sx={minButtonWidth}
            >
              Close
            </Button>
            <LoadingButton
              loading={updateMutation.isLoading}
              variant="contained"
              type="submit"
              sx={minButtonWidth}
            >
              Save
            </LoadingButton>
          </Stack>
        )}
      </Box>
    </FormProvider>
  )
}

interface Props {
  isOpen: boolean
  handleClose: () => void
  id: number | null
  isCanEdit: boolean
}

const TeamMemberProfileDrawer = (props: Props) => {
  const { isOpen, handleClose, id, isCanEdit } = props

  const { data, isLoading } = useQuery(
    ['getCareTeamById', { id }],
    () => getCareTeamById(id ?? -1),
    {
      enabled: isOpen && id !== null,
      select: (req) => req.data,
    },
  )

  return (
    <Drawer anchor="right" open={isOpen} onClose={handleClose} sx={defaultDrawerSX}>
      <DrawerContainer
        isOpen={isOpen}
        hasData={!!data}
        loading={isLoading}
        handleClose={handleClose}
      >
        <DrawerHeader handleClose={handleClose}>Care Team Profile</DrawerHeader>
        {!isLoading && data && (
          <Content id={id ?? -1} data={data} handleClose={handleClose} isCanEdit={isCanEdit} />
        )}
      </DrawerContainer>
    </Drawer>
  )
}

export default TeamMemberProfileDrawer
