import type { TypedUseSelectorHook } from 'react-redux'
import { useDispatch, useSelector } from 'react-redux'
import type { AppDispatch, RootState } from './store'
import { useMemo } from 'react'
import { bindActionCreators } from '@reduxjs/toolkit'

import { getTimelineById, getTimelineSilent } from './slice/timeline.slice'
import { getCareTeamById } from './slice/care-teams.slice'
import { getAssessmentById } from './slice/assesment.slice'
import { getProgressResultsById } from './slice/progress-results.slice'
import { signIn, updateProfile, updateCarePerson, logout } from './slice/user.slice'
import { getTopics, changeStatus, getTopicsInReview } from './slice/learn-library.slice'

export const useAppDispatch: () => AppDispatch = useDispatch
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector

const rootActions = {
  getTimelineById,
  getTimelineSilent,
  getCareTeamById,
  getAssessmentById,
  getProgressResultsById,
  signIn,
  logout,
  updateProfile,
  getTopics,
  getTopicsInReview,
  updateCarePerson,
  learnLibraryChangeStatus: changeStatus,
}

export const useActions = () => {
  const dispatch = useDispatch()

  return useMemo(() => bindActionCreators(rootActions, dispatch), [dispatch])
}
