import { zodResolver } from '@hookform/resolvers/zod/dist/zod'
import { LoadingButton } from '@mui/lab'
import { Box, Button, Grid, Modal, Stack, Typography } from '@mui/material'
import FormDatePicker from 'components/form-elements/FormDatePicker'
import FormInput from 'components/form-elements/FormInput'
import FormSelect from 'components/form-elements/FormSelect'
import {
  defaultModalStyle,
  defaultModalZIndex,
  FIELD_IS_REQUIRED,
  minButtonWidth,
  twoLettersStatesUSA,
} from 'const'
import moment from 'moment'
import { Moment } from 'moment/moment'
import React, { useCallback, useMemo, useState } from 'react'
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form'
import { AccessCode, BaseAccessCode, EAccessCodeType } from 'types'
import { Nullish } from 'types/common.types'
import {
  formatDateAccessCode,
  formatDateDuration,
  getDateDiff,
  isDateSameOrAfter,
  parseDateDuration,
} from 'utils'
import { any, coerce, object, string, TypeOf } from 'zod'
import {
  organizationSingleNumbers,
  organizationTypes,
  typeOptions,
} from '../../models/AccessManagement.model'

interface Props {
  isOpen: boolean
  handleClose: () => void
  onSubmit: (code: BaseAccessCode) => void
  loading: boolean
  type: EAccessCodeType
  editedItem: Nullish<AccessCode>
}

const organizationValidationText = 'The identifier of organization must contain 2 digits'
const specificUnitIDValidationText =
  'The identifier of specific unit must contain 2 characters (letter and/or digit)'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const isExpiryLaterThatEffective = (data: any) => {
  if (!moment(data.expiryDate).isValid() || !moment(data.effectiveDate).isValid()) {
    return true
  }
  return moment(data.expiryDate).isAfter(moment(data.effectiveDate))
}

const commonSchema = {
  type: string().min(1, FIELD_IS_REQUIRED),
  reason: string(),
  effectiveDate: any().refine(Boolean, FIELD_IS_REQUIRED),
  expiryDate: any().refine(Boolean, FIELD_IS_REQUIRED),
  numberOfUsers: coerce.number().min(1, FIELD_IS_REQUIRED),
}

const institutionalSchema = {
  organizationName: string().min(1, FIELD_IS_REQUIRED),
  twoDigitsOrg: string()
    .min(1, FIELD_IS_REQUIRED)
    .max(2, organizationValidationText)
    .refine((val) => /\d\d/.test(val), organizationValidationText),
  organizationType: string().min(1, FIELD_IS_REQUIRED),
  state: string().min(1, FIELD_IS_REQUIRED),
  specificUnitName: string().min(1, FIELD_IS_REQUIRED),
  twoDigitsSpecific: string()
    .min(1, FIELD_IS_REQUIRED)
    .max(2, specificUnitIDValidationText)
    .refine((val) => /[0-9a-z]{2}/i.test(val), specificUnitIDValidationText),
  duration: string().min(1, FIELD_IS_REQUIRED),
  numberOfUsers: coerce.number().min(1, FIELD_IS_REQUIRED),
}

const formSchema = (codeType: EAccessCodeType) =>
  object({
    ...commonSchema,
    ...(codeType === EAccessCodeType.institutionAccessCode
      ? institutionalSchema
      : ({} as typeof institutionalSchema)), // required for typing
  })
    .refine(isExpiryLaterThatEffective, {
      path: ['expiryDate'],
      message: 'Expiry date must be later that Effective date',
    })
    .refine(
      (data) => {
        if (data.type === EAccessCodeType.promoCode) {
          return true
        }

        const formatRegex = new RegExp(/^\s*(\d+y\s+)?(\d+m\s+)?(\d+d\s*)?$/)
        return formatRegex.test(data.duration)
      },
      {
        path: ['duration'],
        message: 'Wrong duration format',
      },
    )

const formSettings = (codeType: EAccessCodeType, editedItem: Nullish<AccessCode>) => ({
  mode: 'onChange' as const,
  resolver: zodResolver(formSchema(codeType)),
  defaultValues: !editedItem
    ? {
        type: codeType,
        reason: '',
        organizationName: '',
        twoDigitsOrg: '',
        effectiveDate: null,
        expiryDate: null,
        organizationType: '',
        state: '',
        specificUnitName: '',
        twoDigitsSpecific: '',
        duration: '',
        numberOfUsers: 1,
      }
    : {
        ...editedItem,
        type: codeType,
      },
})

type FormType = TypeOf<ReturnType<typeof formSchema>>

const ModalContent = (props: Props) => {
  const { onSubmit, handleClose, loading, editedItem } = props

  const isUpdateForm = useMemo(() => {
    return !!editedItem
  }, [editedItem])

  const [codeType, setCodeType] = useState(props.type)
  const methods = useForm<FormType>(formSettings(codeType, editedItem))

  const { register, setValue, handleSubmit, trigger, watch } = methods
  const effectiveDateValue = watch('effectiveDate')

  const onSubmitHandler: SubmitHandler<FormType> = (values: FormType) => {
    const singleLetterOrg = organizationSingleNumbers.find(
      (it) => it.value === values.organizationType,
    )?.label as string

    const accessCode: BaseAccessCode = {
      codeType: codeType,
      reason: values.reason,
      organizationName: values.organizationName,
      twoDigitsOrg: values.twoDigitsOrg,
      effectiveDate: formatDateAccessCode(values.effectiveDate),
      expiryDate: formatDateAccessCode(values.expiryDate),
      organizationType: values.organizationType,
      singleLetterOrg,
      state: values.state,
      specificUnitName: values.specificUnitName,
      twoDigitsSpecific: values.twoDigitsSpecific,
      duration: values.duration,
      numberOfUsers: Number(values.numberOfUsers),
    }
    onSubmit(
      editedItem
        ? {
            ...accessCode,
            id: editedItem.id,
          }
        : accessCode,
    )
  }

  const handleChangeDuration = useCallback(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (e: any) => {
      const { years, months, days } = parseDateDuration(e.target.value)
      const newExpiryDate = moment(effectiveDateValue).add({
        years,
        months,
        days,
      })
      setValue('duration', e.target.value)
      setValue('expiryDate', newExpiryDate)
    },
    [effectiveDateValue, setValue],
  )

  const handleChangeExpiryDate = useCallback(
    (value: Moment | null) => {
      if (!value || !isDateSameOrAfter(value, effectiveDateValue)) {
        return
      }

      setValue('expiryDate', value)

      const diff = getDateDiff(effectiveDateValue, value)
      const duration = formatDateDuration(diff)
      setValue('duration', duration)
      trigger('duration')
    },
    [effectiveDateValue, setValue, trigger],
  )

  return (
    <Box
      sx={{
        ...defaultModalStyle,
        width: 800,
      }}
    >
      <Typography variant="h5" mb={3}>
        {isUpdateForm ? 'Update Access Code' : 'Access Code Generation'}
      </Typography>

      <FormProvider {...methods}>
        <Box component="form" onSubmit={handleSubmit(onSubmitHandler)} width="100%" noValidate>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <FormSelect
                label="Type"
                name="type"
                options={typeOptions}
                required
                value={codeType}
                onChange={(e) => {
                  setCodeType(e.target.value as EAccessCodeType)
                }}
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <FormInput label="Reason for Code" name="reason" fullWidth />
            </Grid>

            {codeType === EAccessCodeType.institutionAccessCode && (
              <>
                <Grid item xs={12} sm={6}>
                  <FormInput label="Organization Name" name="organizationName" required fullWidth />
                </Grid>

                <Grid item xs={12} sm={6}>
                  <FormInput
                    label="Two-Digit Organization Number"
                    name="twoDigitsOrg"
                    required
                    fullWidth
                  />
                </Grid>
              </>
            )}
            <Grid item xs={6} sm={6}>
              <FormDatePicker label="Effective Date" name="effectiveDate" required fullWidth />
            </Grid>
            <Grid item xs={6} sm={6}>
              <FormDatePicker
                label="Expiry Date"
                name="expiryDate"
                required
                fullWidth
                onChange={handleChangeExpiryDate}
              />
            </Grid>
            {codeType === EAccessCodeType.institutionAccessCode && (
              <>
                <Grid item xs={12} sm={6}>
                  <FormSelect
                    label="Organization Type"
                    name="organizationType"
                    fullWidth
                    required
                    options={organizationTypes}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <FormSelect
                    label="Two-Letter State Location of Trainees"
                    name="state"
                    fullWidth
                    required
                    options={twoLettersStatesUSA}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <FormInput
                    label="Specific Unit Name"
                    name="specificUnitName"
                    required
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <FormInput
                    label="Two-digit Specific Unit Letter/ Number"
                    name="twoDigitsSpecific"
                    required
                    fullWidth
                  />
                </Grid>
                <Grid item xs={6} sm={6}>
                  <FormInput
                    label="Duration (eg. 1y 2md 3d)"
                    required
                    fullWidth
                    {...register('duration', {
                      onChange: handleChangeDuration,
                    })}
                  />
                </Grid>
              </>
            )}
            <Grid item xs={6} sm={6}>
              <FormInput label="Number of Users" name="numberOfUsers" required fullWidth />
            </Grid>
          </Grid>

          <Stack direction="row" spacing={2} justifyContent="flex-end" sx={{ mt: 4 }}>
            <Button variant="outlined" onClick={handleClose} sx={minButtonWidth}>
              Close
            </Button>
            <LoadingButton loading={loading} variant="contained" type="submit" sx={minButtonWidth}>
              {isUpdateForm ? 'Update' : 'Generate'}
            </LoadingButton>
          </Stack>
        </Box>
      </FormProvider>
    </Box>
  )
}

export const AccessCodeGenerationModal = (props: Props) => {
  const { isOpen, handleClose } = props

  return (
    <Modal sx={defaultModalZIndex} open={isOpen} onClose={handleClose}>
      <div>{isOpen ? <ModalContent {...props} /> : <Box />}</div>
    </Modal>
  )
}
