import { getCohorts } from 'api/cohorts'
import { UIFilterAsyncAutocomplete } from 'features/UI'
import React, { useCallback, useMemo, useState } from 'react'
import { FilterModelNumberArray, Nullish, Option } from 'types'

export function useCohortsFilter() {
  const [cohorts, setCohorts] = useState<Option[]>([])

  const cohortsFilterValue: Nullish<FilterModelNumberArray> = useMemo(() => {
    if (cohorts.length > 0) {
      return {
        type: 'number',
        op: 'in',
        value: cohorts.map((cohort) => cohort.value),
      } as FilterModelNumberArray
    }

    return null
  }, [cohorts])

  const handleChangeCohortFilter = useCallback((newValue: Option[]) => {
    setCohorts(newValue)
  }, [])

  const CohortsFilter = useMemo(() => {
    return (
      <UIFilterAsyncAutocomplete
        label="Cohorts"
        value={cohorts}
        onChange={handleChangeCohortFilter}
        queryId="cohorts"
        apiRequest={getCohorts}
      />
    )
  }, [cohorts, handleChangeCohortFilter])

  return { CohortsFilter, cohortsFilterValue }
}
