import React, { PropsWithChildren, ReactElement } from 'react'
import { styled } from '@mui/material/styles'
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip'
import Typography from '@mui/material/Typography'
import { Colors } from 'styles/colors'

const HtmlTooltip = styled(({ className, children, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }}>
    {children}
  </Tooltip>
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: '#f5f5f9',
    color: 'rgba(0, 0, 0, 0.87)',
    maxWidth: 240,
    fontSize: theme.typography.pxToRem(20),
    border: `2px solid ${Colors.PINK}`,
  },
  [`& .${tooltipClasses.tooltipArrow} .${tooltipClasses.arrow}`]: {
    color: Colors.PINK,
  },
}))

interface Props {
  children: ReactElement
  text: string
  disabled?: boolean
  placement?:
    | 'bottom-end'
    | 'bottom-start'
    | 'bottom'
    | 'left-end'
    | 'left-start'
    | 'left'
    | 'right-end'
    | 'right-start'
    | 'right'
    | 'top-end'
    | 'top-start'
    | 'top'
}

const HelpTooltip = (props: Props) => {
  if (props.disabled) {
    return props.children
  }

  const title = (
    <Typography variant="body1" fontWeight={400} color="inherit">
      {props.text}
    </Typography>
  )

  return (
    <HtmlTooltip arrow title={title} placement={props.placement}>
      {props.children}
    </HtmlTooltip>
  )
}

export default HelpTooltip
