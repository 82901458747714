import { Box, Stack, Typography } from '@mui/material'
import grey from '@mui/material/colors/grey'
import { UserCurrentIcon } from 'app/imports/App.components'
import React from 'react'
import { formatDate } from 'utils'

export const TimelineItemHeader = ({
  title,
  showAvatar,
  hideBorderBottom = false,
  authorName,
  date,
  onClick,
}: {
  title: string
  showAvatar: boolean
  hideBorderBottom?: boolean
  authorName?: string
  date?: string
  onClick?: () => void
}) => {
  return (
    <Stack
      direction="row"
      spacing={{ sm: 2, xs: 1.5 }}
      sx={{
        borderBottom: hideBorderBottom ? '' : '1px solid',
        borderColor: grey[300],
        cursor: onClick ? ' pointer' : '',
      }}
      paddingBottom={1}
      alignItems="center"
      onClick={onClick}
    >
      {showAvatar && (
        <Box display="flex" flexShrink={0}>
          <UserCurrentIcon />
        </Box>
      )}
      <Stack>
        <Typography>{title}</Typography>
        <Stack direction="row" flexWrap="wrap">
          {authorName && (
            <Typography color="textSecondary" marginRight={1}>
              by {authorName}
            </Typography>
          )}
          {date && (
            <Typography color="textSecondary">{formatDate(date, 'MMM. Do, YYYY')}</Typography>
          )}
        </Stack>
      </Stack>
    </Stack>
  )
}
