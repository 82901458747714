import React, { useEffect, useRef, useState } from 'react'
import { GridApi } from 'ag-grid-community/dist/lib/gridApi'
import { ColDef, ColumnApi } from 'ag-grid-community'
import { GridReadyEvent } from 'ag-grid-community/dist/lib/events'
import CircularProgress from '@mui/material/CircularProgress'
import CustomDateComponent from '../components/table/CustomDateComponent'
import { SelectValue } from '../components/select/SimpleSelect'
import { DEFAULT_PAGE_SIZE } from '../const'
import { IDatasource } from 'ag-grid-community/dist/lib/interfaces/iDatasource'
import { AgGridReact } from 'ag-grid-react'
import { Typography } from '@mui/material'
import { PLACEHOLDER } from 'components/column-renderers'

export const ROWS_PER_PAGE = DEFAULT_PAGE_SIZE

export const loadingOverlayComponent = () => {
  return <CircularProgress />
}

export const customNoRowsOverlay = () => {
  return (
    <Typography variant="body1" fontWeight={400}>
      Sorry, there is no matching data to display.
    </Typography>
  )
}

export const defaultColDef: ColDef = {
  minWidth: 180,
  width: 220,

  filterParams: {
    suppressAndOrCondition: true,
  },
  floatingFilter: true,
  resizable: true,
  sortable: true,
  cellStyle: { fontSize: '1rem' },
  valueFormatter: (params) => {
    if (params.value === undefined || params.value === null) {
      return PLACEHOLDER
    }
    return params.value
  },
}

export const gridComponents = { agDateInput: CustomDateComponent }

export const useGridControl = (getDataSource?: (gridApi: GridApi) => IDatasource) => {
  const gridRef = useRef<AgGridReact>(null)

  const [gridApi, setGridApi] = useState<GridApi | null>(null)
  const [columnApi, setColumnApi] = useState<ColumnApi | null>(null)

  const onGridReady = (event: GridReadyEvent) => {
    setGridApi(event.api)
    setColumnApi(event.columnApi)
    event.api.sizeColumnsToFit()
  }

  const [pageSize, setPageSize] = useState(DEFAULT_PAGE_SIZE)

  useEffect(() => {
    if (gridApi && getDataSource) {
      gridApi.setDatasource(getDataSource(gridApi))
    }
  }, [getDataSource, gridApi])

  const handleChangePagination = (name: string, value: SelectValue) => {
    setPageSize(Number(value))
    gridRef.current?.api.paginationSetPageSize(Number(value))
  }

  return { gridRef, gridApi, columnApi, onGridReady, pageSize, handleChangePagination }
}
