import { getTags } from 'api/tags'
import { UIFilterAsyncAutocomplete } from 'features/UI'
import React, { useCallback, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { FilterModelNumberArray, Nullish, Option } from 'types'
import { selectTags, setTags } from '../state/Tags.slice'

export function useTagsFilter() {
  const dispatch = useDispatch()
  const tags = useSelector(selectTags)

  const tagsFilterValue: Nullish<FilterModelNumberArray> = useMemo(() => {
    if (tags.length > 0) {
      return {
        type: 'number',
        op: 'in',
        value: tags.map((tag) => tag.value),
      } as FilterModelNumberArray
    }

    return null
  }, [tags])

  const handleChangeTagFilter = useCallback(
    (newValue: Option[]) => {
      dispatch(setTags({ tags: newValue }))
    },
    [dispatch],
  )

  const TagFilter = useMemo(() => {
    return (
      <UIFilterAsyncAutocomplete
        label="Tags"
        value={tags}
        onChange={handleChangeTagFilter}
        queryId="tags"
        apiRequest={getTags}
      />
    )
  }, [handleChangeTagFilter, tags])

  return { TagFilter, tagsFilterValue }
}
