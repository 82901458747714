import { Grid } from '@mui/material'
import VerticalButtonBox from 'components/buttons/VerticalButtonBox'
import AgreeTermsCheckbox from 'components/form-elements/AgreeTermsCheckbox'
import FormInput from 'components/form-elements/FormInput'
import FormSelect from 'components/form-elements/FormSelect'
import FormPasswordCheckList from 'components/FormPasswordCheckList'
import { careLevels } from 'models/user.model'
import React, { useCallback } from 'react'
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'
import { routes } from 'routes/routes'
import { AuthFormTitle } from '../AuthFormTitle/AuthFormTitle'
import { familyFormSettings, FamilyFormType } from './types'

interface Props {
  handleCreate: (form: FamilyFormType) => void
}

const SignUpFamilyForm = (props: Props) => {
  const { handleCreate } = props

  const methods = useForm<FamilyFormType>(familyFormSettings)
  const { handleSubmit } = methods

  const onSubmitHandler: SubmitHandler<FamilyFormType> = useCallback(
    (values: FamilyFormType) => {
      handleCreate(values)
    },
    [handleCreate],
  )

  const navigate = useNavigate()

  const onCancel = () => navigate(routes.signUp)

  return (
    <FormProvider {...methods}>
      <AuthFormTitle>Create Account</AuthFormTitle>

      <Grid
        container
        spacing={1.5}
        component="form"
        onSubmit={handleSubmit(onSubmitHandler)}
        noValidate
        autoComplete="off"
      >
        <Grid item xs={12} sm={6}>
          <FormInput label="First Name" name="firstName" required fullWidth />
        </Grid>
        <Grid item xs={12} sm={6}>
          <FormInput label="Last Name" name="lastName" required fullWidth />
        </Grid>

        <Grid item xs={12}>
          <FormInput label="My Email" name="email" required fullWidth autoComplete="off" />
        </Grid>

        <Grid item xs={12} sm={12} md={6}>
          <FormInput
            label="Person of Concern First Name"
            name="personOfConcernFirstName"
            required
            fullWidth
            autoComplete="off"
          />
        </Grid>
        <Grid item xs={12} sm={12} md={6}>
          <FormInput
            label="Person of Concern Last Name or Initial"
            name="personOfConcernLastNameOrInitial"
            required
            fullWidth
            autoComplete="off"
          />
        </Grid>

        <Grid item xs={12} sm={12}>
          <FormInput
            label="My new password"
            required
            fullWidth
            name="myNewPassword"
            type="password"
            autoComplete="new-password"
          />
          <FormPasswordCheckList name="myNewPassword" />
        </Grid>
        <Grid item xs={12} sm={12}>
          <FormInput
            label="Same new password"
            required
            fullWidth
            name="sameNewPassword"
            type="password"
            autoComplete="new-password"
          />
        </Grid>

        <Grid item xs={12}>
          <FormSelect
            label="What level of care does your person of concern need?"
            name="levelOfCareNeeded"
            required
            options={careLevels}
          />
        </Grid>

        <Grid item xs={12}>
          <AgreeTermsCheckbox name="readTermsAndConditions" />
        </Grid>

        <Grid item xs={12}>
          <VerticalButtonBox
            mt={1}
            onCancel={onCancel}
            buttonSaveType="submit"
            saveText="Create my Account"
          />
        </Grid>
      </Grid>
    </FormProvider>
  )
}

export default SignUpFamilyForm
