import { Box, Button, Typography } from '@mui/material'
import React from 'react'
import { isDefined } from 'utils'
import { UIEntityItemBox } from '../UIEntityItemBox/UIEntityItemBox'

export function UIEntityItemConnection({
  text,
  onEdit,
  onDelete,
  linkId,
  articleName,
}: {
  linkId?: number
  text: string
  onEdit: () => void
  onDelete?: () => void
  articleName: string | null
}) {
  if (!isDefined(linkId)) {
    return (
      <Box>
        <Button variant="contained" onClick={onEdit}>
          Add {text}
        </Button>
      </Box>
    )
  }

  return (
    <UIEntityItemBox onEdit={onEdit} onDelete={onDelete}>
      <Typography variant="body1" fontSize="1.125rem" pr={2}>
        {text}
        {articleName ? ` – ${articleName}` : ''}
      </Typography>
    </UIEntityItemBox>
  )
}
