import { Box, useMediaQuery } from '@mui/material'
import React, { useMemo } from 'react'

interface DeltaSpace {
  xs: number
  sm: number
  md: number
}

const toolbarHeight = 65

interface Props {
  children: React.ReactNode
  deltaSpace: DeltaSpace
  fixLeft?: boolean
}

const ScrollContainer = (props: Props) => {
  const { children, deltaSpace, fixLeft } = props

  const matchesHeightXS = useMediaQuery('(max-height: 600px)')

  const style = useMemo(() => {
    const doubledToolbar = !matchesHeightXS ? toolbarHeight * 2 : toolbarHeight

    return {
      overflowY: {
        xs: 'unset',
        sm: 'auto',
      },
      maxHeight: {
        xs: `unset`,
        sm: `calc(100vh - ${doubledToolbar}px - ${deltaSpace.sm}px)`,
        md: `calc(100vh - ${doubledToolbar}px - ${deltaSpace.md}px)`,
      },
      paddingLeft: fixLeft ? '6px' : '',
      marginLeft: fixLeft ? '-6px' : '',
      paddingRight: 1,
      paddingBottom: '16px',
    }
  }, [deltaSpace, fixLeft, matchesHeightXS])

  return <Box sx={style}>{children}</Box>
}

export default React.memo(ScrollContainer)
