import { GridApi } from 'ag-grid-community/dist/lib/gridApi'
import { useEffect, useRef } from 'react'

export const useRefreshTable = ({
  params,
  gridApi,
}: {
  params: unknown
  gridApi: GridApi | null
}) => {
  const isFirstRenderWithApi = useRef(true)

  useEffect(() => {
    if (!isFirstRenderWithApi.current) {
      gridApi?.sizeColumnsToFit()
      gridApi?.refreshServerSide()
    }
    if (gridApi !== null) {
      isFirstRenderWithApi.current = false
    }
  }, [gridApi, params])
}
