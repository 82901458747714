import { zodResolver } from '@hookform/resolvers/zod/dist/zod'
import { Grid } from '@mui/material'
import { getInvitationInfo, registerTeamMember } from 'api/auth'
import VerticalButtonBox from 'components/buttons/VerticalButtonBox'
import { useAuthContext } from 'components/context/AuthProvider'
import AgreeTermsCheckbox from 'components/form-elements/AgreeTermsCheckbox'
import FormInput from 'components/form-elements/FormInput'
import FormSelect from 'components/form-elements/FormSelect'
import FormPasswordCheckList, { validatePassword } from 'components/FormPasswordCheckList'
import NotificationSys from 'components/NotificationSystem'
import FailPlaceholder from 'components/placeholders/FailPlaceholder'
import LoadingPlaceholder from 'components/placeholders/LoadingPlaceholder'
import { EMAIL_IS_INVALID, PASSWORD_REQUIREMENTS } from 'const'
import { careLevels } from 'models/user.model'
import React, { useCallback } from 'react'
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form'
import { useMutation, useQuery } from 'react-query'
import { useNavigate } from 'react-router-dom'
import { router } from 'routes/router'
import { routes } from 'routes/routes'
import { InvitationInfo } from 'types'
import { useSearchParamsObject } from 'utils'
import { boolean, object, string, TypeOf } from 'zod'
import { AuthFormTitle } from '../AuthFormTitle/AuthFormTitle'
import { AuthLogo } from '../AuthLogo/AuthLogo'

interface Props {
  token: string
  info: InvitationInfo
}

const formSchema = object({
  firstName: string().min(1, 'First name is required'),
  lastName: string().min(1, 'Last name is required'),

  email: string().min(1, 'Email is required').email(EMAIL_IS_INVALID),

  personOfConcernFirstName: string(),
  personOfConcernLastNameOrInitial: string(),

  myNewPassword: string().refine(
    (password: string) => validatePassword(password).allCheck,
    PASSWORD_REQUIREMENTS,
  ),
  sameNewPassword: string().min(1, 'Password is required'),

  levelOfCareNeeded: string(),
  readTermsAndConditions: boolean().refine(
    (val) => val,
    'Please confirm of reading terms and conditions.',
  ),
}).refine((data) => data.myNewPassword === data.sameNewPassword, {
  path: ['sameNewPassword'],
  message: 'Passwords do not match',
})

type FormType = TypeOf<typeof formSchema>

const useInviteForm = (info: InvitationInfo) => {
  const defaultValues = {
    firstName: '',
    lastName: '',

    email: info.email,

    personOfConcernFirstName: info.personOfConcernFirstName || '',
    personOfConcernLastNameOrInitial: info.personOfConcernLastName || '',

    myNewPassword: '',
    sameNewPassword: '',

    levelOfCareNeeded: info.careLevel || '',
    readTermsAndConditions: false,
  }

  const settings = {
    mode: 'onChange' as const,
    resolver: zodResolver(formSchema),
    defaultValues,
  }

  return useForm<FormType>(settings)
}

const Form = (props: Props) => {
  const { token, info } = props

  const { authLoading, login } = useAuthContext()
  const methods = useInviteForm(info)
  const { handleSubmit, watch } = methods

  const email = watch('email')
  const password = watch('myNewPassword')

  const onSuccessLogin = () => {
    setTimeout(() => {
      router.navigate(routes.journey)
    }, 0)
  }

  const onFailLogin = (reason: unknown) => {
    console.error('Error', reason)
    router.navigate(routes.signIn)
    NotificationSys.showWarning(
      "Can't automatically sign-in after register. Please sign-in manually",
    )
  }

  const createMutation = useMutation(registerTeamMember, {
    onSuccess: () => {
      NotificationSys.showSuccess(`User successfully created`)
      login(email, password, onSuccessLogin, onFailLogin)
    },
  })

  const onSubmitHandler: SubmitHandler<FormType> = useCallback(
    (values: FormType) => {
      createMutation.mutate({
        token,
        firstname: values.firstName,
        lastname: values.lastName,
        password: values.myNewPassword,
      })
    },
    [createMutation, token],
  )

  const navigate = useNavigate()

  const onCancel = () => navigate(routes.signUp)

  if (authLoading) {
    return <LoadingPlaceholder />
  }

  return (
    <FormProvider {...methods}>
      <AuthFormTitle>Create Account</AuthFormTitle>

      <Grid
        container
        spacing={1.5}
        component="form"
        onSubmit={handleSubmit(onSubmitHandler)}
        noValidate
        autoComplete="off"
      >
        <Grid item xs={12} sm={6}>
          <FormInput label="First Name" name="firstName" required fullWidth autoComplete="off" />
        </Grid>
        <Grid item xs={12} sm={6}>
          <FormInput label="Last Name" name="lastName" required fullWidth autoComplete="off" />
        </Grid>

        <Grid item xs={12}>
          <FormInput label="My Email" name="email" required fullWidth disabled autoComplete="off" />
        </Grid>

        <Grid item xs={12} sm={12} md={6}>
          <FormInput
            label="Person of Concern First Name"
            name="personOfConcernFirstName"
            required
            disabled
            fullWidth
            autoComplete="off"
          />
        </Grid>
        <Grid item xs={12} sm={12} md={6}>
          <FormInput
            label="Person of Concern Last Name or Initial"
            name="personOfConcernLastNameOrInitial"
            required
            disabled
            fullWidth
            autoComplete="off"
          />
        </Grid>

        <Grid item xs={12} sm={12}>
          <FormInput
            label="My new password"
            required
            fullWidth
            name="myNewPassword"
            type="password"
            autoComplete="new-password"
          />
          <FormPasswordCheckList name="myNewPassword" />
        </Grid>
        <Grid item xs={12} sm={12}>
          <FormInput
            label="Same new password"
            required
            fullWidth
            name="sameNewPassword"
            type="password"
            autoComplete="new-password"
          />
        </Grid>

        <Grid item xs={12}>
          <FormSelect
            label="What level of care does your person of concern need?"
            name="levelOfCareNeeded"
            disabled
            required
            options={careLevels}
          />
        </Grid>

        <Grid item xs={12}>
          <AgreeTermsCheckbox name="readTermsAndConditions" />
        </Grid>

        <Grid item xs={12}>
          <VerticalButtonBox
            mt={1}
            onCancel={onCancel}
            buttonSaveType="submit"
            saveText="Create my Account"
            loading={createMutation.isLoading || authLoading}
          />
        </Grid>
      </Grid>
    </FormProvider>
  )
}

export const AuthPageSignUpTeamMember = () => {
  const { invite: token } = useSearchParamsObject()
  const query = useQuery(['getInvitationInfo', { token }], () => getInvitationInfo(token || ''), {
    enabled: !!token,
    select: (req) => req.data,
  })

  return (
    <>
      <AuthLogo />
      {query.isLoading && <LoadingPlaceholder />}
      {!query.isLoading && !query.data && <FailPlaceholder error="Can't load data by invitation" />}
      {!token && <FailPlaceholder error="Invite token is not provided by link" />}
      {query.data && <Form token={token || ''} info={query.data} />}
    </>
  )
}
