import ClearIcon from '@mui/icons-material/Clear'
import DoneIcon from '@mui/icons-material/Done'
import { Box, Link, Stack, Typography } from '@mui/material'
import { green, red } from '@mui/material/colors'
import { UserCurrentIcon } from 'app/imports/App.components'
import { useDrawersContext } from 'components/context/ClientsDrawersProvider'
import React from 'react'
import { QuestioningType } from 'types'

interface Props {
  completedLessonId?: number
  completedCourseId?: number
  type: QuestioningType
  id: number
  title: string
  percentsOfCorrectAnswers: number
  onQuestioningRetaken: () => void
}

const sx = {
  background: 'white',
  paddingY: 2,
  paddingX: 2,
  borderRadius: 2,
  width: '100%',
}

const getIconByResult = (result?: number) => {
  if (result === undefined) {
    return undefined
  }
  if (result >= 75) {
    return <DoneIcon titleAccess="passed" sx={{ color: green[500], fontSize: '1.3rem' }} />
  }
  if (result < 75) {
    return <ClearIcon titleAccess="failed" sx={{ color: red[400], fontSize: '1.3rem' }} />
  }
}

export const ProgressQuestioningItem = (props: Props) => {
  const { title } = props

  const { handleOpenQuestioning } = useDrawersContext()

  const onClick = () => {
    handleOpenQuestioning({
      id: props.id,
      extraParameters: {
        completedLessonId: props.completedLessonId,
        completedCourseId: props.completedCourseId,
      },
      mode: 'view_results',
      type: props.type,
      onQuestioningTaken: props.onQuestioningRetaken,
    })
  }

  return (
    <Box>
      <Stack sx={sx} display="flex" direction="row" alignItems="center">
        <Box mr={2} display="flex" justifyContent="center">
          <UserCurrentIcon />
        </Box>
        <Stack direction="column" alignItems="flex-start" spacing={0.25}>
          <Typography>
            {title}
            {' has been completed.'}
          </Typography>
          <Link
            component="button"
            variant="body1"
            onClick={onClick}
            color="textSecondary"
            fontSize=".9em"
            sx={{ textDecoration: 'none' }}
          >
            View Results -{' '}
            <span style={{ display: 'inline-flex', lineHeight: '21px' }}>
              {props.percentsOfCorrectAnswers}% {getIconByResult(props.percentsOfCorrectAnswers)}
            </span>
          </Link>
        </Stack>
      </Stack>
    </Box>
  )
}
