import { styled } from '@mui/material/styles'

export const TimelineLine = styled('div')(({ theme }) => ({
  height: '100%',
  width: 3,
  background: theme.palette.grey['700'],
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate3d(-50%, -50%, 0)',
}))
