import { routes } from 'routes/routes'
import { Query, QuestioningType, Questionnaire, Quiz } from 'types'

export const getPath = (type: QuestioningType, data: Quiz | Questionnaire | Query | null) => {
  if (!data) return []

  if (type === 'quiz') {
    return [
      {
        id: (data as Quiz).lessonId,
        href: routes.lessonEdit,
        text: 'Lesson',
      },
      {
        text: `Quiz - ${data.name}`,
      },
    ]
  } else if (type === 'questionnaire') {
    return [
      {
        id: (data as Questionnaire).courseId,
        href: routes.courseEdit,
        text: 'Course',
      },
      {
        text: `Exam ${data.name}`,
      },
    ]
  } else if (type === 'query') {
    return [
      {
        id: (data as Query).learnArticleId,
        href: routes.learnArticleEdit,
        text: 'Learn Article',
      },
      {
        text: `Query ${data.name}`,
      },
    ]
  }

  return []
}
