type PluralMap = {
  [key in number | 'any']: string
}

interface PluralizeParams {
  count: number
  pluralMap: PluralMap
}

export function pluralize({ count, pluralMap }: PluralizeParams): string {
  const pluralWord = pluralMap[count] || pluralMap.any

  return `${count} ${pluralWord}`
}
