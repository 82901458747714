import React from 'react'
import { Link, useLocation } from 'react-router-dom'
import Typography from '@mui/material/Typography'
import ListItem from '@mui/material/ListItem'
import ListItemButton from '@mui/material/ListItemButton'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'
import styled from '@emotion/styled'

interface Props {
  path: string
  icon?: React.ReactNode
  external?: boolean

  isSmall?: boolean
  paddingLeft?: number
  disabled?: boolean
}

const disableLinkStyles = { textDecoration: 'none', color: 'black' }

const iconContainerWidth = '40px'

const NavItem = (props: React.PropsWithChildren<Props>) => {
  const { path, icon, external = false, isSmall = false, paddingLeft = 2, disabled } = props

  const paddingY = isSmall ? 1 : 2

  const location = useLocation()
  const isSelected = path === location.pathname

  const itemText = (
    <Typography
      variant={isSmall ? 'body1' : 'h6'}
      color={isSelected ? 'secondary.main' : 'text.secondary'}
    >
      {props.children}
    </Typography>
  )

  const node = (
    <ListItem disablePadding sx={{ borderBottom: '1px solid #ccc' }}>
      <ListItemButton
        selected={isSelected}
        sx={{ paddingLeft, paddingRight: 2, paddingY }}
        disabled={disabled}
      >
        {icon && <ListItemIcon sx={{ minWidth: iconContainerWidth }}>{icon}</ListItemIcon>}
        <ListItemText primary={itemText} />
      </ListItemButton>
    </ListItem>
  )

  if (disabled) {
    return node
  }

  if (external) {
    return (
      <a href={path} style={disableLinkStyles}>
        {node}
      </a>
    )
  }

  return (
    <Link to={path} style={disableLinkStyles}>
      {node}
    </Link>
  )
}

export default NavItem
