import { ColDef, ICellRendererParams } from 'ag-grid-community'
import { PLACEHOLDER } from 'components/column-renderers'
import { CSVField } from 'hooks/useExportCSV'
import React from 'react'
import { CourseCompleted } from 'types'
import { isDefined } from 'utils'
import { ReportsAttemptsCell } from '../components/ReportsAttemptsCell/ReportsAttemptsCell'

export const createColumnExamAttempts = (entityName: string, field: string): ColDef => {
  return {
    headerName: `${entityName} attempts`,
    sortable: false,
    cellRenderer: (props: ICellRendererParams<{ [field: string]: CourseCompleted[] }>) => {
      const completedItems = props.data?.[field]
      if (isDefined(completedItems)) {
        return <ReportsAttemptsCell items={completedItems} entityName={entityName} />
      }
      return PLACEHOLDER
    },
  }
}

export const createCsvColumnExamAttempts = (title: string, field: string): CSVField => {
  return {
    label: title,
    value: (data) => {
      const completedItems = data?.[field]
      if (isDefined(completedItems)) {
        return completedItems.length
      }
      return PLACEHOLDER
    },
  }
}
