import { Profile, ProfileUpdateRequest, TrainingDetails } from 'types'
import axiosInstance from './axios'
import { AxiosResponse } from 'axios'

export const getProfile = async (userId?: number) => {
  const response = await axiosInstance.post<void, AxiosResponse<Profile>>('/basic-info', { userId })
  return response.data
}

export const updateProfile = async (data: ProfileUpdateRequest) => {
  return axiosInstance.post<ProfileUpdateRequest, void>('/basic-info/update', data)
}

export const getTrainingDetails = async () => {
  const response = await axiosInstance.post<void, AxiosResponse<TrainingDetails>>(
    '/basic-info/training-details',
  )
  return response.data
}
