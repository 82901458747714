import { zodResolver } from '@hookform/resolvers/zod/dist/zod'
import { Grid } from '@mui/material'
import { resetPassword } from 'api/auth'
import VerticalButtonBox from 'components/buttons/VerticalButtonBox'
import FormInput from 'components/form-elements/FormInput'
import FormPasswordCheckList, { validatePassword } from 'components/FormPasswordCheckList'
import NotificationSys from 'components/NotificationSystem'
import { PASSWORD_REQUIREMENTS } from 'const'
import React from 'react'
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form'
import { useMutation } from 'react-query'
import { useNavigate } from 'react-router-dom'
import { routes } from 'routes/routes'
import { getQueryParams } from 'utils'
import { object, string, TypeOf } from 'zod'
import { AuthFormTitle } from '../AuthFormTitle/AuthFormTitle'
import { AuthLogo } from '../AuthLogo/AuthLogo'

const formSchema = object({
  password: string().refine(
    (password: string) => validatePassword(password).allCheck,
    PASSWORD_REQUIREMENTS,
  ),
  passwordRepeat: string().min(1, 'Password is required'),
}).refine((data) => data.password === data.passwordRepeat, {
  path: ['passwordRepeat'],
  message: 'Passwords do not match',
})

type FormType = TypeOf<typeof formSchema>

export function AuthPageResetPassword() {
  const navigate = useNavigate()

  const methods = useForm<FormType>({
    mode: 'onChange',
    resolver: zodResolver(formSchema),
    defaultValues: {
      password: '',
      passwordRepeat: '',
    },
  })
  const { handleSubmit } = methods

  const onCancel = () => navigate(routes.signIn)

  const submitMutation = useMutation(resetPassword, {
    onSuccess: () => {
      NotificationSys.showSuccess(
        `Your password was reset successfully. You can now log in with your new password.`,
      )
      onCancel()
    },
  })

  const onSubmitHandler: SubmitHandler<FormType> = (values: FormType) => {
    const { email, token } = getQueryParams()
    if (!email || !token) {
      NotificationSys.showWarning('Incorrect form link')
      return
    }

    submitMutation.mutate({
      password: values.password,
      email,
      token,
    })
  }

  return (
    <FormProvider {...methods}>
      <Grid
        container
        rowSpacing={1.5}
        component="form"
        onSubmit={handleSubmit(onSubmitHandler)}
        width="100%"
        noValidate
      >
        <Grid item xs={12} sm={12}>
          <AuthLogo mb={0} />
        </Grid>
        <Grid item xs={12} sm={12}>
          <AuthFormTitle mb={0}>Reset Password</AuthFormTitle>
        </Grid>

        <Grid item xs={12} sm={12}>
          <FormInput
            label="My new password"
            required
            fullWidth
            name="password"
            type="password"
            autoComplete="off"
          />
          <FormPasswordCheckList name="password" />
        </Grid>
        <Grid item xs={12} sm={12}>
          <FormInput
            label="Same new password"
            required
            fullWidth
            name="passwordRepeat"
            type="password"
            autoComplete="off"
          />
        </Grid>
        <Grid item xs={12} mt={2}>
          <VerticalButtonBox
            buttonSaveType="submit"
            saveText="Reset password"
            loading={submitMutation.isLoading}
            onCancel={onCancel}
          />
        </Grid>
      </Grid>
    </FormProvider>
  )
}
