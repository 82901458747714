import { blue } from '@mui/material/colors'
import { custom, string, ZodEffects } from 'zod'
import moment from 'moment/moment'
import { ZodString, ZodTypeAny } from 'zod/lib/types'
import React from 'react'
import { Moment } from 'moment'

export const minButtonWidth = { minWidth: 100 }

export const defaultModalStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 570,
  maxWidth: '100%',
  bgcolor: 'background.paper',
  boxShadow: 24,
  py: 3,
  px: 3,
  zIndex: 1400,
  maxHeight: 'calc(100% - 24px)',
  overflowY: 'auto',
}

export const defaultModalZIndex = { zIndex: 1400 }

export const NAV_DRAWER_WIDTH = 245
export const NAV_DRAWER_ZINDEX = 1350
export const ADMIN_NAV_DRAWER_WIDTH = 280
export const PROFILE_DRAWER_WIDTH = 1100

export const LOREM_IPSUM = `Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt
ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation
ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in
reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur
sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id
est laborum.`

export const HEADER_ZINDEX = 1400
export const PROFILE_DRAWER_ZINDEX = 1300

export const getDrawerSx = (width: number, zIndex: number) => ({
  zIndex,
  width,
  maxWidth: '100%',
  flexShrink: 0,
  '& .MuiDrawer-paper': {
    width,
    maxWidth: '100%',
    boxSizing: 'border-box',
  },
})

export const defaultDrawerSX = getDrawerSx(PROFILE_DRAWER_WIDTH, PROFILE_DRAWER_ZINDEX)

export const DEFAULT_PAGE_SIZE = 25

export const PRIMARY_ID_MISSING = 'For this table not provided primary column "id"'

export const reactDomLinkStyles = { color: blue[500], textDecoration: 'none' }

export const statesUSA = [
  {
    value: 'AK',
    label: 'Alaska',
  },
  {
    value: 'AL',
    label: 'Alabama',
  },
  {
    value: 'AR',
    label: 'Arkansas',
  },
  {
    value: 'AS',
    label: 'American Samoa',
  },
  {
    value: 'AZ',
    label: 'Arizona',
  },
  {
    value: 'CA',
    label: 'California',
  },
  {
    value: 'CO',
    label: 'Colorado',
  },
  {
    value: 'CT',
    label: 'Connecticut',
  },
  {
    value: 'DC',
    label: 'District of Columbia',
  },
  {
    value: 'DE',
    label: 'Delaware',
  },
  {
    value: 'FL',
    label: 'Florida',
  },
  {
    value: 'GA',
    label: 'Georgia',
  },
  {
    value: 'GU',
    label: 'Guam',
  },
  {
    value: 'HI',
    label: 'Hawaii',
  },
  {
    value: 'IA',
    label: 'Iowa',
  },
  {
    value: 'ID',
    label: 'Idaho',
  },
  {
    value: 'IL',
    label: 'Illinois',
  },
  {
    value: 'IN',
    label: 'Indiana',
  },
  {
    value: 'KS',
    label: 'Kansas',
  },
  {
    value: 'KY',
    label: 'Kentucky',
  },
  {
    value: 'LA',
    label: 'Louisiana',
  },
  {
    value: 'MA',
    label: 'Massachusetts',
  },
  {
    value: 'MD',
    label: 'Maryland',
  },
  {
    value: 'ME',
    label: 'Maine',
  },
  {
    value: 'MI',
    label: 'Michigan',
  },
  {
    value: 'MN',
    label: 'Minnesota',
  },
  {
    value: 'MO',
    label: 'Missouri',
  },
  {
    value: 'MS',
    label: 'Mississippi',
  },
  {
    value: 'MT',
    label: 'Montana',
  },
  {
    value: 'NC',
    label: 'North Carolina',
  },
  {
    value: 'ND',
    label: 'North Dakota',
  },
  {
    value: 'NE',
    label: 'Nebraska',
  },
  {
    value: 'NH',
    label: 'New Hampshire',
  },
  {
    value: 'NJ',
    label: 'New Jersey',
  },
  {
    value: 'NM',
    label: 'New Mexico',
  },
  {
    value: 'NV',
    label: 'Nevada',
  },
  {
    value: 'NY',
    label: 'New York',
  },
  {
    value: 'OH',
    label: 'Ohio',
  },
  {
    value: 'OK',
    label: 'Oklahoma',
  },
  {
    value: 'OR',
    label: 'Oregon',
  },
  {
    value: 'PA',
    label: 'Pennsylvania',
  },
  {
    value: 'PR',
    label: 'Puerto Rico',
  },
  {
    value: 'RI',
    label: 'Rhode Island',
  },
  {
    value: 'SC',
    label: 'South Carolina',
  },
  {
    value: 'SD',
    label: 'South Dakota',
  },
  {
    value: 'TN',
    label: 'Tennessee',
  },
  {
    value: 'TX',
    label: 'Texas',
  },
  {
    value: 'UT',
    label: 'Utah',
  },
  {
    value: 'VA',
    label: 'Virginia',
  },
  {
    value: 'VI',
    label: 'Virgin Islands',
  },
  {
    value: 'VT',
    label: 'Vermont',
  },
  {
    value: 'WA',
    label: 'Washington',
  },
  {
    value: 'WI',
    label: 'Wisconsin',
  },
  {
    value: 'WV',
    label: 'West Virginia',
  },
  {
    value: 'WY',
    label: 'Wyoming',
  },
]

export const twoLettersStatesUSA = statesUSA.map((it) => ({
  value: it.value,
  label: it.value,
}))

export const FIELD_IS_REQUIRED = 'Field is required'
export const EMAIL_IS_INVALID = 'Email is invalid'

export const zodDateObject = custom().refine((value: unknown) => {
  return moment(value as Moment | string | Date).isValid()
}, FIELD_IS_REQUIRED)

export const STRIPE_KEY =
  'pk_test_51MmX3EEcbGMUwPAGpX5MrzBP6D9AsVK2TKo6q6S1qevYJ0dOAEAPqXrFBoueFTBGre94ONGXFNn4DSwSocm6HTrQ00auZAqOdn'

interface NumberProps {
  isRequired?: boolean
  requiredText?: string

  isPositive?: boolean
  positiveText?: string

  correctNumberText?: string
}

export const constructZODNumber = (props: NumberProps) => {
  const {
    requiredText = FIELD_IS_REQUIRED,
    positiveText = 'Please input positive number',
    correctNumberText = 'Please input correct number',
  } = props

  let obj: ZodString | ZodEffects<ZodTypeAny> = string()
  if (props.isRequired) {
    obj = obj.min(1, requiredText)
  }
  obj = obj.refine((value: string) => !isNaN(Number(value)), correctNumberText)
  if (props.isPositive) {
    obj = obj.refine((value: string) => !isNaN(Number(value)) && Number(value) > 0, positiveText)
  }
  return obj
}

export const heightOfToolbar = {
  md: 124,
  sm: 112,
  xs: 108,
}

export const defaultQuestionInstruction = 'Choose the single best answer'
export const defaultQueryInstruction = 'Choose the best answer that applies.'

export const PASSWORD_REQUIREMENTS =
  'The password will require a number, letter, and symbol in a password of at least 8 characters'

export const SESSION_IS_EXPIRED = (
  <>
    Your session has expired.
    <br /> Please re-login
  </>
)
