import { Button, Stack, Typography } from '@mui/material'
import { UIItemContentContainer, UIShadowContainer } from 'features/UI'
import React from 'react'
import { TimelineItemHeader } from '../../../features/Timeline/components/TimelineItemHeader/TimelineItemHeader'

interface Props {
  title: string
  author: string
  date: string
  text: string
  showActions: boolean

  onDelete: () => void
  onReviewLater: () => void
  onDone: () => void
}

const DrawerNoteItem = (props: Props) => {
  const { title, author, date, text, showActions, onDelete, onReviewLater, onDone } = props

  return (
    <UIShadowContainer>
      <UIItemContentContainer>
        <TimelineItemHeader title={title} authorName={author} date={date} showAvatar={true} />
        <Typography paddingY={2}>{text}</Typography>
        {showActions && (
          <Stack direction="row" spacing={2} justifyContent="flex-end">
            <Button variant="outlined" color="error" onClick={onDelete}>
              Delete
            </Button>
            <Button variant="contained" onClick={onReviewLater}>
              Review Later
            </Button>
            <Button variant="contained" onClick={onDone}>
              Done
            </Button>
          </Stack>
        )}
      </UIItemContentContainer>
    </UIShadowContainer>
  )
}

export default React.memo(DrawerNoteItem)
