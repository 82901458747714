import { OutputData } from '@editorjs/editorjs/types/data-formats/output-data'
import classnames from 'clsx'
import Blocks, { DataProp } from 'editorjs-blocks-react-renderer'
import HTMLReactParser from 'html-react-parser'
import React from 'react'
import { IMetricScope, IMetricType } from 'types/metric.types'
import FailPlaceholder from '../../../../components/placeholders/FailPlaceholder'
import { ArticleYoutubeWrapper } from '../ArticleYoutubeWrapper/ArticleYoutubeWrapper'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function youtubeEmbed(block: any) {
  const videoId = block.data.url.split('?v=')[1]
  return (
    <ArticleYoutubeWrapper
      videoId={videoId}
      metricType={block.metricType}
      metricEntityId={block.metricEntityId}
      onSaveMetric={block.onSaveMetric}
    />
  )
}

function imageAndText(block: unknown) {
  const props = block as { data: { description: string; photo: string } }
  return (
    <div>
      <div className="cdx-personality__photo-wrapper" style={{ float: 'left' }}>
        <img
          className={classnames('cdx-personality__photo', {
            'cdx-personality__photo-preview': !props.data.photo,
          })}
          src={props.data.photo}
          alt=""
          style={{ cursor: 'default' }}
        />
      </div>
      <div
        className="cdx-personality__description"
        style={{
          width: '100%',
          maxWidth: '100%',
        }}
      >
        {HTMLReactParser(props.data.description)}
      </div>
      <div style={{ clear: 'both' }}></div>
    </div>
  )
}

const renderers = {
  youtubeEmbed,
  imageAndText,
}

const config = {
  header: {
    className: 'ce-header',
  },
  paragraph: {
    className: 'ce-paragraph cdx-block preview_paragraph',
    actionsClassNames: {
      alignment: 'text-{alignment}',
    },
  },
  list: {
    className: 'cdx-block cdx-list',
  },
}

export function ArticlePreview({
  data,
  metricType,
  metricEntityId,
  onSaveVideoMetric,
}: {
  data: OutputData | null
  metricType?: IMetricType
  metricEntityId?: number
  onSaveVideoMetric?: (value: number, uuid: string, scope: IMetricScope) => void
}) {
  if (!data) {
    return <FailPlaceholder error="Can't load data of article" />
  }

  const blocksData: DataProp = {
    blocks: data.blocks,
    time: data.time || 0,
    version: data.version || '2.26.4',
  }

  return (
    <Blocks
      data={blocksData}
      renderers={renderers}
      config={{
        ...config,
        youtubeEmbed: {
          metricType: metricType,
          metricEntityId: metricEntityId,
          onSaveMetric: onSaveVideoMetric,
        },
      }}
    />
  )
}
