import { Grid, Tab, Tabs, useMediaQuery } from '@mui/material'
import { useTheme } from '@mui/material/styles'
import PageTab from 'components/tabs/PageTab'
import { getTitle } from 'components/tabs/TabsTitle'
import { useCheckAuthorizationAndRedirect } from 'hooks/useCheckAuthorizationAndRedirect'
import { useTabsControl } from 'hooks/useTabsControl'
import React from 'react'
import { UserType } from 'types'
import { ProgressResultsTab } from '../ProgressResultsTab/ProgressResultsTab'
import { traineeAssessmentTooltip, traineeResultsTooltip } from '../../Progress.model'
import { ProgressAssessmentTab } from '../ProgressAssessmentTab/ProgressAssessmentTab'

const assessmentPanelText = 'Assessment'
const resultPanelText = 'Results'

export const ProgressPage = () => {
  const theme = useTheme()
  const matchesMd = useMediaQuery(theme.breakpoints.down('lg'))
  const { selectedTab, handleChangeSelectedTab } = useTabsControl<string>('first')

  const redirect = useCheckAuthorizationAndRedirect([UserType.TRAINEE])

  if (redirect) {
    return <>{redirect}</>
  }

  const firstTab = <ProgressAssessmentTab />
  const secondTab = <ProgressResultsTab />

  if (matchesMd) {
    return (
      <>
        <Tabs
          value={selectedTab}
          onChange={handleChangeSelectedTab}
          color="secondary"
          indicatorColor="secondary"
          textColor="secondary"
          variant="fullWidth"
          sx={{ background: 'white' }}
        >
          <Tab
            value="first"
            label={getTitle(assessmentPanelText, undefined, traineeAssessmentTooltip)}
          />
          <Tab value="second" label={getTitle(resultPanelText, undefined, traineeResultsTooltip)} />
        </Tabs>

        {selectedTab === 'first' && firstTab}
        {selectedTab === 'second' && secondTab}
      </>
    )
  }

  return (
    <Grid container spacing={1} flexGrow={1}>
      <Grid item sm={12} md={6} lg={8} display="flex" flexDirection="column">
        <PageTab title={assessmentPanelText} tooltip={traineeAssessmentTooltip} />
        {firstTab}
      </Grid>

      <Grid item sm={12} md={6} lg={4} display="flex" flexDirection="column">
        <PageTab title={resultPanelText} tooltip={traineeResultsTooltip} />
        {secondTab}
      </Grid>
    </Grid>
  )
}
