import React from 'react'
import { Button, Grid } from '@mui/material'
import { LoadingButton } from '@mui/lab'

interface Props {
  onClickFirstButton: () => void
  onClickSecondButton: () => void

  disableFirstButton?: boolean

  firstBtnText?: string
  secondBtnText?: string

  firstButtonLoading?: boolean
}

const HorizontalButtonBox = (props: Props) => {
  const {
    firstBtnText,
    secondBtnText,
    disableFirstButton = false,
    onClickFirstButton,
    onClickSecondButton,
    firstButtonLoading = false,
  } = props

  return (
    <Grid container spacing={2} direction="row" alignItems="center" justifyContent="center">
      <Grid item>
        <LoadingButton
          loading={firstButtonLoading}
          onClick={onClickFirstButton}
          variant="contained"
          color="secondary"
          sx={{ minWidth: 200 }}
          disabled={disableFirstButton}
        >
          {firstBtnText}
        </LoadingButton>
      </Grid>
      <Grid item>
        <Button
          onClick={onClickSecondButton}
          variant="contained"
          color="secondary"
          sx={{ minWidth: 200 }}
        >
          {secondBtnText}
        </Button>
      </Grid>
    </Grid>
  )
}

export default HorizontalButtonBox
