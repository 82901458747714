import { Button, Grid, Stack, Typography } from '@mui/material'
import React from 'react'
import { useNavigate } from 'react-router-dom'
import { routes } from 'routes/routes'
import { AuthLogo } from '../AuthLogo/AuthLogo'

export const AuthPageSignUp = () => {
  const navigate = useNavigate()

  const onClickFamilyCaregiver = () => {
    navigate(routes.signUpFamily)
  }

  const onClickTraining = () => {
    navigate(routes.signUpTrainee)
  }

  const handleCancel = () => {
    navigate(routes.signIn)
  }

  return (
    <>
      <AuthLogo />
      <Grid container spacing={2} direction="row" justifyContent="center">
        <Grid item xs={12} sm={6}>
          <Button onClick={onClickFamilyCaregiver} variant="outlined" fullWidth>
            <Stack>
              <Typography textTransform="none">Family Caregiver</Typography>
              <Typography textTransform="none">{`I'm taking care of`}</Typography>
              <Typography textTransform="none">a loved one</Typography>
            </Stack>
          </Button>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Button onClick={onClickTraining} variant="outlined" fullWidth sx={{ height: '100%' }}>
            <Stack>
              <Typography textTransform="none">Professional Caregiver</Typography>
              <Typography textTransform="none">for Training</Typography>
            </Stack>
          </Button>
        </Grid>

        <Grid item xs={12} sm={4} mt={2}>
          <Button onClick={handleCancel} variant="outlined" color="info" fullWidth>
            Cancel
          </Button>
        </Grid>
      </Grid>
    </>
  )
}
