import { ICellRendererParams } from 'ag-grid-community'
import { PLACEHOLDER } from './common'

const publishedRenderer = (props: ICellRendererParams<{ isPublished: boolean }>) => {
  if (props?.data?.isPublished === undefined) {
    return PLACEHOLDER
  }
  const isPublished = props?.data?.isPublished
  return isPublished ? 'Published' : 'Unpublished'
}

export default publishedRenderer
