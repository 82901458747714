import { ReactNode } from 'react'
import { Stack } from '@mui/material'

export function UIItemContentContainer({
  children,
  justifyContent,
  alignItems,
  spacing,
  onClick,
}: {
  children: ReactNode
  spacing?: number
  justifyContent?: string
  alignItems?: string
  onClick?: () => void
}) {
  return (
    <Stack
      spacing={spacing}
      alignItems={alignItems}
      justifyContent={justifyContent}
      onClick={onClick}
      sx={{
        background: 'white',
        padding: 2,
        borderRadius: 2,
        width: '100%',
        height: '100%',
        cursor: onClick ? 'pointer' : 'inherit',
      }}
      style={{ marginTop: 8, marginBottom: 8 }}
    >
      {children}
    </Stack>
  )
}
