import { pathwaysDeletePoints } from 'api/pathways'
import ConfirmModal from 'components/modals/ConfirmModal'
import { useCallback, useMemo } from 'react'
import { useMutation } from 'react-query'
import { ETrainingPathwayPointType, PathwayGroupedPoint } from 'types'

export function PathwayModalRemoveConfirm({
  points,
  pathwayId,
  handleClose,
  onDeleted,
}: {
  points: PathwayGroupedPoint[]
  pathwayId: number
  handleClose: () => void
  onDeleted: (points: PathwayGroupedPoint[]) => void
}) {
  const deletePoints = useMutation(pathwaysDeletePoints)

  const handleConfirm = useCallback(async () => {
    const pointIds: number[] = []
    points.forEach((point) => {
      if (point.type === 'course') {
        const ids = point.points.map((innerPoint) => innerPoint.id)
        pointIds.push(...ids)
      } else {
        pointIds.push(point.id)
      }
    })

    deletePoints.mutate(
      { id: pathwayId, pointIds },
      {
        onSuccess: () => {
          onDeleted(points)
        },
      },
    )
  }, [deletePoints, onDeleted, pathwayId, points])

  const bodyText = useMemo(() => {
    if (points.length == 1) {
      const point = points[0]
      if (point.type == ETrainingPathwayPointType.questionnaire) {
        return `You're deleting the exam. All lessons from the same course will also be deleted. Please confirm.`
      } else {
        return `Are you sure you want to delete this item?`
      }
    } else {
      if (points.some((p) => p.type === ETrainingPathwayPointType.questionnaire)) {
        return `You're deleting the exam among the other items. All lessons from the same course will also be deleted. Please confirm.`
      } else {
        return `Are you sure you want to delete these items?`
      }
    }
  }, [points])

  return (
    <ConfirmModal
      title="Removing items"
      body={bodyText}
      handleClose={handleClose}
      handleConfirm={handleConfirm}
      loading={deletePoints.isLoading}
    />
  )
}
