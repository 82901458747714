import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { getProgressAssessments } from 'api/progress'
import { ProgressCourse } from 'types'

export const getAssessmentById = createAsyncThunk(
  'progress-assessment/by-user-id',
  async (_, thunkAPI) => {
    try {
      const response = await getProgressAssessments()
      return response.data
    } catch (e) {
      thunkAPI.rejectWithValue({})
    }
  },
)

export const assessmentSlice = createSlice({
  name: 'assessment',
  initialState: {
    assessment: null as ProgressCourse[] | null,
    isLoading: false,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getAssessmentById.pending, (state) => {
        state.isLoading = true
      })
      .addCase(getAssessmentById.fulfilled, (state, action) => {
        state.isLoading = false
        state.assessment = action.payload ?? null
      })
      .addCase(getAssessmentById.rejected, (state) => {
        state.isLoading = false
        state.assessment = null
      })
  },
})
