import { combineReducers, configureStore } from '@reduxjs/toolkit'
import { tagsSlice } from 'features/Tags'
import {
  createMigrate,
  FLUSH,
  PAUSE,
  PERSIST,
  persistReducer,
  persistStore,
  PURGE,
  REGISTER,
  REHYDRATE,
} from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import { migrations } from './migrations'
import { assessmentSlice } from './slice/assesment.slice'
import { careTeamSlice } from './slice/care-teams.slice'
import { learnLibrarySlice } from './slice/learn-library.slice'
import { learnLibraryFiltersSlice } from './slice/learnLibraryFilters.slice'
import { metricSlice } from './slice/metric.slice'
import { navigationSlice } from './slice/navigation.slice'
import { progressResultsSlice } from './slice/progress-results.slice'
import { tagsFiltersSlice } from './slice/tagsFilters.slice'
import { timelineSlice } from './slice/timeline.slice'
import { timelineFiltersSlice } from './slice/timelineFilters.slice'
import { currentUserSlice } from './slice/user.slice'

const reducers = combineReducers({
  timeline: timelineSlice.reducer,
  timelineFilters: timelineFiltersSlice.reducer,
  careTeam: careTeamSlice.reducer,
  assessment: assessmentSlice.reducer,
  progressResults: progressResultsSlice.reducer,
  currentUser: currentUserSlice.reducer,
  learnLibrary: learnLibrarySlice.reducer,
  learnLibraryFilters: learnLibraryFiltersSlice.reducer,
  metric: metricSlice.reducer,
  navigation: navigationSlice.reducer,
  adminTags: tagsSlice.reducer,
  tagsFilters: tagsFiltersSlice.reducer,
})

const persistConfig = {
  key: 'root',
  version: 3,
  storage,
  whitelist: ['timelineFilters', 'adminTags'],
  migrate: createMigrate(migrations, { debug: false }),
}

const persistedReducer = persistReducer(persistConfig, reducers)

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }),
})

export const persistor = persistStore(store)

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch
