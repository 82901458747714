import { ReactNode, useEffect } from 'react'
import { isDesktop } from '../utils/device'

// PAMS-603
// need to disable history navigation by swipe in ios

export function SwipeProvider({ children }: { children: ReactNode }) {
  // eslint-disable-next-line
  function touchStart(e: any) {
    // is not near edge of view, exit
    if (e.pageX === undefined || e.pageX > 16) return

    // prevent swipe to navigate back gesture
    e.preventDefault()
  }

  useEffect(() => {
    if (isDesktop) {
      return
    }

    const options = { passive: false }

    window.addEventListener('touchstart', touchStart, options)

    return () => {
      // @ts-ignore
      window.removeEventListener('touchstart', touchStart, options)
    }
  }, [])

  return <>{children}</>
}
