export { PLACEHOLDER } from './common'
import { capitalizeRenderer } from './capitalizeRenderer'
import checkbox from './checkbox'
import createLink from './create-link'
import createdAt from './created-at'
import date from './date'
import published from './published'
import updatedAt from './updated-at'

export default {
  checkbox,
  date,
  published,
  createLink,
  createdAt,
  updatedAt,
  capitalizeRenderer,
}
