import { LabelValue } from 'features/Metadata'
import { useMemo } from 'react'
import { Question, TableResponse } from 'types'

export function useQuestionMetadata(
  title: string,
  response?: TableResponse<Question>,
): LabelValue[] {
  return useMemo(() => {
    if (!response) {
      return []
    }

    return [
      {
        label: `${title} Total`,
        value: response.data.count,
      },
      {
        label: `${title} Published`,
        value: response.data.publishedCount || 0,
      },
    ]
  }, [response, title])
}
