import { AxiosResponse } from 'axios'
import { BaseTableRequest, Contact, RequestById, TableResponse } from 'types'
import axiosInstance from './axios'

export const getContact = (id: number) =>
  axiosInstance.post<void, TableResponse<Contact>>('/contacts', {
    filter: {
      carePersonId: {
        type: 'number',
        op: 'equals',
        value: id,
      },
    },
  } satisfies BaseTableRequest)

export const createContact = (contact: Partial<Contact>) => {
  return axiosInstance.post<void, AxiosResponse<void>>('/contacts/create', contact)
}

export const deleteContact = (request: RequestById) => {
  return axiosInstance.post<void, AxiosResponse<void>>('/contacts/delete', request)
}
