import { Stack } from '@mui/material'
import { CourseCollapseItem } from 'app/imports/App.components'
import { UILearnArticleImg, useCollapseContext } from 'features/UI'
import { useEffectOnce } from 'hooks/useEffectOnce'
import { useMemo } from 'react'
import { useParams } from 'react-router-dom'
import {
  ArticleType,
  CourseTimelineItem,
  ETimelinePostType,
  LessonTimelineItem,
  QuestionnaireTimelineItem,
} from 'types'
import { TimelineExamItem } from '../TimelineExamItem/TimelineExamItem'
import { TimelineLessonItem } from '../TimelineLessonItem/TimelineLessonItem'
import { TimelineLine } from '../TimelineLine/TimelineLine'

export function TimelineCourseItem({
  item,
  isForceExpanded,
}: {
  item: CourseTimelineItem
  isForceExpanded: boolean
}) {
  const { journeyItemType, journeyItemId } = useParams()
  const { setActiveCollapse } = useCollapseContext()

  const lessons = useMemo(() => {
    return item.items.filter(
      (itemInCourse) => itemInCourse.type === ETimelinePostType.lessons,
    ) as LessonTimelineItem[]
  }, [item])

  const exams = useMemo(() => {
    return item.items.filter(
      (itemInCourse) => itemInCourse.type === ETimelinePostType.questionnaire,
    ) as QuestionnaireTimelineItem[]
  }, [item])

  const childIds = useMemo(() => {
    return lessons.map((itemInCourse) => (itemInCourse as LessonTimelineItem).lessonId)
  }, [lessons])

  useEffectOnce(() => {
    const isLessonUrl = journeyItemType === ArticleType.Lesson
    const hasChildInCourse = childIds.includes(Number(journeyItemId))
    if (isLessonUrl && hasChildInCourse) {
      setActiveCollapse(item.id)
    }
  })

  return (
    <Stack direction="row" className="gap-16">
      <div style={{ position: 'relative', minWidth: 30, minHeight: '100%' }}>
        <UILearnArticleImg isOnTimeline />
        <TimelineLine />
      </div>

      <CourseCollapseItem
        id={item.id}
        name={item.name}
        bannerUrl={item.bannerUrl}
        status={item.status}
        isForceExpanded={isForceExpanded}
      >
        {lessons.map((itemInCourse) => (
          <TimelineLessonItem key={`${itemInCourse.type}_${itemInCourse.id}`} item={itemInCourse} />
        ))}
        {exams.map((itemInCourse) => (
          <TimelineExamItem key={`${itemInCourse.type}_${itemInCourse.id}`} item={itemInCourse} />
        ))}
      </CourseCollapseItem>
    </Stack>
  )
}
