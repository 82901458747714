import { Box, Typography } from '@mui/material'
import { ColDef, ICellRendererParams } from 'ag-grid-community'
import { getEngagementPerSessionDetailReport } from 'api/reports'
import columnRenderers, { PLACEHOLDER } from 'components/column-renderers'
import InfinityAgGrid from 'components/table/InfinityAgGrid'
import TableToolbar from 'components/table/TableToolbar'
import { CSVField, useExportCSV } from 'hooks/useExportCSV'
import { useGridControl } from 'hooks/useGridControl'
import React, { useCallback } from 'react'
import { BaseTableRequest, IMetricScope, ReportSessionDetailItem } from 'types'
import { formatDate, useGetDataSource } from 'utils'
import { createEngagementRenderer } from '../../helpers/createEngagementRenderer'
import { formatEngagement } from '../../helpers/formatEngagement'

type MetricScopeMap = {
  [key in IMetricScope]?: {
    scopeName: string
    field: keyof ReportSessionDetailItem
  }
}

const metricScopeMap: MetricScopeMap = {
  [IMetricScope.lesson]: {
    scopeName: 'Lesson',
    field: 'lessonName',
  },
  [IMetricScope.careArticle]: {
    scopeName: 'Timeline Post',
    field: 'careArticleName',
  },
  [IMetricScope.learnArticle]: {
    scopeName: 'Learn article',
    field: 'leanArticleName',
  },
  [IMetricScope.query]: {
    scopeName: 'Query',
    field: 'queryName',
  },
  [IMetricScope.questionnaire]: {
    scopeName: 'Exam',
    field: 'questionnaireName',
  },
  [IMetricScope.quiz]: {
    scopeName: 'Quiz',
    field: 'quizName',
  },
}

const columnDefs: ColDef[] = [
  {
    headerName: 'Spent Time',
    field: 'duration',
    cellRenderer: createEngagementRenderer('duration'),
  },
  {
    headerName: 'Item Type',
    field: 'entityType',
    cellRenderer: (props: ICellRendererParams<ReportSessionDetailItem>) => {
      if (!props.data) return PLACEHOLDER

      const { entityType } = props.data
      return metricScopeMap[entityType]?.scopeName || PLACEHOLDER
    },
  },
  {
    headerName: 'Item Name',
    cellRenderer: (props: ICellRendererParams<ReportSessionDetailItem>) => {
      if (!props.data) return PLACEHOLDER

      const { entityType } = props.data
      const field: keyof ReportSessionDetailItem | undefined = metricScopeMap[entityType]?.field
      if (!field) return PLACEHOLDER

      return props.data[field] || PLACEHOLDER
    },
  },
  {
    headerName: 'Date',
    field: 'startedAt',
    cellRenderer: columnRenderers.date('startedAt', 'YYYY-MM-DD HH:mm:ss'),
  },
]

const csvFields: CSVField[] = [
  {
    label: 'Spent Time',
    value: (data: ReportSessionDetailItem) => formatEngagement(data.duration, '-'),
  },
  {
    label: 'Item Type',
    value: (data: ReportSessionDetailItem) => {
      const { entityType } = data
      return metricScopeMap[entityType]?.scopeName || PLACEHOLDER
    },
  },
  {
    label: 'Item Name',
    value: (data: ReportSessionDetailItem) => {
      const { entityType } = data
      const field: keyof ReportSessionDetailItem | undefined = metricScopeMap[entityType]?.field
      if (!field) return PLACEHOLDER

      return (data[field] as string) || PLACEHOLDER
    },
  },
  {
    label: 'Date',
    value: (data: ReportSessionDetailItem) => formatDate(data.startedAt, 'YYYY-MM-DD HH:mm:ss'),
  },
]

export function ReportsEngagementUserSessionTable({ sessionUuid }: { sessionUuid: string }) {
  const getSessionDetail = useCallback(
    (request: BaseTableRequest) => {
      return getEngagementPerSessionDetailReport({ ...request, params: { sessionUuid } })
    },
    [sessionUuid],
  )

  const getDataSourceActive = useGetDataSource(getSessionDetail)
  const { onGridReady, gridApi, columnApi } = useGridControl(getDataSourceActive)

  const { csvLoading, onExportCSV } = useExportCSV(
    getSessionDetail,
    gridApi,
    columnApi,
    csvFields,
    'Engagement Per Login.csv',
  )

  return (
    <>
      <Box mt={2}>
        <TableToolbar
          left={
            <Typography variant="h6" fontWeight={600}>
              Details
            </Typography>
          }
          exportCSVEnable
          csvLoading={csvLoading}
          onExportCSV={onExportCSV}
        />
      </Box>

      <InfinityAgGrid columnDefs={columnDefs} onGridReady={onGridReady} pagination />
    </>
  )
}
