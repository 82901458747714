import React from 'react'
import { Box, Button, Modal, Stack, Typography } from '@mui/material'
import { defaultModalStyle, defaultModalZIndex, minButtonWidth } from '../../const'
import quickTip from '../../images/quick_tip.png'

interface Props {
  isOpen: boolean
  handleClose: () => void
}

const QuickTipModal = (props: Props) => {
  const { isOpen, handleClose } = props

  return (
    <Modal sx={defaultModalZIndex} open={isOpen} onClose={handleClose}>
      <Box sx={defaultModalStyle} textAlign="center">
        <Typography
          variant="h5"
          fontSize="1.66rem"
          fontWeight={400}
          component="h2"
          color="textSecondary"
          sx={{ mb: 2 }}
        >
          Welcome Back to Memory Care Partner!
        </Typography>

        <Box display="flex" justifyContent="center" sx={{ minHeight: 128 }}>
          <img src={quickTip} alt="Logo" style={{ maxHeight: 128, display: 'block' }} />
        </Box>

        <Box sx={{ mt: 2 }} textAlign="center">
          <Typography variant="h6" color="#666" fontWeight={400} component="span">
            {`Focus on the positive. Having dementia is difficult, both for you and Stephen Test. You
              don’t have to pretend that the challenges and pain don’t exist, but focusing on the
              positives, no matter how small, and adapting to Stephen Test's abilities will help you
              find more joy.`}
          </Typography>
        </Box>

        <Stack direction="row" spacing={2} justifyContent="flex-end" sx={{ mt: 4 }}>
          <Button variant="outlined" onClick={handleClose} sx={minButtonWidth}>
            Close
          </Button>
        </Stack>
      </Box>
    </Modal>
  )
}

export default QuickTipModal
