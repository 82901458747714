import React, { createContext, ReactNode, useContext, useMemo, useState } from 'react'

const defaultValue: ContextType = {} as ContextType

interface ContextType {
  activeCollapseId: string | number | null
  setActiveCollapse: (id: string | number | null) => void
}

const CollapseContext = createContext<ContextType>(defaultValue)

export const useCollapseContext = () => {
  return useContext(CollapseContext)
}

export const UICollapseProvider = ({ children }: { children: ReactNode }) => {
  const [activeCollapseId, setActiveCollapse] = useState<string | number | null>(null)

  const value = useMemo(() => {
    return {
      activeCollapseId,
      setActiveCollapse,
    }
  }, [activeCollapseId, setActiveCollapse])

  return <CollapseContext.Provider value={value}>{children}</CollapseContext.Provider>
}
