import ContentCopyIcon from '@mui/icons-material/ContentCopy'
import { IconButton, Stack } from '@mui/material'
import { ICellRendererParams } from 'ag-grid-community'
import copy from 'copy-to-clipboard'
import React from 'react'
import { PLACEHOLDER } from 'components/column-renderers'

export const AccessManagementColumnCode = (
  props: ICellRendererParams<{ [code: string]: string }>,
) => {
  const code = props?.data?.code
  if (!code) {
    return PLACEHOLDER
  }

  const copyToClipboard = () => {
    copy(code, {
      format: 'text/plain',
    })
  }

  return (
    <Stack direction="row" spacing={1} alignItems="center" style={{ height: '100%' }}>
      <span style={{ minWidth: 72 }}>{code}</span>
      <IconButton component="label" onClick={copyToClipboard} size="small" sx={{ padding: '8px' }}>
        <ContentCopyIcon style={{ width: 24, height: 24 }} />
      </IconButton>
    </Stack>
  )
}
