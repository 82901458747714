import { AxiosRequestConfig } from 'axios'
import {
  ChangePasswordRequest,
  ForgotPasswordRequest,
  InvitationInfo,
  LoginRequest,
  LoginResponse,
  RegisterRequest,
  RegisterTeamMemberRequest,
  ResetPasswordRequest,
  Response,
  User,
} from 'types'
import axiosInstance from './axios'

/* 200 if user find and 401 if user not find */
export const login = async (
  email: string,
  password: string,
  config?: AxiosRequestConfig,
): Promise<Response<LoginResponse>> => {
  const data = {
    email,
    password,
  }
  return axiosInstance.post<LoginRequest, Response<LoginResponse>>('/auth/login', data, config)
}

/* 200 if user find and 401 if user not find */
export const register = async (
  request: RegisterRequest,
  config?: AxiosRequestConfig,
): Promise<Response<User>> => {
  return axiosInstance.post<RegisterRequest, Response<User>>('/auth/register', request, config)
}

export const getInvitationInfo = async (token: string): Promise<Response<InvitationInfo>> => {
  return axiosInstance.post('/auth/invitation-info', { token })
}

export const registerTeamMember = async (
  request: RegisterTeamMemberRequest,
): Promise<Response<User>> => {
  return axiosInstance.post('/auth/register-invited', request)
}

export const forgotPassword = async (request: ForgotPasswordRequest) => {
  return axiosInstance.post('/auth/forgot-password', request)
}

export const resetPassword = async (request: ResetPasswordRequest) => {
  return axiosInstance.post('/auth/reset-password', request)
}

export const changePassword = async (request: ChangePasswordRequest) => {
  return axiosInstance.post('/auth/change-password', request)
}
