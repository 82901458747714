import { useMediaQuery } from '@mui/material'
import Drawer from '@mui/material/Drawer'
import { useTheme } from '@mui/material/styles'
import SwipeableDrawer from '@mui/material/SwipeableDrawer'
import { getDrawerSx, NAV_DRAWER_WIDTH, NAV_DRAWER_ZINDEX } from 'const'
import React from 'react'
import { useSelector } from 'react-redux'
import { useAppDispatch } from 'store/hooks'
import { closeSidebar, openSidebar, selectSidebarIsOpen } from 'store/slice/navigation.slice'

export const NavigationDrawer = ({
  children,
  width = NAV_DRAWER_WIDTH,
}: {
  children: React.ReactNode
  width?: number
}) => {
  const dispatch = useAppDispatch()
  const isOpen = useSelector(selectSidebarIsOpen)

  const theme = useTheme()
  const matchDownLG = useMediaQuery(theme.breakpoints.down('lg'))

  const handleDrawerClose = () => {
    dispatch(closeSidebar())
  }

  const handleDrawerOpen = () => {
    dispatch(openSidebar())
  }

  if (matchDownLG) {
    return (
      <SwipeableDrawer
        open={isOpen}
        ModalProps={{ keepMounted: true }}
        onClose={handleDrawerClose}
        onOpen={handleDrawerOpen}
        sx={getDrawerSx(width, NAV_DRAWER_ZINDEX)}
      >
        {children}
      </SwipeableDrawer>
    )
  }

  return (
    <Drawer
      sx={{
        width,
        flexShrink: 0,
        '& .MuiDrawer-paper': {
          width,
          boxSizing: 'border-box',
        },
      }}
      variant="permanent"
      anchor="left"
      open={isOpen}
    >
      {children}
    </Drawer>
  )
}
