import { Stack } from '@mui/material'
import { styled } from '@mui/material/styles'
import React from 'react'
import { formatTimelineDate } from 'utils'
import { TimelineLine } from '../TimelineLine/TimelineLine'

const Dot = styled('div')(({ theme }) => ({
  height: 15,
  width: 15,
  borderRadius: '100%',
  background: theme.palette.grey['700'],
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate3d(-50%, -50%, 0)',
}))

export const TimelineDateItem = ({ text, date }: { text: string; date: string }) => {
  return (
    <Stack direction="row" spacing={2} alignItems="center">
      <div style={{ position: 'relative', width: 30, height: 30 }}>
        <Dot />
        <TimelineLine />
      </div>
      <div>
        {text} · {formatTimelineDate(date)}
      </div>
    </Stack>
  )
}
