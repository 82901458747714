import { ColDef, ICellRendererParams } from 'ag-grid-community'
import { GridReadyEvent } from 'ag-grid-community/dist/lib/events'
import columnRenderers, { PLACEHOLDER } from 'components/column-renderers'
import InfinityAgGrid from 'components/table/InfinityAgGrid'
import capitalize from 'lodash/capitalize'
import React, { SyntheticEvent, useMemo, useState } from 'react'
import { CourseCompleted, LessonCompletedInReport } from 'types'
import { isDefined } from 'utils'
import { UIModal } from 'features/UI'

export function ReportsAttemptsCell({
  entityName,
  items,
}: {
  entityName: string
  items: LessonCompletedInReport[] | CourseCompleted[]
}) {
  const [isOpen, setIsOpen] = useState(false)

  const handleOpen = (event: SyntheticEvent) => {
    event.preventDefault()
    setIsOpen(true)
  }

  const handleClose = () => {
    setIsOpen(false)
  }

  const passedPercentHeader = useMemo(() => {
    if (entityName === 'Quiz') {
      return `% Quizzes passed`
    }

    return `% ${entityName} passed`
  }, [entityName])

  const columnDefs: ColDef[] = useMemo(() => {
    return [
      {
        headerName: 'Attempt Date',
        field: 'completedAt',
        cellRenderer: columnRenderers.date('completedAt', 'YYYY-MM-DD HH:mm:ss'),
      },
      {
        headerName: passedPercentHeader,
        field: 'passedPercent',
        cellRenderer: (props: ICellRendererParams<{ passedPercent: number }>) => {
          const passedPercent = props.data?.passedPercent
          if (isDefined(passedPercent)) {
            return `${passedPercent}%`
          }
          return PLACEHOLDER
        },
      },
      {
        headerName: 'Result',
        field: 'status',
        cellRenderer: (props: ICellRendererParams<{ status: string }>) => {
          const status = props.data?.status
          if (isDefined(status)) {
            return capitalize(status)
          }
          return PLACEHOLDER
        },
      },
    ]
  }, [passedPercentHeader])

  const handleGridReady = (event: GridReadyEvent) => {
    event.api.sizeColumnsToFit()
  }

  return (
    <div>
      <a href="#" onClick={handleOpen}>
        {items.length}
      </a>

      <UIModal isOpen={isOpen} onClose={handleClose} width={648} title={`${entityName} attempts`}>
        <div
          className="ag-theme-alpine"
          style={{ height: '400px', width: '600px', fontSize: '1rem' }}
        >
          <InfinityAgGrid
            rowData={items}
            columnDefs={columnDefs}
            rowModelType="clientSide"
            onGridReady={handleGridReady}
          />
        </div>
      </UIModal>
    </div>
  )
}
