import buttonOverride from './buttonOverride'
import { Theme } from '@mui/material/styles'
import formHelperTextOverride from './formHelperTextOverride'
import popoverOverride from './popoverOverride'
import autoCompleteOverride from './autoCompleteOverride'
import datePickerOverride from './datePickerOverride'

export function componentsOverrides(theme: Theme) {
  return {
    ...buttonOverride(),
    ...popoverOverride(),
    ...datePickerOverride(),
    ...autoCompleteOverride(),
    ...formHelperTextOverride(),
  }
}
