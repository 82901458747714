import { Box, Button, Typography } from '@mui/material'
import { createContact, deleteContact, getContact } from 'api/contacts'
import { getActionColumn, RowAction } from 'features/UI'
import { useGridControl } from 'hooks/useGridControl'
import { useRemoveModalControl } from 'hooks/useRemoveModalControl'
import { useShowControl } from 'hooks/useShowControl'
import React, { useCallback, useMemo } from 'react'
import { useMutation } from 'react-query'
import { Contact } from 'types'
import { useGetDataSource } from 'utils'
import AddContactModal from '../../../modals/AddContactModal'
import ConfirmRemoveModal from '../../../modals/ConfirmRemoveModal'
import NotificationSys from '../../../NotificationSystem'
import InfinityAgGrid from '../../../table/InfinityAgGrid'
import TableToolbar from '../../../table/TableToolbar'
import { contactsColumnDefs } from '../const'

const btnSX = { minWidth: 120 }

type Props = {
  carePersonId: number
}

const ConcernProfileContactsTab = ({ carePersonId }: Props) => {
  const getDataSource = useGetDataSource(() => getContact(carePersonId)) // TODO
  const { gridApi, onGridReady } = useGridControl(getDataSource)

  const [isOpen, handleOpen, handleClose] = useShowControl()

  const createContactMutation = useMutation(createContact, {
    onSuccess: () => {
      handleClose()
      gridApi?.purgeInfiniteCache()
      NotificationSys.showSuccess(`Contact added successfully`)
    },
  })

  const handleAddContactSubmit = useCallback(
    (contact: Partial<Contact>) => {
      createContactMutation.mutate({
        ...contact,
        carePersonId: carePersonId,
      })
    },
    [createContactMutation, carePersonId],
  )

  const { idToRemove, openDeleteModal, closeDeleteModal, removeLoading, handleConfirmRemove } =
    useRemoveModalControl({
      deleteFunc: deleteContact, // TODO
      successCallback: () => gridApi?.purgeInfiniteCache(),
      warning: "Can't remove the contact",
    })

  const actions: RowAction[] = useMemo(() => {
    return [{ name: 'Delete', onClick: openDeleteModal }]
  }, [openDeleteModal])

  const columnDefsWithAction = [...contactsColumnDefs, { ...getActionColumn(actions) }]

  return (
    <Box className="ag-theme-alpine">
      <Typography color="textSecondary" variant="body1">
        Keep track of contacts for Pharmacy or Doctors here.
      </Typography>
      <TableToolbar>
        <Button variant="outlined" sx={btnSX} onClick={handleOpen}>
          Add Contact
        </Button>
      </TableToolbar>
      <InfinityAgGrid
        columnDefs={columnDefsWithAction}
        onGridReady={onGridReady}
        domLayout="autoHeight"
      />
      <AddContactModal
        loading={createContactMutation.isLoading}
        isOpen={isOpen}
        handleClose={handleClose}
        onSubmit={handleAddContactSubmit}
      />
      <ConfirmRemoveModal
        entityToRemove="Contact"
        loading={removeLoading}
        isOpen={idToRemove !== null}
        handleConfirm={handleConfirmRemove}
        handleClose={closeDeleteModal}
      />
    </Box>
  )
}

export default ConcernProfileContactsTab
