import {
  ApiSuccessResponse,
  BaseTableRequest,
  LearnArticle,
  LearnArticleRequestCreate,
  PartialNull,
  RequestById,
  Response,
  TableResponse,
} from 'types'
import { prepareTableRequestFilterByNameAndLinks } from 'utils'
import axiosInstance from './axios'

export const getLibraryArticlesByName = async (name: string) => {
  return getLibraryArticles(prepareTableRequestFilterByNameAndLinks(name))
}

export const getLibraryArticles = async (request: BaseTableRequest) => {
  return axiosInstance.post<BaseTableRequest, TableResponse<LearnArticle>>(
    '/learn-articles',
    request,
  )
}

export const createLibraryArticle = async (
  request: LearnArticleRequestCreate,
): Promise<Response<LearnArticle>> => {
  return axiosInstance.post('/learn-articles/create', request)
}

export const updateLibraryArticle = async (
  request: PartialNull<LearnArticle>,
): Promise<Response<LearnArticle>> => {
  const fixedRequest = {
    ...request,
    article: request.article || {
      blocks: [],
    },
  }
  return axiosInstance.post('/learn-articles/update', fixedRequest)
}

export const getLibraryArticleById = async (
  request: RequestById,
): Promise<Response<LearnArticle>> => {
  return axiosInstance.post('/learn-articles/read', request)
}

export const deleteLibraryArticle = async (request: RequestById): Promise<unknown> => {
  return axiosInstance.post('/learn-articles/delete', request)
}

export const duplicateLearnArticle = async (request: {
  id: number
  topicId: number
}): Promise<Response<{ id: number }>> => {
  return axiosInstance.post('/learn-articles/duplicate', request)
}

export const moveLearnArticle = async (request: {
  id: number
  topicId: number
}): Promise<ApiSuccessResponse> => {
  return axiosInstance.post('/learn-articles/move', request)
}
