import { useState } from 'react'
import { useShowControl } from './useShowControl'
import { AxiosProgressEvent } from 'axios'

export const useUploadAudioModal = () => {
  const [mediaLoading, setMediaLoading] = useState(false)
  const [isOpen, handleOpen, handleClose] = useShowControl()
  const [progressBarValue, setProgressBarValue] = useState(0)

  const onUploadProgress = (progressEvent: AxiosProgressEvent) => {
    const totalLength = progressEvent.total || 0

    if (totalLength !== null) {
      setProgressBarValue(Math.round((progressEvent.loaded * 100) / totalLength))
    }
  }

  return {
    mediaLoading,
    setMediaLoading,
    isOpen,
    handleOpen,
    handleClose,
    progressBarValue,
    onUploadProgress,
  }
}
