import React from 'react'
import { Button, CircularProgress, Icon, Stack } from '@mui/material'
import { minButtonWidth } from '../../../const'

interface Props {
  isPreviousVisible?: boolean
  isNextVisible?: boolean
  rightButton: React.ReactNode
  leftButton: React.ReactNode
}

const QuestionnaireFooter = (props: Props) => {
  const { isPreviousVisible, isNextVisible } = props

  const justifyContent =
    isNextVisible && isPreviousVisible
      ? 'space-between'
      : !isPreviousVisible
      ? 'flex-end'
      : 'flex-start'

  return (
    <Stack direction="row" spacing={2} justifyContent={justifyContent} sx={{ mt: 4 }}>
      {isPreviousVisible && props.leftButton}
      {isNextVisible && props.rightButton}
    </Stack>
  )
}

export default QuestionnaireFooter
