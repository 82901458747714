import React, { useMemo } from 'react'
import profileLogo from '../../../images/profile_logo.png'
import { Box, Button, Typography } from '@mui/material'
import styled from '@emotion/styled'
import { useDrawersContext } from '../../context/ClientsDrawersProvider'
import HelpTooltip from '../../tooltips/HelpTooltip'
import { profileOfConcernTooltip, traineeProfileTooltip } from '../../tooltips/tooltips'
import { useAuthContext } from '../../context/AuthProvider'

const Container = styled(Box)`
  padding: 16px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: linear-gradient(180deg, #71cacb 0%, #005b8c 100%);
`

const getFirstLetter = (str: string | undefined) => {
  if (!str) {
    return ''
  }
  if (str[0]) {
    return str[0] + '.'
  }
  return ''
}

const ProfileBanner = () => {
  const { isTrainee, user } = useAuthContext()
  const { handleOpenProfile, handleOpenConcernProfile } = useDrawersContext()

  const profileName = useMemo(() => {
    if (!user) {
      return ''
    }

    if (isTrainee) {
      return (user.firstname || '') + ' ' + getFirstLetter(user.lastname)
    }

    return `${user.personOfConcernFirstName || ''} ${user.personOfConcernLastName || ''}`
  }, [isTrainee, user])

  const profileTypeText = isTrainee ? 'Trainee Profile' : 'Person of Concern'

  const onClickProfile = () => {
    if (isTrainee) {
      handleOpenProfile(user?.id)
    } else {
      if (!!user?.carePersonId) {
        handleOpenConcernProfile(user.carePersonId)
      }
    }
  }

  return (
    <Container>
      <Typography variant="h5" color="white" sx={{ mb: 2 }}>
        Welcome
      </Typography>
      <Box sx={{ mb: 1 }}>
        <img src={profileLogo} alt="Logo" />
      </Box>
      <Typography variant="body2" color="white">
        {profileTypeText}
      </Typography>
      <Typography variant="h6" color="white" fontWeight={400} sx={{ mb: 2 }}>
        {profileName}
      </Typography>
      <HelpTooltip
        text={isTrainee ? traineeProfileTooltip : profileOfConcernTooltip}
        placement="right"
      >
        <Button variant="contained" onClick={onClickProfile}>
          View/Edit Profile
        </Button>
      </HelpTooltip>
    </Container>
  )
}

export default ProfileBanner
