import List from '@mui/material/List'
import React from 'react'
import { DrawerListItem } from 'types'
import NavItem from './common/NavItem'

export function DrawerCollapseList({ list }: { list: DrawerListItem[] }) {
  return (
    <List disablePadding={true}>
      {list.map(({ text, path }) => (
        <NavItem key={text} path={path} isSmall paddingLeft={4}>
          {text}
        </NavItem>
      ))}
    </List>
  )
}
