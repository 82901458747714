import React from 'react'
import LoadingPlaceholder from '../placeholders/LoadingPlaceholder'
import DrawerHeader from './common/DrawerHeader'

interface Props {
  handleClose: () => void
}

const DrawerLoadingPlaceholder = ({ handleClose }: Props) => {
  return (
    <>
      <DrawerHeader handleClose={handleClose} />
      <LoadingPlaceholder />
    </>
  )
}
export default DrawerLoadingPlaceholder
