import emotionStyled from '@emotion/styled'
import CheckBoxOutlinedIcon from '@mui/icons-material/CheckBoxOutlined'
import IconLearnArticle from 'images/icons/1_man_with_board.png'
import IconArticle from 'images/icons/icon_article.png'
import IconJournal from 'images/icons/icon_journal.png'

interface Props {
  isOnTimeline?: boolean
}

type PropsWithSize = Props & {
  size?: number
  svgSize?: number
}

const baseStyles = `
    min-height: 40px;
    min-width: 40px;
    max-width: 40px;
    max-height: 40px;
  `

const timelineStyles = `
    position: absolute;
    top: 28px;
    left: 28px;
    left: 50%;
    transform: translate3d(-50%, 0, 0);
    z-index: 1;
  `

export const UIItemImage = emotionStyled('img')<Props>(({ isOnTimeline }) => {
  if (isOnTimeline) {
    return `
      ${baseStyles}
      ${timelineStyles}
    `
  }

  return baseStyles
})

export const UIItemSvg = emotionStyled('div')<PropsWithSize>(({ isOnTimeline, size, svgSize }) => {
  const sizes = `
    width: ${size}px;
    height: ${size}px;
  `

  const svgSizes = svgSize
    ? `
        svg {
          width: ${svgSize}px;
          height: ${svgSize}px;
        }
  `
    : ''
  const timelineCss = isOnTimeline ? timelineStyles : ''

  return `
    ${sizes}
    background-color: #bdbdbd;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    ${svgSizes}
    ${timelineCss}
  `
})

export const UIPencilImg = (props: Props) => {
  return <UIItemImage src={IconJournal} isOnTimeline={props.isOnTimeline} />
}

export const UICarePostArticleImg = (props: Props) => {
  return <UIItemImage src={IconArticle} isOnTimeline={props.isOnTimeline} />
}

export const UITaskPostArticleImg = ({
  isOnTimeline = true,
  size = 40,
  svgSize,
}: PropsWithSize) => {
  return (
    <UIItemSvg isOnTimeline={isOnTimeline} size={size} svgSize={svgSize}>
      <CheckBoxOutlinedIcon />
    </UIItemSvg>
  )
}

export const UILearnArticleImg = (props: Props) => {
  return <UIItemImage src={IconLearnArticle} isOnTimeline={props.isOnTimeline} />
}
