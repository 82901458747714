import { Stack, Typography } from '@mui/material'
import { LibraryCategoriesItem } from 'components/ui/dasboard-items/learn-library/LibraryCategoriesItem'
import { UICollapseProvider } from 'features/UI'
import React from 'react'
import { ArticleCategory } from 'types'

export function CoursesList({ categoriesOfArticles }: { categoriesOfArticles: ArticleCategory[] }) {
  if (categoriesOfArticles.length === 0) {
    return (
      <Stack spacing={2}>
        <Typography variant="body1">No articles to read</Typography>
      </Stack>
    )
  }

  return (
    <Stack spacing={2}>
      <UICollapseProvider>
        {categoriesOfArticles.map((article: ArticleCategory) => {
          return (
            <LibraryCategoriesItem
              key={article.id}
              id={Number(article.id)}
              title={article.name}
              icon={article.icon}
              articles={article.articles}
            />
          )
        })}
      </UICollapseProvider>
    </Stack>
  )
}
