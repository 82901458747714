import { Theme } from '@mui/material/styles'
import { Palette } from '@mui/material/styles/createPalette'
import { Colors } from 'styles/colors'

export function paletteOverride(theme: Theme): Palette {
  return {
    ...theme.palette,
    secondary: {
      ...theme.palette.secondary,
      main: Colors.PINK,
      dark: Colors.PINK,
    },
  }
}
