import { Moment } from 'moment'

export enum DateRangeType {
  Overall = 'Overall',
  Yearly = 'Yearly',
  Monthly = 'Monthly',
  Weekly = 'Weekly',
  Range = 'Range',
}

export interface DateInterval {
  startDate: Moment
  endDate: Moment
}
