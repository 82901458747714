import styled from '@emotion/styled'
import { Stack, Typography } from '@mui/material'
import Breadcrumbs from 'components/Breadcumbs'
import { PLACEHOLDER } from 'components/column-renderers'
import React, { useMemo } from 'react'
import { routes } from 'routes/routes'
import { EngagementReportTypes, ReportSession } from 'types'
import { getRoute } from 'utils'
import { formatEngagement } from '../../helpers/formatEngagement'

const StyledInfo = styled('div')`
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 32px;
  row-gap: 8px;
`

export function ReportsEngagementUserSessionInfo({ session }: { session: ReportSession }) {
  const path = useMemo(() => {
    return [
      {
        href: getRoute(routes.reports_engagement_user, {
          engagementType: EngagementReportTypes.Session,
        }),
        text: 'Login Report',
      },
      {
        text: `${session.firstName} ${session.lastName}`,
      },
    ]
  }, [session.firstName, session.lastName])

  return (
    <>
      <Breadcrumbs path={path} />

      <Typography variant="h6" fontWeight={600} mt={2}>
        User Info
      </Typography>

      <Stack direction="row">
        <StyledInfo>
          <Typography fontWeight={600}>Anonymous ID</Typography>
          <Typography>{session.anonymousId}</Typography>

          <Typography fontWeight={600}>Login</Typography>
          <Typography>{session.email}</Typography>

          <Typography fontWeight={600}>Access Code</Typography>
          <Typography>{session.accessCode || PLACEHOLDER}</Typography>

          <Typography fontWeight={600}>Total time online during login</Typography>
          <Typography>{formatEngagement(session.duration)}</Typography>
        </StyledInfo>
      </Stack>
    </>
  )
}
