import { Box, Button, Typography } from '@mui/material'
import React from 'react'
import { useNavigate } from 'react-router-dom'
import { routes } from 'routes/routes'
import { useSearchParamsObject } from 'utils'
import { AuthFormTitle } from '../AuthFormTitle/AuthFormTitle'
import { AuthLogo } from '../AuthLogo/AuthLogo'

const traineeText = 'You can now access the Training Software via your email and password.'
const familyText = 'You can now access the system via your email and password.'

export const AuthPageSuccessSubscription = () => {
  const { type } = useSearchParamsObject()
  const navigate = useNavigate()

  const onClick = () => navigate(routes.signIn)

  const text = type === 'trainee' ? traineeText : familyText

  return (
    <Box display="flex" flexDirection="column" alignItems="center">
      <AuthLogo mb={2} />
      <AuthFormTitle mb={2}>Your payment was successful!</AuthFormTitle>

      <Typography textAlign="center" color="grey.700">
        {text}
      </Typography>

      <Button variant="contained" onClick={onClick} sx={{ mt: 4, minWidth: 160 }}>
        Go to Sign In
      </Button>
    </Box>
  )
}
