export enum EAccessCodeType {
  institutionAccessCode = 'institutionAccessCode',
  promoCode = 'promoCode',
}

export interface BaseAccessCode {
  codeType: EAccessCodeType
  reason: string
  organizationName: string
  twoDigitsOrg: string
  effectiveDate: string
  expiryDate: string
  organizationType: string
  singleLetterOrg: string
  state: string
  specificUnitName: string
  twoDigitsSpecific: string
  duration: string
  numberOfUsers: number
  id?: number
}

export interface AccessCode extends BaseAccessCode {
  code: string
}
