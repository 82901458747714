import React from 'react'
import { Box } from '@mui/material'

export function UITag({
  children,
  background,
  sx = {},
}: {
  children: React.ReactNode
  background: string
  sx?: object
}) {
  const containerSX = {
    ...sx,
    display: 'inline-block',
    color: '#FFF',
    background,
    paddingLeft: '8px',
    paddingRight: '8px',
    textTransform: 'uppercase',
    borderRadius: '4px',
    textAlign: 'center',
    fontSize: '0.875rem',
  }

  return <Box sx={containerSX}>{children}</Box>
}
