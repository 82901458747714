import React from 'react'
import { Box, IconButton, Stack, Typography } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'

interface Props {
  children?: React.ReactNode
  handleClose: () => void
  borderEnable?: boolean
}

const sx = {
  borderBottom: '1px solid #C7C8C7',
}

const DrawerHeader = (props: Props) => {
  const { children, handleClose, borderEnable } = props

  return (
    <Box
      display="flex"
      justifyContent="space-between"
      alignItems="center"
      pb={borderEnable ? 2 : 0}
      mb={3}
      sx={borderEnable ? sx : undefined}
    >
      <Typography variant="h5" color="grey.600">
        <Stack direction="row" spacing={1.5} alignItems="center">
          {children}
        </Stack>
      </Typography>
      <IconButton size="large" onClick={handleClose} disableRipple>
        <CloseIcon />
      </IconButton>
    </Box>
  )
}

export default React.memo(DrawerHeader)
