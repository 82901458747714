import React from 'react'

export const useTabsControl = <T>(initial: T) => {
  const [selectedTab, setSelectedTab] = React.useState(initial)

  const handleChangeSelectedTab = (event: React.SyntheticEvent, newValue: string) => {
    setSelectedTab(newValue as T)
  }

  return { selectedTab, handleChangeSelectedTab, setSelectedTab }
}
