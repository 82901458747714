import { AddNoteRequest, BaseTableRequest, Note, Response, TableResponse } from 'types'
import axiosInstance from './axios'

export const getNotes = async (request: BaseTableRequest) => {
  return axiosInstance.post<BaseTableRequest, TableResponse<Note>>('/notes', request)
}

export const createNote = async (request: AddNoteRequest): Promise<Response<Note>> => {
  return axiosInstance.post('/notes/create', request)
}

export const updateNote = async (request: Note): Promise<Response<Note>> => {
  return axiosInstance.post('/notes/update', request)
}

export const getNoteById = async (id: number): Promise<Response<Note>> => {
  return axiosInstance.post('/notes/read', { id })
}

export const deleteNote = async (id: number): Promise<unknown> => {
  return axiosInstance.post('/notes/delete', { id })
}
