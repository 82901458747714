import { useMemo } from 'react'
import { CSVField } from 'hooks/useExportCSV'
import { formatDate } from 'utils'

export function useTagCSVField() {
  return useMemo<CSVField[]>(
    () => [
      {
        label: 'Tag name',
        value: 'name',
      },
      {
        label: 'Creation Date',
        value: ({ createdAt }) => formatDate(createdAt),
      },
      {
        label: 'Last Edited',
        value: ({ updatedAt }) => formatDate(updatedAt),
      },
      {
        label: 'Use of filter',
        value: ({ isFilterable }) => (isFilterable ? 'Yes' : 'No'),
      },
    ],
    [],
  )
}
