import { Box, Stack, Typography } from '@mui/material'
import ScrollContainer from 'components/page/ScrollContainer'
import FailPlaceholder from 'components/placeholders/FailPlaceholder'
import LoadingPlaceholder from 'components/placeholders/LoadingPlaceholder'
import { heightOfToolbar } from 'const'
import moment from 'moment'
import React, { useMemo } from 'react'
import { useQuery } from 'react-query'
import { useActions, useAppSelector } from 'store/hooks'
import { ProgressResult } from 'types'
import { formatTimelineDate } from 'utils'
import { ProgressQuestioningItem } from '../ProgressQuestioningItem/ProgressQuestioningItem'

export const ProgressResultsTab = () => {
  const { getProgressResultsById } = useActions()
  const { refetch, isLoading } = useQuery('getProcessResultsById', () => getProgressResultsById())
  const { progressResults } = useAppSelector((state) => state.progressResults)

  const percentCorrect = (progressResult: ProgressResult) => {
    if (progressResult.answeredQuestions.length == 0) {
      return 0
    }

    const totalCorrect = progressResult.answeredQuestions.reduce((acc, item) => {
      acc += item.status == 'correct' ? 1 : 0
      return acc
    }, 0)
    return Math.round((totalCorrect / Object.values(progressResult.answeredQuestions).length) * 100)
  }

  const groupedResults = useMemo(() => {
    if (!progressResults) {
      return []
    }

    const hash = progressResults.reduce((acc, item) => {
      const key = moment(item.completedAt).startOf('day').valueOf()
      if (!acc[key]) {
        acc[key] = []
      }

      acc[key].push(item)
      return acc
    }, {} as Record<number, ProgressResult[]>)

    return Object.entries(hash).map((v) => ({ completedDate: parseInt(v[0]), result: v[1] }))
  }, [progressResults])

  if (isLoading) {
    return <LoadingPlaceholder />
  }

  if (!progressResults) {
    return <FailPlaceholder error="Can't load data of results" />
  }

  const renderQuestioningItem = (result: ProgressResult) => {
    if (result.quizId && result.quiz) {
      return (
        <ProgressQuestioningItem
          id={result.quizId}
          title={result.quiz.name}
          completedLessonId={result.id}
          onQuestioningRetaken={refetch}
          percentsOfCorrectAnswers={percentCorrect(result)}
          type="quiz"
        />
      )
    }

    if (result.questionnaireId && result.questionnaire) {
      return (
        <ProgressQuestioningItem
          id={result.questionnaireId}
          title={result.questionnaire.name}
          completedCourseId={result.id}
          onQuestioningRetaken={refetch}
          percentsOfCorrectAnswers={percentCorrect(result)}
          type="questionnaire"
        />
      )
    }
  }

  const renderGroup = (completedDate: number, results: ProgressResult[]) => {
    return (
      <Stack key={completedDate}>
        <Typography color="textSecondary" variant="h6" fontWeight={400} mb={1.5}>
          {formatTimelineDate(completedDate)}
        </Typography>

        {results
          .filter((r) => !!r.quizId || !!r.questionnaireId)
          .map((result) => (
            <Box key={result.id} sx={{ margin: '5px 0' }}>
              {renderQuestioningItem(result)}
            </Box>
          ))}
      </Stack>
    )
  }

  return (
    <Stack
      paddingTop={1.5}
      paddingLeft={{ xs: 2, sm: 2 }}
      paddingRight={{ xs: 1, sm: 2 }}
      spacing={1}
    >
      <ScrollContainer deltaSpace={heightOfToolbar}>
        {groupedResults.length === 0 && (
          <Typography textAlign="center" variant="h6" fontWeight={400} color="textSecondary">
            There are no results
          </Typography>
        )}
        <Stack spacing={1}>
          {groupedResults.map((g) => renderGroup(g.completedDate, g.result))}
        </Stack>
      </ScrollContainer>
    </Stack>
  )
}
