import { Box, Button } from '@mui/material'
import { addQuestionnaireEngagementMetric } from 'api/metrics'
import { getQuestionnaireWithSpecificResultsById } from 'api/questionaries'
import { useAuthContext } from 'components/context/AuthProvider'
import { minButtonWidth } from 'const'
import { noop } from 'lodash'
import React, { useEffect, useMemo, useState } from 'react'
import {
  LessonAnsweredQuestion,
  IMetricScope,
  IMetricType,
  QuestionnaireResultData,
  Questionnaire,
} from 'types'
import { formatMetricDate } from 'utils'
import QuestionScreen from '../../question-screen'
import QuestionnaireFooter from '../../QuestionnaireFooter'
import { QuestionnaireMetric } from '../QuestionnaireMetric'
import FinalScreen from '../summary'

interface Props {
  id: number
  completedCourseId: number
  handleClose: () => void
  onLoadingStart: () => void
  onLoadingFail: () => void
  onLoadingFinish: () => void
  retakeQuiz: () => void
}

const FIRST_SCREEN = -1

const ModalContent = (props: Props) => {
  const { handleClose } = props
  const { user } = useAuthContext()

  const [stage, setStage] = useState(FIRST_SCREEN)
  const [questionnaire, setQuestionnaire] = useState<Questionnaire>()
  const [answers, setAnswers] = useState<Record<string, LessonAnsweredQuestion>>({})
  const [results, setResults] = useState<QuestionnaireResultData>()

  useEffect(() => {
    props.onLoadingStart()
    getQuestionnaireWithSpecificResultsById({ id: props.completedCourseId })
      .then(
        (response) => {
          const { questionnaire, results } = response.data
          setQuestionnaire(questionnaire)
          if (results) {
            setResults(results)
            const answers = results.answeredQuestions.reduce((acc, item) => {
              acc[item.questionId] = item
              return acc
            }, {} as Record<string, LessonAnsweredQuestion>)

            setAnswers(answers)
          }
          props.onLoadingFinish()
        },
        () => props.onLoadingFail(),
      )
      .catch(() => props.onLoadingFail())
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const question = useMemo(() => {
    const index = Math.floor(stage)
    return questionnaire?.questions[index]
  }, [questionnaire, stage])

  const answer = useMemo(() => {
    if (question) {
      return answers[question?.id]
    }
  }, [answers, question])

  const stageType = useMemo(() => {
    if (stage == FIRST_SCREEN) {
      return 'summary'
    }

    return 'question'
  }, [stage])

  const onClickNext = () => {
    setStage(stage + 1)
  }

  const onClickPrevious = () => {
    setStage(stage - 1)
  }

  const isPreviousVisible = stage !== FIRST_SCREEN
  const isNextVisible = stage < (questionnaire?.questions.length ?? 0) - 1

  const handleSaveAudioMetric = async (value: number, uuid: string, scope: IMetricScope) => {
    if (!user || !questionnaire) {
      return
    }

    await addQuestionnaireEngagementMetric({
      userId: user.id,
      questionnaireId: questionnaire.id,
      scope,
      engagedFrom: formatMetricDate(value),
      engagedTo: formatMetricDate(),
    })
  }

  if (!questionnaire) {
    return null
  }

  const renderStage = () => {
    if (stageType == 'summary' && results)
      return (
        <FinalScreen
          results={results}
          retake={props.retakeQuiz}
          handleClose={handleClose}
          name={questionnaire.name}
        />
      )

    if (!question) return null

    return (
      <QuestionScreen
        answer={answer?.answer}
        onChange={noop}
        metricType={IMetricType.questionnaire}
        metricEntityId={questionnaire.id}
        onSaveAudioMetric={handleSaveAudioMetric}
        handleClose={handleClose}
        question={question}
        disabled={true}
      />
    )
  }

  return (
    <>
      <Box sx={{ mb: 4, flex: 1 }}>{renderStage()}</Box>
      <QuestionnaireMetric questionnaire={questionnaire} />

      <Box>
        <QuestionnaireFooter
          leftButton={
            <Button variant="outlined" onClick={onClickPrevious} sx={minButtonWidth}>
              {'< Previous'}
            </Button>
          }
          rightButton={
            <Button variant="outlined" onClick={onClickNext} sx={minButtonWidth}>
              {'Next >'}
            </Button>
          }
          isPreviousVisible={isPreviousVisible}
          isNextVisible={isNextVisible}
        />
      </Box>
    </>
  )
}

export default ModalContent
