export default function buttonOverride(): object {
  return {
    MuiButton: {
      defaultProps: {
        disableElevation: true,
      },
      styleOverrides: {
        root: {
          fontWeight: 400,
          textTransform: 'none',
        },
        sizeSmall: {
          padding: '4px 8px',
          fontSize: '14px',
          lineHeight: '22px',
        },
      },
    },
  }
}
