import { Alert, AlertTitle, Button } from '@mui/material'
import { useEffectOnce } from 'hooks/useEffectOnce'
import { useTimer } from 'hooks/useTimer'
import React from 'react'
import { pluralize } from 'utils/pluralize'

export function AlertWithTimer({
  title,
  buttonTitle,
  intervalMin,
}: {
  title: string // 'string {time} string'`
  buttonTitle: string
  intervalMin: number
}) {
  const { state, startTimer, stopTimer } = useTimer()

  useEffectOnce(() => {
    startTimer()
  })

  const handleClick = () => {
    stopTimer()
  }

  const secondsPlural = pluralize({
    count: intervalMin * 60 - state,
    pluralMap: {
      1: 'second',
      any: 'seconds',
    },
  })

  const titleResult = title.replace('{time}', secondsPlural)

  return (
    <Alert severity="warning">
      <AlertTitle>{titleResult}</AlertTitle>

      <Button variant="contained" color="warning" onClick={handleClick}>
        {buttonTitle}
      </Button>
    </Alert>
  )
}
