import React, { ChangeEvent, useMemo } from 'react'
import { MenuItem, SelectProps, TextField } from '@mui/material'
import { selectMenuProps } from './const'

export type SelectValue = number | string | boolean | null

interface Option {
  label: string
  value: SelectValue
}

interface FixedOption {
  id: string
  label: string
  value: SelectValue
}

interface Props {
  label?: string
  name: string
  value: SelectValue
  options: Option[]
  onChange: (name: string, value: SelectValue) => void
  sx?: object
  required?: boolean
}

const selectProps = { MenuProps: selectMenuProps }

const SimpleSelect = (props: Props) => {
  const { label, name, value, options, onChange, sx = {}, required } = props

  const fixedOptions: FixedOption[] = useMemo(() => {
    return options.map((it, idx) => ({
      id: String(idx),
      label: it.label,
      value: it.value,
    }))
  }, [options])

  const selectedId = fixedOptions.find((it) => it.value === value)?.id || null

  const handleChange = (event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    const { name, value: id } = event.target
    const option = fixedOptions.find((it) => it.id === id)
    if (!option) {
      return
    }
    onChange(name, option.value)
  }

  return (
    <TextField
      label={label}
      select
      name={name}
      value={selectedId}
      sx={{ minWidth: 130, ...sx }}
      onChange={handleChange}
      required={required}
      // error={required && selectedId === null}
      SelectProps={selectProps as SelectProps}
      size="small"
    >
      {fixedOptions.map((it) => (
        <MenuItem key={it.id} value={it.id}>
          {it.label}
        </MenuItem>
      ))}
    </TextField>
  )
}

export default SimpleSelect
