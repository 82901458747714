import { createSelector, createSlice } from '@reduxjs/toolkit'
import { ETimelinePostStatus, Nullish } from 'types'
import { RootState } from '../store'

interface InitialState {
  status: Nullish<ETimelinePostStatus[]>
}

const initialState: InitialState = {
  status: null,
}

export const learnLibraryFiltersSlice = createSlice({
  name: 'learnLibraryFilters',
  initialState,
  reducers: {
    setLearnLibraryFilterStatus: (state, { payload }) => {
      state.status = payload
    },
    resetLearnLibraryFilter: () => {
      return initialState
    },
  },
})

export const { setLearnLibraryFilterStatus, resetLearnLibraryFilter } =
  learnLibraryFiltersSlice.actions

export const selectLearnLibraryFiltersSlice = (state: RootState) => state.learnLibraryFilters

export const selectLearnLibraryStatusFilter = createSelector(
  selectLearnLibraryFiltersSlice,
  (slice) => slice.status,
)
