import MenuIcon from '@mui/icons-material/Menu'
import { Button, Stack, Typography, useMediaQuery } from '@mui/material'
import { useTheme } from '@mui/material/styles'
import headerLogo from 'images/header_short.png'
import profileLogo from 'images/profile_logo.png'
import React from 'react'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { routes } from 'routes/routes'
import { useActions, useAppDispatch } from 'store/hooks'
import { selectSidebarIsOpen, toggleSidebar } from 'store/slice/navigation.slice'
import { useAuthContext } from '../../context/AuthProvider'
import AdminProfileInfo from '../admin-profile/AdminProfileInfo'
import { StyledProfileLogo } from './ClientsHeaderContent'

const iconBackColor = 'grey.100'
const iconBackColorOpen = 'grey.300'

export const AdminHeaderContent = () => {
  const dispatch = useAppDispatch()
  const isShowDrawer = useSelector(selectSidebarIsOpen)

  const { logout } = useActions()

  const { clearUserData } = useAuthContext()
  const navigate = useNavigate()

  const handleLogout = () => {
    logout()
    navigate(routes.signIn)
    clearUserData()
  }

  const handleDrawerToggle = () => {
    dispatch(toggleSidebar())
  }

  const theme = useTheme()
  const matchDownLG = useMediaQuery(theme.breakpoints.down('lg'))

  return (
    <>
      {matchDownLG && (
        <Stack direction="row" alignItems="center" spacing={1}>
          <StyledProfileLogo src={profileLogo} alt="Logo" />

          <Button
            aria-label="open drawer"
            onClick={handleDrawerToggle}
            color="secondary"
            disableRipple
            sx={{
              minWidth: 0,
              color: 'text.primary',
              bgcolor: isShowDrawer ? iconBackColorOpen : iconBackColor,
            }}
          >
            <MenuIcon />
            <Typography ml={0.5}>Menu</Typography>
          </Button>
        </Stack>
      )}

      {!matchDownLG && <img src={headerLogo} alt="Logo" />}

      <AdminProfileInfo handleLogout={handleLogout} />
    </>
  )
}
