import { useCallback, useState } from 'react'

type Out = [boolean, () => void]

export const useToggle = (initialValue: boolean): Out => {
  const [state, setState] = useState(initialValue)

  const toggle = useCallback(() => {
    setState((previous) => !previous)
  }, [])

  return [state, toggle]
}
