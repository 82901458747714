import { useAuthContext } from 'components/context/AuthProvider'
import React, { useState } from 'react'
import { ECareLevel, FamilyRegisterRequest, UserType } from 'types'
import { AuthLogo } from '../AuthLogo/AuthLogo'
import { AuthPromoCodeForm, PromocodeFormType } from '../AuthPromoCodeForm/AuthPromoCodeForm'
import FamilyCardForm from './FamilyCardForm'
import SignUpFamilyForm from './SignUpFamilyForm'
import { FamilyFormType, familyInitialState, FamilySignupStage } from './types'

export const AuthPageSignupFamily = () => {
  const { register } = useAuthContext()
  const [state, setState] = useState(familyInitialState)
  const [stage, setStage] = useState<FamilySignupStage>(FamilySignupStage.FamilyForm)

  const handleCreate = (form: FamilyFormType) => {
    setState({
      ...state,
      familyForm: form,
    })
    setStage(FamilySignupStage.PromocodeForm)
  }

  const handleUsePromocode = (accessCode: string) => {
    const newState = {
      ...state,
      accessCode,
    }
    setState(newState)

    const request: FamilyRegisterRequest = {
      accessCode,
      firstname: newState.familyForm.firstName,
      lastname: newState.familyForm.lastName,
      email: newState.familyForm.email,
      type: UserType.CAREGIVER,
      password: newState.familyForm.myNewPassword,
      personOfConcernFirstName: newState.familyForm.personOfConcernFirstName,
      personOfConcernLastName: newState.familyForm.personOfConcernLastNameOrInitial,
      careLevel: newState.familyForm.levelOfCareNeeded as ECareLevel,
    }
    register(request)
  }

  const handleUseCard = () => {
    setState({
      ...state,
      accessCode: undefined,
    })
    setStage(FamilySignupStage.CardForm)
  }

  return (
    <>
      <AuthLogo />
      {stage === FamilySignupStage.FamilyForm && <SignUpFamilyForm handleCreate={handleCreate} />}
      {stage === FamilySignupStage.PromocodeForm && (
        <AuthPromoCodeForm
          type={PromocodeFormType.FamilyCaregiver}
          handleUsePromocode={handleUsePromocode}
          handleUseCard={handleUseCard}
        />
      )}
      {stage === FamilySignupStage.CardForm && <FamilyCardForm formData={state.familyForm} />}
    </>
  )
}
