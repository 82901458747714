import moment, { Moment } from 'moment/moment'
import { DateInterval, DateRangeType } from 'types'

export const prepareDateRangeParams = (
  rangeType: DateRangeType,
  year: string,
  month: string,
  selectedDate: Moment,
  dateInterval: DateInterval,
) => {
  const dateFormat = 'YYYY-MM-DD'
  const date = moment(`${month}/01/${year}`)
  let fromDate = ''
  let toDate = ''

  switch (rangeType) {
    case DateRangeType.Overall:
      return undefined
    case DateRangeType.Yearly:
      fromDate = date.startOf('year').format(dateFormat)
      toDate = date.endOf('year').format(dateFormat)
      break
    case DateRangeType.Monthly:
      fromDate = date.startOf('month').format(dateFormat)
      toDate = date.endOf('month').format(dateFormat)
      break
    case DateRangeType.Weekly:
      fromDate = selectedDate.startOf('week').format(dateFormat)
      toDate = selectedDate.endOf('week').format(dateFormat)
      break
    case DateRangeType.Range:
      fromDate = dateInterval.startDate.format(dateFormat)
      toDate = dateInterval.endDate.format(dateFormat)
      break
  }

  return {
    fromDate,
    toDate,
  }
}
