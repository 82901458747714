import { LoadingButton } from '@mui/lab'
import { Box, Typography } from '@mui/material'
import { getCarePersonPdfReport } from 'api/carePersons'
import { getContact } from 'api/contacts'
import { getMedicine } from 'api/medicine'
import { useGridControl } from 'hooks/useGridControl'
import React, { useCallback } from 'react'
import { useQuery } from 'react-query'
import { useGetDataSource } from 'utils'
import InfinityAgGrid from '../../../table/InfinityAgGrid'
import { contactsColumnDefs, medicinesColumnDefs } from '../const'

const buttonSX = { minWidth: 160, mt: 3, mb: 1 }

type Props = {
  carePersonId: number
  carePersonFullName: string
}

const ConcernProfileReportsForm = ({ carePersonId, carePersonFullName }: Props) => {
  const getMedicinesCallback = useCallback(() => {
    return getMedicine(carePersonId)
  }, [carePersonId])

  const getMedicineDataSource = useGetDataSource(getMedicinesCallback)
  const { onGridReady: onMedicineGridReady } = useGridControl(getMedicineDataSource)

  const getContactsCallback = useCallback(() => {
    return getContact(carePersonId)
  }, [carePersonId])

  const getContactsDataSource = useGetDataSource(getContactsCallback)
  const { onGridReady: onContactsGridReady } = useGridControl(getContactsDataSource)

  const getReport = useQuery('getReport', getCarePersonPdfReport, {
    enabled: false,
  })

  const handleGetPdfReportClick = useCallback(() => {
    getReport.refetch()
  }, [getReport])

  return (
    <Box className="ag-theme-alpine">
      <Typography variant="h5" textAlign="center">
        Summary Report
      </Typography>
      <Typography color="textSecondary" textAlign="center">
        from the Memory Care Partner App
      </Typography>
      <Typography color="textSecondary" textAlign="center" mb={2}>
        http://www.memorycarepartner.com/
      </Typography>

      <Typography fontWeight={400} variant="h6" mb={2}>
        {carePersonFullName}
      </Typography>

      <Typography fontWeight={400} variant="h6" mb={2}>
        Contacts
      </Typography>

      <InfinityAgGrid
        domLayout="autoHeight"
        columnDefs={contactsColumnDefs}
        onGridReady={onContactsGridReady}
      />

      <Typography fontWeight={400} variant="h6" mt={4} mb={2}>
        Medicines
      </Typography>

      <Typography color="textSecondary">Medicines for {carePersonFullName}</Typography>
      <Typography color="textSecondary" mb={1}>
        Include ALL prescription drugs over-the-counter drugs, vitamins, and herbal supplements):
      </Typography>

      <InfinityAgGrid
        domLayout="autoHeight"
        columnDefs={medicinesColumnDefs}
        onGridReady={onMedicineGridReady}
      />

      <LoadingButton
        loading={getReport.isLoading}
        variant="contained"
        sx={buttonSX}
        onClick={handleGetPdfReportClick}
      >
        Save as PDF
      </LoadingButton>
    </Box>
  )
}

export default ConcernProfileReportsForm
