import { ICellRendererParams } from 'ag-grid-community'
import { Engagement } from 'types'
import { PLACEHOLDER } from 'components/column-renderers'
import { formatEngagement } from './formatEngagement'

export const createEngagementRenderer =
  (propName: string) => (props: ICellRendererParams<{ [propName: string]: Engagement }>) => {
    const engagement = props?.data?.[propName]

    if (!engagement) {
      return PLACEHOLDER
    }

    return formatEngagement(engagement)
  }
