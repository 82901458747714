import {
  BaseTableRequest,
  CareTeamRequestCreate,
  CareTeam,
  User,
  CareTeamMemberRequestInvite,
  RequestById,
  TableResponse,
  Response,
} from 'types'
import { getFromLocalStorage, USER_DATA } from 'utils'
import axiosInstance from './axios'

export const getCareTeams = async (request: BaseTableRequest) => {
  return axiosInstance.post<BaseTableRequest, TableResponse<CareTeam>>('/care-teams', request)
}

export const createCareTeam = async (
  request: CareTeamRequestCreate,
): Promise<Response<CareTeam>> => {
  return axiosInstance.post('/care-teams/create', request)
}

export const getCareTeamById = async (id: number): Promise<Response<CareTeam>> => {
  return axiosInstance.post('/care-teams/read', { id })
}

export const updateCareTeam = async (request: Partial<CareTeam>): Promise<Response<CareTeam>> => {
  return axiosInstance.post('/care-teams/update', request)
}

export const deleteCareTeam = async (request: RequestById): Promise<unknown> => {
  return axiosInstance.post('/care-teams/delete', request)
}

export const inviteTeamMember = async (request: CareTeamMemberRequestInvite): Promise<unknown> => {
  const user = getFromLocalStorage(USER_DATA) as User | null
  return axiosInstance.post('/care-teams/invite', {
    ...request,
    userId: user?.id,
  })
}

export const withdrawInvitation = (request: RequestById): Promise<void> => {
  return axiosInstance.post('/care-teams/discard-invited', request)
}
