import { getCareArticles } from 'api/careArticles'
import { getCourses } from 'api/courses'
import { pathwaysAddPoints } from 'api/pathways'
import { getQuery } from 'api/query'
import React, { useCallback } from 'react'
import { useMutation } from 'react-query'
import { AddTrainingPathwayPointType, PathwayRequestEntityType } from 'types'
import { PathwayAddModalItem } from '../../models/Pathway.model'
import { PathwayAddModal } from '../PathwayAddModal/PathwayAddModal'

export function PathwayAddModalFactory({
  itemType,
  addedItems,
  pathwayId,
  onAdd,
  onClose,
}: {
  itemType: AddTrainingPathwayPointType
  addedItems: number[]
  pathwayId: number
  onAdd: () => void
  onClose: () => void
}) {
  const addPoints = useMutation(pathwaysAddPoints)

  const handleAdd = useCallback(
    (items: PathwayAddModalItem[]) => {
      const type: PathwayRequestEntityType =
        itemType === AddTrainingPathwayPointType.taskArticle ? 'careArticle' : itemType

      const entityIds = items.map((i) => i.id)
      addPoints.mutate(
        { entityType: type, entityIds, id: pathwayId },
        {
          onSuccess: () => {
            onAdd()
          },
        },
      )
    },
    [addPoints, itemType, onAdd, pathwayId],
  )

  if (itemType === AddTrainingPathwayPointType.course) {
    return (
      <PathwayAddModal
        title="Course"
        saving={addPoints.isLoading}
        addedItems={addedItems}
        getItems={() =>
          getCourses({
            filter: {
              isPublished: { type: 'boolean', op: 'equals', value: true },
            },
          })
        }
        handleClose={onClose}
        handleAdd={handleAdd}
      />
    )
  }

  if (itemType == AddTrainingPathwayPointType.query) {
    return (
      <PathwayAddModal
        title="Query"
        saving={addPoints.isLoading}
        addedItems={addedItems}
        getItems={() => getQuery({})}
        handleClose={onClose}
        handleAdd={handleAdd}
      />
    )
  }

  if (
    itemType == AddTrainingPathwayPointType.careArticle ||
    itemType == AddTrainingPathwayPointType.taskArticle
  ) {
    let title = 'Guide Post'
    if (itemType === AddTrainingPathwayPointType.taskArticle) {
      title = 'Action Post'
    }

    return (
      <PathwayAddModal
        title={title}
        saving={addPoints.isLoading}
        addedItems={addedItems}
        getItems={() =>
          getCareArticles({
            filter: {
              type: { type: 'text', op: 'equals', value: itemType },
              isPublished: { type: 'boolean', op: 'equals', value: true },
            },
          })
        }
        handleClose={onClose}
        handleAdd={handleAdd}
      />
    )
  }

  return null
}
