import { Button } from '@mui/material'
import { useCallback, useState } from 'react'
import { PathwayGroupedPoint } from 'types'
import { PathwayModalRemoveConfirm } from '../PathwayModalRemoveConfirm/PathwayModalRemoveConfirm'

export function PathwayControlPointDelete({
  pathwayId,
  points,
  onDeleted,
}: {
  pathwayId: number
  points: PathwayGroupedPoint[]
  onDeleted: () => void
}) {
  const [showDeleteModal, setShowDeleteModal] = useState(false)

  const handleClose = useCallback(() => {
    setShowDeleteModal(false)
  }, [])

  const handleDeleteButtonClick = useCallback(() => {
    setShowDeleteModal(true)
  }, [])

  return (
    <>
      {showDeleteModal && (
        <PathwayModalRemoveConfirm
          pathwayId={pathwayId}
          handleClose={handleClose}
          points={points}
          onDeleted={onDeleted}
        />
      )}
      <Button variant="contained" onClick={handleDeleteButtonClick}>
        Delete {points.length} items
      </Button>
    </>
  )
}
