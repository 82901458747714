import Breadcrumbs from 'components/Breadcumbs'
import React, { useMemo } from 'react'
import { routes } from 'routes/routes'
import { UserModel } from 'types'

export function UserDetailsBreadcrumbs({ user }: { user: UserModel }) {
  const path = useMemo(() => {
    return [
      { href: routes.users, text: 'Users' },
      {
        text: `${user.firstname} ${user.lastname}`,
      },
    ]
  }, [user.firstname, user.lastname])

  return <Breadcrumbs path={path} />
}
