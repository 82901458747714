import { getQueryById, updateQuery } from 'api/query'
import { getQuestionnaireById, updateQuestionnaire } from 'api/questionaries'
import { getQuizById, updateQuiz } from 'api/quiz'
import { Query, QuestioningType, Questionnaire, Quiz, RequestById, Response } from 'types'

type IExamParamsByType = {
  [key in QuestioningType]: {
    apiMethodLoad: (request: RequestById) => Promise<Response<Quiz | Questionnaire | Query>>
    apiMethodSave: Function
    messageSuccess: string
    messageFail: string
  }
}

export const ExamParamsByType: IExamParamsByType = {
  quiz: {
    apiMethodLoad: getQuizById,
    apiMethodSave: updateQuiz,
    messageSuccess: 'The Quiz was saved in the Lesson',
    messageFail: "Can't load data of Quiz",
  },
  query: {
    apiMethodLoad: getQueryById,
    apiMethodSave: updateQuery,
    messageSuccess: 'The Query was saved in the Learn Library Article',
    messageFail: "Can't load data of Query",
  },
  questionnaire: {
    apiMethodLoad: getQuestionnaireById,
    apiMethodSave: updateQuestionnaire,
    messageSuccess: 'The Exam was saved in the Course',
    messageFail: "Can't load data of Exam",
  },
}
