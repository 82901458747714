import Drawer from '@mui/material/Drawer'
import { createNote, deleteNote, getNoteById, updateNote } from 'api/notes'
import { defaultDrawerSX } from 'const'
import { UIPencilImg } from 'features/UI'
import React, { useCallback, useEffect, useState } from 'react'
import { useMutation, useQuery } from 'react-query'
import { useActions } from 'store/hooks'
import { Note } from 'types'
import { useAuthContext } from '../../context/AuthProvider'
import NotificationSys from '../../NotificationSystem'
import DrawerContainer from '../common/DrawerContainer'
import DrawerHeader from '../common/DrawerHeader'
import NoteEditForm from './NoteEditForm'

interface Props {
  isOpen: boolean
  id?: number
  handleClose: () => void
  handleOpen: (id?: number) => void
}

const EditNoteDrawer = (props: Props) => {
  const { isOpen, id, handleClose, handleOpen } = props

  const [data, setData] = useState<Note | undefined>(undefined)

  const { user } = useAuthContext()
  const { getTimelineById } = useActions()

  useEffect(() => {
    if (!isOpen) {
      setData(undefined)
    }
  }, [isOpen])

  const query = useQuery(['getNoteById', { id }], () => getNoteById(id ?? -1), {
    enabled: isOpen && !!id,
    select: (req) => req.data,
    onSuccess: setData,
    cacheTime: 0,
    staleTime: 0,
  })

  const createMutation = useMutation(createNote, {
    onSuccess: () => {
      getTimelineById({})
      NotificationSys.showSuccess(`Note successfully created`)
      handleClose()
    },
  })

  const updateMutation = useMutation(updateNote, {
    onSuccess: (response) => {
      setData(response.data)
      getTimelineById({})
      NotificationSys.showSuccess(`Note successfully updated`)
      handleClose()
    },
  })

  const removeMutation = useMutation(deleteNote, {
    onSuccess: () => {
      getTimelineById({})
      NotificationSys.showSuccess(`Note successfully removed`)
      handleClose()
    },
  })

  const onRemoveNote = useCallback(() => {
    if (id) {
      removeMutation.mutate(id)
    }
  }, [id, removeMutation])

  const onSubmitHandler = (values: { subject: string; body: string; link: string }) => {
    if (data) {
      updateMutation.mutate({
        ...values,
        id: data.id,
        userId: data.userId,
        createdAt: data?.createdAt,
        authorId: data.authorId,
        authorName: data.authorName,
      })
    } else {
      createMutation.mutate({
        ...values,
        userId: user?.id ?? -1,
      })
    }
  }

  return (
    <Drawer anchor="right" open={isOpen} onClose={handleClose} sx={defaultDrawerSX}>
      <DrawerContainer
        isOpen={isOpen}
        hasData={!query.isError}
        loading={
          query.isLoading ||
          createMutation.isLoading ||
          updateMutation.isLoading ||
          removeMutation.isLoading
        }
        handleClose={handleClose}
      >
        <DrawerHeader handleClose={handleClose} borderEnable={true}>
          <UIPencilImg />
          <span>{id === null ? 'Note to add to Timeline' : 'Edit Note'}</span>
        </DrawerHeader>
        <NoteEditForm
          values={data}
          onSubmitHandler={onSubmitHandler}
          removeDisabled={id === null}
          onRemove={onRemoveNote}
        />
      </DrawerContainer>
    </Drawer>
  )
}

export default EditNoteDrawer
