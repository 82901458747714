import styled from '@emotion/styled'

export const ArticlePreviewTitle = styled.h4`
  margin-top: 0;
  font-size: 25px;
  font-weight: 400;
  margin-bottom: 18px;
`

export const ArticlePreviewContainer = styled.div<{
  width: string
  height: string
  padding: string
}>`
  width: ${({ width }) => width};
  padding: ${({ padding }) => padding};
  height: ${({ height }) => height};

  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
    'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  overflow-y: auto;
  scrollbar-width: thin;
  line-height: 1.6em;

  code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
  }

  .ag-overlay-loading-wrapper {
    transition: background-color 200ms linear;
  }
  @media (min-width: 651px) {
    .ce-block__content {
      margin: 0 0 0 72px;
    }
  }

  .ce-toolbar__actions {
    right: unset !important;
    left: 0 !important;
  }

  .ce-toolbar__content {
    margin: unset !important;
  }

  img {
    display: block;
    max-width: 100%;
    height: auto;
  }

  p.preview_paragraph {
    margin: 0;
  }

  .text-max-lines-1 {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1; /* number of lines to show */
    line-clamp: 1;
    -webkit-box-orient: vertical;
  }

  .text-max-lines-2 {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2; /* number of lines to show */
    line-clamp: 2;
    -webkit-box-orient: vertical;
  }

  .text-max-lines-3 {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3; /* number of lines to show */
    line-clamp: 3;
    -webkit-box-orient: vertical;
  }

  .sdx-list {
    padding-left: 32px !important;
  }

  .cdx-list li {
    margin-bottom: 8px;
    line-height: 1.6em;
    padding: 0 !important;
  }

  .img {
    display: block;
    max-width: 100%;
  }

  .ag-dnd-ghost {
    display: none;
  }

  .gap-4 {
    gap: 4px;
  }

  .gap-8 {
    gap: 8px;
  }

  .gap-16 {
    gap: 16px;
  }

  .cdx-personality {
    display: flex;
    justify-content: space-between;
  }

  .cdx-personality [contentEditable='true'][data-placeholder]::before {
    display: none;
    position: absolute;
    content: attr(data-placeholder);
    color: #707684;
    font-weight: normal;
    opacity: 0;
    cursor: text;
  }

  .cdx-personality [contentEditable='true'][data-placeholder]:empty::before {
    display: block;
    opacity: 1;
  }

  .cdx-personality [contentEditable='true'][data-placeholder]:empty:focus::before {
    display: block;
    opacity: 0.3;
  }

  .cdx-personality__photo-wrapper {
    margin-top: 4px;
    padding-bottom: 16px;
    width: 50%;
    max-width: 50%;
    margin-right: 16px;
  }

  @media (max-width: 600px) {
    .cdx-personality__photo-wrapper {
      width: 100%;
      max-width: 100%;
    }
  }

  .cdx-personality__photo {
    display: block;
    width: 100%;
    max-width: 100%;
    height: auto;
    background: #f6f6f9
      url('data:image/svg+xml,<svg fill="rgb(171, 175, 188)" width="35" height="41" xmlns="http://www.w3.org/2000/svg"><path d="M17.347 22.087h.272c2.495-.042 4.514-.916 6.004-2.589 3.278-3.684 2.733-10 2.674-10.602-.213-4.524-2.359-6.689-4.13-7.7C20.847.442 19.305.035 17.585 0H17.441c-.946 0-2.802.153-4.582 1.163-1.788 1.01-3.968 3.175-4.181 7.733-.06.603-.605 6.918 2.674 10.602 1.481 1.673 3.5 2.547 5.995 2.59zM10.95 9.108c0-.025.009-.05.009-.068.28-6.086 4.615-6.74 6.472-6.74H17.534c2.3.051 6.208.985 6.472 6.74 0 .026 0 .051.009.068.008.06.604 5.832-2.104 8.87-1.073 1.206-2.503 1.8-4.385 1.817h-.086c-1.873-.017-3.312-.61-4.377-1.816-2.7-3.022-2.12-8.82-2.112-8.87z"/><path d="M34.983 32.562v-.025c0-.068-.009-.136-.009-.212-.05-1.681-.161-5.611-3.857-6.868l-.085-.025c-3.841-.976-7.034-3.183-7.068-3.209a1.15 1.15 0 0 0-1.601.28 1.142 1.142 0 0 0 .28 1.596c.145.102 3.535 2.453 7.775 3.54 1.985.704 2.206 2.818 2.266 4.754 0 .076 0 .144.008.212.009.764-.042 1.944-.179 2.623-1.38.78-6.787 3.48-15.013 3.48-8.192 0-13.634-2.708-15.022-3.489-.136-.679-.196-1.859-.179-2.623 0-.068.009-.136.009-.212.06-1.935.28-4.049 2.265-4.754 4.24-1.086 7.63-3.446 7.775-3.54.52-.364.647-1.077.281-1.595a1.15 1.15 0 0 0-1.601-.28c-.034.025-3.21 2.232-7.068 3.208-.034.009-.06.017-.085.026C.179 26.714.068 30.644.017 32.316c0 .077 0 .144-.008.212v.026c-.009.441-.018 2.708.434 3.845.085.22.238.408.443.535.255.17 6.378 4.058 16.623 4.058 10.244 0 16.367-3.897 16.622-4.058.196-.127.358-.314.443-.535.426-1.129.417-3.395.409-3.837z"/></svg>')
      center center no-repeat;
    cursor: pointer;
    overflow: hidden;
  }

  .cdx-personality__photo-preview {
    min-height: 300px;
  }

  .cdx-personality__description {
    width: 50%;
    max-width: 50%;
    font-size: inherit;
    outline: none;
    line-height: 1.6em;
  }

  .codex-editor--narrow .cdx-personality {
    padding: 15px;
  }
`
