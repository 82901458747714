import { zodResolver } from '@hookform/resolvers/zod/dist/zod'
import { Button, CircularProgress, Grid } from '@mui/material'
import { updateCarePerson } from 'api/carePersons'
import { EMAIL_IS_INVALID } from 'const'
import moment from 'moment'
import React from 'react'
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form'
import { useMutation } from 'react-query'
import { BackendGender, CarePerson } from 'types'
import { TypeOf, z } from 'zod'
import FormDatePicker from '../../../form-elements/FormDatePicker'
import FormInput from '../../../form-elements/FormInput'
import FormRadioGroup from '../../../form-elements/FormRadioGroup'

const genderOptions = {
  male: BackendGender.male,
  female: BackendGender.female,
  'Prefer to self describe': BackendGender.selfDescribe,
  'Prefer not to answer': BackendGender.notAnswer,
}

type Props = {
  carePerson: CarePerson
  readonly?: boolean
  onPersonUpdated: (person: CarePerson) => void
}

const required = z
  .string({
    required_error: 'Required',
    invalid_type_error: 'Invalid value',
  })
  .min(1, 'Required')

const formSchema = z.object({
  email: required.email(EMAIL_IS_INVALID),
  firstname: required,
  lastname: required,
  streetAddress: required,
  zipCode: required,
  country: required,
  city: required,
  state: required,
  phoneNumber: required,
  dateOfBirth: z.date({
    required_error: 'Requried',
    invalid_type_error: 'Invalid date',
    coerce: true,
  }),
  gender: z.string().transform((value, ctx) => {
    if (value === null) {
      ctx.addIssue({ code: 'custom', message: 'Required' })
    }

    return value
  }),
})

type FormType = TypeOf<typeof formSchema>

const formSettings = {
  mode: 'onChange' as const,
  resolver: zodResolver(formSchema),
}

const ConcernProfileBasicInfoForm = ({ carePerson, readonly, onPersonUpdated }: Props) => {
  const formMethods = useForm<FormType>({
    ...formSettings,
    defaultValues: {
      city: carePerson.city ?? '',
      country: carePerson.country ?? '',
      email: carePerson.email ?? '',
      firstname: carePerson.firstname ?? '',
      gender: carePerson.gender ?? '',
      lastname: carePerson.lastname ?? '',
      phoneNumber: carePerson.phoneNumber ?? '',
      state: carePerson.state ?? '',
      streetAddress: carePerson.streetAddress ?? '',
      zipCode: carePerson.zipCode ?? '',
      dateOfBirth: moment(carePerson.dateOfBirth).toDate(),
    },
  })

  const onSubmitHandler: SubmitHandler<FormType> = (values: FormType) => {
    updater.mutate(
      {
        city: values.city,
        country: values.country,
        dateOfBirth: moment(values.dateOfBirth).format('YYYY-MM-DD'),
        email: values.email,
        firstname: values.firstname,
        gender: values.gender,
        streetAddress: values.streetAddress,
        zipCode: values.zipCode,
        lastname: values.lastname,
        phoneNumber: values.phoneNumber,
        state: values.state,
      },
      {
        onSuccess: ({ data }) => {
          onPersonUpdated(data)
        },
      },
    )
  }

  const updater = useMutation((values: Partial<CarePerson>) =>
    updateCarePerson(carePerson.id, values),
  )

  return (
    <FormProvider {...formMethods}>
      <form onSubmit={formMethods.handleSubmit(onSubmitHandler)} noValidate>
        <FormInput
          disabled={readonly}
          label="Email Address"
          required
          fullWidth
          sx={{ mb: 1 }}
          name="email"
        />

        <Grid container columnSpacing={2}>
          <Grid item sm={6} xs={12}>
            <FormInput
              disabled={readonly}
              label="First Name"
              required
              fullWidth
              name="firstname"
              sx={{ mb: 1 }}
            />
          </Grid>
          <Grid item sm={6} xs={12}>
            <FormInput
              disabled={readonly}
              label="Last Name"
              required
              fullWidth
              name="lastname"
              sx={{ mb: 1 }}
            />
          </Grid>

          <Grid item sm={6} xs={12}>
            <FormInput
              disabled={readonly}
              label="Street Address"
              required
              fullWidth
              name="streetAddress"
              sx={{ mb: 1 }}
            />
          </Grid>
          <Grid item sm={6} xs={12}>
            <FormInput
              disabled={readonly}
              label="Zip Code"
              required
              fullWidth
              name="zipCode"
              sx={{ mb: 1 }}
            />
          </Grid>

          <Grid item sm={6} xs={12}>
            <FormInput
              disabled={readonly}
              label="City"
              required
              fullWidth
              name="city"
              sx={{ mb: 1 }}
            />
          </Grid>
          <Grid item sm={6} xs={12}>
            <FormInput
              disabled={readonly}
              label="State"
              required
              fullWidth
              name="state"
              sx={{ mb: 1 }}
            />
          </Grid>
        </Grid>

        <Grid container columnSpacing={2} sx={{ mb: 1 }}>
          <Grid item sm={6} xs={12}>
            <FormInput disabled={readonly} label="Country" required fullWidth name="country" />
          </Grid>
        </Grid>

        <Grid container spacing={2}>
          <Grid item sm={6} xs={12}>
            <FormInput
              disabled={readonly}
              label="Phone Number"
              required
              fullWidth
              name="phoneNumber"
            />
          </Grid>
          <Grid item sm={6} xs={12}>
            <FormDatePicker
              disabled={readonly}
              label="Date of Birth"
              name="dateOfBirth"
              required
              fullWidth
            />
          </Grid>
          <Grid item sm={6} xs={12}>
            <FormRadioGroup
              disabled={readonly}
              label="Gender"
              required
              name="gender"
              options={Object.entries(genderOptions).map((e) => ({ label: e[0], value: e[1] }))}
            />
          </Grid>
        </Grid>

        {!readonly && (
          <Button
            variant="contained"
            type="submit"
            sx={{ minWidth: 160, height: 36.5, mt: 3, mb: 1, color: '#FFF' }}
          >
            {updater.isLoading && <CircularProgress size="1.5rem" color="inherit" />}
            {!updater.isLoading && 'Save'}
          </Button>
        )}
      </form>
    </FormProvider>
  )
}

export default ConcernProfileBasicInfoForm
