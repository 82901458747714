import { LoadingButton } from '@mui/lab'
import { Box, Stack } from '@mui/material'
import { CardElement, Elements, useElements, useStripe } from '@stripe/react-stripe-js'
import { loadStripe } from '@stripe/stripe-js'
import { StripeCardElement } from '@stripe/stripe-js/types/stripe-js/elements'
import StyledCardElement from 'components/form-elements/StyledCardElement'
import NotificationSys from 'components/NotificationSystem'
import { STRIPE_KEY } from 'const'
import React, { useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { routes } from 'routes/routes'
import { createPaymentIntent } from '../../api/payment'
import { AuthFormTitle } from '../AuthFormTitle/AuthFormTitle'
import { TraineeFormType } from './types'

interface Props {
  formData: TraineeFormType
}

const InnerForm = (props: Props) => {
  const { formData } = props
  const stripe = useStripe()
  const elements = useElements()
  const navigate = useNavigate()

  const [loading, setLoading] = useState(false)

  const handleSubmitSub = async () => {
    if (!stripe || !elements) {
      return
    }

    try {
      setLoading(true)

      const result = await stripe.createPaymentMethod({
        type: 'card',
        card: elements.getElement(CardElement) as StripeCardElement,
        billing_details: {
          email: formData.email,
        },
      })

      if (result.error) {
        setLoading(false)
        const warning = (
          <>
            Your payment has been declined.
            <br />
            {result.error.message}
          </>
        )
        NotificationSys.showWarning(warning)
      } else {
        const res = await createPaymentIntent(
          formData.email,
          result.paymentMethod.id,
          'trainee',
          formData,
        )

        const { client_secret, status } = res.data

        if (status === 'requires_action') {
          stripe.confirmCardPayment(client_secret).then(function (result) {
            if (result.error) {
              setLoading(false)
              const warning = (
                <>
                  Your payment has been declined.
                  <br />
                  Please check the correctness of the data entry or the operation of the card
                </>
              )
              NotificationSys.showWarning(warning)
              console.error('Payment error:', result.error)
            } else {
              navigate(`${routes.successSubscription}?type=trainee`)
            }
          })
        } else {
          navigate(`${routes.successSubscription}?type=trainee`)
        }
      }
    } finally {
      setLoading(false)
    }
  }

  if (!stripe || !elements) {
    return <div>Loading...</div>
  }

  return (
    <Stack direction="column" justifyContent="space-between">
      <AuthFormTitle textColor="secondary.main">
        Full access to MemoryCarePartner Pro Training
        <br /> for one year using a credit card: $90
      </AuthFormTitle>

      <StyledCardElement />

      <Box display="flex" justifyContent="center" sx={{ mt: 4 }}>
        <LoadingButton
          type="submit"
          loading={loading}
          variant="contained"
          color="secondary"
          sx={{ minWidth: 200 }}
          disabled={!stripe || !elements}
          onClick={handleSubmitSub}
        >
          Submit Payment
        </LoadingButton>
      </Box>
    </Stack>
  )
}

const TraineeCardForm = (props: Props) => {
  const stripePromise = useMemo(() => {
    return loadStripe(STRIPE_KEY)
  }, [])

  return (
    <Elements stripe={stripePromise}>
      <InnerForm formData={props.formData} />
    </Elements>
  )
}

export default TraineeCardForm
