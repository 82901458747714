import React from 'react'
import { ETimelinePostStatus } from 'types'
import { UITag } from '../UITag/UITag'

type IStatusMap = {
  [key in ETimelinePostStatus | string]?: {
    title: string
    color: string
  }
}

const StatusMap: IStatusMap = {
  review: {
    title: 'Continue',
    color: '#8094A1',
  },
  retake: {
    title: 'Retake',
    color: '#8094A1',
  },
  new: {
    title: 'New',
    color: '#99285F',
  },
  done: {
    title: 'Done',
    color: '#8094A1',
  },
}

export function UIStatusBadge({ status }: { status: ETimelinePostStatus | string }) {
  const statusData = StatusMap[status]

  if (!statusData) {
    return null
  }

  return <UITag background={statusData.color}>{statusData.title}</UITag>
}
