import EditorJS, { EditorConfig } from '@editorjs/editorjs'
import { OutputData } from '@editorjs/editorjs/types/data-formats/output-data'
// @ts-ignore
import Header from '@editorjs/header'
// @ts-ignore
import ImageTool from '@editorjs/image'
// @ts-ignore
import List from '@editorjs/list'
import { Box } from '@mui/material'
import { Block } from 'editorjs-blocks-react-renderer'
// @ts-ignore
import ColorPlugin from 'editorjs-text-color-plugin'
import { ImageEditorModal } from 'features/ImageEditor/components/ImageEditorModal/ImageEditorModal'
import { UIShadowContainer } from 'features/UI'
import { useShowControl } from 'hooks/useShowControl'
import React, {
  ForwardedRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from 'react'
import { base64ToFile, fileToBase64 } from 'utils'
import { v4 as uuid } from 'uuid'
import { EditorAPI } from '../../models/ArticleEditor.model'
import { ImageAndText } from '../../plugins/ImageAndText/ImageAndText'
// @ts-ignore
import { YoutubeEmbed } from '../../plugins/YoutubeEmbed/YoutubeEmbed'

const EditIcon =
  '<?xml version="1.0" ?><svg class="feather feather-edit" fill="none" height="24" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg"><path d="M11 4H4a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2v-7"/><path d="M18.5 2.5a2.121 2.121 0 0 1 3 3L12 15l-4 1 1-4 9.5-9.5z"/></svg>'

interface Props {
  data?: OutputData | null
}

const Editor = ({ data }: Props, ref: ForwardedRef<EditorAPI>) => {
  const elementId = 'editor'
  const editor = useRef<EditorJS | null>(null)

  const [editedBlockData, setEditedBlockData] = useState<Block | null>(null)
  const [isOpenImageEditor, onOpenImageEditor, onCloseImageEditor] = useShowControl()

  const editedBase64 = useMemo(() => {
    if (!editedBlockData) return null

    if (editedBlockData.type === 'image') {
      return editedBlockData.data.file.url
    }

    if (editedBlockData.type === 'imageAndText') {
      return editedBlockData.data.photo
    }

    return null
  }, [editedBlockData])

  const handleEditImage = useCallback(
    async (blockIndex: number) => {
      if (!editor.current) {
        return
      }

      const { blocks } = await editor.current.save()
      const block = blocks[blockIndex]
      setEditedBlockData(block)
      onOpenImageEditor()
    },
    [onOpenImageEditor],
  )

  const handleSaveImage = useCallback(
    async (base64: string) => {
      if (!editor.current) return
      if (!editedBlockData || !editedBlockData.id) return

      const file = await base64ToFile(base64, uuid())
      if (!file) return

      if (editedBlockData.type === 'imageAndText') {
        const blockData = {
          ...editedBlockData.data,
          file,
          photo: base64,
        }
        editor.current.blocks.update(editedBlockData.id, blockData)
        return
      }

      const blockData = {
        ...editedBlockData.data,
        file: {
          file,
          url: base64,
        },
      }

      editor.current.blocks.update(editedBlockData.id, blockData)
    },
    [editedBlockData],
  )

  const imageToolConfig = useMemo(() => {
    return {
      uploader: {
        uploadByFile(file: File) {
          const formData = new FormData()
          formData.append('image', file)

          return fileToBase64(file).then((base64) => {
            return {
              success: 1,
              file: {
                url: base64,
                file,
              },
            }
          })
        },
      },
      actions: [
        {
          name: 'edit',
          icon: EditIcon,
          title: 'Edit',
          toggle: false,
          action: () => {
            if (!editor.current) return

            const blockIndex = editor.current.blocks.getCurrentBlockIndex()
            handleEditImage(blockIndex)
            editor.current.toolbar.close()
          },
        },
      ],
    }
  }, [handleEditImage])

  const baseConfig: EditorConfig = {
    holder: elementId,

    tools: {
      header: {
        class: Header,
        inlineToolbar: true,
      },
      image: {
        class: ImageTool,
        config: imageToolConfig,
      },
      list: {
        class: List,
        inlineToolbar: true,
      },
      youtubeEmbed: YoutubeEmbed,
      imageAndText: {
        class: ImageAndText,
        config: {
          ...imageToolConfig,
          descriptionPlaceholder: 'Please input text',
        },
      },
      Color: {
        class: ColorPlugin,
        config: {
          colorCollections: [
            '#FF1300',
            '#EC7878',
            '#9C27B0',
            '#673AB7',
            '#3F51B5',
            '#0070FF',
            '#03A9F4',
            '#00BCD4',
            '#4CAF50',
            '#8BC34A',
            '#CDDC39',
            '#FFF',
          ],
          defaultColor: '#FF1300',
          type: 'text',
          customPicker: true,
        },
      },
    },
  }

  useEffect(() => {
    if (!editor.current) {
      editor.current = new EditorJS({
        ...baseConfig,
        data: data ?? undefined,
      })
    }

    return () => {
      if (editor.current && editor.current.destroy) {
        editor.current.destroy()
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useImperativeHandle(
    ref,
    () => {
      return {
        getData: () => {
          if (!editor.current) {
            return Promise.reject([])
          }
          return editor.current?.save()
        },
      }
    },
    [],
  )

  return (
    <Box
      sx={{ minWidth: '100%', flexGrow: 1, display: 'flex' }}
      flexDirection="column"
      alignItems="center"
    >
      <UIShadowContainer
        sx={{
          flexGrow: 1,
          background: '#fff',
          width: '100%',
          maxWidth: 800,
          paddingTop: 2,
          paddingX: 4,
          marginY: 'auto',
          paddingBottom: 2,
        }}
      >
        <div style={{ display: 'block' }} id={elementId} />
      </UIShadowContainer>

      {editedBase64 && (
        <ImageEditorModal
          imgData={editedBase64}
          isOpen={isOpenImageEditor}
          hasRatioRestrictions={false}
          onClose={onCloseImageEditor}
          onSave={handleSaveImage}
        />
      )}
    </Box>
  )
}

export const ArticleEditor = React.memo(React.forwardRef<EditorAPI, Props>(Editor))
