import React from 'react'
import { Checkbox, FormControlLabel, Link, Stack, Typography } from '@mui/material'
import { TERMS_OF_USE } from '../../routes/routes'
import { Controller, useFormContext } from 'react-hook-form'

interface Props {
  name: string
}

const AgreeTermsCheckbox = (props: Props) => {
  const { name } = props

  const {
    control,
    formState: { errors },
  } = useFormContext()

  return (
    <Stack>
      <FormControlLabel
        control={
          <Controller
            control={control}
            name={name}
            render={({ field }) => (
              <Checkbox
                name={name}
                checked={field.value}
                color="primary"
                required={true}
                onChange={(e) => field.onChange(e.target.checked)}
              />
            )}
          />
        }
        label={
          <Typography variant="body2">
            By clicking here you indicate you have read and agree to our{' '}
            <Link color="inherit" href={TERMS_OF_USE} target="_blank">
              Terms and Conditions
            </Link>
            .
          </Typography>
        }
      />
      {errors[name] ? (
        <Typography variant="body2" sx={{ fontSize: '0.75rem' }} color="error">
          {(errors[name] as { message?: string })?.message || ''}
        </Typography>
      ) : (
        ''
      )}
    </Stack>
  )
}

export default AgreeTermsCheckbox
