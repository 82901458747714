// @ts-ignore
import { stringExcel } from '@json2csv/formatters'
// @ts-ignore
import { Parser } from '@json2csv/plainjs'
import { getPathway, pathwaysOrderPoints } from 'api/pathways'
import PageTitle from 'components/page/PageTitle'
import Loader from 'components/placeholders/ModalLoadingPlaceholder'
import TableToolbar from 'components/table/TableToolbar'
import FileSaver from 'file-saver'
import React, { useCallback, useMemo, useState } from 'react'
import { useMutation, useQuery } from 'react-query'
import { ETrainingPathwayPointType, Pathway, PathwayCoursePoint, PathwayGroupedPoint } from 'types'
import { useSearchParamsObject } from 'utils'
import { getPathwayCsvColumns } from '../../models/PathwayColumns'
import { PathwayControlPointAdd } from '../PathwayControlPointAdd/PathwayControlPointAdd'
import { PathwayControlPointDelete } from '../PathwayControlPointDelete/PathwayControlPointDelete'
import { PathwayGrid } from '../PathwayGrid/PathwayGrid'
import { PathwayModalRemoveConfirm } from '../PathwayModalRemoveConfirm/PathwayModalRemoveConfirm'
import { PathwayToolbarLeft } from '../PathwayToolbarLeft/PathwayToolbarLeft'
import { Metadata } from 'features/Metadata'
import { usePathwayMetadata } from 'features/Pathway/hooks/usePathwayMetadata'
import { Box } from '@mui/material'

export function PathwayPageEdit() {
  const [pathway, setPathway] = useState<Pathway>()

  const points = useMemo(() => {
    if (!pathway) {
      return []
    }

    const groupedPoints: PathwayGroupedPoint[] = []
    const coursesIndexes: { [key: number]: number } = {}

    pathway.points.forEach((point) => {
      if (
        point.type === ETrainingPathwayPointType.lesson ||
        point.type === ETrainingPathwayPointType.questionnaire
      ) {
        const courseId = point[point.type]?.courseId
        if (!courseId) {
          return
        }

        if (coursesIndexes[courseId] === undefined) {
          coursesIndexes[courseId] = groupedPoints.length
          groupedPoints.push({
            id: courseId,
            name: point[point.type]?.course.name || '',
            createdAt: point[point.type]?.course.createdAt || '',
            updatedAt: point[point.type]?.course.updatedAt || '',
            type: 'course',
            order: point.order,
            points: [],
          })
        }

        const course = groupedPoints[coursesIndexes[courseId]] as PathwayCoursePoint
        course.points.push(point)
        return
      }

      groupedPoints.push(point)
    })

    return groupedPoints
  }, [pathway])

  const [pathwayPointToRemove, setPathwayPointToRemove] = useState<PathwayGroupedPoint>()
  const [selectedPoints, setSelectedPoints] = useState<PathwayGroupedPoint[]>([])

  const params = useSearchParamsObject()
  const id = useMemo(() => {
    const value = parseInt(params.id as string)
    if (!isNaN(value)) {
      return value
    }

    return 0
  }, [params])

  const getPathwayQuery = useQuery(['getPathwayQuery', id], () => getPathway({ id: id }), {
    enabled: !!id,
    onSuccess: ({ data }) => {
      setPathway(data)
    },
  })

  const orderPoints = useMutation(pathwaysOrderPoints)
  const handleReorder = useCallback(
    (ids: number[]) => {
      const result: number[] = []
      ids.forEach((id) => {
        const point = points.find((p) => p.id === id)
        if (!point) return
        if (point.type === 'course') {
          const ids = point.points.map((p) => p.id)
          result.push(...ids)
        } else {
          result.push(id)
        }
      })
      orderPoints.mutate(
        { id, points: result },
        {
          onSuccess: () => {
            if (pathway) {
              const orderedPoints = [...pathway.points].sort(
                (a, b) => result.indexOf(a.id) - result.indexOf(b.id),
              )
              setPathway({ ...pathway, points: orderedPoints })
            }
          },
          onError: () => {
            if (pathway) {
              setPathway({ ...pathway, points: [...pathway.points] })
            }
          },
        },
      )
    },
    [id, orderPoints, pathway, points],
  )

  const handleAdd = useCallback(() => {
    getPathwayQuery.refetch()
  }, [getPathwayQuery])

  const handleSelectionChanged = useCallback(
    (points: PathwayGroupedPoint[]) => {
      setSelectedPoints(points)
    },
    [setSelectedPoints],
  )

  const handlePointsDeleted = useCallback(() => {
    getPathwayQuery.refetch()
    setSelectedPoints([])
  }, [getPathwayQuery])

  const handlePointDeleted = useCallback(() => {
    if (pathwayPointToRemove) {
      getPathwayQuery.refetch()
      setPathwayPointToRemove(undefined)

      if (selectedPoints.includes(pathwayPointToRemove)) {
        setSelectedPoints(selectedPoints.filter((s) => s !== pathwayPointToRemove))
      }
    }
  }, [pathwayPointToRemove, getPathwayQuery, selectedPoints])

  const handleDeleteSinglePoint = useCallback(
    (id: number) => {
      const point = points.find((p) => p.id === id)
      if (point) {
        setPathwayPointToRemove(point)
      }
    },
    [points],
  )

  const handleRemovalModalClose = useCallback(() => {
    setPathwayPointToRemove(undefined)
  }, [])

  const handleSettingsChanged = useCallback(
    (newPostsCount: number) => {
      if (pathway) {
        setPathway({ ...pathway, newTimelinePostCount: newPostsCount })
      }
    },
    [pathway],
  )

  const csvFields = getPathwayCsvColumns()

  const handleExportCsv = useCallback(() => {
    try {
      const opts = {
        delimiter: ',',
        fields: csvFields,
        formatters: {
          string: stringExcel,
        },
      }

      const parser = new Parser(opts)
      const csv = parser.parse(points)
      const blob = new Blob([csv], { type: 'text/plain;charset=utf-8' })
      FileSaver.saveAs(blob, pathway ? `${pathway.name} pathway.csv` : 'pathway.csv')
    } catch (e: unknown) {
      console.error(e)
    }
  }, [csvFields, pathway, points])

  const metadata = usePathwayMetadata(getPathwayQuery.data)

  if (getPathwayQuery.isLoading) {
    return <Loader show={true} />
  }

  return (
    <>
      <PageTitle>Pathway View</PageTitle>

      <TableToolbar
        left={<PathwayToolbarLeft pathway={pathway} onSettingsChanged={handleSettingsChanged} />}
        exportCSVEnable
        csvLoading={false}
        onExportCSV={handleExportCsv}
      >
        {!!selectedPoints.length && (
          <PathwayControlPointDelete
            pathwayId={id}
            points={selectedPoints}
            onDeleted={handlePointsDeleted}
          />
        )}
        {!!pathway && (
          <PathwayControlPointAdd
            id={id}
            points={points}
            onAdd={handleAdd}
            type={pathway?.trainingPathwayTypeId}
          />
        )}
      </TableToolbar>

      <Box sx={{ mb: 2 }}>
        <Metadata data={metadata} />
      </Box>

      <PathwayGrid
        points={points}
        onDelete={handleDeleteSinglePoint}
        onReorder={handleReorder}
        onSelectionChanged={handleSelectionChanged}
      />

      {!!pathwayPointToRemove && (
        <PathwayModalRemoveConfirm
          points={[pathwayPointToRemove]}
          onDeleted={handlePointDeleted}
          pathwayId={id}
          handleClose={handleRemovalModalClose}
        />
      )}
    </>
  )
}
