import { ColDef, ICellRendererParams } from 'ag-grid-community'
import { PLACEHOLDER } from 'components/column-renderers'
import flatten from 'lodash/flatten'
import uniqBy from 'lodash/uniqBy'
import { Tag } from 'types'
import { isDefined } from 'utils'
import { TagsCellRenderer } from '../components/TagsCellRenderer/TagsCellRenderer'

export const createTagsColumn = (field: string): ColDef => {
  return {
    headerName: 'Tags',
    field,
    minWidth: 300,
    sortable: false,
    cellRenderer: (props: ICellRendererParams<{ [field: string]: Tag[] }>) => {
      const tags = props.data?.[field]
      if (isDefined(tags)) {
        return <TagsCellRenderer tags={tags} />
      }
      return PLACEHOLDER
    },
  }
}

export const createSummaryTagsColumn = (childEntityField: string): ColDef => {
  return {
    headerName: 'Tags',
    minWidth: 300,
    sortable: false,
    cellRenderer: (props: ICellRendererParams) => {
      if (!props.data) return PLACEHOLDER

      const childList = props.data[childEntityField]
      if (!childList) return PLACEHOLDER

      const allTags = childList.map((child: { tags: Tag[] }) => child.tags)
      const uniqTags: Tag[] = uniqBy(flatten(allTags), 'id')

      return <TagsCellRenderer tags={uniqTags} />
    },
  }
}

export const createSummaryTagsColumnCSV = (propName: string, label = 'Tags') => ({
  label,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  value: (row: any) => {
    if (!row[propName]) return PLACEHOLDER
    const allTags = row[propName].map((child: { tags: Tag[] }) => child.tags)
    const uniqTags: Tag[] = uniqBy(flatten(allTags), 'id')
    return uniqTags.map(({ name }) => name).join(', ')
  },
})

export const createTagsColumnCSV = (propName: string, label = 'Tags') => ({
  label,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  value: (row: any) => {
    if (!isDefined(row[propName])) return PLACEHOLDER
    return row[propName].map((tag: Tag) => tag.name).join(', ')
  },
})
