import React from 'react'
import { Box } from '@mui/material'
import { CardElement } from '@stripe/react-stripe-js'
import { StripeCardElementOptions } from '@stripe/stripe-js'

const sx = { padding: 1.5, boxRadius: '8px', border: '1px solid #CACACA' }

const options: StripeCardElementOptions = {
  style: {
    base: {
      // color: '#fff',
      fontWeight: 500,
      fontFamily: 'Roboto, Open Sans, Segoe UI, sans-serif',
      fontSize: '16px',
      // fontSmoothing: 'antialiased',
    },
  },
}

const StyledCardElement = () => {
  return (
    <Box sx={sx}>
      <CardElement options={options} />
    </Box>
  )
}

export default StyledCardElement
