import { RequestById } from 'types'
import { noop } from 'utils'
import { useShowControlWithId } from './useShowControlWithId'

interface Props {
  deleteFunc: (request: RequestById) => Promise<unknown | void>
  successCallback?: () => void
  warning: string
}

export const useRemoveModalControl = (props: Props) => {
  const { deleteFunc, successCallback = noop, warning } = props

  const [idToRemove, openDeleteModal, closeDeleteModal, removeLoading, setRemoveLoading] =
    useShowControlWithId()

  const handleConfirmRemove = async () => {
    try {
      setRemoveLoading(true)
      await deleteFunc({ id: idToRemove ?? -1 })
      closeDeleteModal()
      successCallback()
    } finally {
      setRemoveLoading(false)
    }
  }

  return {
    idToRemove,
    openDeleteModal,
    closeDeleteModal,
    removeLoading,
    handleConfirmRemove,
  }
}
