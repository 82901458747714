import { AxiosRequestConfig, AxiosResponse } from 'axios'
import {
  ETimelinePostStatus,
  LearnLibrary,
  LearnLibraryRequestGet,
  Response,
  TableResponse,
} from 'types'
import axiosInstance from './axios'

export const learnLibrarySetStatus = (request: {
  id: number
  status: ETimelinePostStatus
}): Promise<Response<{ status: string }>> => {
  return axiosInstance.post('/learn-libraries/status', { ...request, type: 'learnArticle' })
}

export const getLearnLibraries = (
  request: LearnLibraryRequestGet,
): Promise<TableResponse<LearnLibrary>> => {
  return axiosInstance.post('/learn-libraries', request)
}

export const getLearnLibraryItem = (
  entityId: number,
  learnArticleId: number,
  config?: AxiosRequestConfig,
): Promise<Response<LearnLibrary>> => {
  return axiosInstance.post(
    '/learn-libraries/read-by-entity',
    {
      entityId,
      learnArticleId,
      type: 'learnTopics',
    },
    config,
  )
}

export const getLearnLibrariesInReviewCount = async (): Promise<AxiosResponse<number>> => {
  return axiosInstance.post('/learn-libraries/status/review/count')
}
