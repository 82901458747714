export default function popoverOverride(): object {
  return {
    MuiPopover: {
      defaultProps: {
        sx: {
          zIndex: 1400,
        },
      },
    },
  }
}
