import { getPathways } from 'api/pathways'
import { getUser } from 'api/users'
import PageTitle from 'components/page/PageTitle'
import LoadingPlaceholder from 'components/placeholders/LoadingPlaceholder'
import React, { useCallback, useState } from 'react'
import { useQuery } from 'react-query'
import { UserType } from 'types'
import { UserDetailsCaregiverForm } from '../UserDetailsCaregiverForm/UserDetailsCaregiverForm'
import { UserDetailsForm } from '../UserDetailsForm/UserDetailsForm'
import { UserHistory } from '../UserHistory/UserHistory'

export function UserDetails({ userId }: { userId: number }) {
  const [needUpdateHistory, setNeedUpdateHistory] = useState(false)
  const userQuery = useQuery(['user', userId], () => getUser({ id: userId }))
  const pathways = useQuery(['pathways'], () => getPathways())

  const handleAfterUpdate = useCallback(() => {
    userQuery.refetch()
    setNeedUpdateHistory(true)
  }, [userQuery])

  const handleResetNeedUpdateHistory = useCallback(() => {
    setNeedUpdateHistory(false)
  }, [])

  if (userQuery.isLoading || pathways.isLoading) {
    return <LoadingPlaceholder />
  }

  if (!userQuery.data || !pathways.data) {
    return <div>Something went wrong</div>
  }

  const isCaregiver = userQuery.data.data.type.includes(UserType.CAREGIVER)

  return (
    <>
      <PageTitle>Edit User View</PageTitle>

      {isCaregiver ? (
        <UserDetailsCaregiverForm user={userQuery.data.data} onAfterUpdate={handleAfterUpdate} />
      ) : (
        <UserDetailsForm
          user={userQuery.data.data}
          pathways={pathways.data}
          onAfterUpdate={handleAfterUpdate}
        />
      )}

      <UserHistory
        userId={userId}
        needUpdate={needUpdateHistory}
        resetNeedUpdate={handleResetNeedUpdateHistory}
      />
    </>
  )
}
