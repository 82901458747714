import { Box } from '@mui/material'
import { ColDef } from 'ag-grid-community'
import { getViewCountOfLessonsReport } from 'api/reports'
import InfinityAgGrid from 'components/table/InfinityAgGrid'
import TableToolbar from 'components/table/TableToolbar'
import {
  createCohortsNamesColumn,
  createCohortsNamesCsvColumn,
  useCohortsFilter,
} from 'features/Cohorts'
import { useExportCSV } from 'hooks/useExportCSV'
import { useGridControl } from 'hooks/useGridControl'
import React, { useCallback } from 'react'
import { BaseTableRequest, FilterModel } from 'types'
import { useGetDataSource } from 'utils'

const columnDefs: ColDef[] = [
  { headerName: 'Lesson Name', field: 'lessonName', filter: 'agTextColumnFilter' },
  { headerName: 'Course Name', field: 'courseName', filter: 'agTextColumnFilter' },
  createCohortsNamesColumn('cohortNames'),
  { headerName: 'View Count', field: 'viewCount', minWidth: 160 },
  { headerName: ' Number of Users Assigned to the Lesson', field: 'numberOfUsers', minWidth: 360 },
]

const csvFields = [
  {
    label: 'Lesson Name',
    value: 'lessonName',
  },
  {
    label: 'Course Name',
    value: 'courseName',
  },
  createCohortsNamesCsvColumn('cohortNames'),
  {
    label: 'View Count',
    value: 'viewCount',
  },
  {
    label: ' Number of Users Assigned to the Lesson',
    value: 'numberOfUsers',
  },
]

export const ReportsViewCountLessons = () => {
  const { cohortsFilterValue, CohortsFilter } = useCohortsFilter()

  const getRequest = useCallback(
    (req: BaseTableRequest) => {
      const filter: FilterModel = {
        ...(req.filter || {}),
      }

      if (cohortsFilterValue) {
        filter.cohortId = cohortsFilterValue
      }

      const request = { ...req, filter }

      return getViewCountOfLessonsReport(request)
    },
    [cohortsFilterValue],
  )

  const getDataSource = useGetDataSource(getRequest)
  const { gridApi, columnApi, onGridReady } = useGridControl(getDataSource)

  const { csvLoading, onExportCSV } = useExportCSV(
    getRequest,
    gridApi,
    columnApi,
    csvFields,
    'View Count of Lessons Report.csv',
  )

  return (
    <>
      <TableToolbar
        left={<Box minWidth="200px">{CohortsFilter}</Box>}
        exportCSVEnable
        csvLoading={csvLoading}
        onExportCSV={onExportCSV}
      />
      <InfinityAgGrid pagination columnDefs={columnDefs} onGridReady={onGridReady} />
    </>
  )
}
