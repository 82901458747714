import { Stack } from '@mui/material'
import ScrollContainer from 'components/page/ScrollContainer'
import FailPlaceholder from 'components/placeholders/FailPlaceholder'
import LoadingPlaceholder from 'components/placeholders/LoadingPlaceholder'
import { heightOfToolbar } from 'const'
import { UICollapseProvider } from 'features/UI'
import React, { useEffect } from 'react'
import { useActions, useAppSelector } from 'store/hooks'
import { ProgressCourseItem } from '../ProgressCourseItem/ProgressCourseItem'

export const useAssessmentTabData = () => {
  const { isLoading, assessment } = useAppSelector((state) => state.assessment)

  const { getAssessmentById } = useActions()

  useEffect(() => {
    getAssessmentById()
  }, [getAssessmentById])

  return { isLoading, assessment }
}

export const ProgressAssessmentTab = () => {
  const { isLoading, assessment } = useAssessmentTabData()

  if (isLoading) {
    return <LoadingPlaceholder />
  }

  if (!assessment) {
    return <FailPlaceholder error="Can't load data of assessment" />
  }

  return (
    <Stack
      paddingTop={1.5}
      paddingLeft={{ xs: 2, sm: 2 }}
      paddingRight={{ xs: 1, sm: 2, lg: 0 }}
      spacing={2}
    >
      <ScrollContainer deltaSpace={heightOfToolbar}>
        <Stack spacing={1}>
          <UICollapseProvider>
            {assessment.map((course) => {
              return <ProgressCourseItem key={course.courseId} course={course} />
            })}
          </UICollapseProvider>
        </Stack>
      </ScrollContainer>
    </Stack>
  )
}
