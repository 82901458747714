import { reorderQuestions } from 'models/exam.model'
import {
  BaseTableRequest,
  QuestionnaireRequestComplete,
  QuestionnaireRequestCreate,
  Questionnaire,
  QuestionnaireResultData,
  QuestionnaireResults,
  QuestionnaireRequestUpdate,
  RequestById,
  TableResponse,
  Response,
} from 'types'
import axiosInstance from './axios'

export const getQuestionnaires = async (
  request: BaseTableRequest,
): Promise<TableResponse<Questionnaire>> => {
  return axiosInstance.post('/questionnaires', request)
}

export const createQuestionnaire = async (
  request: QuestionnaireRequestCreate,
): Promise<Response<Questionnaire>> => {
  return axiosInstance.post('/questionnaires/create', request)
}

export const getQuestionnaireById = async (
  request: RequestById,
): Promise<Response<Questionnaire>> => {
  return axiosInstance
    .post('/questionnaires/read', request)
    .then((response: Response<Questionnaire>) => {
      return {
        ...response,
        data: reorderQuestions(response.data) as Questionnaire,
      }
    })
}

export const updateQuestionnaire = async (
  request: QuestionnaireRequestUpdate,
): Promise<Response<Questionnaire>> => {
  return axiosInstance.post('/questionnaires/update', request)
}

export const deleteQuestionnaire = async (request: RequestById): Promise<unknown> => {
  return axiosInstance.post('/questionnaires/delete', request)
}

export const getQuestionnaireWithLastResultsById = async (
  request: RequestById,
): Promise<Response<QuestionnaireResults>> => {
  return axiosInstance.post('/questionnaires/read-with-last-results', request)
}

export const getQuestionnaireWithSpecificResultsById = async (
  request: RequestById,
): Promise<Response<QuestionnaireResults>> => {
  return axiosInstance.post('/questionnaires/read-with-specific-results', request)
}

export const completeQuestionnaire = (
  request: QuestionnaireRequestComplete,
): Promise<Response<QuestionnaireResultData>> => {
  return axiosInstance.post('/questionnaires/complete', request)
}
