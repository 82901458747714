import { getEngagementPerSessionDetailReport } from 'api/reports'
import LoadingPlaceholder from 'components/placeholders/LoadingPlaceholder'
import React from 'react'
import { useQuery } from 'react-query'
import { ReportsEngagementUserSessionInfo } from './ReportsEngagementUserSessionInfo'
import { ReportsEngagementUserSessionTable } from './ReportsEngagementUserSessionTable'

export function ReportsEngagementUserSession({ sessionUuid }: { sessionUuid: string }) {
  const sessionQuery = useQuery('getSessionReport', () =>
    getEngagementPerSessionDetailReport({ params: { sessionUuid } }),
  )

  if (sessionQuery.isLoading || !sessionQuery.data) {
    return <LoadingPlaceholder />
  }

  return (
    <>
      <ReportsEngagementUserSessionInfo session={sessionQuery.data.data.session} />
      <ReportsEngagementUserSessionTable sessionUuid={sessionUuid} />
    </>
  )
}
