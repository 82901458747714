import { getLearnLibraryItem, learnLibrarySetStatus } from 'api/learnLibrary'
import { useEffect, useMemo, useRef } from 'react'
import { useMutation, useQuery } from 'react-query'
import { useSelector } from 'react-redux'
import { useActions } from 'store/hooks'
import { selectLearnLibraryStatusFilter } from 'store/slice/learnLibraryFilters.slice'
import { selectTagsFiltersIds } from 'store/slice/tagsFilters.slice'
import { allFiltersStatuses, ETimelinePostStatus } from 'types'

export function LearnLibraryArticleStatusChanger({ id, topicId }: { id: number; topicId: number }) {
  const isUpdated = useRef(false)

  const { data: learnTopic, isFetched } = useQuery('learnLibraryItem', () => {
    return getLearnLibraryItem(topicId, id, {
      suppressErrorHandler: [404],
    })
  })

  const itemStatus = useMemo(() => {
    return learnTopic?.data?.status
  }, [learnTopic])

  const { getTopicsInReview, getTopics, learnLibraryChangeStatus } = useActions()
  const statusFilter = useSelector(selectLearnLibraryStatusFilter)
  const selectedTagIdsFilter = useSelector(selectTagsFiltersIds)

  const setLearnArticleStatus = useMutation(learnLibrarySetStatus, {
    onSuccess: () => {
      getTopicsInReview()
      getTopics({
        status: statusFilter !== null ? statusFilter : allFiltersStatuses,
        selectedTagIds: selectedTagIdsFilter,
        silent: true,
      })
    },
  })

  useEffect(() => {
    if (isFetched && itemStatus === ETimelinePostStatus.new && !isUpdated.current) {
      isUpdated.current = true
      setLearnArticleStatus.mutate(
        { id, status: ETimelinePostStatus.review },
        {
          onSuccess: () => {
            learnLibraryChangeStatus({ learnArticleId: id, status: ETimelinePostStatus.review })
          },
        },
      )
    }
  }, [id, isFetched, itemStatus, learnLibraryChangeStatus, setLearnArticleStatus])

  return null
}
