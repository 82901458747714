import { reorderQuestions } from 'models/exam.model'
import {
  BaseTableRequest,
  Quiz,
  QuizRequestComplete,
  QuizRequestCreate,
  QuizRequestUpdate,
  QuizResultData,
  QuizResults,
  RequestById,
  Response,
  TableResponse,
} from 'types'
import axiosInstance from './axios'

export const getQuizzes = async (request: BaseTableRequest): Promise<TableResponse<Quiz>> => {
  return axiosInstance.post('/quizzes', request)
}

export const createQuiz = async (request: QuizRequestCreate): Promise<Response<Quiz>> => {
  return axiosInstance.post('/quizzes/create', request)
}

export const getQuizById = async (request: RequestById): Promise<Response<Quiz>> => {
  return axiosInstance.post('/quizzes/read', request).then((response: Response<Quiz>) => {
    return {
      ...response,
      data: reorderQuestions(response.data) as Quiz,
    }
  })
}

export const getQuizWithLastResultsById = async (
  request: RequestById,
): Promise<Response<QuizResults>> => {
  return axiosInstance.post('/quizzes/read-with-last-results', request)
}

export const getQuizWithResultsById = async (
  request: RequestById,
): Promise<Response<QuizResults>> => {
  return axiosInstance.post('/quizzes/read-with-specific-results', request)
}

export const updateQuiz = async (request: QuizRequestUpdate): Promise<Response<Quiz>> => {
  return axiosInstance.post('/quizzes/update', request)
}

export const deleteQuiz = async (request: RequestById): Promise<unknown> => {
  return axiosInstance.post('/quizzes/delete', request)
}

export const completeQuiz = (request: QuizRequestComplete): Promise<Response<QuizResultData>> => {
  return axiosInstance.post('/quizzes/complete', request)
}
