import { useCallback } from 'react'
import { useActions } from 'store/hooks'

export const useRefetchCareTeam = () => {
  const { getCareTeamById } = useActions()

  return useCallback(() => {
    getCareTeamById({})
  }, [getCareTeamById])
}
