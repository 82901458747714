import styled from '@emotion/styled'
import { Box } from '@mui/material'
import React from 'react'

export const photoPreviewSX = {
  minWidth: { xs: 150, sm: 150, md: 130, lg: 150 },
  maxWidth: { xs: 'unset', sm: 150, md: 130, lg: 150 },
  minHeight: { xs: 150, sm: 100 },
  maxHeight: { xs: 'unset', sm: 100 },
  borderRadius: 1,
}

const StyledImg = styled.img`
  height: 100%;
  object-fit: contain;
`

export function TimelinePhotoPreview({ url }: { url: string }) {
  return (
    <Box sx={photoPreviewSX} overflow="hidden" display="flex" alignItems="center">
      <StyledImg src={url} alt="" />
    </Box>
  )
}
