import { zodResolver } from '@hookform/resolvers/zod'
import { LoadingButton } from '@mui/lab'
import { Box, Button, Modal, Stack, Typography } from '@mui/material'
import { updatePathway } from 'api/pathways'
import FormInput from 'components/form-elements/FormInput'
import FormSelect from 'components/form-elements/FormSelect'
import { defaultModalStyle, defaultModalZIndex, minButtonWidth } from 'const'
import React, { useMemo } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { useMutation } from 'react-query'
import { Pathway } from 'types'
import zod, { TypeOf } from 'zod'

const formSchema = zod.object({
  newPostsCount: zod.string().transform((arg, ctx) => {
    const numberValue = parseInt(arg)
    if (isNaN(numberValue)) {
      ctx.addIssue({
        code: 'custom',
        message: 'Invalid value',
      })
    }

    return numberValue
  }),
  name: zod.string(),
})

type FormType = TypeOf<typeof formSchema>

export function PathwaySettingsModal({
  pathway,
  onClose,
  onSave,
}: {
  pathway: Pathway
  onClose: () => void
  onSave: (data: { newPostsCount: number }) => void
}) {
  const formSettings = {
    mode: 'onChange' as const,
    resolver: zodResolver(formSchema),
    defaultValues: {
      newPostsCount: pathway.newTimelinePostCount,
      name: pathway.name,
    },
  }

  const methods = useForm<FormType>(formSettings)

  const { handleSubmit } = methods

  const updatePathwayMutation = useMutation(updatePathway)

  const onSubmitHandler = (data: { newPostsCount: number; name: string }) => {
    const { points, ...pathwayWithoutPoints } = pathway
    updatePathwayMutation.mutate(
      { ...pathwayWithoutPoints, newTimelinePostCount: data.newPostsCount },
      {
        onSuccess: () => {
          onSave(data)
        },
      },
    )
  }

  const postsCountOptions = useMemo(() => {
    const allOption = { label: 'All', value: -1 }
    const defaultOptions = Array.from({ length: 20 }, (value, index) => ({
      label: (index + 1).toString(),
      value: index + 1,
    }))
    return [allOption, ...defaultOptions]
  }, [])

  return (
    <Modal sx={defaultModalZIndex} open>
      <>
        <FormProvider {...methods}>
          <Box
            sx={defaultModalStyle}
            component="form"
            onSubmit={handleSubmit(onSubmitHandler)}
            width="100%"
            noValidate
          >
            <Typography variant="h5" mb={3}>
              Settings
            </Typography>
            <Stack spacing={1.5}>
              <FormInput label="Name" disabled name="name" />
              <FormSelect
                label="How many New posts will be in Timeline?"
                required
                fullWidth
                name="newPostsCount"
                type="number"
                options={postsCountOptions}
              />
            </Stack>

            <Stack direction="row" spacing={2} justifyContent="flex-end" sx={{ mt: 4 }}>
              <Button variant="outlined" onClick={onClose} sx={minButtonWidth}>
                Close
              </Button>
              <LoadingButton
                loading={updatePathwayMutation.isLoading}
                variant="contained"
                type="submit"
                sx={minButtonWidth}
              >
                Save
              </LoadingButton>
            </Stack>
          </Box>
        </FormProvider>
      </>
    </Modal>
  )
}
