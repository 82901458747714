import { Moment } from 'moment'
import React from 'react'
import { FormLabel, Stack, TextField } from '@mui/material'
import { TextFieldProps } from '@mui/material/TextField/TextField'
import { Controller, useFormContext } from 'react-hook-form'
import { DesktopDatePicker } from '@mui/x-date-pickers'
import { ControllerRenderProps } from 'react-hook-form/dist/types/controller'

type IFormInputProps = {
  name: string
  onChange?: (value: Moment | null) => void
} & Omit<TextFieldProps, 'onChange'>

const FormDatePicker = (props: IFormInputProps) => {
  const { label, sx = {}, name, defaultValue, required, onChange, ...otherProps } = props

  const {
    control,
    formState: { errors },
  } = useFormContext()

  const handleChange = (field: ControllerRenderProps) => (value: Moment | null) => {
    if (!onChange) {
      field.onChange(value)
      return
    }

    onChange(value)
  }

  return (
    <Controller
      control={control}
      name={name}
      defaultValue={defaultValue}
      render={({ field }) => (
        <Stack spacing={0.5} sx={sx}>
          <FormLabel required={required}>{label}</FormLabel>
          <DesktopDatePicker
            {...field}
            inputFormat="MM/DD/YYYY"
            onChange={handleChange(field)}
            renderInput={(params) => (
              <TextField
                size="small"
                {...params}
                {...otherProps}
                error={!!errors[name]}
                helperText={(errors[name] ? errors[name]?.message : '') as string}
              />
            )}
          />
        </Stack>
      )}
    />
  )
}

export default React.memo(FormDatePicker)
