import { LabelValue } from 'features/Metadata'
import { useMemo } from 'react'
import { LearnTopic, TableResponse } from 'types'

export function useLearnLibraryMetadata(response?: TableResponse<LearnTopic>): LabelValue[] {
  return useMemo(() => {
    if (!response) {
      return []
    }

    return [
      {
        label: 'Topics Total',
        value: response.data.count,
      },
      {
        label: 'Learn Articles Total',
        value: response.data.countOfArticles || 0,
      },
      {
        label: 'Published Learn Articles',
        value: response.data.countOfPublishedArticles || 0,
      },
      {
        label: 'Linked Learn Articles',
        value: response.data.countOfLinkedArticles || 0,
      },
      {
        label: 'Linked Published Learn Articles',
        value: response.data.countOfLinkedPublishedArticles || 0,
      },
    ]
  }, [response])
}
