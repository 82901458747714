import React from 'react'
import Box from '@mui/material/Box'
import { Stack, Typography, useMediaQuery } from '@mui/material'
import Profile from './Profile'
import { useTheme } from '@mui/material/styles'
import { useAuthContext } from '../../context/AuthProvider'
import { formatDate } from '../../../utils'

const ProfileInfo = () => {
  const theme = useTheme()
  const { user } = useAuthContext()
  const matchesXs = useMediaQuery(theme.breakpoints.down('md'))

  const info = (
    <Stack direction="column">
      {user?.accessCodeExpiryDate && (
        <Stack direction="row">
          <Typography variant="body2">Access expires on </Typography>
          <Typography variant="body2" color="error.main">
            {formatDate(new Date(user?.accessCodeExpiryDate))}
          </Typography>
        </Stack>
      )}
      {user?.trainingPathwayTypeName && (
        <Stack direction="row">
          <Typography variant="body2" color="textSecondary">
            Assigned Training Pathway: 
          </Typography>
          <Typography variant="body2" color="textSecondary">
            {user?.trainingPathwayTypeName}
          </Typography>
        </Stack>
      )}
    </Stack>
  )

  return (
    <Box sx={{ display: 'flex', flexGrow: 1, justifyContent: 'flex-end', alignItems: 'center' }}>
      {!matchesXs && info}
      <Profile />
    </Box>
  )
}

export default React.memo(ProfileInfo)
