import styled from '@emotion/styled'

export const Range = styled.input`
  height: 7px;
  appearance: none;
  border-radius: 8px;
  background: #3b7677;
  transition: background 0.2s ease;
  cursor: pointer;
  flex-grow: 1;
  margin-left: 8px;

  &::-moz-range-thumb {
    width: 15px;
    height: 15px;
    background: #ecf0f1;
    border: 2px solid #777;
    border-radius: 50%;
    cursor: pointer;
  }

  &::-webkit-slider-thumb {
    width: 18px;
    height: 18px;
    background: #ecf0f1;
    border: 2px solid #777;
    border-radius: 50%;
    cursor: pointer;
    -webkit-appearance: none;
  }
`

export const LoaderContainer = styled.div`
  display: flex;
  height: 36px;
  width: 36px;
  justify-content: center;
  align-items: center;
`
