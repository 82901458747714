import React from 'react'
import { Stack, Typography } from '@mui/material'
import DrawerHeader from './common/DrawerHeader'

interface Props {
  handleClose: () => void
}

const CantLoadDataDrawerPlaceholder = (props: Props) => {
  const { handleClose } = props

  return (
    <>
      <DrawerHeader handleClose={handleClose} />
      <Stack justifyContent="center" alignItems="center" flexGrow={1}>
        <Typography variant="h6" fontWeight={400} textAlign="center">
          {"Can't load data."}
          <br />
          Please retry later or contact with administrator.
        </Typography>
      </Stack>
    </>
  )
}

export default CantLoadDataDrawerPlaceholder
