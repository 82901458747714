import { Link, Stack } from '@mui/material'
import Breadcrumbs from 'components/Breadcumbs'
import NotificationSys from 'components/NotificationSystem'
import { useCallback, useState } from 'react'
import { routes } from 'routes/routes'
import { Pathway } from 'types'
import { PathwaySettingsModal } from '../PathwaySettingsModal/PathwaySettingsModal'

export function PathwayToolbarLeft({
  pathway,
  onSettingsChanged,
}: {
  pathway?: Pathway
  onSettingsChanged: (newPostsCount: number) => void
}) {
  const [showSettingsModal, setShowSettingsModal] = useState(false)

  const openSettings = useCallback(() => {
    setShowSettingsModal(true)
  }, [])

  const closeSettings = useCallback(() => {
    setShowSettingsModal(false)
  }, [])

  const handleSettingsSave = useCallback(
    ({ newPostsCount }: { newPostsCount: number }) => {
      onSettingsChanged(newPostsCount)
      setShowSettingsModal(false)
      NotificationSys.showSuccess('Training pathway settings has been updated')
    },
    [onSettingsChanged],
  )

  return (
    <>
      <Stack direction="row" alignItems="center">
        <Breadcrumbs
          path={[
            { text: 'Training Pathways', href: routes.managePathways },
            { text: pathway?.name || '' },
          ]}
        />
        <Link
          href="#"
          onClick={openSettings}
          sx={{ textTransform: 'uppercase', fontSize: '12px', ml: 1 }}
        >
          Edit
        </Link>
      </Stack>

      {showSettingsModal && !!pathway && (
        <PathwaySettingsModal
          onClose={closeSettings}
          pathway={pathway}
          onSave={handleSettingsSave}
        />
      )}
    </>
  )
}
