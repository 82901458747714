export const USER_DATA = 'userData'
export const TOKEN = 'token'

export const clearLocalStorage = () => {
  localStorage.removeItem(USER_DATA)
  localStorage.removeItem(TOKEN)
  localStorage.removeItem('drawer.reports')
  localStorage.removeItem('drawer.contentManagement')
}

export const getFromLocalStorage = (path: string) => {
  try {
    if (localStorage && localStorage.getItem) {
      const savedData = localStorage.getItem(path)
      if (savedData) {
        return JSON.parse(savedData) || undefined
      }
    }
  } catch (e) {
    return undefined
  }
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const saveToLocalStorage = (path: string, newState: any) => {
  if (localStorage && localStorage.setItem) {
    try {
      if (typeof newState === 'string') {
        localStorage.setItem(path, newState)
      } else {
        localStorage.setItem(path, JSON.stringify(newState))
      }
    } catch (e) {
      console.warn(e)
    }
  }
}
