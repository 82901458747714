import React from 'react'
import { Typography } from '@mui/material'
import { Variant } from '@mui/material/styles/createTypography'

interface Props {
  children: React.ReactNode
  mb?: number
  variant?: Variant
}

const PageTitle = (props: Props) => {
  const { children, mb = 0, variant = 'h6' } = props

  return (
    <Typography variant={variant} color="black" alignSelf="center" align="center" sx={{ mb }}>
      {children}
    </Typography>
  )
}

export default React.memo(PageTitle)
