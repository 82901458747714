import { Stack, Typography } from '@mui/material'
import { useMemo } from 'react'
import { formatDurationFromMinutes } from '../../Metric.model'

export function MetricTextItem({
  label,
  value,
  precision = 2,
  isMinutes = false,
}: {
  label: string
  value: number
  precision?: number
  isMinutes?: boolean
}) {
  const valueText = useMemo(() => {
    if (isMinutes) {
      return formatDurationFromMinutes(value)
    }

    return value.toFixed(precision)
  }, [isMinutes, precision, value])

  return (
    <Stack direction="row" className="gap-4">
      <Typography fontWeight={600}>{label}:</Typography>
      <Typography>{valueText}</Typography>
    </Stack>
  )
}
