import { useCallback, useState } from 'react'

type OutProps = [
  number | null,
  (id: number) => void,
  () => void,
  boolean,
  (loading: boolean) => void,
]

export const useShowControlWithId = (): OutProps => {
  const [id, setId] = useState<number | null>(null)
  const [loading, setLoading] = useState(false)

  const onOpen = useCallback((id: number) => {
    setId(id)
  }, [])

  const onClose = useCallback(() => {
    setId(null)
  }, [])

  return [id, onOpen, onClose, loading, setLoading]
}
