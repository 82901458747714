import { InfoOutlined } from '@mui/icons-material'
import { Chip, IconButton, Stack } from '@mui/material'
import Tooltip from '@mui/material/Tooltip'
import React, { useMemo } from 'react'
import { Tag } from 'types'

export function TagsCellRenderer({ tags }: { tags: Tag[] }) {
  const tooltipContent = useMemo(() => {
    return tags.map((tag) => tag.name).join(', ')
  }, [tags])

  return (
    <Stack direction="row" alignItems="center" height="100%" spacing={0.5}>
      <Stack direction="row" alignItems="center" spacing={1} overflow="hidden">
        {tags.map((tag) => (
          <Chip key={tag.id} label={tag.name} />
        ))}
      </Stack>

      {tags.length > 2 && (
        <Tooltip title={tooltipContent} placement="top" arrow>
          <IconButton size="small">
            <InfoOutlined />
          </IconButton>
        </Tooltip>
      )}
    </Stack>
  )
}
