import { useCallback, useState } from 'react'

type Out = [boolean, () => void, () => void, () => void]

const invert = (prev: boolean) => !prev

export const useShowControl = (initial: boolean = false): Out => {
  const [state, setState] = useState<boolean>(initial)

  const onOpen = useCallback(() => {
    setState(true)
  }, [])

  const onClose = useCallback(() => {
    setState(false)
  }, [])

  const toggle = useCallback(() => {
    setState(invert)
  }, [])

  return [state, onOpen, onClose, toggle]
}
