import { CourseCollapseItem } from 'app/imports/App.components'
import { ProgressCourse } from 'types'
import { ProgressExamItem } from '../ProgressExamItem/ProgressExamItem'
import { ProgressLessonItem } from '../ProgressLessonItem/ProgressLessonItem'

export function ProgressCourseItem({ course }: { course: ProgressCourse }) {
  return (
    <CourseCollapseItem
      id={course.courseId}
      name={course.courseName}
      bannerUrl={course.courseBannerUrl}
      status={course.courseStatus}
      isForceExpanded={false}
    >
      {course.lessons.map((lesson) => {
        return <ProgressLessonItem key={lesson.lessonId} lesson={lesson} />
      })}
      <ProgressExamItem exam={course.questionnaire} />
    </CourseCollapseItem>
  )
}
