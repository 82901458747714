import { useEffect, useRef } from 'react'

export function useUnmount(callback: Function) {
  const componentWillUnmount = useRef(false)

  useEffect(() => {
    return () => {
      componentWillUnmount.current = true
    }
  }, [])

  useEffect(() => {
    return () => {
      if (componentWillUnmount.current) {
        callback()
      }
    }
  }, [callback])
}
