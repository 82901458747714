import { createSelector, createSlice } from '@reduxjs/toolkit'
import { Nullish } from 'types'
import { RootState } from '../store'

interface InitialState {
  selectedTagIds: Nullish<number[]>
}

const initialState: InitialState = {
  selectedTagIds: null,
}

export const tagsFiltersSlice = createSlice({
  name: 'tagsFilters',
  initialState,
  reducers: {
    setTagFilters: (state, { payload }) => {
      state.selectedTagIds = payload
    },
    resetTagFilters: () => {
      return initialState
    },
  },
})

export const { setTagFilters, resetTagFilters } = tagsFiltersSlice.actions

export const selectTagsFiltersSlice = (state: RootState) => state.tagsFilters

export const selectTagsFiltersIds = createSelector(
  selectTagsFiltersSlice,
  (slice) => slice.selectedTagIds,
)
