import styled from '@emotion/styled'
import { Colors } from 'styles/colors'

type StyledBadgeProps = {
  $active?: boolean
  $isClickable?: boolean
}

export const StyledBadge = styled('span')<StyledBadgeProps>`
  background: #fff;
  border: 2px solid #a1a1a1;
  color: #a1a1a1;
  border-radius: 999px;
  min-width: 28px;
  height: 28px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-size: 18px;
  font-weight: 500;
  padding: 0 4px;

  ${({ $active }) =>
    $active &&
    `
    background: ${Colors.PINK};
    border-color: ${Colors.PINK};
    color: ${Colors.WHITE};
  `}

  ${({ $isClickable }) =>
    $isClickable &&
    `
    &:hover {
      background: ${Colors.PINK};
      border-color: ${Colors.PINK};
      color: ${Colors.WHITE};
    }
  `}
`

export function NumberBadge({
  value,
  active = false,
  onClick,
}: {
  value: number
  active?: boolean
  onClick?: () => void
}) {
  return (
    <StyledBadge $active={active} $isClickable={!!onClick} onClick={onClick}>
      {value}
    </StyledBadge>
  )
}
