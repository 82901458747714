import { useCallback } from 'react'
import { useNavigate } from 'react-router-dom'
import { routes } from 'routes/routes'
import { ArticleType } from 'types'
import { getRoute } from 'utils'

const useLearnMore = (id: number) => {
  const navigate = useNavigate()

  const learnMoreClicked = useCallback(() => {
    const route = getRoute(routes.learnLibraryItem, {
      learnLibraryItemType: ArticleType.LearnLibrary,
      learnLibraryItemId: id,
    })
    navigate(route)
  }, [id, navigate])

  return [learnMoreClicked]
}

export default useLearnMore
