import { zodResolver } from '@hookform/resolvers/zod'
import { Stack } from '@mui/material'
import FormInput from 'components/form-elements/FormInput'
import { useCharactersLeft } from 'hooks/useCharactersLeft'
import { ChangeEvent } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { object, string, TypeOf } from 'zod'

export function ArticleNote({
  note,
  onChange,
}: {
  note: string
  onChange: (note: string) => void
}) {
  const formSchema = object({
    note: string().max(2000, 'Please enter a maximum of 2000 characters'),
  })

  type FormType = TypeOf<typeof formSchema>

  const methods = useForm<FormType>({
    mode: 'onChange' as const,
    resolver: zodResolver(formSchema),
    defaultValues: {
      note,
    },
  })

  const charactersLeft = useCharactersLeft(2000, note.length)

  const handleChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
    onChange(e.target.value)
  }

  return (
    <Stack sx={{ width: '100%', maxWidth: 800 }}>
      <FormProvider {...methods}>
        <FormInput
          label="Internal Note"
          name="note"
          fullWidth
          minRows={3}
          maxRows={6}
          multiline
          placeholder="Start typing"
          onChange={handleChange}
          helperText={charactersLeft}
        />
      </FormProvider>
    </Stack>
  )
}
