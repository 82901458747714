import React from 'react'
import { Controller, FieldValues, useFormContext } from 'react-hook-form'
import { ControllerRenderProps } from 'react-hook-form/dist/types/controller'
import { ImageType } from 'react-images-uploading/dist/typings'
import { isAspectRatio16by9 } from 'utils'
import ImageUpload from './ImageUpload'

const FormImageUpload = ({
  name,
  hasRatioRestrictions,
}: {
  name: string
  hasRatioRestrictions?: boolean
}) => {
  const {
    control,
    formState: { errors },
    setError,
  } = useFormContext()

  const handleChange =
    (field: ControllerRenderProps<FieldValues, string>) => async (imgData: ImageType) => {
      field.onChange(imgData)
      if (imgData.dataURL && hasRatioRestrictions) {
        const isRatioOk = await isAspectRatio16by9(imgData.dataURL)
        if (!isRatioOk) {
          setError(name, {
            message: 'The image resolution is incorrect. It should be 16:9.',
          })
        }
      }
    }

  return (
    <Controller
      control={control}
      name={name}
      defaultValue={null}
      render={({ field }) => (
        <ImageUpload
          onSave={handleChange(field)}
          imgData={field.value}
          error={(errors[name] ? errors[name]?.message : null) as string}
          hasRatioRestrictions={hasRatioRestrictions}
        />
      )}
    />
  )
}

export default FormImageUpload
