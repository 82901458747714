/* eslint-disable @typescript-eslint/no-explicit-any */
import { TextField } from '@mui/material'
import { TextFieldProps } from '@mui/material/TextField/TextField'
import { DatePicker, PickersDay } from '@mui/x-date-pickers'
import { PickersDayProps } from '@mui/x-date-pickers/PickersDay/PickersDay'
import { UIDatePickerDay } from 'features/UI'
import moment from 'moment'
import React, { useState } from 'react'

interface Props {
  selectedDate: any
  onChangeSelectedDate: any
  classes?: any
}

const WeekPicker = (props: Props) => {
  const { selectedDate, onChangeSelectedDate } = props

  const handleWeekChange = (date: any) => {
    onChangeSelectedDate(date.startOf('isoWeek'))
  }

  const renderWrappedWeekDay = (
    date: Date,
    selectedDates: Array<Date | null>,
    pickersDayProps: PickersDayProps<Date>,
  ) => {
    const dateClone = moment(date).clone()
    const selectedDateClone = moment(selectedDates[0]).clone()

    if (!selectedDateClone) {
      return <PickersDay {...pickersDayProps} />
    }

    const start = selectedDateClone.startOf('week').toDate()
    const end = selectedDateClone.endOf('week').toDate()

    const dayIsBetween = dateClone.isBetween(start, end, null, '[]')
    const isFirstDay = dateClone.isSame(start, 'day')
    const isLastDay = dateClone.isSame(end, 'day')

    return (
      <UIDatePickerDay
        {...pickersDayProps}
        selected={dayIsBetween}
        outsideCurrentMonth={false}
        dayIsBetween={dayIsBetween}
        isFirstDay={isFirstDay}
        isLastDay={isLastDay}
      />
    )
  }

  const [isOpen, setIsOpen] = useState<boolean>(false)

  const renderInput = (props: TextFieldProps) => {
    const { ref, inputProps, disabled, onChange, value, ...other } = props

    const text =
      selectedDate && selectedDate.isValid()
        ? `Week of ${selectedDate.startOf('week').format('MM/DD/YY')}`
        : 'Week not selected'

    return (
      <TextField
        size="small"
        value={text}
        onClick={() => {
          setIsOpen(!isOpen)
        }}
        sx={{ width: 200 }}
        {...other}
      />
    )
  }

  return (
    <div className="picker">
      <DatePicker
        open={isOpen}
        onClose={() => {
          setIsOpen(false)
        }}
        value={selectedDate}
        onChange={handleWeekChange}
        renderDay={renderWrappedWeekDay}
        renderInput={renderInput}
      />
    </div>
  )
}

export default WeekPicker
