import { AxiosResponse } from 'axios'
import { CarePerson, RequestById } from 'types'
import axiosInstance from './axios'

export const getCarePerson = (request: RequestById) => {
  return axiosInstance
    .post<void, AxiosResponse<CarePerson>>('/care-persons', request)
    .then((response) => response.data)
}

export const updateCarePerson = (carePersonId: number, carePerson: Partial<CarePerson>) => {
  return axiosInstance.post<CarePerson, AxiosResponse<CarePerson>>('/care-persons/update', {
    ...carePerson,
    id: carePersonId,
  })
}

export const getCarePersonPdfReport = async () => {
  const response = await axiosInstance.get(`/care-persons/download-profile`, {
    responseType: 'blob',
  })
  const blob = new Blob([response.data])
  const href = URL.createObjectURL(blob)

  // create "a" HTML element with href to file & click
  const link = document.createElement('a')
  link.href = href
  link.download = 'poc_profile.pdf'
  document.body.appendChild(link)
  link.click()

  // clean up "a" element & remove ObjectURL
  document.body.removeChild(link)
  URL.revokeObjectURL(href)
}
