import { createAsyncThunk, createSelector, createSlice } from '@reduxjs/toolkit'
import { getTimelines } from 'api/timelines'
import { BaseTableRequest, CareArticleType, ETimelinePostType, TimelineItem } from 'types'
import { RootState } from '../store'

interface TimelineState {
  timeline: TimelineItem[] | null
  isLoading: boolean
  error: string | null
}

const getTimelinePayloadCreator = async (request: BaseTableRequest = {}) => {
  const response = await getTimelines(request)
  return response.data.rows
}

export const getTimelineById = createAsyncThunk('timeline/by-user-id', getTimelinePayloadCreator)

export const getTimelineSilent = createAsyncThunk('timeline/silent', getTimelinePayloadCreator)

const fillTimeline = (state: TimelineState, timelineItems: TimelineItem[] | null) => {
  state.isLoading = false
  if (!timelineItems) {
    state.timeline = null
  } else {
    // replace type for task care articles
    state.timeline = timelineItems.map((item) => {
      if (
        item.type === ETimelinePostType.careArticle &&
        item.careArticle?.type === CareArticleType.Task
      ) {
        return {
          ...item,
          type: ETimelinePostType.taskArticle,
        }
      }

      return item
    })
  }
}

export const timelineSlice = createSlice({
  name: 'timeline',
  initialState: {
    timeline: null as TimelineItem[] | null,
    isLoading: false,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getTimelineById.pending, (state) => {
        state.isLoading = true
      })
      .addCase(getTimelineById.fulfilled, (state, action) => {
        fillTimeline(state, action.payload || null)
      })
      .addCase(getTimelineById.rejected, (state) => {
        state.isLoading = false
        state.timeline = null
      })
      .addCase(getTimelineSilent.fulfilled, (state, action) => {
        fillTimeline(state, action.payload || null)
      })
      .addCase(getTimelineSilent.rejected, (state) => {
        state.isLoading = false
        state.timeline = null
      })
      .addCase('currentUser/logout', (state) => {
        state.timeline = []
      })
  },
})

export const selectTimelineSlice = (state: RootState) => state.timeline

export const selectTimelineItems = createSelector(
  selectTimelineSlice,
  (slice) => slice.timeline || [],
)
