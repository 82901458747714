import { Box } from '@mui/material'
import profileLogo from 'images/sigin_short.png'
import React from 'react'

export const AuthLogo = ({ mb = 3 }: { mb?: number }) => {
  return (
    <Box display="flex" justifyContent="center" sx={{ mb }}>
      <img src={profileLogo} alt="Logo" />
    </Box>
  )
}
