import styled from '@emotion/styled'
import { IconButton, Stack } from '@mui/material'
import { blue } from '@mui/material/colors'

export const IconWrapper = styled(IconButton)`
  position: absolute;
  top: 5px;
  right: 5px;
`

export const Container = styled(Stack, {
  shouldForwardProp: (prop) => prop !== 'pending',
})<{ pending: boolean }>`
  padding: 16px;
  border-radius: 8px;
  width: 100%;
  background-color: ${({ pending }) => (pending ? blue[100] : 'white')};
  position: relative;
`
