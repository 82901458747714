import { Button, Menu } from '@mui/material'
import React, { ReactNode, useState } from 'react'
import FilterAltIcon from '@mui/icons-material/FilterAlt'
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp'

export const UIDropdown = ({
  buttonName,
  buttonColor,
  children,
}: {
  buttonName: string
  buttonColor: 'primary' | 'secondary'
  children: ReactNode
}) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }

  return (
    <div>
      <Button
        variant="contained"
        color={buttonColor}
        onClick={handleClick}
        size="small"
        startIcon={<FilterAltIcon />}
        endIcon={open ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
        sx={{ whiteSpace: 'nowrap' }}
      >
        {buttonName}
      </Button>

      <Menu
        transformOrigin={{ horizontal: 'left', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'left', vertical: 'bottom' }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        {children}
      </Menu>
    </div>
  )
}
