import { Grid, Tab, Tabs, useMediaQuery } from '@mui/material'
import { useTheme } from '@mui/material/styles'
import React from 'react'
import { useAuthContext } from '../../../components/context/AuthProvider'
import PageTab from '../../../components/tabs/PageTab'
import { getTitle } from '../../../components/tabs/TabsTitle'

import {
  familyLearnLibraryTooltipHeader,
  queriesTooltip,
  traineeLearnLibraryHeaderTooltip,
} from '../../../components/tooltips/tooltips'
import { useTabsControl } from '../../../hooks/useTabsControl'
import LearnLibraryTab from './LearnLibraryTab'
import QueriesTab from './QueriesTab'

const LearnLibrary = () => {
  const theme = useTheme()
  const matchesMd = useMediaQuery(theme.breakpoints.down('lg'))
  const { selectedTab, handleChangeSelectedTab } = useTabsControl<string>('first')

  const { isTrainee } = useAuthContext()

  const learnLibraryText = 'Learn Library'
  const queriesText = isTrainee ? 'Queries' : 'Queries'

  const firstTab = <LearnLibraryTab />
  const secondTab = <QueriesTab />

  const learnLibraryTooltip = isTrainee
    ? traineeLearnLibraryHeaderTooltip
    : familyLearnLibraryTooltipHeader

  if (matchesMd) {
    return (
      <>
        <Tabs
          value={selectedTab}
          onChange={handleChangeSelectedTab}
          color="secondary"
          indicatorColor="secondary"
          textColor="secondary"
          variant="fullWidth"
          sx={{ background: 'white' }}
        >
          <Tab value="first" label={getTitle(learnLibraryText, undefined, learnLibraryTooltip)} />
          <Tab value="second" label={getTitle(queriesText, undefined, queriesTooltip)} />
        </Tabs>

        {selectedTab === 'first' && firstTab}
        {selectedTab === 'second' && secondTab}
      </>
    )
  }

  return (
    <Grid container spacing={1}>
      <Grid item sm={12} md={6} lg={8}>
        <PageTab title={learnLibraryText} tooltip={learnLibraryTooltip} />
        {firstTab}
      </Grid>

      <Grid item sm={12} md={6} lg={4}>
        <PageTab title={queriesText} tooltip={queriesTooltip} />
        {secondTab}
      </Grid>
    </Grid>
  )
}

export default LearnLibrary
