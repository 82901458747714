import DeviceDetector from 'device-detector-js'
import { ClientResult } from 'device-detector-js/dist/parsers/client'
import { Result as OperatingSystemResult } from 'device-detector-js/dist/parsers/operating-system'
import type { GenericDeviceResult } from 'device-detector-js/dist/typings/device'
import { Nullish } from 'types'

interface DefineDevicePayload {
  device: Nullish<GenericDeviceResult>
  browserInfo: Nullish<ClientResult>
  osInfo: OperatingSystemResult
  isDesktop: boolean
  isApple: boolean
  isMobile: boolean
}

export function defineDevice(): DefineDevicePayload {
  const detectorResult = new DeviceDetector().parse(navigator.userAgent)
  let isDesktop: boolean

  if (detectorResult.device) {
    isDesktop = detectorResult.device.type === 'desktop'
  } else {
    isDesktop = false
  }

  const isApple = detectorResult.device?.brand === 'Apple'

  return {
    device: detectorResult.device,
    browserInfo: detectorResult.client,
    osInfo: detectorResult.os,
    isDesktop,
    isApple,
    isMobile: !isDesktop,
  }
}

export const { isDesktop, isMobile, isApple, device, browserInfo, osInfo } = defineDevice()
