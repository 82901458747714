import { Box, Container, Stack } from '@mui/material'
import { useAuthContext } from 'components/context/AuthProvider'
import React from 'react'
import { Navigate, Outlet } from 'react-router-dom'
import { routes } from 'routes/routes'
import { AuthCopyright } from '../AuthCopyright/AuthCopyright'

export function AuthPagesLayout() {
  const { checkIsLoggedIn } = useAuthContext()

  if (checkIsLoggedIn()) {
    return <Navigate to={routes.journey} />
  }

  return (
    <Container component="main" sx={{ display: 'flex', justifyContent: 'center' }}>
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        width="100%"
        sx={{ maxWidth: 664 }}
      >
        <Stack mt={3} spacing={2} alignItems="center" maxWidth="460px" width="100%">
          <iframe
            title="_"
            width="100%"
            height="250"
            src="https://www.youtube.com/embed/0F0VmD8QfaM?modestbranding=0"
            frameBorder="0"
            allowFullScreen
          />
        </Stack>

        <Box alignItems="center" width="100%" sx={{ mt: 2, background: 'white', p: 4 }}>
          <Outlet />
        </Box>

        <AuthCopyright />
      </Box>
    </Container>
  )
}
