import { Typography } from '@mui/material'
import { grey } from '@mui/material/colors'
import { UIEntityItemBox } from 'features/UI'
import React, { CSSProperties } from 'react'
import { Draggable, DraggingStyle, NotDraggingStyle } from 'react-beautiful-dnd'

const getDraggableItemStyle = (
  isDragging: boolean,
  draggableStyle: DraggingStyle | NotDraggingStyle | undefined,
): CSSProperties => ({
  userSelect: 'none',
  margin: `0 0 16px 0`,
  background: isDragging ? grey[300] : '#EDEFF0',
  ...draggableStyle,
})

export function ExamQuestionDraggableItem({
  id,
  title,
  index,
  onDelete,
}: {
  id: number
  title: string
  index: number
  onDelete: (id: number) => void
}) {
  const handleDelete = () => {
    onDelete(id)
  }

  return (
    <Draggable draggableId={`item-${id}`} index={index}>
      {(provided, snapshot) => (
        <div
          ref={provided.innerRef}
          {...provided.draggableProps}
          style={getDraggableItemStyle(snapshot.isDragging, provided.draggableProps.style)}
        >
          <UIEntityItemBox
            isDashed
            dragHandleProps={provided.dragHandleProps}
            onDelete={handleDelete}
          >
            <Typography variant="body1" fontSize="1.125rem">
              {title}
            </Typography>
          </UIEntityItemBox>
        </div>
      )}
    </Draggable>
  )
}
