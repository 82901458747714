import React, { useCallback } from 'react'
import { useTheme } from '@mui/material/styles'
import { List, ListItem, ListItemButton, ListItemIcon, ListItemText } from '@mui/material'
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined'
import LogoutOutlinedIcon from '@mui/icons-material/LogoutOutlined'
import Typography from '@mui/material/Typography'
import LockIcon from '@mui/icons-material/Lock'
import GroupIcon from '@mui/icons-material/Group'
import EmailIcon from '@mui/icons-material/Email'
import { useDrawersContext } from '../../context/ClientsDrawersProvider'
import { routes } from '../../../routes/routes'
import { useNavigate } from 'react-router-dom'
import { useAuthContext } from '../../context/AuthProvider'
import { useActions } from '../../../store/hooks'

interface Props {
  setOpen: (isShow: boolean) => void
}

const ProfileList = (props: Props) => {
  const { setOpen } = props
  const { logout } = useActions()

  const navigate = useNavigate()
  const theme = useTheme()
  const { clearUserData, user } = useAuthContext()
  const {
    handleOpenInviteMember,
    handleOpenProfile,
    handleOpenChangePasswordDrawer,
    handleOpenContactUs,
  } = useDrawersContext()

  const onClickInvite = useCallback(() => {
    setOpen(false)
    handleOpenInviteMember()
  }, [handleOpenInviteMember, setOpen])

  const onClickContactUs = useCallback(() => {
    setOpen(false)
    handleOpenContactUs()
  }, [handleOpenContactUs, setOpen])

  const onClickViewProfile = () => {
    handleOpenProfile(user?.id)
    setOpen(false)
  }

  const onClickChangePassword = () => {
    handleOpenChangePasswordDrawer()
    setOpen(false)
  }

  const handleLogout = () => {
    logout()
    navigate(routes.signIn)
    clearUserData()
  }

  const viewProfileText = <Typography variant="body1">View Profile</Typography>
  const changePassword = <Typography variant="body1">Change Password</Typography>
  const inviteTeamMember = <Typography variant="body1">Invite Team Member</Typography>
  const logoutText = <Typography variant="body1">Logout</Typography>
  const contactUsText = <Typography variant="body1">Contact Us</Typography>

  const myTeams = (
    <Typography variant="body1" color="grey.700" textAlign="center">
      My Teams
    </Typography>
  )
  const teamsName = (
    <Typography
      variant="body2"
      color="grey.700"
      fontWeight={500}
      textAlign="center"
      textTransform="uppercase"
    >
      Velvetech Test Team
    </Typography>
  )

  return (
    <List
      component="nav"
      sx={{ p: 0, '& .MuiListItemIcon-root': { minWidth: 32, color: theme.palette.grey[500] } }}
    >
      <ListItemButton onClick={onClickViewProfile}>
        <ListItemIcon>
          <PersonOutlineOutlinedIcon />
        </ListItemIcon>
        <ListItemText primary={viewProfileText} />
      </ListItemButton>

      <ListItemButton onClick={onClickChangePassword}>
        <ListItemIcon>
          <LockIcon />
        </ListItemIcon>
        <ListItemText primary={changePassword} />
      </ListItemButton>

      <ListItemButton onClick={onClickInvite}>
        <ListItemIcon>
          <GroupIcon />
        </ListItemIcon>
        <ListItemText primary={inviteTeamMember} />
      </ListItemButton>

      <ListItemButton onClick={onClickContactUs}>
        <ListItemIcon>
          <EmailIcon />
        </ListItemIcon>
        <ListItemText primary={contactUsText} />
      </ListItemButton>

      <ListItemButton onClick={handleLogout}>
        <ListItemIcon>
          <LogoutOutlinedIcon />
        </ListItemIcon>
        <ListItemText primary={logoutText} />
      </ListItemButton>

      <ListItem sx={{ padding: 0 }}>
        <ListItemText primary={myTeams} />
      </ListItem>

      <ListItemButton>
        <ListItemText primary={teamsName} />
      </ListItemButton>
    </List>
  )
}

export default ProfileList
