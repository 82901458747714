import React from 'react'
import { Button, Stack } from '@mui/material'
import FailPlaceholder from './FailPlaceholder'

interface Props {
  show: boolean
  py: number
  error: string
  handleClose: () => void
}

const ModalFailPlaceholder = (props: Props) => {
  if (!props.show) {
    return null
  }
  return (
    <Stack py={props.py} alignItems="center" justifyContent="center" minHeight={500}>
      <Stack spacing={2} alignItems="center" justifyContent="center">
        <FailPlaceholder error={props.error} />
        <Button sx={{ minWidth: 120 }} variant="outlined" onClick={props.handleClose}>
          Close
        </Button>
      </Stack>
    </Stack>
  )
}

export default ModalFailPlaceholder
