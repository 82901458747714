import { zodResolver } from '@hookform/resolvers/zod'
import LoadingButton from '@mui/lab/LoadingButton'
import { Box, Checkbox, FormControlLabel, Grid, Stack, Typography } from '@mui/material'
import { useAuthContext } from 'components/context/AuthProvider'
import FormInput from 'components/form-elements/FormInput'
import { EMAIL_IS_INVALID } from 'const'
import signLogo from 'images/sigin_logo.png'
import React, { useCallback } from 'react'
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form'
import { Link, useNavigate } from 'react-router-dom'
import { routes } from 'routes/routes'
import { object, string, TypeOf } from 'zod'

const formSchema = object({
  email: string().min(1, 'Email is required').email(EMAIL_IS_INVALID),
  password: string().min(1, 'Password is required'),
})

type FormType = TypeOf<typeof formSchema>

export function AuthPageSignIn() {
  const { login, authLoading } = useAuthContext()

  const methods = useForm<FormType>({
    mode: 'onChange',
    resolver: zodResolver(formSchema),
    defaultValues: {
      email: '',
      password: '',
    },
  })

  const {
    formState: { isDirty, isValid },
    handleSubmit,
  } = methods

  const navigate = useNavigate()

  const onClickForgot = useCallback(() => {
    navigate(routes.forgotPassword)
  }, [navigate])

  const onSubmitHandler: SubmitHandler<FormType> = useCallback(
    (values: FormType) => {
      const { email, password } = values
      login(email, password)
    },
    [login],
  )

  return (
    <FormProvider {...methods}>
      <Box display="flex" justifyContent="center" mb={4}>
        <img src={signLogo} alt="Sign In Logo" />
      </Box>

      <Box component="form" onSubmit={handleSubmit(onSubmitHandler)} width="100%" noValidate>
        <FormInput
          label="Email Address"
          autoComplete="email"
          name="email"
          required
          fullWidth
          sx={{ mb: 1 }}
        />

        <FormInput
          label="Password"
          required
          fullWidth
          name="password"
          type="password"
          autoComplete="current-password"
          sx={{ mb: 1 }}
        />

        <Grid container alignItems="center">
          <Grid item xs>
            <FormControlLabel
              control={<Checkbox value="remember" color="primary" />}
              label="Remember me"
            />
          </Grid>

          <Grid item>
            <Typography
              variant="body1"
              color="grey.600"
              onClick={onClickForgot}
              sx={{ cursor: 'pointer' }}
            >
              Forgot password?
            </Typography>
          </Grid>
        </Grid>

        <Grid container justifyContent="center" mt={2}>
          <Grid item xs={12} sm={6}>
            <LoadingButton
              loading={authLoading}
              disabled={!isDirty || !isValid}
              fullWidth
              variant="contained"
              type="submit"
            >
              Sign In
            </LoadingButton>

            <Stack direction="row" alignItems="center" justifyContent="center" spacing={1} mt={2}>
              <Typography variant="body1" color="grey.600">
                Don&apos;t have an account?
              </Typography>

              <Link to={routes.signUp}>
                <Typography variant="body1" color="info.main">
                  Sign Up
                </Typography>
              </Link>
            </Stack>
          </Grid>
        </Grid>
      </Box>
    </FormProvider>
  )
}
