import intersection from 'lodash/intersection'
import sortBy from 'lodash/sortBy'
import moment from 'moment'
import { useCallback, useMemo } from 'react'
import { useSelector } from 'react-redux'
import { selectTagsFiltersIds } from 'store/slice/tagsFilters.slice'
import { selectTimelineFilter, selectTimelineFiltersTypes } from 'store/slice/timelineFilters.slice'
import {
  CourseTimelineItem,
  ETimelinePostStatus,
  ETimelinePostType,
  Tag,
  TimelineItem,
} from 'types'

export function useTimelineList(timeline: TimelineItem[]) {
  const timelineTypes = useSelector(selectTimelineFiltersTypes)
  const timelineStatus = useSelector(selectTimelineFilter)
  const tagsIds = useSelector(selectTagsFiltersIds)

  const hasCourseFilter = useMemo(() => {
    if (!timelineTypes) return true
    return timelineTypes.includes(ETimelinePostType.course)
  }, [timelineTypes])

  const hasLessonFilter = useMemo(() => {
    if (!timelineTypes) return true
    return timelineTypes.includes(ETimelinePostType.lessons)
  }, [timelineTypes])

  const groupedTimelineElements = useMemo(() => {
    if (!timeline) {
      return []
    }

    // group lessons and exams by course
    const groupedItems: (TimelineItem | CourseTimelineItem)[] = []
    const coursesIndexes: { [key: number]: number } = {}

    timeline.forEach((item) => {
      if (
        (item.type === ETimelinePostType.lessons ||
          item.type === ETimelinePostType.questionnaire) &&
        item.course
      ) {
        const courseId = item.course.id
        if (coursesIndexes[courseId] === undefined) {
          coursesIndexes[courseId] = groupedItems.length
          groupedItems.push({
            id: courseId,
            type: ETimelinePostType.course,
            name: item.course.name,
            status: item.course.status,
            bannerUrl: item.course.bannerUrl,
            items: [],
            updatedAt: item.course.updatedAt,
          })
        }

        const course = groupedItems[coursesIndexes[courseId]] as CourseTimelineItem
        course.items.push(item)
        if (moment(item.updatedAt).isSameOrAfter(course.updatedAt)) {
          course.updatedAt = item.updatedAt
        }
        return
      }

      groupedItems.push(item)
    })

    return groupedItems
  }, [timeline])

  const filterRootTimelineItemByType = useCallback(
    (item: TimelineItem | CourseTimelineItem) => {
      if (timelineTypes === null) return true // no filters by type

      if (item.type !== ETimelinePostType.course) {
        return timelineTypes.includes(item.type)
      }

      return hasCourseFilter || hasLessonFilter
    },
    [hasCourseFilter, hasLessonFilter, timelineTypes],
  )

  const filterChildTimelineItemByType = useCallback(
    (item: TimelineItem | CourseTimelineItem) => {
      if (timelineTypes === null) return item
      if (item.type !== ETimelinePostType.course) return item

      // if we have only lessons filter, we should filter exams
      if (!hasCourseFilter && hasLessonFilter) {
        const filteredItems = item.items.filter((lesson) => {
          return timelineTypes.includes(lesson.type)
        })

        return {
          ...item,
          items: filteredItems,
        }
      }

      return item
    },
    [hasCourseFilter, hasLessonFilter, timelineTypes],
  )

  const filterRootTimelineItemByStatus = useCallback(
    (item: TimelineItem | CourseTimelineItem) => {
      if (timelineStatus === null) return true // no filters by status

      if (item.type !== ETimelinePostType.course) {
        return timelineStatus.includes(item.status)
      }

      if (hasCourseFilter && !hasLessonFilter) {
        return timelineStatus.includes(item.status)
      }

      return true
    },
    [hasCourseFilter, hasLessonFilter, timelineStatus],
  )

  const filterChildTimelineItemByStatus = useCallback(
    (item: TimelineItem | CourseTimelineItem) => {
      if (timelineStatus === null) return item // no filters by status
      if (item.type !== ETimelinePostType.course) return item

      if (hasCourseFilter && !hasLessonFilter) return item

      const filteredItems = item.items.filter((childItem) => {
        return timelineStatus.includes(childItem.status)
      })

      return {
        ...item,
        items: filteredItems,
      }
    },
    [hasCourseFilter, hasLessonFilter, timelineStatus],
  )

  const hasTags = useCallback((tags: Tag[], tagsIds: number[]) => {
    if (!tags) {
      return false
    }

    const itemTagsIds = tags.map((tag) => tag.id)
    return intersection(itemTagsIds, tagsIds).length > 0
  }, [])

  const filterTimelineRootItemByTags = useCallback(
    (item: TimelineItem | CourseTimelineItem) => {
      if (tagsIds === null) return true

      if (item.type === ETimelinePostType.course) {
        return true
      }

      if (item.type === ETimelinePostType.careArticle) {
        return hasTags(item.careArticle.tags, tagsIds)
      }

      return false
    },
    [hasTags, tagsIds],
  )

  const filterTimelineChildItemByTags = useCallback(
    (item: TimelineItem | CourseTimelineItem) => {
      if (tagsIds === null) return item
      if (item.type !== ETimelinePostType.course) return item

      const filteredItems = item.items.filter((childItem) => {
        if (childItem.type !== ETimelinePostType.lessons) return false

        return hasTags(childItem.lesson.tags, tagsIds)
      })

      return {
        ...item,
        items: filteredItems,
      }
    },
    [hasTags, tagsIds],
  )

  const filterEmptyCourses = useCallback((item: TimelineItem | CourseTimelineItem) => {
    if (item.type !== ETimelinePostType.course) return true

    return item.items.length > 0
  }, [])

  const sortElements = useCallback((elements: (TimelineItem | CourseTimelineItem)[]) => {
    const itemsNotDone = elements.filter((item) => item.status !== ETimelinePostStatus.done)

    const itemsDone = elements.filter((item) => item.status === ETimelinePostStatus.done)
    const sortedItemsDone = sortBy(itemsDone, (item) => moment(item.updatedAt).toDate())

    return itemsNotDone.concat(sortedItemsDone)
  }, [])

  const filteredTimelineElements = useMemo(() => {
    const filteredItems = groupedTimelineElements
      // filter by type
      .filter((item) => filterRootTimelineItemByType(item))
      .map((item) => filterChildTimelineItemByType(item))
      // filter by status
      .filter((item) => filterRootTimelineItemByStatus(item))
      .map((item) => filterChildTimelineItemByStatus(item))
      // filter by tags
      .filter((item) => filterTimelineRootItemByTags(item))
      .map((item) => filterTimelineChildItemByTags(item))
      // filter empty courses
      .filter((item) => filterEmptyCourses(item))

    const sortedItems = sortElements(filteredItems)
    return sortedItems
  }, [
    filterChildTimelineItemByStatus,
    filterChildTimelineItemByType,
    filterEmptyCourses,
    filterRootTimelineItemByStatus,
    filterRootTimelineItemByType,
    filterTimelineChildItemByTags,
    filterTimelineRootItemByTags,
    groupedTimelineElements,
    sortElements,
  ])

  const amountsByType = useMemo(() => {
    const amounts = {
      [ETimelinePostType.course]: 0,
      [ETimelinePostType.lessons]: 0,
      [ETimelinePostType.careArticle]: 0,
      [ETimelinePostType.taskArticle]: 0,
      [ETimelinePostType.queries]: 0,
      [ETimelinePostType.note]: 0,
      [ETimelinePostType.questionnaire]: 0,
    }

    filteredTimelineElements.forEach((item) => {
      if (item.type === ETimelinePostType.course) {
        amounts[ETimelinePostType.course] += 1
        item.items.forEach((childItem) => {
          amounts[childItem.type] += 1
        })
        return
      }

      amounts[item.type] += 1
    })

    return amounts
  }, [filteredTimelineElements])

  const amountInReviewStatus = useMemo(() => {
    let result = 0

    groupedTimelineElements.forEach((item) => {
      if (item.type === ETimelinePostType.course) {
        item.items.forEach((childItem) => {
          if (childItem.status === ETimelinePostStatus.review) {
            result += 1
          }
        })
        return
      }

      if (item.status === ETimelinePostStatus.review) {
        result += 1
      }
    })

    return result
  }, [groupedTimelineElements])

  return {
    timelineElements: filteredTimelineElements,
    amountsByType,
    amountInReviewStatus,
  }
}
