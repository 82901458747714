import React from 'react'
import { Link } from 'react-router-dom'
import { withIdParam } from '../utils'
import { reactDomLinkStyles } from '../const'

interface Props {
  id: number | string
  children: string
  href: string
  lastPart?: string
}

const IdLink = (props: Props) => {
  const { id, children, href, lastPart = '' } = props

  const link = withIdParam(href, id) + lastPart

  return (
    <Link to={link} style={reactDomLinkStyles}>
      {children}
    </Link>
  )
}

export default IdLink
