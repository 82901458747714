import { Button } from '@mui/material'
import { createMediaFile } from 'api/mediaFiles'
import { AxiosProgressEvent } from 'axios'
import NotificationSys from 'components/NotificationSystem'
import { useShowControl } from 'hooks/useShowControl'
import React, { Dispatch, SetStateAction, useRef, useState } from 'react'
import { LearnArticle, Lesson } from 'types'
import { isDefined } from 'utils'
import {
  ArticleOptionsByType,
  AudioFiles,
  AudioLanguages,
  ContentType,
} from '../../models/Article.model'
import { ArticleAudioUploadModal } from '../ArticleAudioUploadModal/ArticleAudioUploadModal'

export function ArticleAudioControls({
  entityType,
  data,
  setData,
}: {
  entityType: ContentType
  data: Lesson | LearnArticle
  setData: Dispatch<SetStateAction<Lesson | LearnArticle | null>>
}) {
  const [isOpen, handleOpen, handleClose] = useShowControl()
  const [mediaLoading, setMediaLoading] = useState(false)
  const [progressBarValue, setProgressBarValue] = useState<Record<AudioLanguages, number>>({
    [AudioLanguages.EN]: 0,
    [AudioLanguages.ES]: 0,
  })

  const fileAbortControllerRef = useRef(new AbortController())

  const onAbortUpload = () => {
    fileAbortControllerRef.current.abort()
    fileAbortControllerRef.current = new AbortController()
  }

  const onUploadProgress = (lang: AudioLanguages) => (progressEvent: AxiosProgressEvent) => {
    const totalLength = progressEvent.total || 0

    if (totalLength !== null) {
      setProgressBarValue((prev) => {
        return {
          ...prev,
          [lang]: Math.round((progressEvent.loaded * 100) / totalLength),
        }
      })
    }
  }

  const onSubmit = async (files: AudioFiles, filesToDelete: Record<AudioLanguages, boolean>) => {
    setMediaLoading(true)
    try {
      const { loadFunc, updateFunc } = ArticleOptionsByType[entityType]
      const entityResponse = await loadFunc({ id: Number(data.id) })
      const updateRequestData = { ...entityResponse.data }
      if (files[AudioLanguages.EN] !== null) {
        const mediaResponse = await createMediaFile(
          files[AudioLanguages.EN].file,
          onUploadProgress(AudioLanguages.EN),
          fileAbortControllerRef.current.signal,
        )
        const newMediaId = mediaResponse.data.id
        updateRequestData.enMediaFileId = newMediaId
      } else if (filesToDelete[AudioLanguages.EN]) {
        updateRequestData.enMediaFileId = null
      }
      if (files[AudioLanguages.ES] !== null) {
        const mediaResponse = await createMediaFile(
          files[AudioLanguages.ES].file,
          onUploadProgress(AudioLanguages.ES),
          fileAbortControllerRef.current.signal,
        )
        const newMediaId = mediaResponse.data.id
        updateRequestData.esMediaFileId = newMediaId
      } else if (filesToDelete[AudioLanguages.ES]) {
        updateRequestData.esMediaFileId = null
      }

      const updatedData = await updateFunc(updateRequestData)
      setData(updatedData.data)
      NotificationSys.showSuccess('Audio successfully changed')
      handleClose()
    } finally {
      setMediaLoading(false)
    }
  }

  return (
    <>
      <Button variant="outlined" onClick={handleOpen} sx={{ minWidth: 90 }}>
        {isDefined(data.enMediaFileId) || isDefined(data.esMediaFileId)
          ? 'Change Audio'
          : 'Add Audio'}
      </Button>

      <ArticleAudioUploadModal
        isOpen={isOpen}
        onSubmit={onSubmit}
        handleClose={handleClose}
        enMediaUrl={data.enMediaUrl}
        enMediaMimeType={data.enMediaFile?.mimeType}
        esMediaUrl={data.esMediaUrl}
        esMediaMimeType={data.esMediaFile?.mimeType}
        title="Upload Audio"
        loading={mediaLoading}
        progressBarValue={progressBarValue}
        onAbortUpload={onAbortUpload}
      />
    </>
  )
}
