import { getCourses } from 'api/courses'
import { Option } from 'components/form-elements/FormSelect'
import ModalWithSelect from 'components/modals/simple-modals/ModalWithSelect'
import React, { useMemo } from 'react'
import { useQuery } from 'react-query'

export const LessonModalDuplicate = ({
  onClose,
  loading,
  value,
  onSubmit,
}: {
  onClose: () => void
  value?: number
  onSubmit: (id: number) => void
  loading: boolean
}) => {
  const topics = useQuery('getCourses', () => getCourses())

  const options = useMemo(() => {
    if (!topics.data) {
      return []
    }

    const options = [...topics.data.data.rows].map<Option>((t) => ({ label: t.name, value: t.id }))
    options.sort((a, b) => (a.label > b.label ? 1 : -1))

    return options
  }, [topics])

  return (
    <ModalWithSelect
      value={value}
      title="Duplicate Lesson"
      label="Pick a course for your new lesson."
      buttonText="Save"
      isOpen
      handleClose={onClose}
      onSubmit={onSubmit}
      options={options}
      loading={loading}
      loadingOptions={topics.isLoading}
    />
  )
}
