import CloseIcon from '@mui/icons-material/Close'
import { Box, IconButton, Modal, SxProps, Typography } from '@mui/material'
import { Theme } from '@mui/material/styles/createTheme'
import { defaultModalStyle, defaultModalZIndex } from 'const'
import React, { ReactNode, useMemo } from 'react'

const modalCloseButtonStyle = {
  position: 'absolute',
  right: 8,
  top: 8,
  color: (theme: Theme) => theme.palette.grey[500],
}

export function UIModal({
  title,
  isOpen,
  children,
  onClose,
  width = 420,
  maxWidth,
  height,
}: {
  title?: string
  isOpen: boolean
  children: ReactNode
  onClose: () => void
  width?: number | string
  maxWidth?: number | string
  height?: number | string
}) {
  const contentStyles = useMemo(() => {
    const styles: SxProps<Theme> = {
      ...defaultModalStyle,
      width,
    }

    if (maxWidth) {
      styles.maxWidth = maxWidth
    }

    if (height) {
      styles.height = height
    }

    return styles
  }, [height, maxWidth, width])

  return (
    <Modal sx={defaultModalZIndex} open={isOpen} onClose={onClose}>
      {isOpen ? (
        <Box sx={contentStyles}>
          <IconButton onClick={onClose} sx={modalCloseButtonStyle}>
            <CloseIcon />
          </IconButton>

          {title && (
            <Typography variant="h5" mb={3}>
              {title}
            </Typography>
          )}

          {children}
        </Box>
      ) : (
        <Box />
      )}
    </Modal>
  )
}
