import { createSelector, createSlice } from '@reduxjs/toolkit'
import { RootState } from '../store'

export interface NavigationState {
  isSidebarOpen: boolean
}

const initialState: NavigationState = {
  isSidebarOpen: false,
}

export const navigationSlice = createSlice({
  name: 'navigation',
  initialState,
  reducers: {
    toggleSidebar: (state) => {
      state.isSidebarOpen = !state.isSidebarOpen
    },
    closeSidebar: (state) => {
      state.isSidebarOpen = false
    },
    openSidebar: (state) => {
      state.isSidebarOpen = true
    },
  },
})

export const { toggleSidebar, closeSidebar, openSidebar } = navigationSlice.actions

export const selectNavigationSlice = (state: RootState) => state.navigation

export const selectSidebarIsOpen = createSelector(
  selectNavigationSlice,
  (slice) => slice.isSidebarOpen,
)
