import { LabelValue } from 'features/Metadata'
import { useMemo } from 'react'
import { LearnTopic } from 'types'

export function useTopicMetadata(topic: LearnTopic | null): LabelValue[] {
  return useMemo(() => {
    if (!topic) {
      return []
    }

    return [
      {
        label: 'Learn Articles Total',
        value: topic.countOfArticles,
      },
      {
        label: 'Published Learn Articles',
        value: topic.countOfPublishedArticles,
      },
      {
        label: 'Linked to Lesson Articles',
        value: topic.countOfLinkedLessonArticles,
      },
      {
        label: 'Linked to Timeline Post Articles',
        value: topic.countOfLinkedCareArticles,
      },
    ]
  }, [topic])
}
