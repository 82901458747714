import React from 'react'
import { Box, Grid, Typography } from '@mui/material'
import { styled } from '@mui/material/styles'
import { getTitle } from './TabsTitle'
import HelpTooltip from '../tooltips/HelpTooltip'

interface Props {
  title: string
  tooltip?: string
}

const Container = styled(Box)(({ theme }) => ({
  padding: 12,
  textAlign: 'center',
  background: 'white',
  borderBottom: `4px solid ${theme.palette.secondary.main}`,
}))

const PageTab = (props: Props) => {
  const item = (
    <Container>
      <Typography
        variant="body1"
        fontWeight={700}
        fontSize="24px"
        color="secondary.dark"
        textTransform="none"
      >
        {props.title}
      </Typography>
    </Container>
  )

  return props.tooltip ? (
    <HelpTooltip text={props.tooltip} placement="bottom">
      {item}
    </HelpTooltip>
  ) : (
    item
  )
}

export default PageTab
