import { getCourses } from 'api/courses'
import { moveLesson } from 'api/lessons'
import { UIModalSelectEntity } from 'features/UI'
import { useCallback, useMemo, useState } from 'react'
import { useMutation } from 'react-query'
import { useNavigate } from 'react-router'

export function useLessonMove() {
  const navigate = useNavigate()
  const [lessonId, setLessonId] = useState<number>()

  const clearLessonId = useCallback(() => {
    setLessonId(undefined)
  }, [])

  const moveLessonMutation = useMutation(moveLesson)

  const handleDuplicateLesson = useCallback(
    async (courseId: number) => {
      if (lessonId === undefined) return

      moveLessonMutation.mutate(
        { id: lessonId, courseId },
        {
          onSuccess: () => {
            navigate(`/admin/lms/lesson-edit?id=${lessonId}`, {
              state: {
                notification: 'Lesson has been successfully moved',
              },
            })
            setLessonId(undefined)
          },
        },
      )
    },
    [lessonId, moveLessonMutation, navigate],
  )

  const handleMoveClicked = useCallback((id: number) => {
    setLessonId(id)
  }, [])

  const action = useMemo(
    () => ({
      name: 'Move',
      onClick: handleMoveClicked,
    }),
    [handleMoveClicked],
  )

  const MoveModal = useMemo(() => {
    if (!lessonId) return null

    return (
      <UIModalSelectEntity
        queryId="getCourses"
        apiRequest={getCourses}
        title="Move Lesson"
        label="Pick a course"
        description="The moved lesson will be displayed in the new course as an unpublished."
        onClose={clearLessonId}
        onSubmit={handleDuplicateLesson}
        loading={moveLessonMutation.isLoading}
      />
    )
  }, [clearLessonId, handleDuplicateLesson, lessonId, moveLessonMutation.isLoading])

  return {
    moveAction: action,
    MoveModal,
  }
}
