import { ICellRendererParams } from 'ag-grid-community'
import { formatDate } from 'utils'
import { PLACEHOLDER } from './common'

const dateRenderer =
  (propName: string, format?: string) =>
  // eslint-disable-next-line react/display-name
  (props: ICellRendererParams<{ [propName: string]: string }>) => {
    const date = props?.data?.[propName]

    if (date === null || date === undefined) {
      return PLACEHOLDER
    }

    return formatDate(date, format)
  }

export default dateRenderer
