import styled from '@emotion/styled'
import { Button, Tooltip, Typography } from '@mui/material'
import React from 'react'

export const StyledButton = styled(Button)`
  min-width: 64px;
`

export function ProgressItemViewButton({
  disabled,
  hasTooltip,
  onClick,
  title = 'View',
}: {
  disabled: boolean
  hasTooltip: boolean
  onClick: () => void
  title?: string
}) {
  return (
    <Tooltip
      title={
        hasTooltip ? (
          <Typography variant="body2">
            Do you want to learn more? Continue your education in Training Journey.
          </Typography>
        ) : null
      }
      arrow
      placement="top"
    >
      <div>
        <StyledButton size="small" variant="contained" disabled={disabled} onClick={onClick}>
          {title}
        </StyledButton>
      </div>
    </Tooltip>
  )
}
