import { zodResolver } from '@hookform/resolvers/zod/dist/zod'
import { LoadingButton } from '@mui/lab'
import { Box, Button, Modal, Stack, Typography } from '@mui/material'
import { checkCourseUniqueness, createCourse } from 'api/courses'
import FormInput from 'components/form-elements/FormInput'
import NotificationSys from 'components/NotificationSystem'
import { defaultModalStyle, defaultModalZIndex, FIELD_IS_REQUIRED, minButtonWidth } from 'const'
import React, { useMemo } from 'react'
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form'
import { useMutation } from 'react-query'
import { useNavigate } from 'react-router-dom'
import { routes } from 'routes/routes'
import { CourseRequestCreate } from 'types'
import { withIdParam } from 'utils'
import { object, string, TypeOf } from 'zod'

interface Props {
  isOpen: boolean
  handleClose: () => void
}

interface InnerProps {
  title: string
  label: string
  buttonText: string

  isOpen: boolean
  handleClose: () => void
  onSubmit: (request: CourseRequestCreate) => void
  loading: boolean
}

const modalStyle = {
  ...defaultModalStyle,
  width: 420,
}

const ModalContent = (props: InnerProps) => {
  const { handleClose, title, label, buttonText, onSubmit, loading } = props

  const formSchema = useMemo(() => {
    return object({
      name: string().min(1, FIELD_IS_REQUIRED),
    })
  }, [])

  const formSettings = useMemo(() => {
    return {
      mode: 'onChange' as const,
      resolver: zodResolver(formSchema),
      defaultValues: {
        name: '',
      },
    }
  }, [formSchema])

  type FormType = TypeOf<typeof formSchema>

  const methods = useForm<FormType>(formSettings)
  const { handleSubmit } = methods

  const onSubmitHandler: SubmitHandler<FormType> = async (values: FormType) => {
    const success = await checkCourseUniqueness({ name: values.name })
    if (!success) {
      NotificationSys.showWarning('Course with such name already exists')
      return
    }

    onSubmit({
      name: values.name,
    })
  }

  return (
    <Box sx={modalStyle}>
      <Typography variant="h5" mb={3}>
        {title}
      </Typography>

      <FormProvider {...methods}>
        <Box component="form" onSubmit={handleSubmit(onSubmitHandler)} width="100%" noValidate>
          <Stack spacing={2}>
            <FormInput label={label} name="name" fullWidth={true} />
          </Stack>
          <Stack direction="row" spacing={2} justifyContent="flex-end" sx={{ mt: 5 }}>
            <Button variant="outlined" onClick={handleClose} sx={minButtonWidth}>
              Close
            </Button>
            <LoadingButton loading={loading} variant="contained" type="submit" sx={minButtonWidth}>
              {buttonText}
            </LoadingButton>
          </Stack>
        </Box>
      </FormProvider>
    </Box>
  )
}

const ModalInnerContent = (props: InnerProps) => {
  const { isOpen } = props

  return (
    <Modal sx={defaultModalZIndex} open={isOpen}>
      <div>{isOpen ? <ModalContent {...props} /> : <Box />}</div>
    </Modal>
  )
}

const AddCourseModal = (props: Props) => {
  const { isOpen, handleClose } = props
  const navigate = useNavigate()

  const mutation = useMutation(createCourse, {
    onSuccess: (response) => {
      if (response.data.id) {
        navigate(withIdParam(routes.courseEdit, response.data.id))
      }
    },
  })

  return (
    <ModalInnerContent
      title="Create Course"
      label="Course name"
      buttonText="Create"
      isOpen={isOpen}
      handleClose={handleClose}
      onSubmit={mutation.mutate}
      loading={mutation.isLoading}
    />
  )
}

export default React.memo(AddCourseModal)
