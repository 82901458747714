import { LabelValue } from 'features/Metadata'
import { useMemo } from 'react'
import { CareArticle, TableResponse } from 'types'

export function useArticleMetadata(
  title: string,
  response?: TableResponse<CareArticle>,
): LabelValue[] {
  return useMemo(() => {
    if (!response) {
      return []
    }

    return [
      {
        label: `${title} Total`,
        value: response.data.count,
      },
      {
        label: `Published ${title}`,
        value: response.data.countOfPublishedArticles || 0,
      },
      {
        label: `Linked ${title}`,
        value: response.data.countOfLinkedArticles || 0,
      },
      {
        label: `Linked Published ${title}`,
        value: response.data.countOfLinkedPublishedArticles || 0,
      },
    ]
  }, [response, title])
}
