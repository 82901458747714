import { Stack, Typography } from '@mui/material'
import React, { ReactNode } from 'react'
import ModalHeader from './ModalHeader'

interface Props {
  title: string | ReactNode
  handleClose: () => void
}

const QuizStep = (props: React.PropsWithChildren<Props>) => {
  const { title, handleClose, children } = props

  return (
    <Stack spacing={1.5}>
      <ModalHeader handleClose={handleClose}>
        <Typography variant="h6" fontWeight={400}>
          {title}
        </Typography>
      </ModalHeader>
      {children}
    </Stack>
  )
}

export default React.memo(QuizStep)
