import { OutputBlockData, OutputData } from '@editorjs/editorjs/types/data-formats/output-data'
import AudioIcon from '@mui/icons-material/Audiotrack'
import ImageIcon from '@mui/icons-material/Image'
import YouTubeIcon from '@mui/icons-material/YouTube'
import { Stack, Typography } from '@mui/material'
import { orange, red, green } from '@mui/material/colors'
import React from 'react'
import { DraggableProvidedDragHandleProps } from 'react-beautiful-dnd'
import { truncateString } from 'utils'
import { UIEntityItemBox } from '../UIEntityItemBox/UIEntityItemBox'

function strip(html: string) {
  const doc = new DOMParser().parseFromString(
    html.replace('<br>', ' ').replace('<br />', ' '),
    'text/html',
  )
  return doc.body.textContent || ''
}

const getFirstBlock = (article: OutputData | null) => {
  const block = article?.blocks?.find((blockData: OutputBlockData) => {
    return (
      blockData.type === 'paragraph' ||
      blockData.type === 'header' ||
      blockData.type === 'imageAndText'
    )
  })

  if (block) {
    switch (block.type) {
      case 'paragraph':
      case 'header':
        return block.data.text
      case 'imageAndText':
        return block.data.description
    }
  }

  return ''
}

export function UIEntityItemArticle({
  text,
  onEdit,
  onDelete,
  dragHandleProps,
  article,
  hasAudio,
}: {
  text: string
  article: OutputData | null
  hasAudio: boolean
  onEdit: () => void
  onDelete?: () => void
  dragHandleProps?: DraggableProvidedDragHandleProps | null | undefined
}) {
  const hasVideo = article?.blocks?.some((it) => it.type === 'youtubeEmbed')
  const hasImage = article?.blocks?.some((it) => it.type === 'imageAndText' || it.type === 'image')

  const firstParagraph = strip(getFirstBlock(article))

  return (
    <UIEntityItemBox
      onEdit={onEdit}
      dragHandleProps={dragHandleProps}
      onDelete={onDelete}
      additionalElements={
        firstParagraph && (
          <Typography className="text-max-lines-1" pr={16}>
            {truncateString(firstParagraph, 80)}
          </Typography>
        )
      }
    >
      <Typography variant="body1" fontSize="1.125rem">
        {text}
      </Typography>
      <Stack direction="row" spacing={1} alignItems="center">
        {hasImage && <ImageIcon sx={{ fontSize: '1.5rem', color: orange[400] }} />}
        {hasVideo && <YouTubeIcon sx={{ fontSize: '1.5rem', color: red[700] }} />}
        {hasAudio && <AudioIcon sx={{ fontSize: '1.5rem', color: green[700] }} />}
      </Stack>
    </UIEntityItemBox>
  )
}
