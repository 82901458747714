import createCache from '@emotion/cache'
import { CacheProvider } from '@emotion/react'
import { PropsWithChildren, useEffect, useState } from 'react'
import { createPortal } from 'react-dom'

type CustomIframeProps = {
  width: string
  height: string
}

const CustomIframe = ({
  children,
  width = '380px',
  height = 'inherit',
}: PropsWithChildren<CustomIframeProps>) => {
  const [contentRef, setContentRef] = useState<HTMLIFrameElement>()

  useEffect(() => {
    const head = contentRef?.contentWindow?.document?.head
    const css = `
      * {
        box-sizing: border-box;
      }
      body { 
        margin: 0;
        overscroll-behavior-x: none;
        font-size: 20px;
      }
    `
    const style = contentRef?.contentWindow?.document?.createElement('style')
    if (!style) return
    style.appendChild(document.createTextNode(css))
    head?.appendChild(style)
  }, [contentRef?.contentWindow?.document])

  const cache = createCache({
    key: 'css',
    container: contentRef?.contentWindow?.document?.head,
    prepend: true,
  })

  const mountNode = contentRef?.contentWindow?.document?.body

  return (
    <CacheProvider value={cache}>
      <iframe
        ref={setContentRef as (value: HTMLIFrameElement) => void}
        style={{
          display: 'block',
          height,
          border: 'none',
          overflow: 'hidden',
          width,
        }}
      >
        {mountNode && createPortal(children, mountNode)}
      </iframe>
    </CacheProvider>
  )
}

export default CustomIframe
