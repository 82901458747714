export default function autoCompleteOverride(): object {
  return {
    MuiAutocomplete: {
      styleOverrides: {
        popper: {
          zIndex: 1400,
        },
      },
    },
  }
}
