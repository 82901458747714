import React from 'react'
import { useParams } from 'react-router-dom'
import { ReportsEngagementUserSession } from './ReportsEngagementUserSession'

export function ReportsEngagementUserSessionPage() {
  const { sessionUuid } = useParams<{ sessionUuid: string }>()

  if (!sessionUuid) {
    return null
  }

  return <ReportsEngagementUserSession sessionUuid={sessionUuid} />
}
