import { AccessCode, BaseAccessCode, BaseTableRequest, Response, TableResponse } from 'types'
import axiosInstance from './axios'

export const getCodes = async (request: BaseTableRequest): Promise<TableResponse<AccessCode>> => {
  return axiosInstance.post('/access-codes', request)
}

export const createCode = async (request: BaseAccessCode): Promise<Response<AccessCode>> => {
  return axiosInstance.post('/access-codes/create', request)
}

export const updateCode = async (request: BaseAccessCode): Promise<Response<AccessCode>> => {
  return axiosInstance.post('/access-codes/update', request)
}
