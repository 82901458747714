import {
  ApiSuccessResponse,
  BaseTableRequest,
  CreateLibraryTopic,
  LearnTopic,
  RequestArticlesOrder,
  RequestById,
  Response,
  TableResponse,
} from 'types'
import axiosInstance from './axios'

export const getLibraryTopics = async (
  request: BaseTableRequest = { filter: {}, sort: null },
): Promise<TableResponse<LearnTopic>> => {
  return axiosInstance.post('/learn-topics', request)
}

export const createLibraryTopic = async (
  request: CreateLibraryTopic,
): Promise<Response<LearnTopic>> => {
  return axiosInstance.post('/learn-topics/create', request)
}

export const updateLibraryTopic = async (request: LearnTopic): Promise<Response<LearnTopic>> => {
  return axiosInstance.post('/learn-topics/update', request)
}

export const getLibraryTopicById = async (request: RequestById): Promise<Response<LearnTopic>> => {
  return axiosInstance.post('/learn-topics/read', request)
}

export const deleteLibraryTopic = async (request: RequestById): Promise<unknown> => {
  return axiosInstance.post('/learn-topics/delete', request)
}

export const updateOrderOfTopicArticles = async (
  request: RequestArticlesOrder,
): Promise<unknown> => {
  return axiosInstance.post('/learn-topics/order-articles', request)
}

export const duplicateTopic = (request: RequestById): Promise<Response<{ id: number }>> => {
  return axiosInstance.post('/learn-topics/duplicate', request)
}

export const updateTopicsOrder = (request: { ids: number[] }): Promise<ApiSuccessResponse> => {
  return axiosInstance.post('/learn-topics/order', request)
}
