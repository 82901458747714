import React from 'react'
import { Box, Stack, Typography } from '@mui/material'
import { Controller, useFormContext } from 'react-hook-form'
import CloseIcon from '@mui/icons-material/Close'
import DoneIcon from '@mui/icons-material/Done'

interface Props {
  name: string
}

interface ItemProps {
  children: React.ReactNode
  hasError: boolean
}

const Item = (props: ItemProps) => {
  const { children, hasError } = props

  const Icon = hasError ? CloseIcon : DoneIcon

  return (
    <Stack direction="row" spacing={0.5}>
      <Icon color={hasError ? 'error' : 'success'} sx={{ width: 20, height: 20 }} />
      <Typography variant="body2" color={hasError ? 'error' : 'textSecondary'}>
        {children}
      </Typography>
    </Stack>
  )
}

export const validatePassword = (value: string) => {
  const hasLength = value.length >= 8
  const hasNumber = /\d/.test(value)
  const hasLetter = /[a-z]/i.test(value)
  const hasSymbol = /[!"#$%&'()*+,-.\/:;<=>?@[\]^_`{|}~\\]/.test(value)

  const allCheck = hasLength && hasNumber && hasLetter && hasSymbol

  return { hasLength, hasNumber, hasLetter, hasSymbol, allCheck }
}

const FormPasswordCheckList = (props: Props) => {
  const { name } = props

  const { control } = useFormContext()

  return (
    <Controller
      control={control}
      name={name}
      render={({ field }) => {
        const value = field.value as string

        const { hasLength, hasNumber, hasLetter, hasSymbol } = validatePassword(value)

        return (
          <Box mt={1}>
            <Typography variant="body2" color="textSecondary" mb={0.5}>
              Password Requirements:
            </Typography>
            <Stack spacing={0.5}>
              <Item hasError={!hasLength}>be at least 8 characters long</Item>
              <Item hasError={!hasNumber}>include at least one number</Item>
              <Item hasError={!hasLetter}>include at least one letter</Item>
              <Item hasError={!hasSymbol}>
                include at least one symbol:
                <br />
                {'!"#$%&\'()*+,-./:;<=>?@[]^_`{|}~\\'.split('').join(' ')}
              </Item>
            </Stack>
          </Box>
        )
      }}
    />
  )
}

export default FormPasswordCheckList
