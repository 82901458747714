import { zodResolver } from '@hookform/resolvers/zod/dist/zod'
import { validatePassword } from 'components/FormPasswordCheckList'
import { EMAIL_IS_INVALID, PASSWORD_REQUIREMENTS } from 'const'
import { boolean, object, string, TypeOf } from 'zod'

export interface FamilyFormValues {
  firstName: string
  lastName: string

  email: string

  personOfConcernFirstName: string
  personOfConcernLastNameOrInitial: string

  myNewPassword: string
  sameNewPassword: string

  levelOfCareNeeded: number | null
  readTermsAndConditions: boolean
}

export const familyFormSchema = object({
  firstName: string().min(1, 'First name is required'),
  lastName: string().min(1, 'Last name is required'),

  email: string().min(1, 'Email is required').email(EMAIL_IS_INVALID),

  personOfConcernFirstName: string().min(1, 'First name is required'),
  personOfConcernLastNameOrInitial: string().min(1, 'Last name is required'),

  myNewPassword: string().refine(
    (password: string) => validatePassword(password).allCheck,
    PASSWORD_REQUIREMENTS,
  ),
  sameNewPassword: string().min(1, 'Password is required'),

  levelOfCareNeeded: string().min(1, 'Level of care is required'),
  readTermsAndConditions: boolean().refine(
    (val) => val,
    'Please confirm of reading terms and conditions.',
  ),
}).refine((data) => data.myNewPassword === data.sameNewPassword, {
  path: ['sameNewPassword'],
  message: 'Passwords do not match',
})

export type FamilyFormType = TypeOf<typeof familyFormSchema>

const defaultValues: FamilyFormType = {
  firstName: '',
  lastName: '',

  email: '',

  personOfConcernFirstName: '',
  personOfConcernLastNameOrInitial: '',

  myNewPassword: '',
  sameNewPassword: '',

  levelOfCareNeeded: '',
  readTermsAndConditions: false,
}

export const familyInitialState = {
  familyForm: defaultValues,
  accessCode: undefined as string | undefined,
}

export const familyFormSettings = {
  mode: 'onChange' as const,
  resolver: zodResolver(familyFormSchema),
  defaultValues,
}

export enum FamilySignupStage {
  FamilyForm,
  PromocodeForm,
  CardForm,
}
