import { Duration } from 'moment'
import moment, { Moment } from 'moment/moment'

export const getTimeDifference = (startDate: Date, endDate: Date) => {
  const diff = Number(endDate) - Number(startDate)

  const hours = Math.floor(diff / 3600000)
  const minutes = Math.floor((diff % 3600000) / 60000)
  const seconds = Math.floor((diff % 60000) / 1000)

  return { hours, minutes, seconds }
}

export const formatDate = (
  date: Date | string | number | moment.Moment,
  format: string = 'MM/DD/YYYY',
) => {
  return moment(date).format(format)
}

export const formatDateAccessCode = (date: Date | string | moment.Moment) => {
  return formatDate(date, 'YYYY-MM-DD')
}

export const formatTimelineDate = (date: string | Date | number | moment.Moment) => {
  return formatDate(date, 'MMMM Do, YYYY')
}

export const formatMetricDate = (date?: number) => {
  return moment(date).format('YYYY-MM-DDTHH:mm:ssZ')
}

export const isDateSameOrAfter = (targetDate: Moment | string, compareDate?: Date | string) => {
  const _endDate = compareDate || moment.utc()
  return moment(targetDate, 'YYYY-MM-DD').isSameOrAfter(_endDate)
}

export const getDateDiff = (startDate: Moment | string, endDate: Moment | string) => {
  const diff = moment(endDate).diff(moment(startDate))
  return moment.duration(diff)
}

export const formatDateDuration = (duration: Duration) => {
  const result: string[] = []
  const years = duration.years()
  const months = duration.months()
  const days = duration.days()
  if (years > 0) {
    result.push(`${years}y`)
  }
  if (months > 0) {
    result.push(`${months}m`)
  }
  if (days > 0) {
    result.push(`${days}d`)
  }

  return result.join(' ')
}

const getValueFromDurationItem = (durationItem: string, postfix: string) => {
  let result = 0
  const value = Number(durationItem.replace(postfix, ''))
  if (Number.isInteger(value)) {
    result += Number(value)
  }

  return result
}

export const parseDateDuration = (durationString: string) => {
  let years = 0
  let months = 0
  let days = 0

  durationString.split(' ').forEach((durationItem) => {
    if (durationItem.endsWith('y')) {
      years += getValueFromDurationItem(durationItem, 'y')
    } else if (durationItem.endsWith('m')) {
      months += getValueFromDurationItem(durationItem, 'm')
    } else if (durationItem.endsWith('d')) {
      days += getValueFromDurationItem(durationItem, 'd')
    }
  })

  return { years, months, days }
}
