import { Box, Stack } from '@mui/material'
import { useDrawersContext } from 'components/context/ClientsDrawersProvider'
import { UIItemContentContainer, UIItemImage, UIStatusBadge } from 'features/UI'
import IconQuestion from 'images/icons/icon_sm_question.png'
import React, { useCallback } from 'react'
import { useActions } from 'store/hooks'
import { ETimelinePostStatus, QuestioningType } from 'types'
import { TimelineItemHeader } from '../TimelineItemHeader/TimelineItemHeader'
import { TimelineLine } from '../TimelineLine/TimelineLine'

export const TimelineQuestioningItem = ({
  id,
  title,
  date,
  type,
  status,
}: {
  title: string
  date: string
  id: number
  type: QuestioningType
  status: ETimelinePostStatus
}) => {
  const { handleOpenQuestioning } = useDrawersContext()
  const { getTimelineById } = useActions()

  const onQuestioningTaken = useCallback(() => {
    getTimelineById({})
  }, [getTimelineById])

  const onClickQuestioning = () => {
    handleOpenQuestioning({
      mode: 'take',
      id: id,
      type: type,
      onQuestioningTaken: onQuestioningTaken,
    })
  }

  return (
    <Stack direction="row" spacing={2}>
      <div style={{ position: 'relative', minWidth: 30, minHeight: '100%' }}>
        <UIItemImage src={IconQuestion} isOnTimeline />
        <TimelineLine />
      </div>

      <UIItemContentContainer onClick={onClickQuestioning}>
        <TimelineItemHeader title={title} date={date} hideBorderBottom showAvatar={true} />
        <Box>
          <UIStatusBadge status={status} />
        </Box>
      </UIItemContentContainer>
    </Stack>
  )
}
