import React from 'react'
import { FormLabel, MenuItem, Stack, TextField } from '@mui/material'
import { TextFieldProps } from '@mui/material/TextField/TextField'
import { Controller, useFormContext } from 'react-hook-form'

export interface Option {
  label: string | number
  value: string | number | boolean
}

type IFormInputProps = {
  name: string
  options: Option[]
} & TextFieldProps

const FormSelect = (props: IFormInputProps) => {
  const { label, options, sx = {}, name, defaultValue, required, ...otherProps } = props

  const {
    control,
    formState: { errors },
  } = useFormContext()

  return (
    <Controller
      control={control}
      name={name}
      defaultValue={defaultValue}
      render={({ field }) => (
        <Stack spacing={0.5} sx={sx}>
          <FormLabel required={required}>{label}</FormLabel>
          <TextField
            select
            required={required}
            size="small"
            {...field}
            {...otherProps}
            onChange={(e) => {
              field.onChange(e.target.value)
              if (props.onChange) {
                props.onChange(e)
              }
            }}
            error={!!errors[name]}
            helperText={(errors[name] ? errors[name]?.message : '') as string}
          >
            {options.map((it, idx) => (
              <MenuItem key={String(it.value)} value={String(it.value)}>
                {it.label}
              </MenuItem>
            ))}
          </TextField>
        </Stack>
      )}
    />
  )
}

export default React.memo(FormSelect)
