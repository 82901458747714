import { SelectValue } from 'components/select/SimpleSelect'
import moment from 'moment'
import { useCallback, useState } from 'react'
import { DateInterval, DateRangeType } from 'types'

const today = moment(new Date())
const todayYear = today.format('YYYY')

export const useDateRange = () => {
  const [rangeType, setRangeType] = useState<DateRangeType>(DateRangeType.Overall)
  const [year, setYear] = useState(todayYear)
  const [month, setMonth] = useState('1')
  const [selectedDate, setSelectedDate] = useState(today)
  const [dateInterval, setDateInterval] = useState<DateInterval>({
    startDate: today,
    endDate: today,
  })

  const onChangeRange = useCallback((name: string, value: SelectValue) => {
    setRangeType(value as DateRangeType)
  }, [])

  const onChangeYear = useCallback((name: string, value: SelectValue) => {
    setYear(value as string)
  }, [])

  const onChangeMonth = useCallback((name: string, value: SelectValue) => {
    setMonth(value as string)
  }, [])

  const onChangeDateInterval = useCallback((value: DateInterval) => {
    setDateInterval(value)
  }, [])

  return {
    rangeType,
    year,
    month,
    dateInterval,
    selectedDate,
    onChangeRange,
    onChangeYear,
    onChangeMonth,
    onChangeDateInterval,
    setSelectedDate,
  }
}
