import { Divider, Stack, Typography } from '@mui/material'
import { LabelValue } from '../models/LabelValue'

export function MetadataItem({ label, value, isLast }: LabelValue & { isLast: boolean }) {
  return (
    <>
      <Stack direction="row" className="gap-4">
        <Typography fontWeight={600}>{label}:</Typography>
        <Typography>{value}</Typography>
      </Stack>
      {!isLast && <Divider orientation="vertical" flexItem />}
    </>
  )
}
