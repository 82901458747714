import { useNavigate } from 'react-router-dom'
import { routes } from 'routes/routes'
import { ArticleType, LessonTimelineItem } from 'types'
import { getRoute } from 'utils'
import { TimelineCourseChildItem } from '../TimelineCourseChildItem/TimelineCourseChildItem'

export function TimelineLessonItem({ item }: { item: LessonTimelineItem }) {
  const navigate = useNavigate()

  const onClickArticle = () => {
    const route = getRoute(routes.journeyItem, {
      journeyItemType: ArticleType.Lesson,
      journeyItemId: item.lessonId,
    })
    navigate(route)
  }

  return (
    <TimelineCourseChildItem
      name={item.lesson.post || item.lesson.name}
      bannerUrl={item.lesson.bannerUrl}
      status={item.status}
      buttonTitle="View"
      onClick={onClickArticle}
    />
  )
}
