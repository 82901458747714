import styled from '@emotion/styled'

export const Container = styled('div')`
  background: white;
  border-bottom: 1px solid #e0e0e0;
  height: 56px;
  display: flex;
  align-items: center;
  justify-content: center;
`
