import React from 'react'
import { Box, Typography } from '@mui/material'

export const AuthFormTitle = ({
  children,
  textColor = 'grey.600',
  mb = 4,
}: {
  children: React.ReactNode
  textColor?: string
  mb?: number
}) => {
  return (
    <Box display="flex" justifyContent="center" sx={{ mb }}>
      <Typography variant="h5" color={textColor} textAlign="center">
        {children}
      </Typography>
    </Box>
  )
}
