export const profileOfConcernTooltip =
  'Use the Person of Concern profile to store information like instructions for care, emergency contacts, and medications. ' +
  'An automatically generated report can share this information with others'

export const careJourneyTooltip =
  'Care Journey provides a list of posts and queries tailored to your situation. ' +
  'Notes from team members and assigned-to-do items also appear here'

export const learnLibraryTooltip =
  'Use the Learn Library to read articles and use queries to guide what is most helpful at each phase of memory loss'

// export const actionsPlanTooltip =
//   'The Action Plan lists important tasks to support your loved one and plan for the future. ' +
//   'Create your own tasks. Assign tasks and set deadlines to make sure tasks are completed.'
export const actionsPlanTooltip = 'This section is under construction.'

export const familyAddNoteTooltip =
  'Add Notes to communicate with other team members. ' +
  'You can provide updates on undefined, ask for help, and share articles'

export const searchTooltip = 'Find posts and articles containing words you enter'

export const familyLearnLibraryTooltipHeader =
  'Use the Learn Library to find in depth articles and you can limit selection to guide what is most helpful at each phase of memory loss'

export const queriesTooltip =
  'Answer short queries to help us provide the most meaningful and effective help along your caregiving journey'

export const assignedTasksTooltip =
  'The Action Plan lists important tasks to support your loved one and plan for the future. ' +
  'Create your own tasks. Assign tasks and set deadlines to make sure tasks are completed.'

export const suggestedTasksTooltip =
  "Suggested Tasks are personalized to help you prepare for the future and avoid complications in your loved one's care."

// -------------Trainee------------------

export const traineeProfileTooltip =
  'Use the Trainee profile to store information like your address, telephone information, ' +
  'your role and experience, and emergency contacts.  ' +
  'An automatically generated report can share this information with other members of your care team or employers.'

export const traineeJourneyTooltip =
  'Training Journey provides a list of posts and lessons tailored to your situation. ' +
  'Notes to yourself about lessons and questions to explore later also appear here'

export const traineeLearnLibraryTooltip =
  'Use the Learn Library to read articles and use queries to guide what is most helpful at each phase of memory loss'

export const traineeProgressTooltip =
  'Progress shows a list of your assignments and your scores on tests you have completed.'

export const traineeAddNoteTooltip =
  'Add Notes to yourself about what you are learning and questions to explore later.'

export const traineeLearnLibraryHeaderTooltip =
  'Use the Learn Library to find in depth articles and you can limit selection to guide what is most helpful at each phase of memory loss'
