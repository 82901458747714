import { OutputData } from '@editorjs/editorjs/types/data-formats/output-data'
import { MediaFile } from './mediaFiles.types'
import { Tag } from './tags.types'

export enum CareArticleType {
  Care = 'careArticle',
  Task = 'taskArticle',
}

export type CareArticle = {
  id: number
  name: string
  post: string | null
  article: OutputData | null
  enMediaUrl: string | null
  enMediaFileId: number | null
  enMediaFile: MediaFile | null
  esMediaUrl: string | null
  esMediaFileId: number | null
  esMediaFile: MediaFile | null
  bannerMediaFile: MediaFile | null
  bannerMediaFileId: number | null
  bannerUrl: string | null
  isPublished: boolean
  hasImage: boolean
  hasVideo: boolean
  hasAudio: boolean
  learnArticleName: string | null
  createdAt: string
  updatedAt: string
  deletedAt: string
  topicId?: number
  queryId?: number
  learnArticleId?: number
  tags: Tag[]
  type: CareArticleType
  note: string | null
}

export interface CareArticleRequestCreate {
  name: string
  type: CareArticleType
}
