import { Response } from 'types'
import { useShowControl } from './useShowControl'

interface Entity {
  name: string
}

export interface BaseChangeNameProps<T> {
  data: T | null
  setLoading: (value: boolean) => void
  setData: (lesson: T) => void
}

interface ChangeProps<T> extends BaseChangeNameProps<T> {
  updateFunc: (value: T) => Promise<Response<T>>
  existedNameWarning?: string
  warning: string
  onSuccess?: () => void
}

interface ChangeFieldProps<T extends Entity> extends ChangeProps<T> {
  field: string
}

type OutProps<P> = [(newValue: P) => Promise<void>, () => void, boolean, () => void]

export const useChangeEntityProperty = <T extends Entity, P>(
  props: ChangeFieldProps<T>,
): OutProps<P> => {
  const {
    data,
    setLoading,
    setData,
    updateFunc,
    warning,
    existedNameWarning = warning,
    field,
    onSuccess,
  } = props

  const [isOpenEditField, openEditField, closeEditField] = useShowControl()

  const onChangeField = async (newValue: P) => {
    if (!data) {
      return
    }
    try {
      setLoading(true)
      const response = await updateFunc({
        ...data,
        [field]: newValue,
      })
      setData(response.data)
      closeEditField()
      setLoading(false)
      if (onSuccess) {
        onSuccess()
      }
    } finally {
      setLoading(false)
    }
  }

  return [onChangeField, openEditField, isOpenEditField, closeEditField]
}

export const useChangeEntityName = <T extends Entity>(props: ChangeProps<T>) => {
  const [onChangeName, openEditName, isOpenEditName, closeEditName] = useChangeEntityProperty({
    ...props,
    field: 'name',
  })
  return {
    onChangeName,
    openEditName,
    isOpenEditName,
    closeEditName,
  }
}
