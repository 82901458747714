import React from 'react'
import { Box, Button, Modal, Stack, Typography } from '@mui/material'
import {
  defaultModalStyle,
  defaultModalZIndex,
  FIELD_IS_REQUIRED,
  minButtonWidth,
} from '../../const'
import FormInput from '../form-elements/FormInput'
import { object, string, TypeOf } from 'zod'
import { zodResolver } from '@hookform/resolvers/zod/dist/zod'
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form'
import { LoadingButton } from '@mui/lab'
import FormDatePicker from 'components/form-elements/FormDatePicker'

const formSchema = object({
  medicine: string().min(1, FIELD_IS_REQUIRED),
  form: string().optional(),
  dose: string().optional(),
  whenTaken: string().optional(),
  dates: string().optional(),
  directions: string().optional(),
  reason: string().optional(),
  notes: string().optional(),
})

type FormType = TypeOf<typeof formSchema>

interface Props {
  isOpen: boolean
  loading: boolean
  handleClose: () => void
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onSubmit: (value: any) => void
}

const defaultValues = {}

const FormContent = (props: Props) => {
  const { handleClose, loading, onSubmit } = props

  const formSettings = {
    mode: 'onChange' as const,
    resolver: zodResolver(formSchema),
    defaultValues,
  }

  const methods = useForm<FormType>(formSettings)
  const { handleSubmit } = methods

  const onSubmitHandler: SubmitHandler<FormType> = (values: FormType) => {
    onSubmit(values)
  }

  return (
    <Box sx={defaultModalStyle}>
      <Typography variant="h5" mb={2}>
        Add Medicine
      </Typography>
      <FormProvider {...methods}>
        <Box component="form" onSubmit={handleSubmit(onSubmitHandler)} width="100%" noValidate>
          <Stack spacing={1.5}>
            <FormInput label="Medicine" required fullWidth name="medicine" />
            <FormInput label="Form" fullWidth name="form" />
            <FormInput label="Dose" fullWidth name="dose" />
            <FormInput label="When Taken" fullWidth name="whenTaken" />
            <FormDatePicker label="Dates" fullWidth name="dates" />
            <FormInput label="Directions" fullWidth name="directions" />
            <FormInput label="Reason" fullWidth name="reason" />
            <FormInput label="Notes" fullWidth name="notes" />
          </Stack>

          <Stack direction="row" spacing={2} justifyContent="flex-end" sx={{ mt: 4 }}>
            <Button disabled={loading} variant="outlined" onClick={handleClose} sx={minButtonWidth}>
              Close
            </Button>
            <LoadingButton loading={loading} variant="contained" type="submit" sx={minButtonWidth}>
              Create
            </LoadingButton>
          </Stack>
        </Box>
      </FormProvider>
    </Box>
  )
}

const AddMedicineModal = (props: Props) => {
  const { isOpen } = props

  return (
    <Modal sx={defaultModalZIndex} open={isOpen}>
      {isOpen ? <FormContent {...props} /> : <Box />}
    </Modal>
  )
}

export default AddMedicineModal
