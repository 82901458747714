import { Chip, Stack } from '@mui/material'
import { ColDef, ICellRendererParams } from 'ag-grid-community'
import { PLACEHOLDER } from 'components/column-renderers'
import { CSVField } from 'hooks/useExportCSV'
import React from 'react'
import { CohortShort } from 'types'
import { isDefined } from 'utils'
import { CohortsCellRenderer } from '../components/CohortsCellRenderer/CohortsCellRenderer'

export const createCohortsColumn = (field: string): ColDef => {
  return {
    headerName: 'Cohorts',
    field,
    minWidth: 300,
    sortable: false,
    cellRenderer: (props: ICellRendererParams<{ [field: string]: CohortShort[] }>) => {
      const cohorts = props.data?.[field]
      if (isDefined(cohorts)) {
        return <CohortsCellRenderer cohorts={cohorts} />
      }
      return PLACEHOLDER
    },
  }
}

export const createCohortsCsvColumn = (field: string): CSVField => {
  return {
    label: 'Cohorts',
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    value: (data: any) => {
      const cohorts = data?.[field]
      if (isDefined(cohorts)) {
        return cohorts.map((cohort: CohortShort) => cohort.name).join(', ')
      }
      return PLACEHOLDER
    },
  }
}

export const createCohortsNamesColumn = (field: string): ColDef => {
  return {
    headerName: 'Cohorts',
    field,
    cellRenderer: (props: ICellRendererParams<{ [field: string]: string[] }>) => {
      const cohorts = props.data?.[field]
      if (isDefined(cohorts) && cohorts.length > 0) {
        return (
          <Stack direction="row" alignItems="center" spacing={1} height="100%">
            {cohorts.map((cohort) => (
              <Chip key={cohort} label={cohort} />
            ))}
          </Stack>
        )
      }
      return PLACEHOLDER
    },
  }
}

export const createCohortsNamesCsvColumn = (field: string): CSVField => {
  return {
    label: 'Cohorts',
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    value: (data: any) => {
      const cohorts = data?.[field]
      if (isDefined(cohorts)) {
        return cohorts.join(', ')
      }
      return PLACEHOLDER
    },
  }
}
