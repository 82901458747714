import { LabelValue } from 'features/Metadata'
import { useMemo } from 'react'
import { Course, TableResponse } from 'types'

export function useCoursesMetadata(courses?: TableResponse<Course>): LabelValue[] {
  return useMemo(() => {
    if (!courses) {
      return []
    }

    return [
      {
        label: 'Courses Total',
        value: courses.data.count,
      },
      {
        label: 'Courses Published',
        value: courses.data.publishedCount || 0,
      },
    ]
  }, [courses])
}
